import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>
            <Banner
                bgUrl="/images/industries/telecom/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={window.innerWidth > 992 ?
                    "Unveiling Innovative IT Services<br/>for The Telecom Industry "
                    :
                    "Unveiling Innovative IT Services<br/>for The Telecom Industry "}
                content={
                    "We offer telecom IT solutions that help businesses increase revenue, adapt to changing customer needs, and expand into new markets."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"telecome-banner"}
            >

            </Banner>

            <CommonSection className="container telecom-sec-1">
                <div className="row  g-0 cr">

                    <div className="col-lg-6  border">
                        <h3>Enhancing Efficiency for<br />Telecom Industries with<br />Our IT Services</h3>

                        <p>Our IT services help drive efficiency for telecom players by reducing operational costs, improving performance and creating new avenues for growth. We offer an array of services, including network design and optimization, application development and maintenance, and managed services. Our team comprises skilled professionals who possess a vast amount of expertise and knowledge that they bring to every project.
                            <br></br><br></br>By outsourcing their IT needs to us, telecom players can focus on their core business while we take care of the rest.
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <img src="/images/industries/telecom/sec-1.png"></img>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container telecom-sec-2 ">
                <div className="row g-0">
                    <div className="col-lg-6 ">
                        <img src="/images/industries/telecom/sec-2.png"></img>
                    </div>
                    <div className="col-lg-6 border">
                        <h3> ‌Next Generation<br />Networks</h3>

                        <p>The evolution of networks has opened up a plethora of opportunities for the telecommunications industry to take charge and facilitate the transformation of other industries' business processes. With the amalgamation of 5G, the IoT, edge computing, AI, and machine learning technologies, the focus of the new battleground is to connect and monetise a sensor-based world.
                            <br></br><br></br>This integration of advanced technologies has provided a platform for telecom companies to lead the way and enable other industries to reinvent themselves to meet future demands.
                        </p>

                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Our Telecom Offerings</h3>

                        <p>Through our cutting-edge solutions, we aim to digitise every aspect of the Telecom Industry, making it more efficient and accessible to everyone. </p>
                    </div>

                </div>
                <div className="row justify-content-between g-5">
                    <div className="col-md-5">

                        <div
                            className="tab-btn-container"

                        >
                            <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Software Development</button>
                            <button className="tab-btn" onClick={(e) => toggleTab(1)}>Artificial Intelligence
                            </button>
                            <button className="tab-btn" onClick={(e) => toggleTab(2)}>Cybersecurity
                            </button>
                            <button className="tab-btn" onClick={(e) => toggleTab(3)}>Infrastructure Management
                            </button>

                        </div>

                    </div>

                    <div className="col-md-6">


                        <div className="tab-content">
                            <h4>Software Development </h4>

                            <p>Our custom software development service can help telecom companies in several ways. We can develop software that automates various tasks or provides analysis and insights that can help them make better business decisions. We can also develop custom applications that allow telecom companies to offer new and innovative services to their customers.</p>


                        </div>
                        <div className="tab-content">
                            <h4>Artificial Intelligence</h4>

                            <p>We offer comprehensive services to telecom companies on leveraging AI's power to streamline tasks such as predictive maintenance, chatbots, and fraud detection. Our services are tailored to assist telecom companies in quickly adopting and integrating AI into their current infrastructure.</p>


                        </div>
                        <div className="tab-content">
                            <h4>Cybersecurity</h4>

                            <p>We offer a wide range of top-notch cybersecurity services, including risk assessment, incident response, preventative measures, and support services. Our advanced tools and technologies provide telecom companies with peace of mind knowing their networks are secure.</p>


                        </div>
                        <div className="tab-content">
                            <h4>Infrastructure Management </h4>

                            <p>Our expertise lies in providing comprehensive assistance to telecom companies by streamlining the entire network lifecycle. This begins with meticulous planning and deployment, followed by rigorous testing to ensure optimal performance.</p>


                        </div>

                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid telecom-sec-4">
                <div className="container">

                    <div className="row">

                        <div className="row mb-5">

                            <div className="col-lg-6">
                                {window.innerWidth > 992 ?
                                    <h3>Endless Possibilities to Every Segment of The Telecom Sector </h3>
                                    :
                                    <h3>Endless Possibilities to<br />Every Segment of The Telecom Sector </h3>
                                }
                            </div>
                            <div className="col-lg-6">
                                <p className="">With an extensive background in software development, network operations, security, and business processes, we possess a wealth of experience that allows us to deliver exceptional value to your organisation. We are well-equipped to provide effective solutions that meet your unique business needs and help you achieve your goals. </p>

                            </div>
                        </div>

                    </div>


                    <div className="row gapless-box-container g-0">
                        <div className="col-lg-4 col-md-6">
                            <div className="box">
                                <div className="wrapper">
                                    <img src="/images/industries/telecom/sec-4-1.svg"></img>
                                    <h4>Managing Effortlessly </h4>
                                </div>
                                <p>
                                    We have decades of experience in the telecom world, which gives us an edge in building, launching, and sustaining key telco processes. From roaming management to network optimisation, we can manage everything effortlessly.
                                </p>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="box">
                                <div className="wrapper">
                                    <img src="/images/industries/telecom/sec-4-2.svg"></img>
                                    <h4>Ensuring Security </h4>
                                </div>

                                <p>
                                    Our ZOG Global telecom security experts have an eagle eye for anything suspicious going on in your network. Whether it is Voice, Messaging, or Signalling – we cover all aspects to ensure your network's security.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box">
                                <div className="wrapper">
                                    <img src="/images/industries/telecom/sec-4-3.svg"></img>
                                    <h4>Assuring Quality</h4>
                                </div>
                                <p>
                                    We have telco-focused QA experts who can assure the quality and reliability of your services. We know precisely where to look and what to test to ensure that your services are reliable and end-users are satisfied.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>



            </CommonSection >
            <CommonSection className="container telecom-sec-5 technology-sec-6">
                <div className="row g-5">

                    <div className="col-lg-4 responsive-col-gap">
                        <h3>Unlock Your<br />Full Potential with<br />IT Solutions</h3>
                        <a href="/contact">  <button className="btn btn-transparent">

                            Get Started

                            <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span>
                        </button></a>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <h4>Better Customer<br />Experience</h4>
                            <p>The future of networking is about more than just connecting devices. It’s about connecting people to the things and information they need when they need it. It’s about delivering the best possible experience to customers, whether using a business’s network to stream a video, make a purchase, or access critical data.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <h4>Simplify Processes and Reduce Costs</h4>
                            <p>Data-driven automation can replace manual processes and disparate tools, providing a clear and concise way to make sense of data. By automating data-driven processes, organisations can improve efficiency, accuracy and reduce costs.</p>
                        </div>
                    </div>
                </div>
            </CommonSection>

        </>
    )
}

export default QualityAssurance