import React from "react";
import { useEffect, useState } from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import API from "../../API"
import $ from 'jquery';

import ErrorModal from "../../ErrorModal";
import PositiveModal from "../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../FixedOverlayLoadingSpinner"

import { useNavigate } from 'react-router-dom';

const Contact = () => {


  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone_number": "",
    "company_name": "",
    "industry": "",
    "category": "",
    "message": ""
  });
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    if (e.target.name === "category") {

      setFormData({
        ...formData,
        "category": $("#category").val()
      });
    }
    else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }

  };

  const setAgree = (index, e) => {
    console.log("e.target.checked", e.target.checked)
    if (index === 1) {
      if (e.target.checked) {
        setAgree1(true)
      }
      else {
        setAgree1(false)
      }

    }
    else if (index === 2){
      if (e.target.checked) {
        setAgree2(true)
      }
      else {
        setAgree2(false)
      }
    }
  }


  const validateForm = (data) => {
    const errors = {};

    // Validate each field and add errors if necessary
    if (!data.first_name.trim()) {
      errors.first_name = "First name is required.";
    }

    if (!data.last_name.trim()) {
      errors.last_name = "Last name is required.";
    }

    if (!data.phone_number.trim()) {
      errors.phone_number = "Phone number is required.";
    } else if (!/^\d{10}$/.test(data.phone_number)) {
      errors.phone_number = "Phone number must be 10 digits.";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Invalid email address.";
    }

    // if (!data.company_name.trim()) {
    //   errors.company_name = "Company name is required.";
    // }
    if (!data.industry.trim()) {
      errors.industry = "Choose Your Industry.";
    }

    if (data.category === "") {
      errors.category = "Choose a Category.";
    }
    if (!data.message.trim()) {
      errors.message = "Enter your message with atleast 100 Character";
    }
   
    if(!agree1){
      errors.agree1="Please agree the above terms and condition to proceed."
    }
    if(!agree2){
      errors.agree2="Please agree the above terms and condition to proceed."
    }
            return errors;
  };



  const handleSubmit = async (e) => {
    console.log("entered into handle submit")
    e.preventDefault();

    // Validate the form fields
    const validationErrors = validateForm(formData);
    console.log("validation errors", validationErrors)
    console.log("formData", formData)
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Perform the API call
      setIsLoading(true)
      try {
        // Replace the following code with your actual API logic
        const response = await API.post("/contact/data/", formData);
        // Handle the API response as needed
        setIsLoading(false)
        setMessage("Your Message is sent Succesfully! Our team will contact you shortly. Thank you for showing interest in ZOG Global.");
        setIsMessageModalOpen(true)

        window.localStorage.removeItem("emailForSignup")
      } catch (error) {
        // Handle API errors
        setIsLoading(false)

        setMessage(error.message);
        setIsErrorModalOpen(true)
      }
    }
  };

  

  return (

    <>

      <Banner
        bgUrl="/images/contact/banner.png"
        theme="light"
        txtAlign="left"
        paddingProp={{ top: "14", bottom: "2" }}
        heading={"Let’s Work Together"}
        content={
          "Are you ready to maximize your performance? We can help you achieve real results. Please take a moment to fill out the short form so we can route your request to the appropriate person."
        }
        customClassName="contact-banner"
      >

      </Banner>


      <CommonSection className="container contact-sec-1  form-section">
        <form onSubmit={handleSubmit} noValidate>

          <div class="mb-lg-5">

            <div class="row form-row">
              <div class="col-lg-6 mb-3">
                <label for="firstName" class="form-label">First Name</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className={`form-control form-control-lg ${errors.first_name ? "is-invalid" : ""}`}
                  value={formData.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
              </div>

              <div class="col-lg-6 mb-3">
                <label for="lastName" class="form-label">Last Name</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className={`form-control form-control-lg ${errors.last_name ? "is-invalid" : ""}`}
                  value={formData.last_name}
                  onChange={handleChange}
                />

                {errors.first_name && <div className="invalid-feedback">{errors.last_name}</div>}
              </div>
            </div>

          </div>

          <div class="col-lg-12 mb-lg-5">
            <div class="row form-row">
              <div class="col-lg-6 mb-3">
                <label for="emai" class="form-label">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className={`form-control form-control-lg ${errors.email ? "is-invalid" : ""}`}
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
              <div className="col-lg-6 mb-3">
                <label for="phone_number" class="form-label">Phone Number</label>
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  className={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                  value={formData.phone_number}
                  onChange={handleChange}
                />
                {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
              </div>
            </div>
          </div>

          <div class="mb-lg-5">
            <div class="row form-row">
              <div class="col-lg-6 mb-3">
                <label for="emai" class="form-label">Company Name</label>
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  className={`form-control form-control-lg ${errors.company_name ? "is-invalid" : ""}`}
                  value={formData.company_name}
                  onChange={handleChange}
                />
                {errors.company_name && <div className="invalid-feedback">{errors.company_name}</div>}
              </div>
              <div className="col-lg-6 mb-3">
                <label for="industry" class="form-label">Industry</label>
                <input
                  type="text"
                  id="industry"
                  name="industry"
                  className={`form-control form-control-lg ${errors.industry ? "is-invalid" : ""}`}
                  value={formData.industry}
                  onChange={handleChange}
                />
                {errors.industry && <div className="invalid-feedback">{errors.industry}</div>} </div>
            </div>
          </div>

          <div class="mb-lg-5">
            <div class="row form-row">
              <div class="col-lg-12 mb-3">
                <label class="form-check-label" for="catSelect">What Can we Help you with?</label>
                <select class="form-select form-control form-control-lg" id="category" name="category"
                  aria-label="Default select example" onChange={handleChange}>
                  <option value={null} selected>Select</option>
                  <option value="General Enquiry">General Enquiry</option>
                  <option value="Partnership Opportunities">Partnership Opportunities</option>
                  <option value="Service Specific">Service Specific</option>
                  <option value="Areer Opportunities">Areer Opportunities</option>
                </select>
                {errors.category && <div className="invalid-feedback">{errors.category}</div>}

              </div>
            </div>
          </div>

          <div class="mb-lg-5">
            <div class="row form-row">
              <div class="col-lg-12 mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">Message</label>

                <textarea
                  type="text"
                  id="message"
                  name="message"
                  className={`form-control form-control-lg ${errors.message ? "is-invalid" : ""}`}
                  value={formData.message}
                  onChange={handleChange}
                />
                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
              </div>
            </div>

          </div>

          <div className="mb-lg-5">
            <div class="row form-row">
              <div class="col-lg-12 mb-3">
                <div class="form-check d-flex">
                  <input class="form-check-input" type="checkbox" value="" id="agree_checkbox_1" name="agree_checkbox_1" onChange={(e) => setAgree(1, e)} checked={agree1}></input>
                  <label class="form-check-label" for="flexCheckDefault">
                    Yes, I agree with the storage and handling of my data by this website, to receive periodic emails from ZOG Global8 related to products and services and can unsubscribe
                    at any time. I accept ZOG Globa’s <span className="pp">Privacy Policy.</span>
                  </label>
                 
                </div>
                {errors.agree1 && <div className="invalid-feedback">{errors.agree1}</div>}
              </div>
            </div>
          </div>

          <div className="mb-lg-5">
            <div class="row form-row">
              <div class="col-lg-12 mb-3">
                <div class="form-check d-flex">
                  <input class="form-check-input" type="checkbox" value="" id="agree_checkbox_2" name="agree_checkbox_2" onChange={(e) => setAgree(2, e)} checked={agree2}></input>
                  <label class="form-check-label" for="flexCheckDefault" >
                    By clicking submit below, you consent to allow ZOG Global to store and process the personal information submitted above to provide you the content requested.*
                  </label>
                  
                </div>
                {errors.agree2 && <div className="invalid-feedback">{errors.agree2}</div>}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary btn-transparent" type="submit" >Submit<span>
              <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
              </svg>
            </span></button>
          </div>

        </form>
      </CommonSection>

      <CommonSection className="container contact-sec-2 form-section">
        <div className="row">
          <div className="col-lg-12">
            <h3>Our Offices</h3>
            {window.innerWidth > 992 ?
              <p>Our offices in the UK and India are a testament to our global reach and commitment to providing top-notch services to our<br />clients. We have a dedicated team in each location with a wealth of knowledge and expertise in their respective markets.</p>

              : window.innerWidth > 768 ?
                <p>Our offices in the UK and India are a testament to our global reach and commitment to providing top-notch services to our<br />clients. We have a dedicated team in each location with a wealth of knowledge and expertise in their respective markets.</p>
                :
                <p>Our offices in the UK and India are a<br /> testament to our global reach and<br /> commitment to providing top-notch<br /> services to our clients. We have a<br /> dedicated team in each location with<br /> a wealth of knowledge and expertise<br /> in their respective markets.</p>
            }
          </div>

        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="box">
              {window.innerWidth > 768 ?
                <img src="/images/contact/sec-2-1.png"></img>
                :
                <img src="/images/contact/sec-2-1-mob.png" className="w-100"></img>}
              <div className="text-box">

                <span className="time">21:00</span>
                <h4>UK</h4>
                <a href="https://goo.gl/maps/fKxHB5hh2iWmvYUK9">

                  Suite V3, 4 Woodland Road,
                  <br></br>
                  Darlington, DL3 7PJ

                </a>

                <a href="mailto:connect@zogglobal.com">
                  <span><img src="/images/mail-icon.svg"></img></span>connect@zogglobal.com

                </a>

                <a href="tel:+442045424350">
                  <span><img src="/images/phone-icon.svg"></img></span>+44 2045424350
                </a>

                <a href="/" className="direction">Get Direction</a>
              </div>
            </div>

          </div>




        </div>
      </CommonSection >

      <ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate('/sign-up')} />
      <PositiveModal message={message} state={isMessageModalOpen} setterFunction={setIsMessageModalOpen} okClickedFunction={() => navigate('/confirm-mail')} />
      {isLoading && <FixedOverlayLoadingSpinner />}

    </>

  )
};

export default Contact;
