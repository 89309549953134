import React from "react";
import { useEffect, useState } from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import $ from 'jquery';
const AIAutomation = () => {


    const [tabImgUrl, setTabImgUrl] = useState("/images/AI-Automation/sec-2-1.png");
    

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      function toggleTab(index) {
        $(".fading-img").animate({ opacity: "0" }, "fast", function() {
            setTabImgUrl("/images/AI-Automation/sec-2-" + index + ".png");
            });
                
                $(".fading-img").animate({ opacity: "1" }, "fast");
            
        
        }
      

    return (
        <>
            <Banner
                bgUrl="/images/AI-Automation/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={"Make Room for<br/>Innovation by Automating<br/>Repetitive Tasks"}
                content={window.innerWidth>992?
                    "Automating repetitive, complicated tasks requiring human<br/>intervention can save money and time by freeing up more for<br/>developing new processes and enhancing your productivity."
                :window.innerWidth>778?
                "Automating repetitive, complicated tasks<br/>requiring human intervention can save<br/>money and time by freeing up more for<br/>developing new processes and<br/>enhancing your productivity."
           :
           "Automating repetitive,<br/> complicated tasks requiring<br/> human intervention can save<br/>money and time by freeing up<br/> more for developing new<br/> processes and enhancing your<br/> productivity."
        }
                contentWidth='50rem'
                mobileVerticalAlignment="center"
                mobileTextAlign="left"
                customClassName={'ai-automation-banner'}
            >

            </Banner>


            <CommonSection className="container AI-Automation-sec-1">
                <div className="row align-items-center">

                    <div className="col-lg-6 responsive-col-gap">
                    {window.innerWidth>992?
                        <h3>Turn Your Business<br/>Tactically with<br/>Intelligent AI Solutions  </h3>
:window.innerWidth>778?
<h3>Turn Your Business<br/>Tactically with<br/>Intelligent AI Solutions  </h3>
:
<h3>Turn Your Business<br/>Tactically with<br/>Intelligent AI<br/> Solutions  </h3>
}  
{window.innerWidth<778&&<img src="/images/AI-Automation/sec-1.png" className="mb-3"></img>
}
{window.innerWidth>992?
                        <p>ZOG Global aims to enhance customers' and employees' experiences<br/>with innovative automation and robotic solutions through smart<br/>applications.</p>

:window.innerWidth>778?
<p>ZOG Global aims to enhance customers' and employees' experiences<br/>with innovative automation and robotic solutions through smart<br/>applications.</p>

:
<p>ZOG Global aims to enhance<br/> customers' and employees'<br/> experiences with innovative<br/> automation and robotic solutions<br/> through smart applications.</p>

}                         <a href="/contact"><button className="btn btn-transparent">
                            Contact Us
                            <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span>

                        </button></a>
                    </div>

                    {window.innerWidth>778&&<div className="col-lg-6">
                        <img src="/images/AI-Automation/sec-1.png"></img>
                    </div>
}
                </div>
            </CommonSection>

            <CommonSection className="container AI-Automation-sec-2">
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-md-center">Our AI<br/>
                            Services Include </h3>
                    </div>
                </div>
                {window.innerWidth > 768 ?
                    <div className="row">
                        <div className="col-lg-6 col-md-4 tab-btn-container responsive-col-gap">
                            <button onClick={() => toggleTab(1)}>

                                <h5>Machine Learning</h5>

                                <p>We empower your business with machine learning technology that interprets complex data, identifies patterns, and detects trends.</p>

                            </button>

                            <button onClick={() => toggleTab(2)}>

                                <h5>Decision Management</h5>

                                <p>We help businesses manage business decisions with AI-driven algorithms and predictive systems to drive accurate results.</p>

                            </button>
                            <button onClick={() => toggleTab(3)}>

                                <h5>Voice Biometrics</h5>

                                <p> It allows machines to understand what people speak and take appropriate<br/>actions.</p>

                            </button>

                            <button onClick={() => toggleTab(4)}>

                                <h5>Predictive Analysis</h5>

                                <p>Using our solutions, you can predict market changes, analyse sales, and better understand your customers' behaviour.</p>

                            </button>
                        </div>

                        <div className="col-lg-6 col-md-8">
                        <img className="fading-img" src={tabImgUrl}></img>
                        </div>
                    </div> :
                    <div className="non-breaking-mob-container">
                        <div className="row">

                            <div>
                                <img src="/images/AI-Automation/sec-2-1.png"></img>
                                <div className="text-box">
                                    <h5 className="orange-line">Machine Learning</h5>

                                    <p>We empower your business with machine learning technology that interprets complex data, identifies patterns, and detects trends.</p>

                                </div>

                            </div>

                            <div>
                            <img src="/images/AI-Automation/sec-2-2.png"></img>
                                <div className="text-box">
                                    <h5 className="orange-line">Decision Management</h5>

                                    <p>We help businesses manage business decisions with AI-driven algorithms and predictive systems to drive accurate results.</p>
                                </div>
                            </div>
                            <div>
                            <img src="/images/AI-Automation/sec-2-3.png"></img>
                                <div className="text-box"><h5 className="orange-line">Voice Biometrics</h5>

                                    <p> It allows machines to understand what people speak and take appropriate actions.</p>
                                </div>
                            </div>

                            <div>
                            <img src="/images/AI-Automation/sec-2-4.png"></img>
                                <div className="text-box"><h5 className="orange-line">Predictive Analysis</h5>

                                    <p>Using our solutions, you can predict market changes, analyse sales, and better understand your customers' behaviour.</p>
                                </div>
                            </div>



                        </div>

                    </div>

                }
            </CommonSection>

            <CommonSection className="container-fluid AI-Automation-sec-3">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="container">

                            <div className="row">
                                <div className="col-12">
                                    <h3>Automation made<br/> easier with ZOG Global </h3>
                                    <p className="">Start your journey with us today!</p>

                                </div>

                                <div className="col-lg-6 tab-btn-container responsive-col-gap">
                                    <a href="/contact"><button className="btn btn-borderless ps-0">
                                        Talk to Our Experts
                                    </button></a>


                                </div>

                            </div>

                            <div className="row">
                                <h4>Blogs</h4>
                                <p className="shift-p">Everything you need to know about Automation: explore it now. </p>
                                </div>
                                <div className="non-breaking-mob-container">
                                <div className="row">
                                <div className="col-md-4">
                                    <img src="/images/AI-Automation/sec-3-1.png"></img>
                                    <h4>Lörem ipsum heterol jäskade.</h4>
                                </div>

                                <div className="col-md-4">
                                    <img src="/images/AI-Automation/sec-3-2.png"></img>
                                    <h4>Lörem ipsum heterol jäskade.</h4>
                                </div>

                                <div className="col-md-4">
                                    <img src="/images/AI-Automation/sec-3-3.png"></img>
                                    <h4>Lörem ipsum heterol jäskade.</h4>
                                </div>
                            </div>
                            </div>
                        </div></div>
                </div>
            </CommonSection>
            <CommonSection className="container AI-Automation-sec-4">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Benefits of<br/>
                            Intelligent Automation</h3>
                    </div>
                    <div className="col-lg-12">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Speed Up Time-to-Automation
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Quickly develop and deploy automation and estimate their relative impact with an all-in-one platform.</p>       </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Decrease Dependence on Technical Resources
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>With DIY automation flows and AI models, automation and AI are democratized.</p>      </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Invest for the future
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Add sophisticated intelligence to your existing systems by seamlessly integrating with legacy software.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </CommonSection>
        </>
    )
}

export default AIAutomation