import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { useEffect, useState } from "react";
import $ from 'jquery';

const AIAutomation = () => {
    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    function toggleTab(index) {
        setTabSelected(index);

    }

    return (
        <>
            <Banner
                bgUrl="/images/what-we-do/engagement-model/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={window.innerWidth>999?
                    "Let's Work Together to Build Something Amazing!"
                :
                "Let's Work<br/>Together to Build<br/>Something Amazing!"
            }
                content={window.innerWidth>999?
                    "Our flexible engagement models help our clients meet their project's exact requirements."
:
                    "Our flexible engagement models help our clients<br/>meet their project's exact requirements."
                }
            >

            </Banner>


            <CommonSection className="container engagement-model-sec-1">
                <div className="row">


                    <div className="col-lg-12 tab-btn-container responsive-col-gap">
                        <button className="tab-btn active" onClick={(e) => toggleTab(0)}>
                            Fixed Bid (FB)
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(1)}>
                            T&M Model
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>
                            Agile Fixed Price (AFP)
                        </button>

                    </div>


                </div>

                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            <h3>Fixed Bid (FB)</h3>

                        </div>
                        <div className="col-lg-6">
                            <p className="">The cost of building an application is discussed and determined before receiving the project. Cost is usually defined based on the specified work and cannot be changed once the contract is signed. The 'milestones' discussed earlier will be charged, and we expect the full payment upon completion. Ideal for small and emergency project sizes. It works perfectly when the details of the project are clearly stated and adopted. </p>

                        </div>
                    </div>
                </div>

                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            <h3>T&M Model</h3>

                        </div>
                        <div className="col-lg-6">
                            <p className="">The Time & Materials model is an attractive choice in cases where the scope, specification, and implementation requirements cannot be clearly defined at the beginning of the project. According to this model, the client pays agreed costs per hour to developers' and project managers' efforts. For this engagement model, ZOG Global builds project teams and provides the tools and infrastructure to meet project needs. The model offers enough flexibility to balance team size and project workload - and, as a result, budget.</p>

                        </div>
                    </div>
                </div>

                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            <h3>Agile Fixed Price (AFP)</h3>

                        </div>
                        <div className="col-lg-6">
                            <p className="">The Scope divides into active user stories that are typical of agile methodology. When completing each story, the provider is paid the price based on the story size set during the planning session, regardless of the actual effort required. User Stories that are not accepted by the customer will only be paid upon completion. This model shifts the provider's risk at the story level and encourages the provider to work faster.</p>

                        </div>
                    </div>
                </div>

                
                <div className="row g-lg-0 gapless-box-container">
                    <div className="col-lg-4 col-md-6 mb-md-4">
                        <div className="box">
                            <div className="d-md-flex d-lg-block">
                            <img src="/images/what-we-do/engagement-model/sec-1-1.svg"></img>
                            <h4>Security</h4>
                            </div>
                            
                            <p>
                            We ensure high security by using advanced scanning tools and thorough analysis.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-md-4">
                        <div className="box">
                            <div className="d-md-flex  d-lg-block">
                            <img src="/images/what-we-do/engagement-model/sec-1-2.svg"></img>
                            <h4>Budget Estimation</h4>
                            </div> 
                            <p>
                            We offer transparent quotes and precise budget estimates.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-md-4">
                        <div className="box">
                        <div className="d-md-flex d-lg-block">
                        <img src="/images/what-we-do/engagement-model/sec-1-3.svg"></img>
                            <h4>Operational Costs</h4>
                                </div> 
                            <p>
                            Decrease in operational costs and definite quicker returns on investment (RoI).

                            </p>
                        </div>

                    </div>
                </div>



            </CommonSection>
            <CommonSection className="container engagement-model-sec-2">

                <div className="mask ">

                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                                <h3>Invest In People Who Understand Your Goal and Care Deeply About Your Project</h3>
                                <p>In order to find the best workforce for your project, our team will carefully review your requirements. Also, ensure that we understand your end goal and support you throughout.</p>

                            </div>

                            <button className="btn btn-transparent">
                            Talk to an Expert
                                <span>
                                    <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                </span>
                            </button>
                        </div>
                        <div className="col-lg-6"></div>
                    </div>



                </div>

            </CommonSection>
            <CommonSection className="container engagement-model-sec-3">
                <div className="row">
                    <div className="col-lg-6 full-height">
                        <h3>With ZOG Global,<br></br> You Will Get </h3>

                    </div>
                    <div className="col-lg-6">
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Strict security measures</h4>
                               </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Agile software development</h4>
                               </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Uninterrupted communication</h4>
                                 </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Flexibility and scalability will be increased</h4>
                               </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Cost-quality ratio at its best</h4>
                               </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Project management by a dedicated team</h4>
                                 </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>To meet tight deadlines, we have experts</h4>
                               </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>A team dedicated to your business's success</h4>
                               </div>
                        </div>
                        <div className="strip">

                            <div>
                                <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Filling the critical skill gap with support</h4>
                                 </div>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container engagement-model-sec-4">
                <div className="row ">
                    <div className="col-lg-3  responsive-col-gap">
                    <div className="black-box">
                        <h3>Advantages of&nbsp;Our Engagement Model</h3>
                        <p>Our engagement model provides the most convenient way of working on your project.</p>
                    </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="box">
                                    <ul>
                                        <li> Our agile and adaptive engagement models are created to satisfy the various requirements of organisations of all sizes. </li>
                                        <li>We evaluate the different routes and develop a strategic plan to best fit our client’s needs. </li>
                                        <li>We provide objective advice that helps our clients take action in the industry.</li>
                                        <li> We offer partnership business development, investment recommendations, and related analysis to help guide them through the path of expansion or sustainability.</li>
                                         <li>We enable them to achieve their unique goals through our data analysis, operational stand up, partnership, expansion strategy, due diligence, and investment needs services.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box">
                                    <ul>
                                        <li>By implementing Agile practices, we can enhance the project's overall value without causing the completion date to slip.</li>
                                        <li>Allows the client the chance to analyse and organise iterations before launch.</li>
                                        <li>Guaranteed dedication to the project's objectives and schedules.</li>
                                        <li>Enhance the ability of the clients to focus on their core competencies.</li>
                                        <li>Utilize the Agile development technique in its entirety to effectively lead long-lasting projects.</li>
                                        
                                        </ul>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="box">
                                    <p>We can assist you in developing a ready-to-market solution whether you are just starting your product development journey or have already finished some work while focusing on expanding your business. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>

        </>
    )
}

export default AIAutomation