import React from "react";

import { useNavigate } from 'react-router-dom';
<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {

    let navigate = useNavigate();


    return (
        <>
            <div className="full-screen-container full-screen-container-borderless mail-confirmed">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 ">
                        <div className="full-sceen-alert-page-card">

                            <div className="body">
                                <img src="/images/app-logo.svg"></img>
                                <h1 className="title">Hurray!</h1>
                                <span className="bold-span">Welcome to our online grocery store!<br></br>
                                    Shop fresh, quality groceries from home."</span>
                                <button className="btn btn-secondary" onClick={()=>{localStorage.setItem("userRoleRequest", "user"); navigate("/login")}}>Start shopping</button>

                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default View;