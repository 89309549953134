import React from 'react';
import { Modal } from "react-bootstrap";

const PositiveModal = ({ message,title, state, setterFunction, okClickedFunction  }) => {

  const closeModal = () => {
    setterFunction(false);
  }

const okClicked=()=>{
  okClickedFunction();
  setterFunction(false)
}

  return (
    <Modal show={state} className="app-modal positive-modal">
   
    <Modal.Header closeButton onClick={closeModal}>
      <Modal.Title> <h4 className='mb-0'><b>{title?title:"Success"}</b></h4></Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p><b>{message}</b></p>
    </Modal.Body>


    <Modal.Footer>
      <button className='btn btn-danger' onClick={okClicked}>Ok</button>
      
    </Modal.Footer>

  </Modal>
  );
};

export default PositiveModal;
