import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import $ from 'jquery';
import { useEffect, useState } from "react";
const AIAutomation = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    function toggleTab(index) {
        setTabSelected(index);

    }



    return (
        <>
            <Banner
                bgUrl="/images/what-we-do/protect-your-customers-data/banner.png"
                theme="dark"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={"Data Protection Services"}
                content={
                    "Our bespoke solutions are your fortress against data vulnerabilities and breaches, meticulously designed to comply with UK data regulations, including the GDPR."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
            >

            </Banner>


            <CommonSection className="container protect-your-customers-data-sec-1">
                <div className="row">

                    <div className="col-lg-6 responsive-col-gap">
                        <h3 className="orange-line">Why Choose ZOG Global for Your Data Protection Needs? </h3>


                    </div>
                    <div className="col-lg-12">
                        <p>ZOG Global offers unparalleled data protection services in the UK, ensuring your information remains secure and compliant. Here are six compelling reasons why our solutions stand out</p>

                    </div>

                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className="box">
                            <h4>Tailored Compliance </h4>
                            <p>We delve into the specifics of your business to ensure every data protection strategy we implement is compliant and tailored to your industry standards and organisational nuances. This proactive approach guarantees seamless adherence to legal requirements without disrupting your operations.</p>
                        </div>
                        <div className="box">
                            <h4>Advanced Threat Detection</h4>
                            <p>Our systems use advanced technology and tools to search for potential threats and risks proactively. We identify and neutralise emerging risks through sophisticated algorithms and real-time monitoring before they manifest, ensuring a robust defence against evolving cyber threats. This proactive stance keeps your data shielded from even the most sophisticated attacks</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="box">
                            <h4> Customised Solutions</h4>
                            <p>No two businesses are alike, so our data protection approach is highly personalised. We conduct in-depth assessments and analyses to understand your unique business landscape. With this knowledge, we craft bespoke data protection strategies seamlessly integrating with your existing infrastructure. </p>
                        </div>
                        <div className="box">
                            <h4>Ongoing Monitoring </h4>
                            <p>Our commitment to your data security doesn't end after implementation. We provide continuous, vigilant monitoring of your systems. This proactive surveillance ensures that any irregularities or potential breaches are swiftly detected and addressed. This ongoing attention guarantees that your data remains safeguarded and compliant, maintaining the integrity of your operations.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="box">
                            <h4>Expert Guidance </h4>
                            <p>Collaborating with ZOG Global provides you with a chance to leverage a team of experienced professionals highly knowledgeable in the complexities of safeguarding data. We're dedicated to providing comprehensive assistance, ensuring seamless support from the first consultation to execution and ongoing maintenance. Our specialists provide invaluable insights and recommendations, empowering you to make informed decisions and maintain a robust data protection posture.</p>
                        </div>
                        <div className="box">
                            <h4>Customer Trust Assurance </h4>
                            <p>By fortifying your data against breaches and diligently adhering to stringent data protection regulations, we assist in fostering and preserving the trust of your customers. Demonstrating your commitment to their privacy and security enhances your reputation as trustworthy, reinforcing relationships and building credibility within your market. This trust translates into enhanced customer loyalty and confidence in your brand.</p>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container protect-your-customers-data-sec-2">
                <div className="row g-md-2">
                    <div className="col-lg-4 col-7">
                        <div className="box">
                            <div>
                                <h4>Safeguard Your Assets</h4>
                                <h3 className="orange-line">Navigating Digital Privacy and Security in the Modern Era</h3>
                            </div>
                            <p>Data holds immense value in the current digital age. Adequate data protection preserves confidentiality, integrity, and availability. Shielding sensitive information from breaches upholds trust and ensures legal compliance, safeguarding against financial and reputational risks.

                                Data protection fosters a secure environment, mitigating threats of cyberattacks and unauthorised access. Preserving privacy is paramount in maintaining customer loyalty and organisational integrity. Embracing robust data protection measures becomes non-negotiable in safeguarding intellectual property and sensitive client information.
                            </p>

                        </div>
                    </div>

                    <div className="col-lg-8 col-5">
                        <div
                            style={{
                                backgroundImage: " url('/images/what-we-do/protect-your-customers-data/sec-2.png')",
                                backgroundSize: "cover",
                            }}
                        >


                        </div>
                    </div>

                </div>
            </CommonSection>
            <CommonSection className="container protect-your-customers-data-sec-3">
                <div className="row g-0">
                    <div className="col-lg-5 full-height">
                        <div className="box">
                            <h3>Tailored Data Protection Solutions Across Industries </h3>
                            <p>Our adaptable data protection services cater to various industries, ensuring robust security measures aligned with sector-specific needs. </p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="box">
                            <div className="strip">

                                <div>
                                    <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Public Sector </h4>
                                    <p>Protect valuable commercial data with our tailored solutions. From financial institutions to retail, our services fortify data integrity, ensuring compliance and enhancing trust among customers, shareholders, and stakeholders.</p>
                                </div>
                            </div>
                            <div className="strip">

                                <div>
                                    <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>SMEs</h4>
                                    <p>Empower small and medium enterprises with robust data security. Our scalable solutions cater to SMEs' unique needs, providing cost-effective yet comprehensive protection, ensuring compliance, and fostering growth without compromising security.</p>
                                </div>
                            </div>
                            <div className="strip">

                                <div>
                                    <h4><span><img src="/images/what-we-do/protect-your-customers-data/sec-3-1.svg" alt="icon"></img></span>Multinationals</h4>
                                    <p>Secure vast multinational operations with our globally compliant strategies. We align data protection across borders, ensuring regulatory adherence while fortifying sensitive information, bolstering trust, and mitigating risks on a global scale.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Comprehensive Data Protection Services by ZOG Global </h3>

                        <p>Our range of specialised services ensures holistic data security and compliance, safeguarding your valuable assets. </p>
                    </div>

                </div>
                {window.innerWidth>992?
            <div className="row justify-content-between g-5">
            <div className="col-md-5">

                <div
                    className="tab-btn-container"

                >
                    <button className={tabSelected===0?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(0)}>Risk Assessment</button>
                    <button className={tabSelected===1?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(1)}>Encryption Solutions
                    </button>
                    <button className={tabSelected===2?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(2)}>Data Privacy Consulting
                    </button>
                    <button className={tabSelected===3?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(3)}>Incident Response Planning
                    </button>
                    <button className={tabSelected===4?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(4)}>Continuous Monitoring
                    </button>
                    <button className={tabSelected===5?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(5)}>Access Control Solutions
                    </button>
                    <button className={tabSelected===6?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(6)}>Compliance Audits
                    </button>


                </div>

            </div>

            <div className="col-md-6">


                <div className="tab-content">
                    <h4>Risk Assessment </h4>
                    <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                    <p>Identify vulnerabilities and assess potential risks within your data infrastructure. Our comprehensive analysis provides insights to fortify defences, ensuring proactive mitigation strategies against potential threats.</p>


                </div>
                <div className="tab-content">
                    <h4>Encryption Solutions </h4>
                    <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                    <p>Implement robust encryption protocols to secure sensitive data. Our encryption services ensure that your information remains unintelligible to unauthorised users, fortifying your data against breaches or unauthorised access.</p>


                </div>
                <div className="tab-content">
                    <h4>Data Privacy Consulting </h4>
                    <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                    <p>Rely on our expertise to navigate complex data privacy regulations. Our consulting services offer guidance in aligning your practices with legal frameworks, ensuring compliance and fostering a privacy-centric culture within your organisation.</p>


                </div>
                <div className="tab-content">
                    <h4>Incident Response Planning </h4>
                    <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                    <p>Prepare for potential data breaches with our incident response planning. We develop tailored strategies to respond to security incidents swiftly, minimising their impact and ensuring a timely and effective resolution.</p>


                </div>
                <div className="tab-content">
                    <h4>Continuous Monitoring</h4>
                    <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                    <p>Our vigilant monitoring services offer real-time oversight of your data environment. Through continuous surveillance, we detect anomalies and potential threats, enabling proactive intervention to maintain data integrity and compliance.</p>


                </div>
                <div className="tab-content">
                    <h4>Access Control Solutions </h4>
                    <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                    <p>Implement robust access control measures to manage data accessibility. Our solutions ensure that only authorised personnel can access specific information, reducing the risk of internal breaches or unauthorised data manipulation.</p>


                </div>
                <div className="tab-content">
                    <h4>Compliance Audits </h4>
                    <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                    <p>Validate your adherence to data protection regulations through our compliance audits. We conduct thorough assessments, identify gaps, and recommend corrective measures to ensure ongoing compliance and minimise legal risks.</p>


                </div>
            </div>
        </div>
        :
        <div className="row justify-content-between g-5">
                    <div className="col-12">

                        <div
                            className="tab-btn-container"

                        >
                            <button className={tabSelected===0?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(0)}>Risk Assessment</button>
                           
                            <div className="tab-content">
                            <h4>Risk Assessment </h4>
                            <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                            <p>Identify vulnerabilities and assess potential risks within your data infrastructure. Our comprehensive analysis provides insights to fortify defences, ensuring proactive mitigation strategies against potential threats.</p>
                        </div>
                        
                        
                        <button className={tabSelected===1?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(1)}>Encryption Solutions
                            </button>
                            <div className="tab-content">
                            <h4>Encryption Solutions </h4>
                            <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                            <p>Implement robust encryption protocols to secure sensitive data. Our encryption services ensure that your information remains unintelligible to unauthorised users, fortifying your data against breaches or unauthorised access.</p>
                        </div>



                            <button className={tabSelected===2?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(2)}>Data Privacy Consulting
                            </button>

                            <div className="tab-content">
                            <h4>Data Privacy Consulting </h4>
                            <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                            <p>Rely on our expertise to navigate complex data privacy regulations. Our consulting services offer guidance in aligning your practices with legal frameworks, ensuring compliance and fostering a privacy-centric culture within your organisation.</p>
                        </div>



                            <button className={tabSelected===3?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(3)}>Incident Response Planning
                            </button>

                            <div className="tab-content">
                            <h4>Incident Response Planning </h4>
                            <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                            <p>Prepare for potential data breaches with our incident response planning. We develop tailored strategies to respond to security incidents swiftly, minimising their impact and ensuring a timely and effective resolution.</p>
                        </div>


                            <button className={tabSelected===4?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(4)}>Continuous Monitoring
                            </button>

                            <div className="tab-content">
                            <h4>Continuous Monitoring</h4>
                            <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                            <p>Our vigilant monitoring services offer real-time oversight of your data environment. Through continuous surveillance, we detect anomalies and potential threats, enabling proactive intervention to maintain data integrity and compliance.</p>
                        </div>


                            <button className={tabSelected===5?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(5)}>Access Control Solutions
                            </button>


                            <div className="tab-content">
                            <h4>Access Control Solutions </h4>
                            <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                            <p>Implement robust access control measures to manage data accessibility. Our solutions ensure that only authorised personnel can access specific information, reducing the risk of internal breaches or unauthorised data manipulation.</p>
                        </div>


                            <button className={tabSelected===6?"tab-btn active":"tab-btn"} onClick={(e) => toggleTab(6)}>Compliance Audits
                            </button>

                            <div className="tab-content">
                            <h4>Compliance Audits </h4>
                            <img src="/images/what-we-do/protect-your-customers-data/sec-4-1.png"></img>
                            <p>Validate your adherence to data protection regulations through our compliance audits. We conduct thorough assessments, identify gaps, and recommend corrective measures to ensure ongoing compliance and minimise legal risks.</p>
                        </div>
                        </div>

                    </div>

                    <div className="col-md-6">


                        
                        
                        
                        
                      
                        
                  
                    </div>
                </div>    
            }
            </CommonSection>
            <CommonSection className="container protect-your-customers-data-sec-5">
                <div className="row g-0">
                    <div className="col-lg-8">
                        <img src="/images/what-we-do/protect-your-customers-data/sec-5.png"></img>
                    </div>
                    <div className="col-lg-4">
                        <div className="box">
                            <h3>Secure Your Data Today</h3>
                            <p>Ready to fortify your data security? Our experts are here to assist you. Schedule a consultation and ensure robust protection for your valuable assets. </p>
                            <button className="btn btn-primary btn-transparent">
                            Talk to an Expert 
                                <span>
                                    <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                </span>
                            </button>
                        </div>

                    </div>
                </div>
            </CommonSection>
        </>
    )
}

export default AIAutomation