import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    function toggleTab(index) {
        setTabSelected(index);

    }


    function prevClicked() {
        if (tabSelected == 0) {
            setTabSelected(4);
        }
        else
            setTabSelected(tabSelected - 1);
    }

    function nextClicked() {
        if (tabSelected == 4) {
            setTabSelected(0);
        }
        else
            setTabSelected(tabSelected + 1);
    }

    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    const settings2 = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                
          slidesToShow: 2,
          slidesToScroll: 1,
                }
              },
        ]
    };


    return (
        <>


            {window.innerWidth > 992 ? <CommonSection className="container de-banner automation-testing-banner">
                <div className="row">

                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Automation Testing Services </h3>

                        <p>Ensure the utmost quality of your software with our automated testing services. We specialise in rapid yet accurate test results that guarantee your application's sustainability, stability, functionality, and user-friendliness. </p>
                    </div>
                    <div className="col-lg-6">
                        <img src="/images/quality-assurance/automation-testing/banner.png"></img>
                    </div>

                </div>

            </CommonSection>
                :
                <Banner
                    bgUrl="/images/quality-assurance/automation-testing/banner.png"
                    theme="light"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"Automation Testing Services "}
                    content={
                        "Ensure the utmost quality of your software with our automated testing services. We specialise in rapid yet accurate test results that guarantee your application's sustainability, stability, functionality, and user-friendliness. "
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-end"
                    mobileTextAlign="left"
                >

                </Banner>}

            <CommonSection className="container ">
                <div className="row align-items-center g-5">
                    <div className="col-lg-6 ">
                    {window.innerWidth > 992 ? <img className="w-100" src="/images/quality-assurance/automation-testing/sec-1.png"></img>
                            : <img className="w-100" src="/images/quality-assurance/automation-testing/sec-1-tab.png"></img>
                        }
                    </div>
                    <div className="col-lg-6 ps-lg-5">
                        <h3> Our Approach to Provide Outstanding Outcomes </h3>

                        <p><b>Our expertise in automation testing services is unmatched, and we have perfected our process to get the best possible results for every project. Our hiring process is rigorous, ensuring that we only work with the most diligent and experienced automation QA engineers.  </b>
                            <br></br> <br></br>
                            In addition, we provide robust mentorship and training programmes to ensure that our team members are continuously informed about the latest trends and best practices in the field. Our dedication to quality is unwavering, and we go above and beyond to ensure that we meet project objectives and exceed client expectations.
                            <br></br> <br></br>
                            Our dedication to excellence has enabled us to become experts in our field and develop solutions tailored to your needs. You can be confident to go above and beyond your expectations and provide exceptional outcomes for your project. </p>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container automation-testing-sec-2">
                <div className="row">
{window.innerWidth>992?
                    <h3>Automation Testing Services We Provide</h3>
:
<h3>Automation Testing Services<br/>We Provide</h3>
}                </div>

                <div className="non-breaking-mob-container">
                <div className="row gapless-box-container g-0">
                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-2.svg"></img>
                            <h4>Unit Testing </h4>
                            <p>It is done to verify if each unit or component of the software is properly working as per the requirement. This type of testing is done early in the development process to catch any issues before they expand into more significant problems. </p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                        <img src="/images/quality-assurance/automation-testing/sec-2.svg"></img>

                            <h4>Integration Testing </h4>
                            <p>Integration testing is a type of automation testing that checks the interaction between different modules or parts of the software. This type of testing verifies that each module or part of the software is functioning correctly and that they are adequately integrated. </p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                        <img src="/images/quality-assurance/automation-testing/sec-2.svg"></img>

                            <h4>Regression Testing </h4>
                            <p>This testing is conducted to confirm that the software functions as intended, even after modifications have been made to it. Regression testing is important to ensure that new features or updates added do not break any existing features or functionality. </p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                        <img src="/images/quality-assurance/automation-testing/sec-2.svg"></img>

                            <h4>API Testing </h4>
                            <p>API testing is a type of automation testing that focuses on the software's application programming interface (API). API testing validates the communication between different software components by checking if data is accurately transmitted between components. </p>
                        </div>
                    </div>
                </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid technology-sec-5 automation-testing-sec-3">
                <div className="container">
                    <div className="row">
                        <div className="col-kg-6">
                            <h3>Frameworks We Use <br />for Automation Testing </h3>
                        </div>
                    </div>
                </div>
                <Slider {...settings2} className="simple-slider">
                    <div className="slide">
                        <img src="/images/quality-assurance/automation-testing/sec-3-1.png"></img>
                        <h4>Linear Automation Testing Framework </h4>
                        <p>Linear Automation Testing Framework improves testing efficiency by automating repetitive tasks, allowing testers to focus on more challenging activities and ensuring the timely delivery of high-quality software applications. </p>
                    </div>

                    <div className="slide">
                        <img src="/images/quality-assurance/automation-testing/sec-3-2.png"></img>
                        <h4>Data-Driven Automation Testing Framework  </h4>
                        <p>Data-driven testing framework allows testers to efficiently test multiple scenarios using external data sources, reducing the need for modifying underlying testing code. This ensures that the application meets the expected results with minimum effort. </p>
                    </div>

                    <div className="slide">
                        <img src="/images/quality-assurance/automation-testing/sec-3-3.png"></img>
                        <h4>Hybrid Framework </h4>
                        <p>The hybrid framework combines keyword-driven and data-driven frameworks for simplified test case creation, execution, and maintenance. It allows for easily updated and reusable modules and is scalable for multiple applications and environments. </p>
                    </div>

                    <div className="slide">
                        <img src="/images/quality-assurance/automation-testing/sec-3-4.png"></img>
                        <h4>Keyword-Driven Testing Framework  </h4>
                        <p>This testing method uses keywords to create test scripts in a table format. As a result, it simplifies test cases, making them easy to understand and maintain. In addition, it reduces the time taken for test development, execution, and reporting and allows for easy updates. </p>
                    </div>


                </Slider>
            </CommonSection>

            <CommonSection className="container industries-sec-3 automation-testing-sec-4">
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
{window.innerWidth>992?
                        <h3>Why Choose Us For<br></br> Automation Testing</h3>
:
<h3>Why Choose Us<br></br> For Automation Testing</h3>
}                    </div>
                </div>
                <div className="row g-0 gapless-box-container">

                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Boosting Confidence in Software Quality</p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Accelerating Time to Market  </p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Cutting Testing Costs  </p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Ensuring Consistent and Repeatable Testing  </p>
                        </div>

                    </div>

                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Enabling Unattended Test Runs  </p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Identifying Regression Bugs</p>
                        </div>

                    </div>
                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Increasing Testing Frequency  </p>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Enhancing Test Coverage </p>
                        </div>
                    </div>
                </div >

            </CommonSection >

            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2 automation-testing-sec-5">
                <div className="row align-items-center g-0">
                    <div className="col-lg-5">
                        <img src="/images/digital-engineering/testing/sec-2.png"></img>
                    </div>
                    <div className="col-lg-7 responsive-col-gap border">
{window.innerWidth>992?
                        <h3> Benefits of Automation Testing </h3>

:
<h3> Benefits of<br/>Automation Testing </h3>
}
                        <p className="bold">Test automation offers many advantages over manual testing, including the ability to execute thousands of varied and lengthy test cases in a short period. Let's take a closer look at the numerous benefits of automated testing. </p>
                        <ul>
                            <li><b>Improved Efficiency</b> - Automation testing can perform multiple tests simultaneously, reducing the time and errors that manual testing could introduce. </li>
                            <li><b>Consistency and Reliability</b> - Automation tests ensure that the same test is executed every time and the result is always consistent, eliminating the possibility of human errors and inconsistencies. </li>
                            <li><b>Improved Quality</b> - By performing automated tests, software developers can identify defects and bugs earlier in the development cycle, so they can fix them before they become significant issues. </li>
                            <li><b>Cost-Effective</b> - It is cost-effective as automation testing eliminates the cost of hiring and training a team for manual testing and reduces the need for hardware infrastructure. </li>
                            <li><b>Increased Test Coverage</b> - It enables developers to run more comprehensive tests by covering the crucial parts of an application and all the functionality without interruption. </li>
                            <li><b>Scalability</b> - Automation tests enable software developers to scale up testing by testing the application on different platforms, operating systems, and devices. </li>



                        </ul>
                    </div>


                </div>
            </CommonSection>

            <CommonSection className="container managed-it-services-sec-2 automation-testing-sec-6">

            {window.innerWidth>992?
            <div className="row">
            <div className="row mb-5">
                <div className="col-lg-12 ">

                    <h3 className="text-center">Onboarding Journey of Automation Testing Project </h3>

                </div>
            </div>
            <div className="col-md-3">

                <div className="tab-btn-container">

                    <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Orientation and training </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(1)}>Requirement analysis
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(2)}>Test planning
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(3)}>Test execution
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(4)}>Test reporting
                    </button>

                </div>

            </div>

            <div className="col-md-9">


                <div className="tab-content">
                    <div className="box">
                        <div className="row">

                            <div className="col-lg-5">
                                <img src="/images/quality-assurance/automation-testing/sec-6-1.svg"></img>

                            </div>
                            <div className="col-lg-7">
                                <h4>ORIENTATION AND TRAINING </h4>
                                <p>The first step in onboarding for automation testing is to conduct an orientation and training session for the team, briefing them on the project's objectives, goals, expectations, and testing tools.  </p>

                            </div>
                        </div>
                    </div>
                </div>



                <div className="tab-content">
                    <div className="box">
                        <div className="row">

                            <div className="col-lg-5">
                                <img src="/images/quality-assurance/automation-testing/sec-6-2.svg"></img>

                            </div>
                            <div className="col-lg-7">
                                <h4>REQUIREMENT ANALYSIS  </h4>
                                <p>The second step in the onboarding journey should involve a detailed requirement analysis. This includes identifying key features, determining the types of tests that need to be conducted, and identifying the necessary tools to execute these tests. </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="tab-content">
                    <div className="box">
                        <div className="row">

                            <div className="col-lg-5">
                                <img src="/images/quality-assurance/automation-testing/sec-6-3.svg"></img>

                            </div>
                            <div className="col-lg-7">
                                <h4>TEST PLANNING </h4>
                                <p>After finalising the requirements, the team needs to begin planning and designing the test cases. The test plan must contain a thorough explanation of each test case, the anticipated results, the pass/fail standards, and the deadlines for completing the tests. </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="tab-content">
                    <div className="box">
                        <div className="row">

                            <div className="col-lg-5">
                                <img src="/images/quality-assurance/automation-testing/sec-6-4.svg"></img>

                            </div>
                            <div className="col-lg-7">
                                <h4>TEST EXECUTION  </h4>
                                <p>The fourth step involves executing the test cases according to the test plan. It is essential to monitor the testing progress regularly and maintain communication between team members to keep track of any issues and ensure that the project stays on schedule.  </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="tab-content">
                    <div className="box">
                        <div className="row">

                            <div className="col-lg-5">
                                <img src="/images/quality-assurance/automation-testing/sec-6-5.svg"></img>

                            </div>
                            <div className="col-lg-7">
                                <h4>TEST REPORTING  </h4>
                                <p>As the last step of the onboarding process, it is essential to carefully examine and document the outcomes of the software application testing. The team should prepare a comprehensive report that assesses the overall quality of the software and identifies any problems or glitches detected. </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="pagination">
                    <button className="slider-prev" onClick={(e) => prevClicked()}></button>
                    <span id="current-slide">0{tabSelected + 1}</span> /0<span id="total-slides">4</span>
                    <button className="slider-next" onClick={(e) => nextClicked()}></button>

                </div>
            </div>
        </div>

        :
        <div className="row">
                    <div className="row mb-5">
                        <div className="col-lg-12 ">

<h3 className="text-center">Onboarding Journey of<br/>Automation Testing Project </h3>

                        </div>
                    </div>
                    <div className="col-12">

                        <div className="tab-btn-container">

                            <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Orientation and training </button>
                            
                            <div className="tab-content">
                            <div className="box">
                                <div className="row">

                                    <div className="col-lg-5">
                                        <img src="/images/quality-assurance/automation-testing/sec-6.svg"></img>

                                    </div>
                                    <div className="col-lg-7">
                                        <h4>ORIENTATION AND TRAINING </h4>
                                        <p>The first step in onboarding for automation testing is to conduct an orientation and training session for the team, briefing them on the project's objectives, goals, expectations, and testing tools.  </p>

                                    </div>
                                </div>
                            </div>
                        </div>



                       
                       <button className="tab-btn" onClick={(e) => toggleTab(1)}>Requirement analysis
                            </button>

                            <div className="tab-content">
                            <div className="box">
                                <div className="row">

                                    <div className="col-lg-5">
                                        <img src="/images/quality-assurance/automation-testing/sec-6.svg"></img>

                                    </div>
                                    <div className="col-lg-7">
                                        <h4>REQUIREMENT ANALYSIS  </h4>
                                        <p>The second step in the onboarding journey should involve a detailed requirement analysis. This includes identifying key features, determining the types of tests that need to be conducted, and identifying the necessary tools to execute these tests. </p>

                                    </div>
                                </div>
                            </div>
                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(2)}>Test planning
                            </button>

                            <div className="tab-content">
                            <div className="box">
                                <div className="row">

                                    <div className="col-lg-5">
                                        <img src="/images/quality-assurance/automation-testing/sec-6.svg"></img>

                                    </div>
                                    <div className="col-lg-7">
                                        <h4>TEST PLANNING </h4>
                                        <p>After finalising the requirements, the team needs to begin planning and designing the test cases. The test plan must contain a thorough explanation of each test case, the anticipated results, the pass/fail standards, and the deadlines for completing the tests. </p>

                                    </div>
                                </div>
                            </div>
                        </div>


                       
                            <button className="tab-btn" onClick={(e) => toggleTab(3)}>Test execution
                            </button>

                            <div className="tab-content">
                            <div className="box">
                                <div className="row">

                                    <div className="col-lg-5">
                                        <img src="/images/quality-assurance/automation-testing/sec-6.svg"></img>

                                    </div>
                                    <div className="col-lg-7">
                                        <h4>TEST EXECUTION  </h4>
                                        <p>The fourth step involves executing the test cases according to the test plan. It is essential to monitor the testing progress regularly and maintain communication between team members to keep track of any issues and ensure that the project stays on schedule.  </p>

                                    </div>
                                </div>
                            </div>
                        </div>


                        
                            <button className="tab-btn" onClick={(e) => toggleTab(4)}>Test reporting
                            </button>


                            <div className="tab-content">
                            <div className="box">
                                <div className="row">

                                    <div className="col-lg-5">
                                        <img src="/images/quality-assurance/automation-testing/sec-6.svg"></img>

                                    </div>
                                    <div className="col-lg-7">
                                        <h4>TEST REPORTING  </h4>
                                        <p>As the last step of the onboarding process, it is essential to carefully examine and document the outcomes of the software application testing. The team should prepare a comprehensive report that assesses the overall quality of the software and identifies any problems or glitches detected. </p>

                                    </div>
                                </div>
                            </div>
                        </div>


                        
                        </div>

                    </div>

                    <div className="col-md-9">


                        


                         <div class="pagination">
                            <button className="slider-prev" onClick={(e) => prevClicked()}></button>
                            <span id="current-slide">0{tabSelected + 1}</span> /0<span id="total-slides">4</span>
                            <button className="slider-next" onClick={(e) => nextClicked()}></button>

                        </div>
                    </div>
                </div>

}
            </CommonSection>

            <CommonSection className="container-fluid  devsecops-sec-4 automation-testing-sec-7">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/quality-assurance/automation-testing/sec-7-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>ENSURE YOUR PROJECT'S SUCCESS WITH OUR AUTOMATION TESTING SERVICES  </h3>
                                        <p>We can provide you with a comprehensive suite of automation testing tools, and services customised to fit your needs. Reach out to us today and learn more about how our services can ensure the success of your project.  </p>

                                        <button className="btn btn-transparent">
                                            TALK TO US
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                                    <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/quality-assurance/automation-testing/sec-7-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>CLOSE COLLABORATION WITH THE DEVELOPMENT TEAM  </h3>
                                        <p>Our team of test automation engineers collaborates closely with your development and quality assurance teams to streamline testing efforts. </p>
                                        <button className="btn btn-transparent">
                                            TALK TO AN EXPERT
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                                    <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                                </svg>
                                            </span>
                                        </button>

                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Slider>
            </CommonSection>



        </>
    )
}

export default QualityAssurance