import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    return (
        <>
            <Banner
                bgUrl="/images/industries/education/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={window.innerWidth > 992 ? "A Future-Oriented Approach<br/> to Education"
                    :
                    "A Future-Oriented<br/>Approach to Education"}
                content={
                    "The education sector is rapidly evolving and adopting new technologies to remain competitive. As a result, organisations are looking for innovative IT solutions that can streamline operations and improve user adoption."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
            >

            </Banner>
            <CommonSection className="container telecom-sec-2 education-sec-1 ">
                <div className="row align-items-center g-0">
                    <div className="col-lg-6 ">
                        <img src="/images/industries/education/sec-1.png"></img>
                    </div>
                    <div className="col-lg-6 border">
                        <h3> Transforming Education in the Digital Era</h3>

                        <p>Educational technology management presents a continuous challenge for educational institutions, with the need to keep up with ever-evolving student-oriented learning systems. IT teams are particularly hard-pressed to allocate time and resources in order to remain current.

                            As the digital world evolves, security and compliance have become increasingly important considerations for administrators and researchers. In order to maximise their technology investments, our team helps schools and universities build safe, reliable cloud infrastructures. ZOG Global is dedicated to providing world-class software, cloud services, and managed solutions to educational institutions and research organisations.
                        </p>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 ">
                        <h3>Our Service Offerings</h3>

                        <p>Maximise the power of digital learning with our innovative solutions! Our comprehensive approach takes educational institutions to a new dimension.  </p>
                    </div>

                </div>
                {window.innerWidth > 992 ?
                    <div className="row justify-content-between g-5">
                        <div className="col-md-5">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Digital Transformation</button>
                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Application Development
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Managed Cloud Services
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(3)}>Product Engineering

                                </button>

                            </div>

                        </div>

                        <div className="col-md-6">


                            <div className="tab-content">
                                <h4>Digital Transformation</h4>

                                <p>In an era of digital transformation, embracing more ingenious innovation is key to staying ahead of the curve. The power of digital technology can help you drive efficiency, productivity, and growth. It can also help you create new experiences and services that connect you with your customers in new ways.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Application Development </h4>

                                <p>Legacy applications can create more value for businesses through modernization and optimization. By using the newest capabilities and features, older technology systems can be modernized to better align with current solutions. This allows organizations to gain access to the latest available features, enabling a higher return on investment from legacy applications.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Managed Cloud Services </h4>

                                <p>We will provide you with the knowledgeable assistance needed when transitioning and managing your applications on one of the leading cloud platforms: AWS, Azure, or Google Cloud. We specialize in integrating these industry-standard solutions into your workflow so that you can run your business quickly and efficiently.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Product Engineering</h4>

                                <p>Our experienced engineering team uses DevOps and an agile method to develop your digital product, allowing for fast time-to-market proficiency. Benefit from our engineering excellence and get the quality product you deserve.</p>


                            </div>

                        </div>
                    </div>
                    :
                    <div className="row justify-content-between g-5">
                        <div className="col-12">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Digital Transformation</button>
                                <div className="tab-content">
                                    <h4>Digital Transformation</h4>
                                    <p>In an era of digital transformation, embracing more ingenious innovation is key to staying ahead of the curve. The power of digital technology can help you drive efficiency, productivity, and growth. It can also help you create new experiences and services that connect you with your customers in new ways.</p>
                                </div>
                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Application Development
                                </button>
                                <div className="tab-content">
                                    <h4>Application Development </h4>
                                    <p>Legacy applications can create more value for businesses through modernization and optimization. By using the newest capabilities and features, older technology systems can be modernized to better align with current solutions. This allows organizations to gain access to the latest available features, enabling a higher return on investment from legacy applications.</p>
                                </div>
                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Managed Cloud Services
                                </button>
                                <div className="tab-content">
                                    <h4>Managed Cloud Services </h4>
                                    <p>We will provide you with the knowledgeable assistance needed when transitioning and managing your applications on one of the leading cloud platforms: AWS, Azure, or Google Cloud. We specialize in integrating these industry-standard solutions into your workflow so that you can run your business quickly and efficiently.</p>
                                </div>
                                <button className="tab-btn" onClick={(e) => toggleTab(3)}>Product Engineering
                                </button>
                                <div className="tab-content">
                                    <h4>Product Engineering</h4>
                                    <p>Our experienced engineering team uses DevOps and an agile method to develop your digital product, allowing for fast time-to-market proficiency. Benefit from our engineering excellence and get the quality product you deserve.</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-6">







                        </div>
                    </div>}
            </CommonSection>

            <CommonSection className="container healthcare-sec-1 education-sec-3">
                <div className="row g-5" >
                    <div className="col-lg-6 img-box">
                        <div className="mask">
                            <div>
                                <h3>A Reliable Technology Partner</h3>
                                <p>Break through the barriers of industry disruption and take advantage of untapped markets with the help of our technology-based solutions and professional guidance. </p>
                            </div>
                            <a href="/contact"> <button className="btn btn-borderless btn-icon-changer-white discover-more-btn">
                                Discover more

                            </button></a>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <diV className="gapless-box-container">
                            <div className="my-row">
                                <div className="col-lg-6">
                                    <img src="/images/industries/education/sec-3-1.svg"></img>
                                    <h4>Reduce Production Costs </h4>
                                    <p>Information technology and e-learning tools can reduce expenditures for educational institutions. This can free up funds, allowing them to focus on areas such as the provision of teaching materials, stationery supplies, and upkeep of facilities.</p>
                                </div>

                                <div className="col-lg-6">
                                    <img src="/images/industries/education/sec-3-2.svg"></img>
                                    <h4>Enhance User Experience</h4>
                                    <p>Utilizing state-of-the-art IT technologies can provide students with unique content and graphics, which will vastly improve their learning process. Such technology will create a more engaging, interactive environment that enhances the student's educational experience.</p>
                                </div>
                            </div>
                            <div className="my-row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <img src="/images/industries/education/sec-3-3.svg" className="me-3"></img>
                                        <h4>Improved Outcomes</h4>
                                    </div>
                                    <p>Integrating personalized e-learning content into the classroom has been demonstrated to be an effective means for teachers to identify and address student learning difficulties. Through improved content presentation quality and increased engagement, learners experience more excellent retention of educational material.</p>

                                </div>
                            </div>

                        </diV>

                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container  education-sec-4">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Related Insights</h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance