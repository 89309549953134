import React from "react";
import { useEffect, useState } from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";

const WhatWeDo = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    return (
        <>
            <Banner
                bgUrl="/images/what-we-do/transform-your-business/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "21", bottom: "2" }}
                heading={window.innerWidth>992?" Defining the<br/>Future of<br/>Enterprises":" Defining the<br/>Future of Enterprises"}
                content={
                    " "
                }
            >

            </Banner>

            <CommonSection
                className="container tyb-sec-1"
            >
                <div className="row justify-content-center">
                    
                    <div className="col-lg-6">
                        <p>Predicting the future is impossible, and we can’t live in fear. Instead, businesses must see uncertainty as an opportunity. Together, we can transform businesses into resilient structures through technology.</p>
                        <p><b>Having a strategic vision for where you want to go can be challenging. However, simply stating a transformation does not cause it to occur. And even if you invest, you may still need to realise its total value.</b> </p>
                        <p>A strategy tied to value and differentiating competencies and an operating model that will support that strategy—one that is people-led and tech-empowered—will result in sustained outcomes. ZOG Global's enthusiastic and creative network of problem solvers helps ideally prepare businesses for today and the future.</p>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container tyb-sec-2">
                <div className="row">
                    <div className="col-lg-5 responsive-col-gap">

                        <h3>Digital<br/>Transformation<br/>at a Glance</h3>
                    </div>
                    <div className="col-lg-7 non-breaking-mob-container">
                        <div className="row">
                            <div className="col-6 ">
                                <div className="box">
                                    <div className="special-border">
                                        <h4>Cloud Journey</h4>
                                    </div>

                                    <p>We drive you to go beyond and elevate your workloads in the cloud. We will help you throughout your cloud journey.
                                    </p>
                                    <ul>
                                        <li>Process
                                        </li>
                                        <li>Migration
                                        </li>
                                        <li>Cloud Elevation</li>
                                    </ul>
                                </div>
                                <div className="box">
                                    <div className="special-border">
                                        <h4>Infra Assessment</h4>
                                    </div>
                                    <p>We evaluate the digital maturity of our client’s infrastructure elements and design a roadmap toward digital excellence.
                                        </p>
                                        <ul>
                                            <li>Infrastructure & Network Environment</li>
                                            <li>Strategic Cloud Migration</li>
                                     <li>   Automation Tools, Users, and Applications</li>
                                        </ul>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="box">
                                    <div className="special-border">
                                        <h4>Service Integration</h4>
                                    </div>
                                    <p>Our integration service professionals are committed to designing and assuring your existing automation and management systems are respected and leveraged.
                                       </p>
                                       <ul>
                                        <li> Separating from a parent firm</li>
                                       <li> Rise of standalone environment</li>
                                      <li>  Integration with any 3rd Party</li>
                                     <li>   Calibration of processes and protocols</li>
                                       </ul>
                                </div>
                                <div className="box">
                                    <div className="special-border">
                                        <h4>Managed Infra Services </h4>
                                    </div>
                                    <p>We evolve a part of your existing team and conduct daily operations of your IT infrastructure from remote
                                       </p>
                                       <ul>
                                        <li> Data Centre</li>
                                        <li>  Network</li>
                                        <li>Servers</li>
                                        <li>Storage</li>
                                        <li>Security</li>
                                        <li>Applications</li>
                                       </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container tyb-sec-3">
                <div className="mask">
                    <div className="row">
                        <div className="col-lg-5">
                            <h3>How We Shield Your Business and IT Infrastructure</h3>
                        </div>
                        <div className="col-lg-7">
                            <ul>
                          

                                <li>Incident Management & Support
                                </li>
                                <li>Dedicated Security Consultant

                                </li>
                                <li> High Performance & Scalable Results</li>

                                <li>Competitively Priced Solutions
                                </li>
                                <li>End-to-End Security and Protection  
                                </li>
                                <li>24×7 SOC Monitoring Analysts
                                </li>
                                <li>Real-time Incident Validation
                                </li>
                                <li>Intelligent Threat Monitoring</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </CommonSection>
            <CommonSection className="container tyb-sec-4">
            <div className="row">
                    <div className="col-lg-8">
                        <h3>Competitive Dynamic Business<br/>Capabilities</h3>
                         </div>
                </div>
                <div className="row">
                    <div className="col-lg-10">
                    {window.innerWidth>992?
                    <p>In the business world, the ability to compete effectively is essential for any company that wants to survive and thrive. This means<br/>having the right mix of resources and capabilities to meet customer needs and anticipate and respond to changes in the<br/> marketplace.</p>
                :
                <p>In the business world, the ability to compete effectively is essential for any company that wants to survive and thrive. This means having the right mix of resources and capabilities to meet customer needs and anticipate and respond to changes in the marketplace.</p>
                   }
                    </div>
                    </div>
                <div className="row gapless-box-container  g-0">
                    <div className="col-lg-6">
                        <div className="box">
                            <img src="/images/what-we-do/transform-your-business/sec-4-1.png"></img>
                            <div className="text-box">
                            {window.innerWidth>992?
                            <h4>Use Technology Integration<br></br> as an Advantage</h4>
                            :
<h4>Use Technology Integration as an Advantage</h4>
}
                                <ul>
                                    <li>Develop data and technology strategies based on real-world insights.</li>
                                    <li>Connect technology strategy to specific customer results.</li>
                                    <li>Improve performance using emerging innovations.</li>
                                   
           
                                </ul>

                            </div>
                        </div>
                        <div className="box">
                            <img src="/images/what-we-do/transform-your-business/sec-4-3.png"></img>
                            <div className="text-box">
                            {window.innerWidth>992?
                            <h4>Formulate a Clear Value<br></br> Strategy</h4>
                            :
<h4>Formulate a Clear Value  Strategy</h4>
}
                                 <ul>
                                    <li>Enterprise and functional strategies that are well-articulated and connected to a comprehensive value development strategy.</li>
                                    <li>Establish the distinct capabilities required to succeed in ecosystems.</li>
                                    <li> Create a targeted portfolio to complement your value-generation strategy.</li>
                                   
                                   </ul>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="box">
                            <img src="/images/what-we-do/transform-your-business/sec-4-2.png"></img>
                            <div className="text-box">
                            {window.innerWidth>992?
                            <h4>Engage People to Speed<br></br> Things Up</h4>
                            :
<h4>Engage People to Speed Things Up</h4>
}
                                <ul>
                                    <li>Create a comprehensive workforce plan that aligns with the organisation's business objectives, mission, and values.</li>
                                    <li>Drive strategic investments in talent development and upskilling to increase people's capabilities.</li>
                                    <li>Give importance to crucial actions that influence culture.</li>
                                   
                                </ul>

                            </div>
                        </div>
                        <div className="box">
                            <img src="/images/what-we-do/transform-your-business/sec-4-4.png"></img>
                            <div className="text-box">
                            {window.innerWidth>992?
                            <h4>Get in Fit<br></br> for Growth</h4>
                            :
                            <h4>Get in Fit for Growth</h4>

}
                                 <ul>
                                    <li>Create an operational structure that promotes strategy, growth, and value objectives.</li>
                                    <li>Concentrate on defining capabilities.</li>
                                    <li>Design a performance management structure that aligns with the strategy for the value creation plan.</li>
                               
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container tyb-sec-5">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Enabling Quick and Enormous Innovation</h3>
                    </div>
                </div>

                <div className="row gapless-box-container g-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <img src="/images/what-we-do/transform-your-business/sec-5-1.svg"></img>
                            <h4>Automated</h4>
                            <p>
                            Our SecOps products are driven by factual data, deep analytics, and end-to-end automation to improve efficiency and lower the chance of a breach.
                            </p>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <img src="/images/what-we-do/transform-your-business/sec-5-2.svg"></img>
                            <h4>Integrated</h4>
                            <p>
                            We blend best-in-class capabilities into the most comprehensive cybersecurity solution to provide maximum visibility and control.
                            </p>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <img src="/images/what-we-do/transform-your-business/sec-5-3.svg"></img>
                            <h4>Secured</h4>
                            <p>
                            We include Zero Trust into every security touchpoint to provide you with the most comprehensive application possible.
                            </p>

                        </div>

                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                <h3>Think And Act in A Distinct Way to<br></br> Transform Your Business. </h3>
                                <p>No matter what stage you're at in your business transformation journey, we're here to help you. Contact us<br/>today to discover how we can speed up the process and get your business back on track.</p>
                                <button className="btn btn-transparent">
                                Talk to an Expert
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default WhatWeDo