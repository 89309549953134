import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected2, setTabSelected2] = useState(0);


    useEffect(() => {
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");

    }, [tabSelected2])


    function toggleTab2(index) {
        setTabSelected2(index);

    }



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>
            <Banner
                bgUrl="/images/insight/blog/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "8", bottom: "8" }}
                heading={"Explore Our Insightful IT Blog Collection"}
                content={
                    "Here, we share relevant and informative articles that cover a comprehensive range of topics related to technology, including cybersecurity, automation, cloud computing, software development, and more. "
                }
                customClassName={"blog-banner"}
            >
                <div className="blog-banner-last-updated">
                    <span>Last Updated on:</span>
                    <span><img src="/images/insight/blog/time-icon-light.svg"></img> August 04 2022</span>
                </div>

            </Banner>


            <CommonSection className="container blog-sec-1">

                {window.innerWidth > 992 ?
                    <div className="tab2 row">
                        <div className="col-lg-12 d-flex justify-content-between">

                            <button className="tab-btn2 active" onClick={(e) => toggleTab2(0)}>Latest
                            </button>

                            <button className="tab-btn2" onClick={(e) => toggleTab2(1)}>Trends
                            </button>

                            <button className="tab-btn2" onClick={(e) => toggleTab2(2)}>A.I
                            </button>

                            <button className="tab-btn2" onClick={(e) => toggleTab2(3)}>Big Data
                            </button>

                            <button className="tab-btn2 active" onClick={(e) => toggleTab2(4)}> Computing
                            </button>

                            <button className="tab-btn2" onClick={(e) => toggleTab2(5)}>CloudNative
                            </button>

                            <button className="tab-btn2" onClick={(e) => toggleTab2(6)}>Database
                            </button>


                            <button className="tab-btn2 active" onClick={(e) => toggleTab2(7)}>Networking
                            </button>

                            <button className="tab-btn2" onClick={(e) => toggleTab2(8)}>News
                            </button>

                            <button className="tab-btn2" onClick={(e) => toggleTab2(9)}>Storage
                            </button>


                            <button className="tab-btn2" onClick={(e) => toggleTab2(10)}>Web Dev
                            </button>
                        </div>
                    </div>

                    :
                    <div className="tab2 row d-flex justify-content-between">
                        <div className="col-md-5 ">
                            <select className="form-control form-select">
                                <option>Latest</option>
                                <option>Trends</option>
                                <option>A.I</option>
                                <option>Big Data</option>
                                <option>Computing</option>
                                <option>CloudNative</option>
                                <option>Database</option>
                                <option>Networking</option>
                                <option>News</option>
                                <option>Storage</option>
                                <option>Web Dev</option>
                            </select>

                        </div>
                        <div className="col-md-7">
                            <div className="d-flex align-items-center">
                                <input placeholder="What are you looking for?"></input>

                                <button className="search-button">
                                    <img src="/images/insight/blog/search.svg" className=""></img>Search</button>
                            </div>
                        </div>
                    </div>
                }

                <div className="tab-content2">
                    <div className="row justify-content-between">
                        <div className="col-lg-8 responsive-col-gap">
                            <div className=" blog-header">  <p>The Latest</p></div>
                            <img src="/images/insight/blog/sec-1-1.png"></img>
                            <div className="mb-lg-2 mb-md-3"><span>Author <b>John Doe</b></span>  <span className="date">13-06-2022</span>
                            </div>
                            <h3>Consequat sed magna urna nulla vitae leo. Integer cursus.</h3>
                            <p className="mb-3">Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror....</p>
                            <button className="btn btn-borderless read-more-btn mb-5">Read More...</button>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src="/images/insight/blog/sec-1-2.png"></img>
                                    <span>Author <b>John Doe</b></span>  <span className="date">13-06-2022</span>
                                    <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                                </div>

                                <div className="col-md-6">
                                    <img src="/images/insight/blog/sec-1-3.png"></img>
                                    <span>Author <b>John Doe</b></span>  <span className="date">13-06-2022</span>
                                    <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className=" blog-header ">  <p>Most Read</p></div>
                            <div className="box">
                                <div className="strip">
                                    <h2>01</h2>
                                    <div><p>BLOG WORDS BY TOM KERSHAW</p>
                                        <p><b>Tempor tristique faucibus aenean ut bibendum dignissim.</b></p></div>
                                </div>
                                <div className="strip">
                                    <h2>02</h2>
                                    <div><p>BLOG WORDS BY TOM KERSHAW</p>
                                        <p><b>Tempor tristique faucibus aenean ut bibendum dignissim.</b></p></div>
                                </div>
                                <div className="strip">
                                    <h2>03</h2>
                                    <div><p>BLOG WORDS BY TOM KERSHAW</p>
                                        <p><b>Tempor tristique faucibus aenean ut bibendum dignissim.</b></p></div>
                                </div>
                                <div className="strip">
                                    <h2>04</h2>
                                    <div><p>BLOG WORDS BY TOM KERSHAW</p>
                                        <p><b>Tempor tristique faucibus aenean ut bibendum dignissim.</b></p></div>
                                </div>
                                <div className="strip">
                                    <h2>05</h2>
                                    <div><p>BLOG WORDS BY TOM KERSHAW</p>
                                        <p><b>Tempor tristique faucibus aenean ut bibendum dignissim.</b></p></div>
                                </div>

                            </div>

                            <div className="box sign-up-box">
                                <div className="text-box">
                                    <img src="/images/insight/blog/bell.svg"></img>
                                    {window.innerWidth > 992 ?
                                        <h4>Sign Up for Our News Letter</h4>

                                        :
                                        <h4>Sign Up for Our News Letter</h4>
                                    }                                </div>
                                <p className="bold">We will protect your data in accordance with our <Link to="/privacy-policy"><u><b>privacy policy.</b></u></Link></p>
                                <form>
                                    <label for="mail">Email Address</label>
                                    <div className="d-flex">
                                        <input name="mail" placeholder="enter your email address"></input>
                                        <button className="btn btn-primary">Subscribe</button>
                                    </div>


                                </form>
                            </div>

                        </div>
                    </div>

                </div>




            </CommonSection>



            <CommonSection className="container home-sec-7 blog-sec-2">
                <div className="row">
                    <div className="col-md-12">
                        <div className=" blog-header ">  <p>EDITOR’S PICK</p></div>
                    </div>
                </div>


                <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="col-4">
                            <img src="/images/insight/blog/sec-2-1.png"></img>
                            <span>Author <b>John Doe</b></span>  <span className="date">13-06-2022</span>
                            <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                        </div>

                        <div className="col-4">
                            <img src="/images/insight/blog/sec-2-2.png"></img>
                            <span>Author <b>John Doe</b></span>  <span className="date">13-06-2022</span>
                            <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                        </div>

                        <div className="col-4">
                            <img src="/images/insight/blog/sec-2-3.png"></img>
                            <span>Author <b>John Doe</b></span> <span className="date">13-06-2022</span>
                            <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                        </div>


                        <div className="col-4">
                            <img src="/images/insight/blog/sec-2-4.png"></img>
                            <span>Author <b>John Doe</b></span> <span className="date">13-06-2022</span>
                            <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                        </div>

                        <div className="col-4">
                            <img src="/images/insight/blog/sec-2-5.png"></img>
                            <span>Author <b>John Doe</b></span> <span className="date">13-06-2022</span>
                            <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                        </div>

                        <div className="col-4">
                            <img src="/images/insight/blog/sec-2-6.png"></img>
                            <span>Author <b>John Doe</b></span> <span className="date">13-06-2022</span>
                            <h4>Tempus leo, faucibus pharetra malesuada. </h4>
                        </div>
                    </div>
                </div>
            </CommonSection>


            <CommonSection className="container-fluid tyb-sec-6 blog-sec-3">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                {window.innerWidth > 992 ?
                                    <h3>We'd like to hear from you! </h3>
                                    :
                                    <h3>We'd like to<br />hear from you! </h3>
                                }
{window.innerWidth>992?
                                <p>Our team of specialists are eager to discuss your specific requirements in more detail and help you find the best possible IT solution for your needs.</p>
:
<p>Our team of specialists are eager to discuss your<br/> specific requirements in more detail and help you find the<br/> best possible IT solution for your needs.</p>
}                                <button className="btn btn-transparent">
                                    Talk To Us
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance