import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";


const Contact = () => {


  return (

    <>

      <CommonSection className="page-not-found full-height d-flex flex-column align-items-center justify-content-center w-100  text-center">
       <h4>Oops!</h4>
        <h1> 404</h1>
        <h3>Page  Not Found!</h3>
      </CommonSection>
    </>

  )
};

export default Contact;
