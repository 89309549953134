import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected2, setTabSelected2] = useState(0);


    useEffect(() => {
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");

    }, [tabSelected2])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function toggleTab2(index) {
        setTabSelected2(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            {window.innerWidth > 992 ? <CommonSection className="container-fluid unif-comm-banner">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 responsive-col-gap">
                            {window.innerWidth>992?
                            <h1>Engage with Your Customers<br /> More Effectively</h1>
                            :window.innerWidth>768?
                            <h1>Engage with Your<br />Customers More<br />Effectively</h1>
                            :
                            <h1>Engage with Your<br />Customers More<br />Effectively</h1>
}

                            <p>Enhance customer experiences across all channels.<br />Collaboration services are designed to streamline<br />business functions, save valuable time, and improve<br />customer engagement.</p>
                        </div>
                        <div className="col-lg-6">
                        </div>

                    </div>
                </div>


            </CommonSection>
                :
                <Banner
                    bgUrl="/images/digital-engineering/unified-communication/banner.png"
                    theme="light"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"Engage with Your Customers More Effectively"}
                    content={
                        "Enhance customer experiences across all channels. Collaboration services are designed to streamline business functions, save valuable time, and improve customer engagement."
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-end"
                    mobileTextAlign="left"
                >

                </Banner>}

            <CommonSection className="container telecom-sec-2 ">
                <div className="row  g-0">
                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/unified-communication/sec-1.png"></img>
                    </div>
                    <div className="col-lg-6 border">
                        {window.innerWidth > 992 ?
                            <h3> Multichannel Customer Engagement & CRM Integration</h3>
                            : <h3> Multichannel Customer<br />Engagement & CRM Integration</h3>
                        }

                        <p>Working in collaboration with highly-rated Customer Relationship Management software, ZOG Global provides secure, reliable, and customizable solutions designed to boost customer engagement and streamline customer relations operations. We offer efficient management of multi-channel communications to help organizations stand out in the crowded and competitive market.</p>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container cyber-security-sec-3 DE-testing-sec-3">
                <div className="tab-btn-wrapper">
                    <div className="tab2 row">


                        <div className="col-lg-12 d-flex justify-content-between">

                            <button className="tab-btn2 active" onClick={(e) => toggleTab2(0)}>Quick Deployment</button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(1)}>Reduce Operational Costs
                            </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(2)}>Enhance Productivity with Integrations
                            </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(3)}>Upgrades are not Required
                            </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(4)}>Compatible for All Devices</button>
                        </div>

                    </div>
                </div>
                <div className="tab-content2">

                    <h3>Quick Deployment</h3>
                    <p>Our staff will work closely with you from the first consultation through deployment to guarantee that your business is up and running with our platform so that you can start reaping the benefits as soon as possible. We will also be available if you require assistance following the installation.</p>
                </div>

                <div className="tab-content2">

                    <h3>Reduce Operational Costs</h3>
                    <p>Using various communication tools from different companies is expensive. You'll have a sizable bank bill at the end of the month if you pay individually for conference calls, phone calls, and other collaboration tools.  It is easy to use and affordable, regardless of whether you operate a large contact centre or a small office.</p>
                </div> <div className="tab-content2">

                    <h3>Enhance Productivity with Integrations</h3>
                    <p>You won't have to switch between applications to set up meetings, send IMs, or make VoIP calls. Google and Microsoft's software suites can be combined with Office's features. Meetings can be scheduled using Outlook and Google Calendar through a single communications platform. It will be possible to add click-to-dial phone numbers in emails and on web pages. Even professional sales and support apps will be possible to integrate.</p>
                </div> <div className="tab-content2">

                    <h3>Upgrades are not Required</h3>
                    <p>ZOG Global strives to simplify things for clients as a unified communications service provider. The ZOG global UCaaS solution provides unmatched capability and quality. But they are straightforward to set up, maintain and use. The platform is hosted in the cloud to simplify getting started. You won't have to worry about replacing outdated mobile or on-premises technology.</p>
                </div>
                <div className="tab-content2">

                    <h3>Compatible for All Devices</h3>
                    <p>Your business will benefit from the simplicity of unified communications as a service. ZOG Global makes sure all team members and workplaces are always linked. All of your devices are compatible with Office's incredibly user-friendly UI. That covers desk phones, mobile phones, tablets, and computers with softphones. You will get access to all of Office's features no matter where or how you work.</p>
                </div>


            </CommonSection>

            {window.innerWidth > 992 ?
                <CommonSection className="container DE-testing-sec-1 unif-comm-sec-3">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="box">

                                <h3>Our Contact Centre Service Offerings</h3>
                                <p> With our Contact Centre Services, you can provide the multichannel accessibility clients require with the excellent service and care they'll value.</p>
                            </div>
                            <div className="box">
                                <h4>Robotic Process Automation in Contact Centre</h4>
                                <p>Transform your contact centres with our cutting-edge Robotic Process Automation (RPA) solutions. Streamline operations, enhance efficiency, and elevate customer experiences. Our RPA solutions optimize workflows, automate repetitive tasks, and deliver seamless interactions for unparalleled service excellence.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="box">
                                <h4>AI-Powered Self Service Automation</h4>
                                <p>ZOG Global develops AI-based self-service channels that incorporate the latest in language processes to create a natural speech for interaction between automated agents and customers. You can automate your customer service interactions and focus on other critical components of your business.</p>
                            </div>
                            <div className="box">
                                <h4>Hosted Collaboration Application Deployment </h4>
                                <p>ZOG Global will make your business communication cost-effective with hosted collaboration app deployment solutions, ensuring flexibility, centralized management, easy administrative tasks, and rapid deployment of services. Organizations are, thus, able to meet consumerization and device evolution challenges.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="box">
                                <h4>Automation Of Identity and Verification Process </h4>
                                <p>ZOG Global will enable your Contact Center solution to integrate speech biometrics and conversational AI to automatically control sign in and data access procedures for employees and customers while maintaining security protocols.</p>
                            </div><div className="box">
                                <h4>On-Premise Contact Centre Design Development </h4>
                                <p>On-site contact centres provide help for the majority of businesses. ZOG Global provides solutions with platforms from vendors such as Cisco, Avaya, Genesys, Twilio, and even open-source Asterisk solutions to ensure the maintenance of high-quality calls.</p>
                            </div>
                        </div>

                    </div>
                </CommonSection>
                :
                <CommonSection className="container DE-testing-sec-1 unif-comm-sec-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="box">

                                <h3>Our Contact Centre<br />Service Offerings</h3>
                                <p> With our Contact Centre Services, you can provide the multichannel accessibility clients require with the excellent service and care they'll value.</p>
                            </div>
                        </div>
                    </div>


                    <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="box">
                            <h4>Robotic Process Automation in Contact Centre</h4>
                            <p>Transform your contact centres with our cutting-edge Robotic Process Automation (RPA) solutions. Streamline operations, enhance efficiency, and elevate customer experiences. Our RPA solutions optimize workflows, automate repetitive tasks, and deliver seamless interactions for unparalleled service excellence.
                            </p>
                        </div>
                        <div className="box">
                            <h4>AI-Powered Self Service Automation</h4>
                            <p>ZOG Global develops AI-based self-service channels that incorporate the latest in language processes to create a natural speech for interaction between automated agents and customers. You can automate your customer service interactions and focus on other critical components of your business.</p>
                        </div>
                        <div className="box">
                            <h4>Hosted Collaboration Application Deployment </h4>
                            <p>ZOG Global will make your business communication cost-effective with hosted collaboration app deployment solutions, ensuring flexibility, centralized management, easy administrative tasks, and rapid deployment of services. Organizations are, thus, able to meet consumerization and device evolution challenges.</p>
                        </div>


                        <div className="box">
                            <h4>Automation Of Identity and Verification Process </h4>
                            <p>ZOG Global will enable your Contact Center solution to integrate speech biometrics and conversational AI to automatically control sign in and data access procedures for employees and customers while maintaining security protocols.</p>
                        </div><div className="box">
                            <h4>On-Premise Contact Centre Design Development </h4>
                            <p>On-site contact centres provide help for the majority of businesses. ZOG Global provides solutions with platforms from vendors such as Cisco, Avaya, Genesys, Twilio, and even open-source Asterisk solutions to ensure the maintenance of high-quality calls.</p>
                        </div>


                    </div>
                    </div>
                </CommonSection>
            }

            <CommonSection className="container soft-dev-sec-4 unif-comm-sec-4">
                <div className="row">
                    <div className="col-lg-9">
                        <h3>Features that make Unified Communication Solutions<br />suitable for Enterprise Users</h3>
                    </div>

                </div>
                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-3">
                        <div className="box">
                            <img src="/images/digital-engineering/unified-communication/sec-4-1.svg"></img>

                            <h4>Scalable<br />Service</h4>
                            <p>Video conferencing services can be scaled to meet the needs of growing enterprises.</p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                            <img src="/images/digital-engineering/unified-communication/sec-4-2.svg"></img>
                            <h4>Single Sign-On<br />(SSO)</h4>
                            <p>With single sign-on, clients gain on average 15x more users and 8x more meetings.</p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                            <img src="/images/digital-engineering/unified-communication/sec-4-3.svg"></img>
                            <h4>Enterprise-<br />Grade Security<br />Features</h4>
                            <p>Your data and information are protected by enterprise-grade security features.</p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                            <img src="/images/digital-engineering/unified-communication/sec-4-4.svg"></img>
                            <h4>Software &<br />Device<br />Interoperability</h4>
                            <p>Users can connect using their choice of video connection, regardless of whether they're using a room system, a mobile device, or a web browser.</p>
                        </div>
                    </div>
                    </div>
                    </div>
            </CommonSection>


            <CommonSection className="container soft-dev-sec-2">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Blogs</h3>
                    </div>

                </div>

                <div className="row g-5">
                    <div className="col-lg-6 responsive-col-gap">

                        <img src="/images/digital-engineering/unified-communication/sec-5-1.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/unified-communication/sec-5-2.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container  education-sec-4  unif-comm-sec-6">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Why We Are
                            Unique?</h3>
                    </div>
                </div>

                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-4">
                        <div className="box">
                            <h4>Improved Efficiency</h4>
                            <p> Our unifying communications services make it simple for people to do their job. Not only does our unified communications allow employees to work more efficiently but also wherever they want to.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box">
                            <h4>Highly
                                Secured </h4>
                            <p>Never be concerned about data theft. We create user roles and permissions for specific users on our UCaaS platforms to maintain control over sensitive data and safeguard your systems.</p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="box">
                            <h4>Better Customer Service</h4>
                            <p>With unified communications, you can still be available to your customers by redirecting your fixed number to your mobile device, even if you're away from your desk or out of the office.</p>
                        </div>
                    </div>

                </div>
                </div>
                
            </CommonSection>


            <CommonSection className="container-fluid tyb-sec-6 mb-0">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                {window.innerWidth>992?
                                <h3>Go ahead, take ZOG Global for a Spin </h3>
                            :
                            <h3>Go ahead,<br/>take ZOG Global <br/>for a Spin </h3>
                            }
                                <p>Talk to our unified communication experts today to see how it works for your business.</p>
                                <a href="../contact"> <button className="btn btn-transparent">
                                    Get in Touch
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance