import React from "react";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {

    let navigate = useNavigate();
    useEffect(()=>{
        localStorage.setItem("userRoleRequest", "user")
        const redirectTimeout = setTimeout(() => {
            navigate("/login");
        }, 3000);
    
        return () => clearTimeout(redirectTimeout); 

    },[])

    return (
        <>
            <div className="full-screen-container full-screen-container-borderless ">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 ">
                        <div className="full-sceen-alert-page-card">

                            <div className="body">
                                <img src="/images/authentication-screens/password-changed-icon.svg"></img>
                                <h2 className="title">Hurray!</h2>
                                <span>Your New Password is saved.<br></br>
You will be redirected to the login page <br></br>
in a few seconds.<br/> Please login with your new password</span>
                               <img src="/images/authentication-screens/loading-spinner.gif"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default View;