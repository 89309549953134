import React from "react";
import { useEffect } from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";

import Slider from "react-slick";
const QualityAssurance = () => {






    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    return (
        <>
            <Banner
                bgUrl="/images/insight/be-a-partner/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "10", bottom: "11" }}
                heading={window.innerWidth > 992 ?
                    "Let’s Grow Together with<br/>ZOG Global"
                    :
                    "Let’s Grow Together <br/>with ZOG Global"}
                content={
                    "We believe that partnering with other businesses is a great way to promote growth and success. We have a track record of standing by our partners and helping them prosper. We want our partners to be successful, and we guarantee that any business information shared with us will remain confidential."
                }
                customClassName={"be-a-partner-banner"}
            >

            </Banner>
            {window.innerWidth > 992 ?
                <CommonSection className="container be-a-partner-sec-1">
                    <div className="row">
                        <div className="col-lg-3 lhs">
                            <div className="box">
                                <h3>The Power of Partnerships</h3>
                                <p>Partnerships based on shared knowledge, experience, commitment, and mutually beneficial investments enable growth and a more significant impact.</p>

                            </div>
                        </div>
                        <div className="col-lg-3">

                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-1.svg"></img>
                                <p>Tailored Solutions</p>
                            </div>

                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-2.svg"></img>
                                <p>Innovative Technologies</p>
                            </div>

                        </div>

                        <div className="col-lg-3">

                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-3.svg"></img>
                                <p>Proven Track Record</p>
                            </div>

                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-4.svg"></img>
                                <p>Scalability & Flexibility</p>
                            </div>

                        </div>

                        <div className="col-lg-3">

                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-5.svg"></img>
                                <p>Enduring Support</p>
                            </div>



                        </div>

                    </div>
                </CommonSection>
                :
                <CommonSection className="container be-a-partner-sec-1">
                    <div className="row">

                        <div className="col-12 lhs">
                            <div className="box">
                                <h3>The Power of Partnerships</h3>
                                <p>Partnerships based on shared knowledge, experience, commitment, and mutually beneficial investments enable growth and a more significant impact.</p>

                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-1.svg"></img>
                                <p>Tailored Solutions</p>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-2.svg"></img>
                                <p>Innovative Technologies</p>
                            </div>

                        </div>

                        <div className="col-4">
                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-3.svg"></img>
                                <p>Proven Track Record</p>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-4.svg"></img>
                                <p>Scalability & Flexibility</p>
                            </div>

                        </div>

                        <div className="col-4">
                            <div className="box">
                                <img src="/images/insight/be-a-partner/sec-1-5.svg"></img>
                                <p>Enduring Support</p>
                            </div>
                        </div>

                    </div>
                </CommonSection>}

            {window.innerWidth > 992 ?
                <CommonSection className="containe-fluid DE-testing-sec-1 be-a-partner-sec-2">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="box">
                                <h3>Why Partnering with Us</h3>
                                <p >We share our knowledge and professional experience to support our clients to beat IT challenges with low cost and limited resources, enabling them to have a strategic alliance and partnership with industry-leading AI & Enterprise companies and solutions – all focused on transparency and accountability.</p>
                            </div>
                            <div className="box">
                                <h4>Improved Customer Solution </h4>
                                <p>We share resources, knowledge, and best practices to create a well-rounded solution that meets your customers' requirements and capabilities.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="box">
                                <h4>Accelerates Growth</h4>
                                <p>A strategic alliance or partnership can significantly accelerate your business's growth. You may use our resources and expertise to expand your business by collaborating with us.</p>
                            </div>
                            <div className="box">
                                <h4>Encourages Innovation </h4>
                                <p>Together, we can push the boundaries of what is achievable and design something that has never been done to meet the challenges of the future.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="box">
                                <h4>Create a Positive Brand Presence </h4>
                                <p>Partnering with us not only helps to ensure that your promotional opportunities are perfectly aligned with your target audience, but it also helps to boost engagement levels across all channels.</p>
                            </div><div className="box">
                                <h4>Uncovers New Possibilities  </h4>
                                <p>We exchange opportunities that will allow you to get access to a new pool of customers, increase your overall revenue and unlock new possibilities. </p>
                            </div>
                        </div>

                    </div>
                </CommonSection>

                :
                <CommonSection className="containe-fluid DE-testing-sec-1 be-a-partner-sec-2">
                    <div className="row">
                        <div className="col-12">
                            <div className="box">
                                <h3>Why Partnering with Us</h3>
                                <p >We share our knowledge and professional experience to support our clients to beat IT challenges with low cost and limited resources, enabling them to have a strategic alliance and partnership with industry-leading AI & Enterprise companies and solutions – all focused on transparency and accountability.</p>
                            </div>

                        </div>
                    </div>


                    <div className="non-breaking-mob-container">

                        <div className="row">
                            <div className="col-4">
                                <div className="box">
                                    <h4>Improved Customer Solution </h4>
                                    <p>We share resources, knowledge, and best practices to create a well-rounded solution that meets your customers' requirements and capabilities.</p>
                                </div>
                            </div>


                            <div className="col-4">
                                <div className="box">
                                    <h4>Accelerates Growth</h4>
                                    <p>A strategic alliance or partnership can significantly accelerate your business's growth. You may use our resources and expertise to expand your business by collaborating with us.</p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="box">
                                    <h4>Encourages Innovation </h4>
                                    <p>Together, we can push the boundaries of what is achievable and design something that has never been done to meet the challenges of the future.</p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="box">
                                    <h4>Create a Positive Brand Presence </h4>
                                    <p>Partnering with us not only helps to ensure that your promotional opportunities are perfectly aligned with your target audience, but it also helps to boost engagement levels across all channels.</p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="box">
                                    <h4>Uncovers New Possibilities  </h4>
                                    <p>We exchange opportunities that will allow you to get access to a new pool of customers, increase your overall revenue and unlock new possibilities. </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </CommonSection>
            }
            <CommonSection className="container-fluid managed-it-services-sec-3 be-a-partner-sec-3 ">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    {window.innerWidth>992?
                                    <h3>Create a Better Future<br />Together</h3>
                                :
                                <h3>Create a Better <br />Future Together</h3>}
                                     {window.innerWidth>992?
                                    <p>Our team has experience success in partnering with other organizations, and we are always looking for new<br />opportunities to collaborate. If you are interested in partnering with ZOG Global, please get in touch with us.</p>

                                    :
                                    <p>Our team has experience success in partnering with other organizations, and we are always looking for new opportunities to collaborate. If you are interested in partnering with ZOG Global, please get in touch with us.</p>

                                     }
                                </div>
                                <a href="../contact">  <button className="btn btn-transparent">
                                    Be a Partner
                                    <span>
                                    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.1718 12.5607C29.7576 11.9749 29.7576 11.0251 29.1718 10.4393L19.6258 0.893398C19.04 0.307611 18.0903 0.307611 17.5045 0.893398C16.9187 1.47919 16.9187 2.42893 17.5045 3.01472L25.9898 11.5L17.5045 19.9853C16.9187 20.5711 16.9187 21.5208 17.5045 22.1066C18.0903 22.6924 19.04 22.6924 19.6258 22.1066L29.1718 12.5607ZM0 13H28.1111V10H0V13Z" fill="#F6F6F6"/>
</svg>

                                    </span>
                                </button></a>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance