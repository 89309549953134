import React from "react";
import { useState } from "react";
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import API from './API';


<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {

    let navigate = useNavigate();


    const [errors, setErrors] = useState({});


    const validateForm = (data) => {
        const errors = {};

        // Validate each field and add errors if necessary

        if (!data.email.trim()) {
            errors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = "Invalid email address.";
        }
        return errors;
    };


    const sendMail = (e) => {
        var email = $("#email").val();
        var data = { "email": email }

        e.preventDefault();

        // Validate the form fields
        const validationErrors = validateForm(data);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            API.post("/password-reset/",  data)
                .then((response)=>
                    navigate("/confirm-mail")
                )
                .catch((error)=>
                    setErrors({ "email": "Not found!" })
                )
        }
    }
    return (
        <>
            <div className="full-screen-container full-screen-container-borderless">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 ">
                        <div className="full-sceen-alert-page-card">

                            <div className="body">
                                <Link to={"/sign-up"} className="back-btn"><img src="/images/authentication-screens/back-icon.svg" ></img></Link>
                                <img src="/images/authentication-screens/forgot-password-icon.svg"></img>
                                <h2 className="title">Forgot Password?</h2>
                                <span>Enter your email address below and we’ll send you<br></br>
                                    password reset OTP </span>
                                <input placeholder="Email Address/Username" className=" input-secondary mb-3" id="email"></input>

                                {errors.email && <div className="invalid-feedback d-block ms-5 mb-3">{errors.email}</div>}

                                <button className="btn btn-secondary" type="button" onClick={sendMail}>Send Mail</button>

                                <div className="footer">

                                    <span>Already have an account?</span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default View;