import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {






    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    return (
        <>

            <Banner
                bgUrl="/images/quality-assurance/accessibility-testing/banner.png"
                theme="dark"
                txtAlign="center"
                paddingProp={{ top: "8.6042", bottom: "8" }}
                heading={window.innerWidth > 992 ?
                    "Accessibility Testing Services"
                    :
                    "Accessibility<br/>Testing Services"}
                content={
                    "Digital accessibility is an essential part of any quality website, application, or software; if it's not in place, your company faces significant reputational risks. At our business, we aim to give our clients, including those working in the public sector, the confidence that their products are available to everybody, regardless of any disability they may have. Our team offers professional, hands-on testing services to guarantee this outcome."
                }

                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"accessibility-testing-banner"}
            >

            </Banner>

            <CommonSection className="container telecom-sec-1">
                <div className="row g-0">

                    <div className="col-lg-6 border">
                        {window.innerWidth>992?
                        <h3 className="mb-5"> Build Compliant and<br />Accessible Applications</h3>
:
window.innerHeight.window>768?
<h3 className="mb-5"> Build Compliant and<br />Accessible<br />Applications</h3>
:
<h3 className="mb-5"> Build Compliant and<br />Accessible<br />Applications</h3>
                    }
                        <p>Around 285 million individuals worldwide have vision impairments, and of those, roughly 39 million are completely blind, according to the World Health Organization. A hurdle for individuals with disabilities is the absence of accessibility standards (video labeling, alt text, subtitles, etc.), which are intended to improve the visual appeal of feature-rich websites.
                            <br></br><br></br>
                            At ZOG Global, we provide comprehensive accessibility testing for your web and mobile applications. Our solution ensures that you are compliant with regulations and makes suggestions to enhance the user experience for differently abled people. Benefiting from our expertise in this field will result in maximum user satisfaction.
                            <br></br><br></br>
                            Our testing solution is part of our ongoing commitment to providing our customers and employees, including those with disabilities, with sustainable solutions. ZOG Global's technology guarantees agility and better compliance while also boosting operational efficiency and significantly reducing costs.</p>

                    </div>

                    <div className="col-lg-6">
                        <img src="/images/quality-assurance/accessibility-testing/sec-1.png"></img>
                    </div>
                </div>
            </CommonSection>





            {window.innerWidth > 992 ?
                <CommonSection className="container accessibility-testing-sec-2">
                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <h3 className="text-center">Quality Testing Complaint<br></br> with the WCAG Standard</h3>
                        </div>
                    </div>
                    <div className="row justify-content-between gx-5 cr">
                        <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-1.svg"></img>
                        <div className="col-lg-6 responsive-col-gap">
                            <h4>Easy to Perceive</h4>
                            <p>When testing the accessibility of a website or application, we need to check if users can discern the content using the browser or assistive technologies in ways they can perceive. This includes checking for things like proper heading levels, alt text on images, and labels on form fields. By ensuring that users can access and understand the content on a website or application, we can help make the internet a more inclusive place for everyone. </p>
                        </div>

                        <div className="col-lg-6">
                            <img src="/images/quality-assurance/accessibility-testing/sec-2-1.png"></img>
                        </div>
                    </div>

                    <div className="row justify-content-between gx-5">
                        <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-2.svg"></img>

                        <div className="col-lg-6 responsive-col-gap">
                            <img src="/images/quality-assurance/accessibility-testing/sec-2-2.png"></img>
                        </div>

                        <div className="col-lg-6">
                            <h4>Easy Functionalities</h4>
                            <p>We ensure that users can successfully operate and control all user interface components and navigations. This includes making sure that buttons, links, and other control elements are easy to find and use and that the overall navigation of the site or app is easy to understand. In addition, we also test for any potential accessibility issues that could impact users with disabilities.</p>
                        </div>
                    </div>

                    <div className="row justify-content-between gx-5 cr">
                        <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-3.svg"></img>
                        <div className="col-lg-6 responsive-col-gap">
                            <h4> Predictable And Consistent</h4>
                            <p>We strive to provide a user experience that is both secure and capable. We want users to feel confident and informed when making decisions. To achieve this, we make sure the user interface is easy to understand and use in a predictable and consistent way. This way, users can focus on achieving their goals rather than on trying to figure out how our products work. </p>
                        </div>

                        <div className="col-lg-6">
                            <img src="/images/quality-assurance/accessibility-testing/sec-2-3.png"></img>
                        </div>
                    </div>

                    <div className="row justify-content-between gx-5">
                        <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-4.svg"></img>
                        <div className="col-lg-6 responsive-col-gap">
                            <img src="/images/quality-assurance/accessibility-testing/sec-2-4.png"></img>
                        </div>

                        <div className="col-lg-6">
                            <h4> Improved Performance and Robustness</h4>
                            <p>We conduct tests to see whether people can engage with content compatible with a variety of user agents. To do this, we test the content against a set of known user agents and check to see if the content can be correctly rendered and interacted with. If the content is incompatible with a particular user agent, we try to find a workaround that will allow the content to be correctly displayed and interacted with.</p>
                        </div>


                    </div>
                </CommonSection>

                :

                <CommonSection className="container accessibility-testing-sec-2">
                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <h3 className="text-center">Quality Testing Complaint<br></br> with the WCAG Standard</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 responsive-col-gap">

                            <div className="d-flex align-items-center">
                                <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-1.svg"></img>
                                <h4>Easy to Perceive</h4>
                            </div>

                            <img src="/images/quality-assurance/accessibility-testing/sec-2-1.png"></img>

                            <p>When testing the accessibility of a website or application, we need to check if users can discern the content using the browser or assistive technologies in ways they can perceive. This includes checking for things like proper heading levels, alt text on images, and labels on form fields. By ensuring that users can access and understand the content on a website or application, we can help make the internet a more inclusive place for everyone. </p>
                        </div>



                        <div className="col-12 responsive-col-gap">

                            <div className="d-flex align-items-center">
                                <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-2.svg"></img>

                                <h4>Easy Functionalities</h4>
                            </div>

                            <img src="/images/quality-assurance/accessibility-testing/sec-2-2.png"></img>

                            <p>We ensure that users can successfully operate and control all user interface components and navigations. This includes making sure that buttons, links, and other control elements are easy to find and use and that the overall navigation of the site or app is easy to understand. In addition, we also test for any potential accessibility issues that could impact users with disabilities.</p>
                        </div>


                        <div className="col-12 responsive-col-gap">

                            <div className="d-flex align-items-center">
                                <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-3.svg"></img>
                                <h4> Predictable And Consistent</h4>
                            </div>

                            <img src="/images/quality-assurance/accessibility-testing/sec-2-3.png"></img>

                            <p>We strive to provide a user experience that is both secure and capable. We want users to feel confident and informed when making decisions. To achieve this, we make sure the user interface is easy to understand and use in a predictable and consistent way. This way, users can focus on achieving their goals rather than on trying to figure out how our products work. </p>
                        </div>



                        <div className="col-12 responsive-col-gap">

                            <div className="d-flex align-items-center">
                                <img className="number" src="/images/quality-assurance/accessibility-testing/sec-2-icon-4.svg"></img>
                                <h4> Improved Performance and Robustness</h4>
                            </div>

                            <img src="/images/quality-assurance/accessibility-testing/sec-2-4.png"></img>

                            <p>We conduct tests to see whether people can engage with content compatible with a variety of user agents. To do this, we test the content against a set of known user agents and check to see if the content can be correctly rendered and interacted with. If the content is incompatible with a particular user agent, we try to find a workaround that will allow the content to be correctly displayed and interacted with.</p>
                        </div>

                    </div>

                </CommonSection >}

            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2 accessibility-testing-sec-3 mannual-testing-sec-2">
                <div className="row g-5">
                    <div className="col-lg-6">
{window.innerWidth>992?
                        <img src="/images/quality-assurance/accessibility-testing/sec-3.png"></img>
:
                        <img src="/images/quality-assurance/accessibility-testing/sec-3-tab.png"></img>
                    }                    </div>
                    <div className="col-lg-6 responsive-col-gap ">
                        <h3> The Perfect Combination for Accessibility Testing</h3>

                        <p>The importance of web accessibility for all users cannot be overstated, and achieving accessibility is essential for staying up-to-date and providing an optimal user experience. However, these tests must be comprehensive and efficient to identify slight and significant accessibility issues properly. As a result, it is essential to employ both manual and automated testing in order to achieve optimal accessibility testing results.
                            <br></br><br></br>
                            Automated testing can catch most of the common accessibility issues, such as basic HTML markup errors. Automated testing can also be used to scan large amounts of pages and data, drastically reducing the time necessary to complete a traditional accessibility test. Despite these advantages, automated testing is not 100% reliable and may produce false positives or negatives. For this reason, manual testing should be used to supplement automated testing and vice versa.
                            <br></br><br></br>
                            Manual testing requires experienced testers who can recognise complex and nuanced accessibility issues. This type of testing is also more efficient for assessing the impact of certain interactive elements or features, as these often cannot be tested with automated processes. Manual accessibility testing is necessary in order to validate automated test results and ensure the accuracy of diagnostic conclusions. It also helps to prevent false-positive values commonly associated with automated tests, as testers can distinguish between non-violative situations and actual accessibility issues.</p>

                    </div>


                </div>
            </CommonSection>

            <CommonSection className="container why-zog-global-sec-2 accessibility-testing-sec-4">
                <div className="row">
                    <div className="col-lg-6 responsive-col-gap">
                        <h4>OUTCOMES </h4>
                        <h3>Why ZOG&nbsp;Global Tops<br />the List of Choices</h3>
                    </div>
                    <div className="col-lg-6">
                        <div className="strip">
                            <img src="/images/quality-assurance/accessibility-testing/sec-4-1.svg" alt="icon"></img>
                            <div>
                                <h4>High-Quality Results</h4>
                                <p>We utilise the latest technologies, tools, and techniques to ensure high-quality results. Our experts are also experienced in identifying design patterns that could impede the user experience and prevent accessibility.</p>
                            </div>
                        </div>

                        <div className="strip">
                            <img src="/images/quality-assurance/accessibility-testing/sec-4-2.svg" alt="icon"></img>
                            <div>
                                <h4>Greater Flexibility</h4>
                                <p>Our services have the flexibility to adapt to the changing needs of our clients. We have the infrastructure, resources, and capabilities to respond quickly to changes in design and development. As a result, we can deliver faster results without sacrificing accuracy and quality.</p>
                            </div>
                        </div>

                        <div className="strip">
                            <img src="/images/quality-assurance/accessibility-testing/sec-4-3.svg" alt="icon"></img>
                            <div>
                                <h4>Highly Secured</h4>
                                <p>We are committed to safeguarding the confidentiality of the information of our clients, and our services are built with a strict security infrastructure. Our experts adhere to strict security best practices and ensure the information is securely stored and handled.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                <h3>Test your Solution<br />Quickly and Accurately </h3>
                                <p>Start making your website accessible today and ensure that everyone can enjoy<br />the same online experience. </p>
                                <a href="../contact"><button className="btn btn-transparent">
                                    Talk to an Expert
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance