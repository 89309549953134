import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {





    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);








    return (
        <>

            <Banner
                bgUrl="/images/AI-Automation/artificial-intelligence/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "8", bottom: "8" }}
                heading={window.innerWidth > 992 ?
                    "Make Your Business<br/>Future-Proof with AI"
                    :window.innerWidth > 992 ?
                    "Make Your Business<br/>Future-Proof with AI"
                :"Make Your<br/>Business<br/>Future-Proof<br/>with AI"}
                content={
                    "Future-proofing your business is not just a buzzword on the radar of tech-savvy leaders. Instead, it's an absolute necessity for those seeking to stay competitive in today's changing marketplace. We'll help you streamline your business and ensure it's ready for tomorrow's challenges"
                }

                mobileVerticalAlignment="center"
                mobileTextAlign="left"
                customClassName={"artificial-intelligence-banner"}
            >
            </Banner>

            <CommonSection className="container">
                <div className="row">
                    <div className="col-lg-4">

                    </div>

                    <div className="col-lg-8">
                        <p>Improve your creativity. Discover new possibilities. Increase your productivity and amplify your impact. Our software includes artificial intelligence capabilities to provide more innovative, automated solutions. We are a leading UK provider of AI services that transform and modernize businesses worldwide. We provide AI solutions to clients from various fields with the help of an intellectual and creative team. With the help of our expert team, businesses can optimize their costs, improve agility, improve efficiency, and deliver value continuously.
                            <br /><br />

                            Through high-quality AI applications, we provide high-quality performance to our esteemed clients. In addition to AI application development, competitive machine learning, natural language processing, and reliable speech recognition, we have extensive expertise in many other areas.</p>
                    </div>
                </div>


            </CommonSection>

            <CommonSection className="container artificial-intelligence-sec-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="orange-line mb-4">Transformative Impact of AI</h3>
                    </div>
                </div>
                <div className="row gapless-box-container g-0">
                    <div className="col-md-4">
                        <div className="box">

                            <span>75%</span>

                            <p>
                                Increase in Efficiency</p>

                        </div>

                    </div>

                    <div className="col-md-4">
                        <div className="box">
                            <span>30%</span>
                            <p>
                                Reduction in Costs</p>

                        </div>

                    </div>

                    <div className="col-md-4">
                        <div className="box">
                            <span>95%</span>
                            <p>Accuracy in Predictive Analysis</p>

                        </div>

                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container artificial-intelligence-sec-3">
                <div className="row">
                    <div className="col-lg-4">
                        {window.innerWidth > 992 ?
                            <h3>Developing your business into the future with Artificial Intelligence </h3>

                            :
                            <h3>Developing your business into<br />the future with Artificial Intelligence </h3>
                        }
                        {window.innerWidth < 992 && <img className="mb-4 " src="/images/AI-Automation/artificial-intelligence/sec-3.png"></img>
                        }    </div>

                    <div className="col-lg-8">
                        <p>For many organizations in the UK, we have delivered certified Artificial Intelligence (AI) solutions and products. With the help of AI algorithms, we can provide a broad range of machine learning capabilities. We have an excellent understanding of AI and machine-learning tools. We have helped organizations create AI Strategies and seamlessly integrate them into the enterprise's digital strategies. We have expertise in several AI streams, including RPA, OCR, and digital Assistants.</p>
                        {window.innerWidth > 992 && <img src="/images/AI-Automation/artificial-intelligence/sec-3.png"></img>
                        }
                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container-fluid  artificial-intelligence-sec-4">
                <div className="mask">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center align-items-center">
                                <p className="text-center">Our AI future-ready platform is designed to deliver quality customer experience and quick turnaround times; while eliminating instances of human error in customer relations.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </CommonSection>

            <CommonSection className="container artificial-intelligence-sec-5">
                <div className="row">
                    <div className="col-lg-6">
                        {window.innerWidth > 992 ?
                            <h3>Why choose<br />
                                ZOG Global as your<br />
                                AI partner?</h3>
                            :
                            <h3>Why choose ZOG Global <br />as your AI partner?</h3>}
                    </div>

                    <div className="col-lg-6">
                        <ul>

                            <li>    Our expert team offers the most customized solutions.</li>
                            <li>When you say AI is data-driven, we are reminded of our excellent team of data analysts.</li>
                            <li>Enhanced productivity, agility & flexibility.</li>
                            <li>An improved decision-making process that gives you an edge over your competitors.</li>
                            <li>Scalable capabilities to meet growing demands effectively.</li>

                        </ul>
                    </div>
                </div>


            </CommonSection>

            <CommonSection className="container artificial-intelligence-sec-6">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>Read Latest Blog</h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row g-5">
                        <div className="col-6 ">
                            <div className="box">
                                <h4>Sit sed et massa interdum. </h4>
                                <p>Nulla arcu morbi mattis nibh odio praesent. Bibendum non in duis felis. Vitae pharetra, dictum lobortis pulvinar semper quis elit. Commodo faucibus ut enim mauris ut. Nibh lobortis tincidunt dui eget sed ante dictum vitae. Egestas scelerisque nulla est sed. Augue a libero turpis maecenas eget diam tincidunt turpis. Mollis vitae amet nisi aliquam. Suspendisse faucibus arcu ut montes, elementum nisl, mauris malesuada facilisi.
                                    Malesuada ligula pharetra id metus feugiat euismod id. Molestie amet elementum volutpat amet mauris, condimentum. Lorem fringilla amet a enim, facilisis non, consequat. Integer sed condimentum maecenas sapien fermentum tortor scelerisque volutpat lacus. Dignissim arcu euismod felis vitae ultrices. Fringilla in quis nullam leo ut et nulla.</p>
                                <button className="btn btn-transparent">See paper details <span>
                                    <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                </span></button>
                            </div>

                        </div>

                        <div className="col-6">
                            <div className="box">
                                <h4>Ultricies urna, ut pellentesque enim vitae, pharetra. Ut ullamcorper. </h4>
                                <p>Eget vel pretium, posuere dignissim orci, velit sed. Nunc habitant risus porttitor pulvinar aliquet. Tristique semper vestibulum habitant feugiat id massa tempor, fusce sit. Aliquam diam ut turpis sapien nunc, faucibus. Vivamus vel vitae nunc sit in blandit aliquam eleifend erat. Senectus pharetra scelerisque pulvinar aliquam. Laoreet ante nam pharetra, vulputate. Amet eu aliquet tortor arcu donec purus. Feugiat condimentum hac fringilla dui sodales laoreet fermentum praesent bibendum. Libero mattis eros, at viverra erat purus. Ullamcorper amet.</p>
                                <button className="btn btn-transparent">See paper details  <span>
                                    <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                </span></button>

                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid managed-it-services-sec-3 artificial-intelligence-sec-7">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div>
{window.innerWidth>992?
                                    <h3>Is your industry in need of Enterprise AI?</h3>

:
<h3>Is your industry in<br/>need of Enterprise AI?</h3>
}                                    <p>For a scoping session with our AI specialists, contact us. </p>
                                </div>
                                <a href="../contact"> <button className="btn btn-transparent orange-btn">
                                    Plan Your Journey
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance