
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Route, Routes, Link , useNavigate} from "react-router-dom";
import './Layout.css';
import './Header.css'
import { navArray } from "../constants/navArray";
import NavSubList from "./NavSubList";
import $ from 'jquery';
import CookieConsent, { Cookies } from "react-cookie-consent";
import "../Pages/base.css"

import Footer from '../common-componets/Footer';

import Home from '../Pages/Home'
import WhatWeDo from '../Pages/WhatWeDo'
import WhyZogGlobal from '../Pages/WhyZogGlobal'
import Industries from '../Pages/Industries'
import ManagedITServices from '../Pages/ManagedITServices'
import QualityAssurance from '../Pages/QualityAssurance'
import AIAutomation from '../Pages/AIAutomation'
import Insight from '../Pages/Insight'
import Contact from '../Pages/Contact'
import NoPage from '../Pages/NoPage';
import PageUnderConstruction from '../Pages/PageUnderConstruction';

import TransformYourBusiness from "../Pages/TransformYourBusiness";
import ProtectYourCustomerData from "../Pages/ProtectYourCustomerData";
import EngagementModel from "../Pages/EngagementModel";

import Technology from "../Pages/Technology";
import Telecom from "../Pages/Telecom";
import Travel from "../Pages/Travel";
import Education from "../Pages/Education";
import Insurance from "../Pages/Insurance";
import Entertainment from "../Pages/Entertainment";
import Banking from "../Pages/Banking";
import Pharma from "../Pages/Pharma";
import Legal from "../Pages/Legal";
import HealthCare from "../Pages/HealthCare";


import Automation from "../Pages/Automation";
import DevOps from "../Pages/DevOps";
import ANS from "../Pages/ANS";
import DevSecOps from "../Pages/DevSecOps";
import Cloud from "../Pages/Cloud";
import CyberSecurity from "../Pages/CyberSecurity";
import SoftwareDevelopment from "../Pages/SoftwareDevelopment";
import Testing from "../Pages/Testing";
import UnifiedCommunication from "../Pages/UnifiedCommunication";

import AutomationTesting from "../Pages/AutomationTesting";
import MannualTesting from "../Pages/MannualTesting";
import PenetrationTesting from "../Pages/PenetrationTesting";
import PerfomanceTesting from "../Pages/PerfomanceTesting";
import ApplicationSecurityTesting from "../Pages/ApplicationSecurityTesting";
import AccessibilityTesting from "../Pages/AccessibilityTesting";

import ArtificialIntelligence from "../Pages/ArtificialIntelligence";
import AutomationRobotics from "../Pages/AutomationRobotics";
import WorkdeckAI from "../Pages/WorkdeckAI";

import Blog from "../Pages/Blog";
import BeAPartner from "../Pages/BeAPartner";
import Career from "../Pages/Career";
import CareerDetails from "../Pages/CareerDetails";



const Layout = () => {
const navigate=useNavigate()
    const [navSelected, setNavSelected] = useState(null)
    const [activeMainPageIndex, setActiveMainPageIndex] = useState(null)

    const navLeft = () => {
        setNavSelected(null)

    }
useEffect(()=>{
    console.log(navSelected,'navSelected')
},[navSelected])
    const [navOpen, setnavOpen] = useState(false);


    const toggleSubNav = () => {
        setnavOpen(!navOpen)
    }

    var LOGOURL;

    if (window.innerWidth < 768) {
        LOGOURL = "/images/zog-logo-mob-header.svg"
    }
    else if (window.innerWidth < 992)  {

        LOGOURL = "/images/zog-logo-tab-header.svg"
    }
    else{

        LOGOURL = "/images/zog-logo.svg"
    }

    

    useEffect(() => {
        if (window.innerWidth <= 992) {
            if (navOpen == true) {
                console.log("navOpen is true")
                $(".navbar").css("display", "block")
            }
            else {
                console.log("navOpen is false")
                $(".navbar").css("display", "none")
            }
        }

    }, [navOpen])

    $('.btn-login').on("click", function () {
        $('.btn-login').css({
            'background-color': '#26B2E3',

        });
    })
    $('.btn-login').on("mouseleave", function () {
        $('.btn-login').css({
            'background-color': '#222222',

        });
    })
    $('.btn-login').on("mouseenter", function () {
        $('.btn-login').css({
            'background-color': '#515151',

        });
    })


    $('.signup-btn').on("mouseenter", function () {
        $('.signup-btn').css({
            'background-color': '#515151',

        });
    })

    $('.signup-btn').on("click", function () {
        $('.signup-btn').css({
            'background-color': '#26B2E3',

        });
    })
    $('.signup-btn').on("mouseleave", function () {
        $('.signup-btn').css({
            'background-color': '#139DD9',

        });
    })


    return (
        < >

            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#FFF", color: "#191919", fontSize: "18px", fontWeight: "700", height: "fit-content", alignItems: "center", padding: "10px" }}
                buttonStyle={{
                    color: "#F6F6F6", background: "#139DD9", fontSize: "14px",
                    fontWeight: "700", padding: "0.667rem 2.4901rem",
                    borderRadius: "1.9565104rem"
                }}
                expires={150}
            >
                We uses cookies to enhance the user experience.{""}
                {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>
            <div className="header">

                <div className="top container ">
                    <div className="left" >
                       <img className="app-logo" src={LOGOURL} style={{cursor:"pointer"}} onClick={()=>{setActiveMainPageIndex(null); navigate("/")}}></img>
                        <div id="navbar-toggler" className={navOpen ? "navbar-toggler" : "collapsed navbar-toggler"} onClick={toggleSubNav}>
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </div>

                    </div>
                    {window.innerWidth > 992 ? <div className="right" >

                        <ul className="dropdown language-dropdown">
                            <li className="dropbtn"><div className="d-flex justify-content-between align-items-center"><img className="globe" src="/images/globe.png"></img><p>English</p><img className="down-arrow" src="/images/down-arrow.png"></img></div>
                            </li>
                            <li className="dropdown-content">
                                <a href="#">English</a>
                                <a href="#">French</a>
                                <a href="#">Chinese</a>
                            </li>
                        </ul>
                        <div className="header-btn-box">
                            <button className="btn btn-primary btn-login " onClick={() => { localStorage.setItem("userRoleRequest", "user"); navigate('/login') }}>Log In</button>
                            <button className="btn btn-primary signup-btn" onClick={() => navigate('/sign-up')}>Sign Up</button>
                        </div>
                    </div> : null}
                </div>

            </div>

            <div className="nav-box" onMouseLeave={() => navLeft()}>
                <nav className="navbar container">
                    {window.innerWidth <= 992 ? <div className="right" >

                        <ul className="dropdown language-dropdown">
                            <li className="dropbtn"><div className="d-flex justify-content-between align-items-center"><img className="globe" src="/images/globe.png"></img><p>English</p><img className="down-arrow" src="/images/down-arrow.png"></img></div>
                            </li>
                            <li className="dropdown-content">
                                <a href="#">English</a>
                                <a href="#">French</a>
                                <a href="#">Chinese</a>
                            </li>
                        </ul>
                        <div className="header-btn-box">
                            <button className="btn btn-primary " onClick={() => { localStorage.setItem("userRoleRequest", "user"); navigate('/login') }}>Log In</button>
                            <button className="btn btn-primary signup-btn" onClick={() => navigate('/sign-up')}>Sign Up</button>
                        </div>
                    </div> : null}
                    <ul>
                        {navArray.map((item, index) => {
                            return (
                                <>
                                    <li key={index} className={(navSelected == index) || (activeMainPageIndex == index) ? "active nav-item" : "nav-item"} ><Link to={item.path} onMouseOver={() => setNavSelected(index)} onClick={() => setActiveMainPageIndex(index)}>{item.label}</Link>

                                        {navSelected != null && (navArray[navSelected].subMenu.length != 0 && navSelected == index) ? <NavSubList navLeft={navLeft} navIndex={navSelected} setActiveMainPageIndex={setActiveMainPageIndex}></NavSubList> : null}
                                    </li> </>
                            )
                        })}
                    </ul>
                </nav>
            </div>
            <div className="app-content" >
               <Routes>
               <Route index  element={<Home/>} />
        <Route exact path="what-we-do" element={<WhatWeDo/>} />
        <Route exact path="why-zog-global" element={<WhyZogGlobal/>} />
        <Route exact path="industries" element={<Industries/>} />
        <Route exact path="digital-engineering" element={<PageUnderConstruction/>} />
        <Route exact path="managed-it-services" element={<ManagedITServices/>} />
        <Route exact path="quality-assurance" element={<QualityAssurance/>} />
        <Route exact path="ai-automation" element={<AIAutomation/>} />
        <Route exact path="insight" element={<Insight/>} />
        <Route exact path="contact" element={<Contact/>} />

        <Route path="what-we-do/transform-your-business" element={<TransformYourBusiness/>} />
        <Route path="what-we-do/protect-your-customer-data" element={<ProtectYourCustomerData/>} />
        <Route path="what-we-do/engagement-model" element={<EngagementModel/>} />

        <Route path="/industries/Healthcare" element={<HealthCare/>}/>
        <Route path="/industries/technology" element={<Technology/>}/>
        <Route path="/industries/banking" element={<Banking/>}/>
        <Route path="/industries/entertainment" element={<Entertainment/>}/>
        <Route path="/industries/education" element={<Education/>}/>
        <Route path="/industries/pharma" element={<Pharma/>}/>
        <Route path="/industries/legal" element={<Legal/>}/>
        <Route path="/industries/insurance" element={<Insurance/>}/>
        <Route path="/industries/travel" element={<Travel/>}/>
        <Route path="/industries/telecom" element={<Telecom/>}/>

        <Route path="/digital-engineering/automation" element={<Automation/>}/>
        <Route path="/digital-engineering/devops" element={<DevOps/>}/>
        <Route path="/digital-engineering/advanced-networking-services" element={<ANS/>}/>
        <Route path="/digital-engineering/devsecops" element={<DevSecOps/>}/>
        <Route path="/digital-engineering/cloud" element={<Cloud/>}/>
        <Route path="/digital-engineering/cyber-security" element={<CyberSecurity/>}/>
        <Route path="/digital-engineering/software-development" element={<SoftwareDevelopment/>}/>
        <Route path="/digital-engineering/testing" element={<Testing/>}/>
        <Route path="/digital-engineering/unified-communication" element={<UnifiedCommunication/>}/>
       
        <Route path="/quality-assurance/automation-testing" element={<AutomationTesting/>}/>
        <Route path="/quality-assurance/mannual-testing" element={<MannualTesting/>}/>
        <Route path="/quality-assurance/penetration-testing" element={<PenetrationTesting/>}/>
        <Route path="/quality-assurance/perfomance-testing" element={<PerfomanceTesting/>}/>
        <Route path="/quality-assurance/application-security-testing" element={<ApplicationSecurityTesting/>}/>
        <Route path="/quality-assurance/accessibility-testing" element={<AccessibilityTesting/>}/>

        <Route path="/ai-automation/artificial-intelligence" element={<ArtificialIntelligence/>}/>
        <Route path="/ai-automation/automation-robotics" element={<AutomationRobotics/>}/>
        <Route path="/ai-automation/workdeck-ai" element={<WorkdeckAI/>}/>

        <Route path="/insight/blog" element={<Blog/>}/>
        <Route path="/insight/be-a-partner" element={<BeAPartner/>}/>
        <Route path="/insight/career" element={<Career/>}/>
        <Route path="/insight/career-details" element={<CareerDetails/>}/>
        
        
        <Route path="*" element={<NoPage />} />
               </Routes>
            </div>

            <Footer></Footer>







        </>
    )
};


export default Layout;
