import React from "react";
import './NavSubList.css';
import { Link } from "react-router-dom";
import { navArray } from "../constants/navArray";




const NavSubList = ({ navIndex ,navLeft, setActiveMainPageIndex}) => {
    var columnCnt
    if ((window.innerWidth >= 992)) {
     columnCnt=Math.ceil(navArray[navIndex].subMenu.length/4);  // to get columnCount of ul to arrange in columns with max 4 elements
    }
    else{
        columnCnt=1
    }

    const ulStyle={
        
        
    }
   
    return (
        <div className="nav-sub-box">
            <div className="list-box">

        <ul className="dropdown-menu" style={{listStyle: "none", columnCount:columnCnt, display:"block"}}>
                {navArray[navIndex].subMenu.map((item, i) => {

                    return (<li key={i}><Link className="dropdown-item" to={item.path} onClick={()=>{navLeft();setActiveMainPageIndex(navIndex)}}>{item.label}</Link></li>)
                })}

</ul>
            </div>
            <div className="side-display" style={{ backgroundImage: `url("/images/nav-sub-bg.png")` }}>
                <h3>Have A Project In Mind?<br></br> Let's Talk!</h3>
                <a href="/contact"><p>Schedule a meeting<span><img src="/images/round-right-arrow.png"></img></span></p></a>
            </div>
        </div>
    )
}


export default NavSubList