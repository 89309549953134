
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Route, Routes, Link , useNavigate} from "react-router-dom";
import '../../user-panel/common-componets/Layout.css';
import '../../user-panel/common-componets/Header.css'
import { navArray } from "../constants/navArray";
import NavSubList from "./NavSubList";
import $ from 'jquery';
import CookieConsent, { Cookies } from "react-cookie-consent";






const Layout = () => {
const navigate=useNavigate()
    const [navSelected, setNavSelected] = useState(null)
    const [activeMainPageIndex, setActiveMainPageIndex] = useState(null)

    const navLeft = () => {
        setNavSelected(null)

    }
useEffect(()=>{
    console.log(navSelected,'navSelected')
},[navSelected])
    const [navOpen, setnavOpen] = useState(false);


    const toggleSubNav = () => {
        setnavOpen(!navOpen)
    }

    var LOGOURL;

    if (window.innerWidth < 768) {
        LOGOURL = "/images/zog-logo-mobile-header.svg"
    }
    else if (window.innerWidth < 992)  {

        LOGOURL = "/images/zog-logo-tab-header.svg"
    }
    else{

        LOGOURL = "/images/zog-logo.svg"
    }

    

    useEffect(() => {
        if (window.innerWidth <= 992) {
            if (navOpen == true) {
                console.log("navOpen is true")
                $(".navbar").css("display", "block")
            }
            else {
                console.log("navOpen is false")
                $(".navbar").css("display", "none")
            }
        }

    }, [navOpen])

    $('.btn-login').on("click", function () {
        $('.btn-login').css({
            'background-color': '#26B2E3',

        });
    })
    $('.btn-login').on("mouseleave", function () {
        $('.btn-login').css({
            'background-color': '#222222',

        });
    })
    $('.btn-login').on("mouseenter", function () {
        $('.btn-login').css({
            'background-color': '#515151',

        });
    })


    $('.signup-btn').on("mouseenter", function () {
        $('.signup-btn').css({
            'background-color': '#515151',

        });
    })

    $('.signup-btn').on("click", function () {
        $('.signup-btn').css({
            'background-color': '#26B2E3',

        });
    })
    $('.signup-btn').on("mouseleave", function () {
        $('.signup-btn').css({
            'background-color': '#139DD9',

        });
    })


    return (
        < >

            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#FFF", color: "#191919", fontSize: "18px", fontWeight: "700", height: "fit-content", alignItems: "center", padding: "10px" }}
                buttonStyle={{
                    color: "#F6F6F6", background: "#139DD9", fontSize: "14px",
                    fontWeight: "700", padding: "0.667rem 2.4901rem",
                    borderRadius: "1.9565104rem"
                }}
                expires={150}
            >
                We uses cookies to enhance the user experience.{""}
                {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>
            <div className="header">

                <div className="top container ">
                    <div className="left" >
                       <img className="app-logo" src={LOGOURL} style={{cursor:"pointer"}} onClick={()=>{setActiveMainPageIndex(null); navigate("/")}}></img>
                        <div id="navbar-toggler" className={navOpen ? "navbar-toggler" : "collapsed navbar-toggler"} onClick={toggleSubNav}>
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </div>

                    </div>
                    {window.innerWidth > 992 ? <div className="right" >

                        <ul className="dropdown language-dropdown">
                            <li className="dropbtn"><div className="d-flex justify-content-between align-items-center"><img className="globe" src="/images/globe.png"></img><p>English</p><img className="down-arrow" src="/images/down-arrow.png"></img></div>
                            </li>
                            <li className="dropdown-content">
                                <a href="#">English</a>
                                <a href="#">French</a>
                                <a href="#">Chinese</a>
                            </li>
                        </ul>
                        <div className="header-btn-box">
                            <button className="btn btn-primary btn-login ">Log In</button>
                            <button className="btn btn-primary signup-btn">Sign Up</button>
                        </div>
                    </div> : null}
                </div>

            </div>

            <div className="nav-box" onMouseLeave={() => navLeft()}>
                <nav className="navbar container">
                    {window.innerWidth <= 992 ? <div className="right" >

                        <ul className="dropdown language-dropdown">
                            <li className="dropbtn"><div className="d-flex justify-content-between align-items-center"><img className="globe" src="/images/globe.png"></img><p>English</p><img className="down-arrow" src="/images/down-arrow.png"></img></div>
                            </li>
                            <li className="dropdown-content">
                                <a href="#">English</a>
                                <a href="#">French</a>
                                <a href="#">Chinese</a>
                            </li>
                        </ul>
                        <div className="header-btn-box">
                            <button className="btn btn-primary ">Log In</button>
                            <button className="btn btn-primary signup-btn">Sign Up</button>
                        </div>
                    </div> : null}
                    <ul>
                        {navArray.map((item, index) => {
                            return (
                                <>
                                    <li key={index} className={(navSelected == index) || (activeMainPageIndex == index) ? "active nav-item" : "nav-item"} ><Link to={item.path} onMouseOver={() => setNavSelected(index)} onClick={() => setActiveMainPageIndex(index)}>{item.label}</Link>

                                        {navSelected != null && (navArray[navSelected].subMenu.length != 0 && navSelected == index) ? <NavSubList navLeft={navLeft} navIndex={navSelected} setActiveMainPageIndex={setActiveMainPageIndex}></NavSubList> : null}
                                    </li> </>
                            )
                        })}
                    </ul>
                </nav>
            </div>
            <div className="app-content" >
               <Routes>
              
        
               </Routes>
            </div>

           







        </>
    )
};


export default Layout;
