import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");
        
    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>
            <Banner
                bgUrl="/images/industries/travel/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={"Unlocking the Future of<br/>Travel with Technology"}
                content={
                    "With advancements in AI, machine learning, and data analysis, we unlock new opportunities for safe, efficient, and personalised travel experiences."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
            >

            </Banner>
            <CommonSection className="container healthcare-sec-5">
                <div className="row align-items-end">
                    <div className="col-lg-12">
                    <h3>ZOG Global's Approach to<br/>Embark on a Successful<br/>Digital Journey</h3>
                    </div>
                    <div className="col-lg-6">
                        
                        <p>When organisations set out on the path of digital transformation, it's crucial to begin with a thorough and all-encompassing assessment of their current standing. Our approach involves scrutinising the company's personnel, technology, and data, as well as its culture, leadership, and governance. We also take into account industry trends and potential disruptions that may impact the organisation. 
<br></br><br></br>With a clear understanding of these factors, we then collaborate with the organisation to develop a strategy that aligns its digital transformation with its desired business objectives and outcomes. We aim to ensure that the organisation's digital transformation is purposeful, impactful and leads to long-term success.
</p>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>We offer an array of IT services geared towards the travel industry, encompassing a range of comprehensive solutions.</b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li><b>Cybersecurity Services:</b> Drawing upon years of expertise, we've developed solutions to ensure the safety of businesses in this ever-changing digital age.</li>
                                <li><b>Automation Services:</b> Our team is well-equipped to assist you in optimising your processes and integrating cutting-edge technologies to ensure that you maintain a competitive edge. </li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li><b>Software Development:</b> Our company specialises in providing top-notch software development services for businesses operating within the travel industry. </li>
                                <li><b> Cloud Services: </b>ZOG Global provides a diverse range of cloud services to enhance the operations of businesses by optimising their efficiency and effectiveness.</li>
                            </div>
                        </ul>
                    </div>
                </div>

            </CommonSection>
            <CommonSection className="container-fluid travel-sec-2">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/industries/travel/sec-2-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Experience Unmatched Cloud-Based Travel Technology Services</h3>
                                        <p>Our Cloud First strategy is dedicated to assisting you in making a seamless transition to the cloud, which allows us to aid you in simplifying your operations and achieving your objectives effortlessly.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/industries/travel/sec-2-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Implement Digital Innovations and Enhance the Passenger Experience</h3>
                                        <p>Incorporating digital advancements into transportation systems can significantly improve passengers' overall satisfaction. By enhancing the passenger experience with technological solutions, such as mobile apps, automated check-in processes, and real-time updates, travellers can enjoy a smoother, more convenient journey.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                
                </Slider>
            </CommonSection>
            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Why Choose ZOG Global? </h3>

                        <p>From improved efficiency and productivity to cost savings and enhanced security, we are committed to delivering results that exceed your expectations.  </p>
                    </div>

                </div>
                <div className="row justify-content-between g-5">
                    <div className="col-md-5">

                        <div
                            className="tab-btn-container"

                        >
                            <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Close Relationship</button>
                            <button className="tab-btn" onClick={(e) => toggleTab(1)}>High-Quality 
                            </button>
                            <button className="tab-btn" onClick={(e) => toggleTab(2)}>Reduce Costs
                            </button>
                            <button className="tab-btn" onClick={(e) => toggleTab(3)}>Dedicated Team

                            </button>

                        </div>

                    </div>

                    <div className="col-md-6">


                        <div className="tab-content">
                            <h4>Close Relationship with Our Clients</h4>

                            <p>Our team is fully capable of addressing any inquiries or concerns that our esteemed customers may have. This commitment to customer service is what sets us apart from our competitors.</p>


                        </div>
                        <div className="tab-content">
                            <h4>High-Quality Customised Solutions </h4>

                            <p>Our utmost priority is to ensure that we always provide exceptional work that exceeds our customers' expectations. To ensure this, we have established a rigorous quality assurance and release management process for all our projects.</p>


                        </div>
                        <div className="tab-content">
                            <h4>Reduce Overall Costs </h4>

                            <p>Reduce your IT-related costs by tapping into our on-demand services. Our solutions allow you to optimise your resources and streamline your operations effectively.</p>


                        </div>
                        <div className="tab-content">
                            <h4>Highly Skilled & Dedicated Team</h4>

                            <p>We have a dedicated team who are confident in their skills and experience in the industry. Everything we do is built on our unshakable commitment to our clients.</p>


                        </div>
                        
                       
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container home-sec-2 technology-sec-6 travel-sec-4">
                <div className="row">
                    <div className="col-lg-4">
                        <div>

                            <h3>Digital Transformation in The Travel Industry</h3>
                            <p>Evaluating the results of current initiatives, understanding areas of need, and researching methods for improvement can help you create a roadmap for future digital transformation plans. </p>
                        </div>
                       
                    </div>

                    <div className="col-lg-8">
                        <div className="bg-box">
                            <h4>Showing the Way to<br/>New Heights </h4>
                            <div className="row g-0">
                                <div className="col-md-6">
                                    <h4>Implement Outcomes </h4>
                                    <p>
                                    We collaborate with businesses to optimise their use of digital technologies and enhance their work processes. Our team assists them in recognising the key areas to prioritise and implementing effective changes that can yield significant improvements.</p>

                                </div>
                                <div className="col-md-6">
                                    <h4>Human-Centred Design </h4>
                                    <p>
                                    We believe in harnessing human skills with cutting-edge technology. Our team is dedicated to creating a top-tier digital talent plan that spans data science to human-Centred design. Let's transform your business and dominate the digital world! </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </CommonSection>


        </>
    )
}

export default QualityAssurance