import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");
        
    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }




    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
      
    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            <CommonSection className="container de-banner mannual-testing-banner">
                <div className="row">

                    <div className="col-md-6 responsive-col-gap">
                        <h1>Manual Software Testing Services</h1>

                        <p>Our manual software testing services can ensure that your software is up to the performance standard. We will test your software carefully to find any possible bugs or issues that could cause problems for users and ensure it behaves as expected.</p>
                    </div>
                    <div className="col-md-6 responsive-col-gap">
                        <div className=" responsive-col-gap"></div>
                        <img src="/images/quality-assurance/mannual-testing/banner.png"></img>
                    </div>

                </div>

            </CommonSection>

            <CommonSection className="container mannual-testing-sec-1">
                <div className="row align-items-center g-5 cr">

                    <div className="col-lg-6">
                        <h3>A Holistic Approach to Assuring Quality Software</h3>

                        <p>Developing complex applications and systems comes with its own set of unique challenges. Companies need effective functional testing to guarantee the ‘perfect’ production quality for their software. In order to do this, manual testing services become an invaluable part of the development process. Manual testing services provide a comprehensive approach to ensuring quality assurance, involving an intricate blend of testing processes, tools, technical expertise, and domain-specific knowledge.
<br></br><br></br>
At ZOG Global, our manual testing services ensure that all parts of your application or system are subjected to thorough testing at each stage of the development process. Our team comprises highly trained and qualified professionals with extensive experience and technical knowledge in various industries. We also use advanced software automation tools so that your manual testing is done more efficiently and quickly.
<br></br><br></br>
We combine all the most advanced industry-leading tools with our own tested and matured testing processes and cutting-edge accelerators. The result? An end-to-end manual testing package that delivers outstanding quality product in the fastest time possible.</p>

                    </div>
                    <div className="col-lg-6 ">
                        <img src="/images/quality-assurance/mannual-testing/sec-1.png"></img>
                    </div>
                </div>
            </CommonSection>


            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2 mannual-testing-sec-2">
                <div className="row g-0">
                    <div className="col-lg-6">
                        <img src="/images/quality-assurance/mannual-testing/sec-2.png"></img>
                    </div>
                    <div className="col-lg-6 responsive-col-gap ">
                        <h3> What Sets Us Apart?</h3>

                        <p className="bold">Our robust and comprehensive QA processes provide a framework to ensure the successful delivery of your software products. By leveraging our well-defined quality assurance processes, you can be confident that you'll achieve your ideal business outcomes.</p>
                        <ul>
                            <li>Fast Onboarding: Walk us through your project details, and you'll get a concise list of all the information we need to start testing immediately</li>
                            <li>No long-term contract required: If all you need is a month of manual testing, then that's all it will be—we've got your back.</li>
                            <li>Effective communication: Our manual testers can communicate with all kinds of people, from project managers to developers to customer service representatives.</li>
                            <li>User experience: We also test the application from a user perspective, examining factors like layout, workflow, and usability other than finding bugs.</li>
                            <li>Client satisfaction: We are constantly improving and innovating our offerings to ensure that we strive to provide the best possible value for our clients.</li>
                        </ul>
                    </div>


                </div>
            </CommonSection>

            <CommonSection className="container industries-sec-3 automation-testing-sec-4 mannual-testing-sec-3">
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
{window.innerWidth>992?
                        <h3>Types Of Manual Testing We Provide</h3>
:
<h3>Types Of Manual Testing<br/>We Provide</h3>
}                    </div>
                </div>
                <div className="row g-0 gapless-box-container">

                    <div className="col-6">
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-1.svg"></img>
                            <p>User Experience<br/>Testing</p>
                        </div>
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-2.svg"></img>
                            <p>Usability Testing</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-3.svg"></img>
                            <p>Test Case Creation</p>
                        </div>
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-4.svg"></img>
                            <p>Cross-Browser<br/>Testing</p>
                        </div>

                    </div>
                </div>

                <div className="row g-0 gapless-box-container">
                    <div className="col-6">
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-5.svg"></img>
                            <p>GUI Testing</p>
                        </div>
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-6.svg"></img>
                            <p>DB Testing</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-7.svg"></img>
                            <p>Localisation Testing</p>
                        </div>
                        <div className="box">
                            <img src="/images/quality-assurance/mannual-testing/sec-3-8.svg"></img>
                            <p>Security Testing</p>
                        </div>
                    </div>
                </div>

            </CommonSection>

            <CommonSection className="container mannual-testing-sec-4">
                <div className="row">
                    <div className="col-12 text-center">
{window.innerWidth>992?
                        <h3>  How our Testing Keeps<br></br> Up with Development </h3>
:
<h3>  How our Testing<br></br> Keeps Up with Development </h3>
}                    </div>
                    <div className="col-12">
                        <img src="/images/quality-assurance/mannual-testing/sec-4.png"></img>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container healthcare-sec-1  ans-sec-2 mannual-testing-sec-5">
                <div className="row" >
                <div className="col-lg-6">
<h3>When You Need Manual Testing?</h3>
                            </div>
                            </div>
                            <div className="row g-5" >
                    <div className="col-lg-4">
                        <div className=" img-box">
                        <div className="mask">
                           

                           </div>
                        </div>
                       
                    </div>
                    <div className="col-lg-8 ">
                        <div className="gapless-box-container">
                            <div className="my-row">
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-start">
                                        <img src="/images/quality-assurance/mannual-testing/sec-5-icon.svg"></img>
                                        <h4>To test new<br/>functionality  </h4>

                                    </div>

                                  <li>If you're looking to get the most comprehensive testing possible, manual testing is the way to go.</li>
                                  <li>It's used to test new functionality as thoroughly and well as possible, using knowledge, ingenuity, and the human brain.</li>

                                </div>

                                <div className="col-lg-6">
                                    <div className="d-flex align-items-start">
                                        <img src="/images/quality-assurance/mannual-testing/sec-5-icon.svg"></img>
                                        <h4>To satisfy all<br/>customer's<br/>requirements </h4>

                                    </div>
                                  
                                  <li>Quality assurance engineers play an important role in testing products from the perspective of the audience.</li>
                                  <li>By conducting manual testing, they can ensure that the product is high quality and meets customer expectations.</li>
    </div>
                            </div>
                            <div className="my-row">
                                <div className="col-lg-6">

                                    <div className="d-flex align-items-start">
                                        <img src="/images/quality-assurance/mannual-testing/sec-5-icon.svg"></img>
                                        <h4>For subjective<br/>checking  </h4>

                                    </div>
                                  < li>User-friendliness and interface design are two important factors in any software application that requires subjective checking.</li>
                                  <li>While many automated testing tools can check for basic functionality, only humans can provide subjective feedback on User-friendliness and interface.</li>
                                 
                                </div>
                                <div className="col-lg-6">

                                    <div className="d-flex align-items-start">
                                        <img src="/images/quality-assurance/mannual-testing/sec-5-icon.svg"></img>
                                        <h4>For short-term<br/>projects  </h4>

                                    </div>
                                   
                                  <li>If a project is small and short-term, it is more cost-effective to apply manual testing than test automation.</li>
                                  <li>Also, manual testing is sufficient to swiftly and completely examine a relatively small product.</li>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                <h3>Need Quality Manual Testing Services?</h3>
                                <p>Ensure your software runs efficiently and provides the best user experience with our manual software testing services.</p>
                                
                                <a href="../contact"><button className="btn btn-transparent">
                                Talk to our Experts
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance