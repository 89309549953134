import React from 'react'
import ProgressFooter from "./ProgressFooter"


const email = () => {
  return (
   <>
    <div className='container d-flex flex-column justify-content-center align-items-center email-section '>
    {window.innerWidth <= 768 ?
        <ProgressFooter label="NEXT : UPLOAD RESUME" width="35%" url="/sign-up"/>
            :
        <p></p>
        }
        <div className='row verify-row'>
        <div className='col-lg-3 col-md-4 col-6 '>
                <img src="/images/verify-email.png" className='title-img' alt=""/>
            </div>
            <div className='col-12 mt-4'>
                <h1>Verify Your Email Address</h1>
                <p className='mt-4 mb-4'>Complete the registration process by verifying your email address </p>
                <div className='col-12'>
                    <button className='btn home-book-button'>Verify your email<span className='ms-1'><img src="/images/arrow 1.svg" alt="" /></span></button>
                </div>
            </div>
        </div>
    </div>
    {window.innerWidth > 768 ?
        <ProgressFooter label="NEXT : UPLOAD RESUME" width="35%" url="/sign-up"/>
            :
        <p></p>
        }
   </>
  )
}

export default email
