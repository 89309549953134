import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [tabSelected, setTabSelected] = useState(0);


  useEffect(()=>{
    $(".tab-btn").removeClass("active");
    $(".tab-btn").eq(tabSelected).addClass("active");


    
   

  },[tabSelected]) 


    function toggleTab(index) {
        setTabSelected(index);

    }






    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            {window.innerWidth > 768 ? <CommonSection className="container de-banner devsecops-banner pt-5">
                <div className="row">

                    <div className="col-lg-6 responsive-col-gap">
                        <h3>DevSecOps as a Service</h3>

                        <p>DevSecOps is a methodology that combines Development, Security, and Operations in order to deliver more secure software faster. By integrating security into the software development process, we can help you to identify and fix vulnerabilities before they become a problem. </p>
                    </div>
                    <div className="col-lg-6 responsive-col-gap">
                        <div className=" responsive-col-gap"></div>
                        <img src="/images/digital-engineering/devsecops/banner.png"></img>
                    </div>

                </div>

            </CommonSection>
                :
                <Banner
                    bgUrl="/images/digital-engineering/devsecops/banner.png"
                    theme="dark"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"DevSecOps as a Service"}
                    content={
                        "DevSecOps is a methodology that combines Development, Security, and Operations in order to deliver more secure software faster. By integrating security into the software development process, we can help you to identify and fix vulnerabilities before they become a problem. "
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-start"
                    mobileTextAlign="left"
                >

                </Banner>}

            {window.innerWidth>992?
            <CommonSection className="container engagement-model-sec-1 devsecops-sec-1">
            <div className="row">


                <div className="col-lg-12 tab-btn-container responsive-col-gap">
                    <button className="tab-btn active" onClick={(e) => toggleTab(0)}>
                        DevSecOps Consulting
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(1)}>
                        CI/CD Pipeline
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(2)}>
                        Automation
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(3)}>
                        Infrastructure as Code (IaC)
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(4)}>
                        Logging and Monitoring
                    </button>
                    <button className="tab-btn" onClick={(e) => toggleTab(5)}>
                        ASOC
                    </button>

                </div>


            </div>


{tabSelected===0&&
<div className="tab-content">
                <div className="row ">
                    <div className="col-lg-6">

                        <h3>DevSecOps Consulting</h3>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>We are well-positioned to design and implement a robust cloud security plan that meets all your needs.</b></p>
                        <ul className="ps-4 w-100 d-flex justify-content-between">

                            <li>We help you identify places where cloud-native security measures can work in tandem with your development process to ensure that security remains a priority.</li>
                            <li>Our DevSecOps team will build a comprehensive strategy to increase your security sophistication and the tools used. This plan is designed to ensure your business is secure against potential threats.</li>


                        </ul>
                        <ul className=" ps-4 d-flex justify-content-between w-100">
                            <li>Our team has an abundance of expertise and experience working with companies of any size across several industries.</li>
                            <li>We provide comprehensive implementation and management of your cloud security solutions and procedures.</li>
                        </ul>
                    </div>
                </div>
            </div>}
            

            {tabSelected===1&&
            <div className="tab-content">
            <div className="row ">
                <div className="col-lg-6">

                    <h3>CI/CD Pipeline</h3>

                </div>
                <div className="col-lg-6">
                    <p ><b>CI/CD helps to automate and streamline the software development and delivery process, making it easier and faster to get new features and updates out to users.</b></p>
                    <ul className="ps-4 w-100">
                        <div className="d-flex justify-content-between w-100">
                            <li>CI/CD creates a secure, automated workflow for code development, testing, and product deployment.</li>
                            <li>The process also establishes a feedback loop between the development and operations teams, assisting them in promptly identifying and resolving issues.</li>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <li>Rapidly resolving problems is beneficial for any business, minimising the likelihood of service disruption and boosting customer satisfaction.</li>
                            <li>The whole process is an essential part of DevSecOps which helps improve the quality of the code and reduces delivery time.</li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
}
            
            {tabSelected===2&&
            <div className="tab-content">
            <div className="row ">
                <div className="col-lg-6">

                    <h3>Automation</h3>

                </div>
                <div className="col-lg-6">
                    <p ><b>DevSecOps automation can facilitate the uniform adoption of security features and minimise remedial tasks.</b></p>
                    <ul className="ps-4 d-flex justify-content-between w-100">

                        <li>Automation frameworks allow applications to be integrated with additional security functions to conduct tests before pushing them into production.</li>
                        <li>Low-level and remedial tasks are good targets for improvements that can be achieved with automation.</li>

                        <div className="d-flex justify-content-between w-100">
                            <li>Automated code verification checks are essential components of DevSecOps frameworks, and they can help reduce the risks associated with fast-paced deployment.</li>
                            <li>A mature DevSecOps automation process removes the need for developers to interact with IT security staff when vulnerabilities are identified directly.</li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>}
            

            {tabSelected===3&&
            <div className="tab-content">
            <div className="row ">
                <div className="col-lg-6">

                    <h3>Infrastructure as Code (IaC)</h3>

                </div>
                <div className="col-lg-6">
                    <p ><b>Infrastructure-as-Code (IaC) is a cutting-edge set of practices that entails designing and creating templates to deploy technology infrastructure. </b></p>
                    <ul className="ps-4 w-100">
                        <div className="d-flex justify-content-between w-100">
                            <li>Infrastructure as Code (IaC) enables organisations to replicate their deployment infinitely by running the appropriate code multiple times.</li>
                            <li>By unifying operations and development, IaC simplifies and automates the entire process while also providing efficient asset management capabilities.</li>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <li>Integrating IaC into DevSecOps strategies can be a practical approach to improving the overall quality and security of applications and workloads.</li>
                            <li>Storing infrastructure as code facilitates the use of version control and allows for easier tracking of all changes made, as well as the current state of the infrastructure.</li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>}
            

            {tabSelected===4&&
            <div className="tab-content">
            <div className="row ">
                <div className="col-lg-6">

                    <h3>Logging and Monitoring</h3>

                </div>
                <div className="col-lg-6">
                    <p ><b>Logging and log analysis play an essential role in achieving and maintaining application security. </b></p>
                    <ul className="ps-4 w-100">
                        <div className="d-flex justify-content-between w-100">
                            <li>Logging provides information on an application's internal workings and development lifecycle, such as when code was updated, pushed into production, or modified.</li>
                            <li>The monitoring, auditing, logging, and alerting of infrastructure are essential for any ITOps or software development operations.</li>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <li>We're able to provide custom Logging and Monitoring solutions which can be integrated into your existing code pipeline and infrastructure.</li>
                            <li>Our experienced monitoring team is available to provide the proper infrastructure monitoring in line with your desired specifications.</li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>}
            

            {tabSelected===5&&
            <div className="tab-content">
            <div className="row ">
                <div className="col-lg-6">

                    <h3>ASOC</h3>

                </div>
                <div className="col-lg-6">
                    <p ><b>An essential AppSec solution, Application Security Orchestration and Correlation (ASOC) uses workflow automation to increase the effectiveness of vulnerability testing.</b></p>
                    <ul className="ps-4 w-100">
                        <div className="d-flex justify-content-between w-100">
                            <li>It helps AppSec teams streamline the processes of vulnerability testing and remediation by automating workflows and providing intelligent insights.</li>
                            <li>With ASOC, it's easier to keep up with the ever-changing threat landscape, enabling organisations to protect their applications more effectively.</li>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <li>An ASOC solution can significantly contribute to cost savings and resource optimization as it allows for critical prioritisation of findings.</li>
                            <li>It can combine and correlate data from various AppSec tools and manual testing in one central hub.</li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>}
            


        </CommonSection>
        :
        <CommonSection className="container engagement-model-sec-1 devsecops-sec-1">
                <div className="row">


                    <div className="col-lg-12 tab-btn-container responsive-col-gap">
                        <button className="tab-btn active" onClick={(e) => toggleTab(0)}>
                            DevSecOps Consulting
                        </button>

                        {tabSelected===0&&
    <div className="tab-content">
                    <div className="row ">
                        <div className="col-lg-6">

                            <h3>DevSecOps Consulting</h3>

                        </div>
                        <div className="col-lg-6">
                            <p ><b>We are well-positioned to design and implement a robust cloud security plan that meets all your needs.</b></p>
                            <ul className="ps-4 w-100 d-flex justify-content-between">

                                <li>We help you identify places where cloud-native security measures can work in tandem with your development process to ensure that security remains a priority.</li>
                                <li>Our DevSecOps team will build a comprehensive strategy to increase your security sophistication and the tools used. This plan is designed to ensure your business is secure against potential threats.</li>


                            </ul>
                            <ul className=" ps-4 d-flex justify-content-between w-100">
                                <li>Our team has an abundance of expertise and experience working with companies of any size across several industries.</li>
                                <li>We provide comprehensive implementation and management of your cloud security solutions and procedures.</li>
                            </ul>
                        </div>
                    </div>
                </div>}
                        <button className="tab-btn" onClick={(e) => toggleTab(1)}>
                            CI/CD Pipeline
                        </button>

                        {tabSelected===1&&
                <div className="tab-content">
                <div className="row ">
                    <div className="col-lg-6">

                        <h3>CI/CD Pipeline</h3>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>CI/CD helps to automate and streamline the software development and delivery process, making it easier and faster to get new features and updates out to users.</b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li>CI/CD creates a secure, automated workflow for code development, testing, and product deployment.</li>
                                <li>The process also establishes a feedback loop between the development and operations teams, assisting them in promptly identifying and resolving issues.</li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li>Rapidly resolving problems is beneficial for any business, minimising the likelihood of service disruption and boosting customer satisfaction.</li>
                                <li>The whole process is an essential part of DevSecOps which helps improve the quality of the code and reduces delivery time.</li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
}
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>
                            Automation
                        </button>
                        {tabSelected===2&&
                <div className="tab-content">
                <div className="row ">
                    <div className="col-lg-6">

                        <h3>Automation</h3>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>DevSecOps automation can facilitate the uniform adoption of security features and minimise remedial tasks.</b></p>
                        <ul className="ps-4 d-flex justify-content-between w-100">

                            <li>Automation frameworks allow applications to be integrated with additional security functions to conduct tests before pushing them into production.</li>
                            <li>Low-level and remedial tasks are good targets for improvements that can be achieved with automation.</li>

                            <div className="d-flex justify-content-between w-100">
                                <li>Automated code verification checks are essential components of DevSecOps frameworks, and they can help reduce the risks associated with fast-paced deployment.</li>
                                <li>A mature DevSecOps automation process removes the need for developers to interact with IT security staff when vulnerabilities are identified directly.</li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>}

                        <button className="tab-btn" onClick={(e) => toggleTab(3)}>
                            Infrastructure as Code (IaC)
                        </button>
                        {tabSelected===3&&
                <div className="tab-content">
                <div className="row ">
                    <div className="col-lg-6">

                        <h3>Infrastructure as Code (IaC)</h3>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>Infrastructure-as-Code (IaC) is a cutting-edge set of practices that entails designing and creating templates to deploy technology infrastructure. </b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li>Infrastructure as Code (IaC) enables organisations to replicate their deployment infinitely by running the appropriate code multiple times.</li>
                                <li>By unifying operations and development, IaC simplifies and automates the entire process while also providing efficient asset management capabilities.</li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li>Integrating IaC into DevSecOps strategies can be a practical approach to improving the overall quality and security of applications and workloads.</li>
                                <li>Storing infrastructure as code facilitates the use of version control and allows for easier tracking of all changes made, as well as the current state of the infrastructure.</li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>}

                        <button className="tab-btn" onClick={(e) => toggleTab(4)}>
                            Logging and Monitoring
                        </button>
                        {tabSelected===4&&
                <div className="tab-content">
                <div className="row ">
                    <div className="col-lg-6">

                        <h3>Logging and Monitoring</h3>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>Logging and log analysis play an essential role in achieving and maintaining application security. </b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li>Logging provides information on an application's internal workings and development lifecycle, such as when code was updated, pushed into production, or modified.</li>
                                <li>The monitoring, auditing, logging, and alerting of infrastructure are essential for any ITOps or software development operations.</li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li>We're able to provide custom Logging and Monitoring solutions which can be integrated into your existing code pipeline and infrastructure.</li>
                                <li>Our experienced monitoring team is available to provide the proper infrastructure monitoring in line with your desired specifications.</li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>}

                        <button className="tab-btn" onClick={(e) => toggleTab(5)}>
                            ASOC
                        </button>

                        {tabSelected===5&&
                <div className="tab-content">
                <div className="row ">
                    <div className="col-lg-6">

                        <h3>ASOC</h3>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>An essential AppSec solution, Application Security Orchestration and Correlation (ASOC) uses workflow automation to increase the effectiveness of vulnerability testing.</b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li>It helps AppSec teams streamline the processes of vulnerability testing and remediation by automating workflows and providing intelligent insights.</li>
                                <li>With ASOC, it's easier to keep up with the ever-changing threat landscape, enabling organisations to protect their applications more effectively.</li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li>An ASOC solution can significantly contribute to cost savings and resource optimization as it allows for critical prioritisation of findings.</li>
                                <li>It can combine and correlate data from various AppSec tools and manual testing in one central hub.</li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>} 

                    </div>


                </div>



                


                
   
                

                
                

                
                

                
                


            </CommonSection>
            }


            <CommonSection className="container telecom-sec-1 devsecops-sec-2">
                <div className="row g-0 cr">

                    <div className="col-lg-7  border">
                        <h3> Why Should You Adopt DevSecOps?</h3>

                        <p className="bold">The rise of DevSecOps has created software development cultures that are faster, more secure, and more resilient. As software teams move from traditional development processes to a DevSecOps approach, they gain many advantages from the way security is handled from the start of an application's lifecycle.</p>
                        <ul>
                            <li>DevSecOps improves automation and streamlines processes by integrating security into the development workflow.</li>
                            <li>Automated processes and security testing ensure that flaws are identified quickly and resolved before they become problems.</li>
                            <li>Speed up incident response and are capable of alerting organisations of potential issues before significant problems occur.</li>
                            <li>Facilitates continuous improvement by enabling organizations to make iterative improvements in their security posture.</li>
                            <li>DevSecOps also creates a more collaborative environment in which security is considered everyone's responsibility.</li>
                        </ul>
                    </div>

                    <div className="col-lg-5">
                        <img src="/images/digital-engineering/devsecops/sec-2.png"></img>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container devops-sec-4 devsecops-sec-3">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="box">
                            <h3 className="orange-line">Core Benefits of Our DevSeCops Services</h3>
                        </div>

                    </div>
                    {window.innerWidth > 992 ?
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>Reduce overall time to develop and deploy new features and the overall compliance costs for an organisation.</p>
                                            </div>
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>Companies can assure faster recovery in the event of a security attack by continuously monitoring networks and data.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>Our DevSecOps services can help you speed up your application development and delivery while reducing risk.</p>
                                            </div>
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>Development teams can accelerate releases and improve your software delivery process by leveraging DevSecOps. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>We emphasize the importance of performing security checks, continuous security monitoring, and automated deployment tests from the very start. </p>
                                            </div>
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>Detailed information on your systems'performance gives you a better understanding of your application. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>The early stages of development are characterised by enhanced transparency, allowing for efficient tracking and collaboration.</p>
                                            </div>
                                            <div className="box">
                                                <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                                <p>Businesses can leverage enhanced and automated security throughout their software development process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-lg-9">
                            <div className="row">


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>Reduce overall time to develop and deploy new features and the overall compliance costs for an organisation.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>Companies can assure faster recovery in the event of a security attack by continuously monitoring networks and data.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>Our DevSecOps services can help you speed up your application development and delivery while reducing risk.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>Development teams can accelerate releases and improve your software delivery process by leveraging DevSecOps. </p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>We emphasize the importance of performing security checks, continuous security monitoring, and automated deployment tests from the very start. </p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>Detailed information on your systems'performance gives you a better understanding of your application. </p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>The early stages of development are characterised by enhanced transparency, allowing for efficient tracking and collaboration.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/devops/sec-4-icon.svg"></img>
                                        <p>Businesses can leverage enhanced and automated security throughout their software development process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </div>
            </CommonSection>

            <CommonSection className="container-fluid  devsecops-sec-4">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/digital-engineering/devsecops/sec-4-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>The Emergence of DIY DevSecOps Platforms </h3>
                                        <p>The DevOps era has seen significant strides in recent years, with businesses being able to quickly and efficiently deploy their workloads using virtual machines. Yet one aspect that may go overlooked is security. To address this issue, the goal of providing DIY DevSecOps platforms has emerged so programmers can build CI-CD pipelines and application monitoring dashboards. In order to move towards this objective, businesses are investing heavily in DevSecOps capabilities and outlining long-term strategies for its deployment.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/digital-engineering/devsecops/sec-4-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>A Comprehensive Solution </h3>
                                        <p>At ZOG Global, we specialise in more than just DevSecOps. Our expert team has a fortune of experience in software development, data privacy, data analytics, and penetration testing. This means that we are able to offer our clients a comprehensive solution that covers all aspects of their digital security needs.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>





                </Slider>
            </CommonSection>

            <CommonSection className="container devops-sec-5 devsecops-sec-5">
                <h3>DevOps to DevSecOps<br/>Transition </h3>
                <div className="row g-5">
                    <div className="col-lg-6 responsive-col-gap">
                        <p>As more organisations adopt DevOps, the focus is now on expanding its principles to embrace security practices, resulting in the emergence of DevSecOps. By shifting the mentality of security right from the beginning of the development cycle, DevSecOps seeks to incorporate security into the DevOps foundation - rather than bolting it on afterward.</p>
                        <button className="btn btn-borderless btn-icon-changer-black">
                            Learn More

                        </button>
                        <img src="/images/digital-engineering/devsecops/sec-5.png" className="mt-4"></img>
                    </div>
                    <div className="col-lg-6">
                        <div className="strip">
                            <div className="d-flex align-items-center">
                                <img src="/images/digital-engineering/devops/sec-5-icon.svg" md-6 alt="icon"></img>
                                <h4>Detailed Analysis of Current Security<br/>Measures</h4>
                            </div>
                            <p>Security teams are helped with in-depth study of the sensitive levels of an organisation's overall security posture as well as assets by threat modelling, monitoring, and end-to-end risk assessments.</p>
                        </div>
                        <div className="strip">
                            <div className="d-flex align-items-center">
                                <img src="/images/digital-engineering/devops/sec-5-icon.svg" md-6 alt="icon"></img>
                                <h4>Security and DevOps<br/>integration</h4>
                            </div>
                            <p>The incorporation of security practices and automation is essential in leading to a smooth development workflow and avoiding or reducing any potential disruptions. This supports continuous delivery and helps ensure reliable results.</p>
                        </div>

                        <div className="strip">
                            <div className="d-flex align-items-center">
                                <img src="/images/digital-engineering/devops/sec-5-icon.svg" md-6 alt="icon"></img>
                                <h4>Including DevSecOps<br/>in Security Operations</h4>
                            </div>
                            <p>An ongoing review of all potential security risks at each stage of development ensures all compliance regulations are met promptly, and any related issues are addressed promptly.</p>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container  education-sec-4 devsecops-sec-6">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Explore more about DevSecOps</h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                </div>
                </div>
                
            </CommonSection>

        </>
    )
}

export default QualityAssurance