import React from 'react'
import {  Link } from "react-router-dom";


const Footer = (props ) => {

  return (

    window.innerWidth>768?

        <div className='container-fluid footer mt-2 '>
        <div className='row'>
          <div className='col-12 '>
              <hr className='footer-line'></hr>
              <hr className='blue-line' style={{width:props.width}}></hr>
          </div>
        </div>
        <div className='row d-flex justify-content-between p-4 mb-4'>
              <div className='col-2 mt-3'>
                  {props.url ? 
                  <Link to={props.url}><button className='btn disagree'>BACK</button></Link>    :
                  <p></p>
              }
              </div>
              <div className='col-3'>
                  <button className='btn verify-btn'>{props.label}</button>
              </div>
        </div>
        </div> 

        
            :
            <div className='container-fluid p-0'>
                <div className='row'>
                    <div className='col-12 mt-0 p-0'>
                        <hr className='footer-line'></hr>
                        <hr className='blue-line' style={{width:props.width}}></hr>
                    </div>
                    <div className='col-12 '>
                    {props.image ? 
                    <button className='btn '><img src={props.image} alt=""/></button>
                                          :
                  <span></span>
              }
                    </div>
                    <div className='col-12 d-flex justify-content-center tab-btn'>
                    {props.label ? 
                     <button className='btn verify-btn top-btn mb-3'>{props.label}</button>
                                          :
                  <span></span>
              }
                       
                    </div>
                </div>
            </div>
            
    
    
  )
}

export default Footer
