import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {






    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    return (
        <>


            <Banner
                bgUrl="/images/quality-assurance/penetration-testing/banner.png"
                theme="dark"
                txtAlign="center"
                paddingProp={{ top: "8.6042", bottom: "8" }}
                heading={window.innerWidth > 992 ?
                    "Penetration Testing Services "
                    :
                    "Penetration<br/>Testing Services "}
                content={window.innerWidth > 992 ?
                    "With our pen testing services, you can gain a first-hand understanding of how attackers might take advantage of your vulnerabilities in the real world. We offer a comprehensive evaluation to help you identify potential weaknesses and take the necessary precautions to protect your systems and data."
                    :
                    "With our pen testing services, you can gain a first-hand <br/>understanding of how attackers might take advantage of your<br/> vulnerabilities in the real world. We offer a comprehensive<br/> evaluation to help you identify potential weaknesses and take the<br/> necessary precautions to protect your systems and data."


                }

                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
            >

            </Banner>

            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2 penetration-testing-sec-1">
                <div className="row">
                    <div className="col-lg-7">
                        <h3>Eliminate All Security Weaknesses and Stay Compliant </h3>
                        <p>Our team of expert penetration testers will simulate cyber-attacks against your organisation’s software and hardware to identify exploitable flaws or logic errors that might leave you vulnerable to hackers. </p>
                    </div>
                </div>
                <div className="row align-items-center g-5 justify-content-between">
                    <div className="col-lg-6">
                        <img src="/images/quality-assurance/penetration-testing/sec-1.png"></img>
                    </div>
                    <div className="col-lg-6 responsive-col-gap ">
                        {window.innerWidth > 992 ?
                            <h4>Why Choose Us For Penetration Testing </h4>

                            :
                            <h4>Why Choose Us For<br />Penetration Testing </h4>
                        }                                               <ul>
                            <li>Our penetration testing services encompass a comprehensive approach to providing clients with a thorough understanding of their security posture. </li>
                            <li>We strive to provide top-notch service in the areas of security consulting, assessment and implementation. </li>
                            <li>We ensure we craft a solution that meets all the requirements, allowing you to achieve your business goals efficiently and effectively. </li>
                            <li>We provide a detailed report that outlines our findings and recommendations to help mitigate security risks. </li>
                            <li>Our penetration testing services help organisations comply with regulatory requirements like HIPAA, PCI DSS, SOC 2, and GDPR. </li>
                            <li>We prioritise customer satisfaction by providing open communication throughout the testing process. </li>


                        </ul>
                    </div>


                </div>
            </CommonSection>

            <CommonSection className="container  education-sec-4 penetration-testing-sec-2">
                <div className="row" >
                    <div className="col-lg-6">
                        {window.innerWidth > 992 ?
                            <h3>Types of Penetration Testing Services We Offer </h3>

                            :
                            <h3>Types of Penetration<br />Testing Services We Offer </h3>
                        }                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row ">

                        <div className="col-4">
                            <div className="box">
                                <div className="special-border">
                                    <h4 className="orange-line">Objective-Based Penetration Testing .</h4>
                                </div>
                                <ul>
                                    <li>It is based on the specific goals and objectives of the organisation, and the pen testing team focuses on identifying potential vulnerabilities that can be exploited to achieve those objectives. </li>
                                    <li>This approach provides a more in-depth understanding of the organisation's security posture and helps develop a more resilient security framework. </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box">
                                <div className="special-border">
                                    <h4 className="orange-line">Infrastructure Penetration Testing </h4>
                                </div>
                                <ul>
                                    <li>The testing focuses on identifying the vulnerabilities in the network infrastructure, hardware, software, and configurations. It helps identify the most common and complex security gaps in the infrastructure.  </li>
                                    <li>A significant feature of this approach is risk analysis. It is essential for effective risk mitigation strategies, which include understanding the impact and cost of the security breach and mitigation measures. </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <div className="special-border">
                                    <h4 className="orange-line">Ransomware Penetration Testing </h4>
                                </div>
                                <ul>
                                    <li>Identifying system vulnerabilities allows for defence against ransomware by discovering security loopholes, misconfigurations, and unpatched software. </li>
                                    <li>It mimics an actual attack to test an organization's response plan. This is done in a controlled environment with a test strain to validate backup and disaster recovery strategies. </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </CommonSection>

            {window.innerWidth > 992 ?
                <CommonSection className="container  education-sec-4 penetration-testing-sec-3">
                    <div className="row">
                        <div className="col-lg-4">
                            <h3>Mitigate Breach Risk with an Objective-Based Approach </h3>
                            <p>If you're concerned about the likelihood of a data breach, one way to reduce the risk is to conduct an objective-based penetration test. This type of test is designed to simulate a real-world attack and can help you identify potential vulnerabilities in your systems and processes. </p>

                        </div>
                        <div className="col-lg-4">
                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-1.svg"></img>
                                <h4>Precise Replication </h4>
                                <p>We replicate both internal and external attacks carried out by malicious parties. </p>
                            </div>
                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-2.svg"></img>
                                <h4>Security Assessment </h4>
                                <p>We evaluate how safe an organisation's systems, networks, and applications are from threats. </p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-3.svg"></img>
                                <h4>Enhanced Security </h4>
                                <p>Our OBPT testing improves security by finding and fixing vulnerabilities before hackers can exploit them. </p>
                            </div>

                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-4.svg"></img>
                                <h4>Recommendations </h4>
                                <p>Assessment at the highest level, including recommendations to strengthen security posture. </p>
                            </div>
                        </div>
                    </div>
                </CommonSection>
                :
                <CommonSection className="container  education-sec-4 penetration-testing-sec-3">
                    <div className="row">
                        <div className="col-lg-4">
                            <h3>Mitigate Breach Risk with an Objective-Based Approach </h3>
                            <p>If you're concerned about the likelihood of a data breach, one way to reduce the risk is to conduct an objective-based penetration test. This type of test is designed to simulate a real-world attack and can help you identify potential vulnerabilities in your systems and processes. </p>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-1.svg"></img>
                                <h4>Precise Replication </h4>
                                <p>We replicate both internal and external attacks carried out by malicious parties. </p>
                            </div>
                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-2.svg"></img>
                                <h4>Security Assessment </h4>
                                <p>We evaluate how safe an organisation's systems, networks, and applications are from threats. </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-3.svg"></img>
                                <h4>Enhanced Security </h4>
                                <p>Our OBPT testing improves security by finding and fixing vulnerabilities before hackers can exploit them. </p>
                            </div>

                            <div className="box border">
                                <img src="/images/quality-assurance/penetration-testing/sec-3-4.svg"></img>
                                <h4>Recommendations </h4>
                                <p>Assessment at the highest level, including recommendations to strengthen security posture. </p>
                            </div>
                        </div>
                    </div>
                </CommonSection>
            }
            <CommonSection className="container soft-dev-sec-1 penetration-testing-sec-4">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center">Highlights of Object-Based<br></br> Penetration Testing Approach </h3>
                    </div>

                </div>



                <div className="row gapless-box-container g-0">
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Infrastructure Testing </h4>
                            <p>Involves testing the security of the organisation's network architecture, hardware and software, and related services. </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Application Testing </h4>
                            <p>Finding vulnerabilities in an application to identify potential security risks.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Social Engineering </h4>
                            <p>Identifying weaknesses in the behaviour of employees and other individuals within the organisation. </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Card Cloning </h4>
                            <p>Test your access badges to detect any potential replay attacks or cloning. </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Device Planting </h4>
                            <p>Assess the response of your facility's network when a new device is connected. </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Email Phishing </h4>
                            <p>Find out where an attacker could take advantage of a credential or endpoint vulnerability. </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Tailgating </h4>
                            <p>Conduct a test of the physical controls to ensure that unauthorised individuals cannot gain access to your facilities. </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Wireless</h4>
                            <p>Test your wireless configuration and password to see if they are appropriately set up.
                            </p>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <h4>Usb Device Drops</h4>
                            <p>Determine whether a user would plug in an unknown USB to their computer.</p>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container penetration-testing-sec-5">
                <div className="row g-0 gapless-box-container">
                    <div className="col-12">

                        <table>
                            <tr  VALIGN={"TOP"}>
                                <th> <h3>Objective-Based Penetration Testing Comparison Chart</h3></th>
                                <th>
                                    <h4 className="orange-line">Infrastructure Penetration Testing </h4>
                                </th>
                                <th>
                                    <h4 className="orange-line">Objective-Based Penetration Testing </h4>
                                </th>
                            </tr>

                            <tr>
                            <td>Comprehensive Assessment of Networks and Systems</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Network Security</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>System Hardening</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>OS and Third-Party Patching</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Authentication Attacks</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Cryptography Attacks</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Email Phishing</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Ransomware Assessment</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>ctive Directory Bloodhound Assessment</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Active Directory Password Audit</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Antivirus Bypass</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Adversary Simulation</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Physical Security Attacks</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>

                            
                            <tr>
                            <td>Social Engineering (Phone/ In-person)</td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            <td><img src="/images/quality-assurance/penetration-testing/sec-5.svg"></img></td>
                            </tr>
      
                        </table>
                    </div>
                </div>


            </CommonSection >

            <CommonSection className="container  education-sec-4 penetration-testing-sec-6">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Related Insights </h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                </div>
                </div>
            </CommonSection>


            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

{window.innerWidth>992?
                                <h3>Ready To Take<br/>The Next Step?  </h3>
:
<h3>Ready To Take<br/>The Next Step?  </h3>
}                                <p>If you need help with testing or preparing for incidents, or learn<br />more about our testing services, talk to one of our experts. </p>
                                <button className="btn btn-transparent">
                                    Talk To An Expert
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance