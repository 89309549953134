import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { jobsAPITestData } from "../constants/APITestData";
import { useNavigate } from 'react-router-dom';
const QualityAssurance = () => {

    const navigate = useNavigate();
    const [tabSelected2, setTabSelected2] = useState(0);



    useEffect(() => {
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");

    }, [tabSelected2])


    function toggleTab2(index) {
        setTabSelected2(index);

    }


const applyNow=(id)=>{

    localStorage.setItem("jobSelectedId", id);
    
    navigate('/insight/career-details');
}

    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    


    return (
        <>
            {window.innerWidth>992?
            <Banner
                bgUrl="/images/insight/career/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "8", bottom: "8" }}
                heading={"Accelerate Your Career and<br/>Craft Moments of Happiness"}
                content={
                    "With ZOG Global by your side, you'll be able to take your career to new heights and create memorable moments of happiness along the way. "
                }
            >

            </Banner>

:
<Banner
                bgUrl="/images/insight/career/banner.png"
                theme="light"
                txtAlign="center"
                paddingProp={{ top: "8", bottom: "8" }}
                heading={"Accelerate Your Career<br/>and Craft Moments of<br/>Happiness"}
                content={
                    "With ZOG Global by your side, you'll be able to take your career to new heights and create memorable moments of happiness along the way. "
                }
                customClassName={"career-banner"}
            >

            </Banner>

            }

            <CommonSection className="container career-sec-3">
                <div className="row mb-5" >
                    <div className="col-lg-9">
                        <h3>Let’s Begin Your Journey Here</h3>
                        <p>It's time to launch your career journey with what you do best. Start today at ZOG Global and discover a world of opportunities!  </p>
                    </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-7 mb-5">
                            <input className="w-100" placeholder="Keyword"></input>
                        </div>
                        <div className="col-lg-3 mb-5">
                            <select className="w-100">

                                <option value="" disabled selected>Department</option>

                                {jobsAPITestData.map((item, index) => {
                                    return (
                                        <option value="hurr">{item.category}</option>
                                    )
                                })}
                            </select>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-3 mb-5">
                            <select className="w-100">
                                <option value="" disabled selected>Job Type</option>
                                <option value="hurr">Full Time</option>
                                <option value="hurr">Part Time</option>
                            </select>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <select className="w-100">
                                <option value="" disabled selected>Location</option>
                                <option value="hurr">Durr</option>
                            </select>
                        </div>
                        <div className="col-lg-3">
                           
                                <button className="btn btn-primary home-book-button career-button mb-5">
                                    Apply Search
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg>
                                    </span>
                                </button>
                            
                        </div>

                    </div>
              

                {jobsAPITestData.map((item, index) => {
                    return (<div key={index} className="category">

                        <div className="row ">
                            <div className="col-lg-12">
                                <h4>{item.category}</h4>
                            </div>
                        </div>
                        {item.list.map((row, listIindex) => {
                            return (

                                <div key={listIindex} className={listIindex % 2 == 0 ? "row justify-content-between table-row even" : "row justify-content-between table-row odd"}>
                                    <div className="col-md-4">
                                        <p>{row.title}</p>
                                    </div>

                                    <div className="col-md-3">
                                        <p>{row.location}</p>
                                    </div>

                                    <div className="col-md-2">
                                        <p>{row.type}</p>
                                    </div>
                                    <div className="col-lg-3">
                                       <button className="btn btn-transparent apply-now-btn"  onClick={() => applyNow(row.jobID)}>Apply Now
                                            <span>
                                                <svg width="39" height="13" viewBox="0 0 39 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.5 5.75C1.08579 5.75 0.75 6.08579 0.75 6.5C0.75 6.91421 1.08579 7.25 1.5 7.25V5.75ZM38.0303 7.03033C38.3232 6.73744 38.3232 6.26256 38.0303 5.96967L33.2574 1.1967C32.9645 0.903806 32.4896 0.903806 32.1967 1.1967C31.9038 1.48959 31.9038 1.96447 32.1967 2.25736L36.4393 6.5L32.1967 10.7426C31.9038 11.0355 31.9038 11.5104 32.1967 11.8033C32.4896 12.0962 32.9645 12.0962 33.2574 11.8033L38.0303 7.03033ZM1.5 7.25H37.5V5.75H1.5V7.25Z" fill="#191919" />
                                                </svg>

                                            </span>
                                        </button>
                                       
                                    </div>
                                </div>
                            )
                        })}
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-end"> <button className="btn btn-borderless mt-3 pe-0">View More>></button>
                            </div>
                        </div>
                    </div>)
                }
                )}

            </CommonSection>

        </>
    )
}

export default QualityAssurance