import React from 'react'
import ProgressFooter from "./ProgressFooter"
import { useState } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import API from './API';
import ErrorModal from "./ErrorModal";
import PositiveModal from "./PositiveModal";
import FixedOverlayLoadingSpinner from "./FixedOverlayLoadingSpinner"

<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phonenumber: "",
        email: window.localStorage.getItem("emailForSignup"),
        username: "",
        password: "",
        confirmPassword: ""
    });
    const [message, setMessage] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [passwordShown1, showPassword1] = useState(false);
    const [passwordShown2, showPassword2] = useState(true);


    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form fields
        const validationErrors = validateForm(formData);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            // Perform the API call
            setIsLoading(true)
            try {
                // Replace the following code with your actual API logic
                const response = await API.post("/user/register/", formData);
                // Handle the API response as needed
                setIsLoading(false)
                setMessage("Signed-Up Succesfully!");
                setIsMessageModalOpen(true)

                window.localStorage.removeItem("emailForSignup")
            } catch (error) {
                // Handle API errors
                setIsLoading(false)

                setMessage(error.message);
                setIsErrorModalOpen(true)
            }
        }
    };

    const validateForm = (data) => {
        const errors = {};

        // Validate each field and add errors if necessary
        if (!data.first_name.trim()) {
            errors.first_name = "First name is required.";
        }

        if (!data.last_name.trim()) {
            errors.last_name = "Last name is required.";
        }

        if (!data.phonenumber.trim()) {
            errors.phonenumber = "Phone number is required.";
        } else if (!/^\d{10}$/.test(data.phonenumber)) {
            errors.phonenumber = "Phone number must be 10 digits.";
        }

        if (!data.email.trim()) {
            errors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = "Invalid email address.";
        }

        // if (!data.username.trim()) {
        //   errors.username = "Username is required.";
        // } else if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(data.username)) {
        //   errors.username = "Username must start with a letter and can only contain letters and numbers.";
        // } else if (/^\d+$/.test(data.username)) {
        //   errors.username = "Username cannot be full of numbers.";
        // }

        if (!data.password.trim()) {
            errors.password = "Password is required.";
        } else if (data.password.length < 8) {
            errors.password = "Password must be at least 8 characters.";
        } else {
            // Password must contain at least one letter, one number, and one special character
            if (!/[a-zA-Z]/.test(data.password)) {
                errors.password = "Password must contain at least one letter.";
            } else if (!/\d/.test(data.password)) {
                errors.password = "Password must contain at least one number.";
            } else if (!/[!@#$%^&*]/.test(data.password)) {
                errors.password = "Password must contain at least one special character (!@#$%^&*).";
            }
        }


        if (!data.confirmPassword.trim()) {
            errors.confirmPassword = "Confirm password is required.";
        } else if (data.password !== data.confirmPassword) {
            errors.confirmPassword = "Passwords do not match.";
        }

        return errors;
    };


    const onGoogleLoginSuccess = (response) => {
        console.log(response);
    };
    const onGoogleLoginError = (error) => {
        console.log(error);
    };





    return (
        <>
            <div className='container d-flex flex-column justify-content-center align-items-center register-section '>
                {window.innerWidth <= 768 ?
                    <ProgressFooter label="VERIFY EMAIL" width="25%" url="/mail-confirmed" image="/images/blue-arrow.svg" />
                    :
                    <p></p>
                }
                <div className='row mt-5'>
                    <div className='col-12'>
                        <h1>Register an Account To Apply</h1>
                        <p className='mt-4 mb-4'>Unlock opportunities by by registering an account ans start applying today!</p>
                        <form onSubmit={handleSubmit} noValidate >
                            <div className='mt-5'>
                                <label for="first_name" className="form-label">Name</label>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-12 mb-2">
                                        <input
                                            type="text"
                                            id="first_name"
                                            name="first_name"
                                            className={`form-control form-control-lg ${errors.first_name ? "is-invalid" : ""}`}
                                            value={formData.first_name}
                                            onChange={handleChange}
                                        />
                                        {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}

                                    </div>
                                    <div className="col-lg-6 col-md-12 col-12 mb-2 mt-md-3 mt-lg-0">
                                        <input
                                            type="text"
                                            id="last_name"
                                            name="last_name"
                                            className={`form-control form-control-lg ${errors.last_name ? "is-invalid" : ""}`}
                                            value={formData.last_name}
                                            onChange={handleChange}
                                        />
                                        {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}

                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <label for="email" className="form-label">Email</label>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12 mb-2">
                                        <input
                                            type="text"
                                            id="email"
                                            name="email"
                                            className={`form-control form-control-lg ${errors.email ? "is-invalid" : ""}`}
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <label for="password" className="form-label">Password</label>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-12 mb-2">
                                        <div className="input-group">
                                            <input
                                                type={passwordShown1 ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                                value={formData.password}
                                                onChange={handleChange}
                                            />
                                            <span className="input-group-text" onClick={() => showPassword1(!passwordShown1)}>
                                                {passwordShown1 ?

                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 12.668C1 12.668 5 4.66797 12 4.66797C19 4.66797 23 12.668 23 12.668C23 12.668 19 20.668 12 20.668C5 20.668 1 12.668 1 12.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12 15.668C13.6569 15.668 15 14.3248 15 12.668C15 11.0111 13.6569 9.66797 12 9.66797C10.3431 9.66797 9 11.0111 9 12.668C9 14.3248 10.3431 15.668 12 15.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    :


                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1777_2198)">
                                                            <path d="M17.94 18.608C16.2306 19.911 14.1491 20.6329 12 20.668C5 20.668 1 12.668 1 12.668C2.24389 10.3499 3.96914 8.3246 6.06 6.72799M9.9 4.90799C10.5883 4.74687 11.2931 4.66633 12 4.66799C19 4.66799 23 12.668 23 12.668C22.393 13.8036 21.6691 14.8727 20.84 15.858M14.12 14.788C13.8454 15.0827 13.5141 15.3191 13.1462 15.4831C12.7782 15.6471 12.3809 15.7353 11.9781 15.7424C11.5753 15.7495 11.1752 15.6754 10.8016 15.5245C10.4281 15.3736 10.0887 15.149 9.80385 14.8641C9.51897 14.5793 9.29439 14.2399 9.14351 13.8664C8.99262 13.4928 8.91853 13.0927 8.92563 12.6899C8.93274 12.2871 9.02091 11.8898 9.18488 11.5218C9.34884 11.1538 9.58525 10.8226 9.88 10.548" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M1 1.66797L23 23.668" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1777_2198">
                                                                <rect width="24" height="24" fill="white" transform="translate(0 0.667969)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                }

                                            </span>
                                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-12 mb-2 mt-md-3 mt-lg-0">
                                        <div className="input-group">
                                            <input
                                                type={passwordShown2 ? "text" : "password"}
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                                                value={formData.confirmPassword}
                                                onChange={handleChange}
                                            />
                                            <span className="input-group-text" onClick={() => showPassword2(!passwordShown2)}>
                                                {passwordShown2 ?

                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 12.668C1 12.668 5 4.66797 12 4.66797C19 4.66797 23 12.668 23 12.668C23 12.668 19 20.668 12 20.668C5 20.668 1 12.668 1 12.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12 15.668C13.6569 15.668 15 14.3248 15 12.668C15 11.0111 13.6569 9.66797 12 9.66797C10.3431 9.66797 9 11.0111 9 12.668C9 14.3248 10.3431 15.668 12 15.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    :


                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1777_2198)">
                                                            <path d="M17.94 18.608C16.2306 19.911 14.1491 20.6329 12 20.668C5 20.668 1 12.668 1 12.668C2.24389 10.3499 3.96914 8.3246 6.06 6.72799M9.9 4.90799C10.5883 4.74687 11.2931 4.66633 12 4.66799C19 4.66799 23 12.668 23 12.668C22.393 13.8036 21.6691 14.8727 20.84 15.858M14.12 14.788C13.8454 15.0827 13.5141 15.3191 13.1462 15.4831C12.7782 15.6471 12.3809 15.7353 11.9781 15.7424C11.5753 15.7495 11.1752 15.6754 10.8016 15.5245C10.4281 15.3736 10.0887 15.149 9.80385 14.8641C9.51897 14.5793 9.29439 14.2399 9.14351 13.8664C8.99262 13.4928 8.91853 13.0927 8.92563 12.6899C8.93274 12.2871 9.02091 11.8898 9.18488 11.5218C9.34884 11.1538 9.58525 10.8226 9.88 10.548" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M1 1.66797L23 23.668" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1777_2198">
                                                                <rect width="24" height="24" fill="white" transform="translate(0 0.667969)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                }

                                            </span>
                                            {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <label for="country" className='form-label'>Phone Number</label><br></br>
                                <div className='col-lg-2 col-md-1 col-2'>
                                    <select className="form-select form-control form-control-lg mb-3" id="number" name="number"
                                        aria-label="Default select example" ><span><img src="/images/Polygon.svg" alt=""></img></span>
                                        <option selected>+91</option>
                                        <option value="+44">+44</option>
                                        <option value="+1">+1</option>
                                    </select>
                                </div>
                                <div className='col-lg-10 col-md-11 col-10'>
                                <input
                  type="text"
                  id="phonenumber"
                  name="phonenumber"
                  className={`form-control ${errors.phonenumber ? "is-invalid" : ""}`}
                  value={formData.phonenumber}
                  onChange={handleChange}
                />
                {errors.phonenumber && <div className="invalid-feedback">{errors.phonenumber}</div>}

                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mb-2 mt-3">
                                <div className="mb-lg-4">
                                    <label className="form-check-label form-label" for="catSelect">Country/ Region of Residence</label><br></br>
                                    <select className="form-select form-control form-control-lg mb-3" id="catSelect" name="country"
                                        aria-label="Default select example" ><span><img src="/images/Polygon.svg" alt=""></img></span>
                                        <option selected>Select</option>
                                        <option value="1">Place</option>
                                        <option value="1">Place</option>
                                        <option value="2">Place</option>
                                        <option value="3">Place</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-12 mt-5 mb-5'>
                                <button className='btn home-book-button'>Register<span className='ms-2' type="submit"><img src="/images/arrow 1.svg" alt="" /></span></button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            {window.innerWidth > 768 ?
                <ProgressFooter label="VERIFY EMAIL" width="25%" url="/mail-confirmed" />
                :
                <p></p>
            }

<ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate('/sign-up')} />
      <PositiveModal message={message} state={isMessageModalOpen} setterFunction={setIsMessageModalOpen} okClickedFunction={() => navigate('/confirm-mail')} />
      {isLoading && <FixedOverlayLoadingSpinner />}

        </>
    )
}

export default View
