import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);
    const [tabSelected2, setTabSelected2] = useState(0);

    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    useEffect(() => {
        console.log("tab2 useffect")
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");

    }, [tabSelected2])



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    function toggleTab(index) {
        setTabSelected(index);

    }

    function toggleTab2(index) {
        setTabSelected2(index);

    }


    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            {window.innerWidth > 992 ? <CommonSection className="container de-banner cyber-security-banner">
                <div className="row cr">

                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Intelligent Protection for Your Digital Business
                        </h3>

                        <p>To safeguard your business information, we provide cybersecurity and data protection services that manage risks and build confidence in your technology.</p>
                    </div>
                    <div className="col-lg-6 responsive-col-gap">
                        <div className=" responsive-col-gap"></div>
                        <img src="/images/digital-engineering/cyber-security/banner.png"></img>
                    </div>

                </div>

            </CommonSection>
                :
                <Banner
                    bgUrl="/images/digital-engineering/cyber-security/banner.png"
                    theme="light"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"Intelligent Protection for Your Digital Business"}
                    content={
                        "To safeguard your business information, we provide cybersecurity and data protection services that manage risks and build confidence in your technology."
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-end"
                    mobileTextAlign="left"
                >

                </Banner>}


            <CommonSection className="container managed-it-services-sec-2">
                <div className="row">
                    <div className="col-lg-9 mb-5">
                        {window.innerWidth > 992 ?
                            <h3>Keeping Your<br />Company Safe from<br />Data Breaches</h3>
                            :
                            <h3>Keeping Your Company<br /> Safe from Data Breaches</h3>}
                        {window.innerWidth > 992 ? <p>Our advanced Cybersecurity systems help improve the resistance to cyber threats. Providing high-quality skills in the fields of<br />Cloud Security, Defence Methodologies, and Cybersecurity is our mission at ZOG Global.</p>
                            :
                            <p>Our advanced Cybersecurity systems help improve the resistance to cyber threats. Providing high-quality skills in the fields of Cloud Security, Defence Methodologies, and Cybersecurity is our mission at ZOG Global.</p>
                        }

                    </div>
                </div>
               {window.innerWidth>992?
                <div className="row g-5">

                <div className="col-lg-5 col-md-4">

                    <div
                        className="tab-btn-container"

                    >

                        <h3>Cybersecurity Services<br />We Offer </h3>
                        <p className="mb-5">Combat your organisation's cybersecurity challenges with our deep vertical expertise, proven best practices grounded in real-world experience, and unparalleled support. </p>

                        <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Data Privacy and Protection </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(1)}>Identity and Access Management (IAM)
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>Infrastructure Security
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(3)}>Threat Monitoring & Incident Response
                        </button>

                    </div>

                </div>

                <div className="col-lg-7 col-md-8">


                    <div className="tab-content">
                        <h4>Data Privacy and Protection </h4>
                        <p>We assist organisations in keeping abreast of leading services and industry initiatives related to data privacy and security, as well as adhering to regulatory guidelines in a rapidly changing threat environment. </p>

                        <div className="row gapless-box-container g-0">
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Encryption and Security Measures </h4>
                                    <p>Data privacy and protection services commonly utilise encryption methods and other security measures to protect data from unauthorised access and theft.  </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Regular Auditing and Reporting</h4>
                                    <p>This process involves monitoring and analysing data usage, access, and security measures to ensure that they align with industry standards and regulatory compliance. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <h4>Identity and Access Management (IAM) </h4>
                        <p>Identity management services provide the basis of proactive security measures while allowing secure access to resources. </p>

                        <div className="row gapless-box-container g-0">
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Single Sign-On (SSO) </h4>
                                    <p>By utilising SSO, users can access multiple applications without needing to remember many different usernames and passwords - saving both time and effort. </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Role-Based Access Control (RBAC)  </h4>
                                    <p>Ensures that users can only access the resources and information they need to perform their job functions, reducing the risk of unauthorised access and data breaches. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <h4>Infrastructure Security </h4>
                        <p>We offer a comprehensive infrastructure security solution to protect your networking, endpoint devices, and the data that passes between them. </p>

                        <div className="row gapless-box-container g-0">
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Intrusion Detection and Prevention </h4>
                                    <p>Monitor network traffic and detect attempts at unauthorised access or potential security breaches in real time.  </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Network Segmentation </h4>
                                    <p>It is a process of dividing the company's network into smaller subnetworks, allowing secure data flow between them. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <h4>Threat Monitoring & Incident Response  </h4>
                        <p>Designed to detect and respond to cyber threats and attacks before they cause significant damage to an organisation. </p>

                        <div className="row gapless-box-container g-0">
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Real-Time Monitoring  </h4>
                                    <p>This allows for quick detection of potential threats and risks and an immediate response to any incidents that may occur. </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Expert Analysis and Response </h4>
                                    <p>Expert professionals identify the source, type, and extent of the threat and take steps to contain it to minimise further damage or disruption. </p>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
            :
            <div className="row g-5">

            <div className="col-12">

                <div
                    className="tab-btn-container"

                >

                    <h3>Cybersecurity Services<br />We Offer </h3>
                    <p className="mb-5">Combat your organisation's cybersecurity challenges with our deep vertical expertise, proven best practices grounded in real-world experience, and unparalleled support. </p>

                    <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Data Privacy and Protection </button>
                    <div className="tab-content">
                    <h4>Data Privacy and Protection </h4>
                    <p>We assist organisations in keeping abreast of leading services and industry initiatives related to data privacy and security, as well as adhering to regulatory guidelines in a rapidly changing threat environment. </p>

                    <div className="row gapless-box-container g-0">
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Encryption and Security Measures </h4>
                                <p>Data privacy and protection services commonly utilise encryption methods and other security measures to protect data from unauthorised access and theft.  </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Regular Auditing and Reporting</h4>
                                <p>This process involves monitoring and analysing data usage, access, and security measures to ensure that they align with industry standards and regulatory compliance. </p>
                            </div>
                        </div>
                    </div>
                </div>
                    <button className="tab-btn" onClick={(e) => toggleTab(1)}>Identity and Access Management (IAM)
                    </button>
                    <div className="tab-content">
                    <h4>Identity and Access Management (IAM) </h4>
                    <p>Identity management services provide the basis of proactive security measures while allowing secure access to resources. </p>

                    <div className="row gapless-box-container g-0">
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Single Sign-On (SSO) </h4>
                                <p>By utilising SSO, users can access multiple applications without needing to remember many different usernames and passwords - saving both time and effort. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Role-Based Access Control (RBAC)  </h4>
                                <p>Ensures that users can only access the resources and information they need to perform their job functions, reducing the risk of unauthorised access and data breaches. </p>
                            </div>
                        </div>
                    </div>
                </div>
                    <button className="tab-btn" onClick={(e) => toggleTab(2)}>Infrastructure Security
                    </button>
                    <div className="tab-content">
                    <h4>Infrastructure Security </h4>
                    <p>We offer a comprehensive infrastructure security solution to protect your networking, endpoint devices, and the data that passes between them. </p>

                    <div className="row gapless-box-container g-0">
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Intrusion Detection and Prevention </h4>
                                <p>Monitor network traffic and detect attempts at unauthorised access or potential security breaches in real time.  </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Network Segmentation </h4>
                                <p>It is a process of dividing the company's network into smaller subnetworks, allowing secure data flow between them. </p>
                            </div>
                        </div>
                    </div>
                </div>
                    <button className="tab-btn" onClick={(e) => toggleTab(3)}>Threat Monitoring & Incident Response
                    </button>


                    <div className="tab-content">
                    <h4>Threat Monitoring & Incident Response  </h4>
                    <p>Designed to detect and respond to cyber threats and attacks before they cause significant damage to an organisation. </p>

                    <div className="row gapless-box-container g-0">
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Real-Time Monitoring  </h4>
                                <p>This allows for quick detection of potential threats and risks and an immediate response to any incidents that may occur. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box">
                                <h4>Expert Analysis and Response </h4>
                                <p>Expert professionals identify the source, type, and extent of the threat and take steps to contain it to minimise further damage or disruption. </p>
                            </div>
                        </div>
                    </div>
                </div>


                </div>

            </div>

            <div className="col-lg-7 col-md-8">


                
                
                
                



            </div>
        </div>}
            </CommonSection>


            <CommonSection className="container cyber-security-sec-2">
                <div className="row g-0">
                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/cyber-security/sec-2-1.png"></img>

                    </div>
                    <div className="col-lg-6 text-box tb-1">
                        <h3>With Actionable and Effective Guidance, We Meet Our Client's Security Needs</h3>
                        <p>We understand your business challenges by working closely with your team and applying our extensive industry experience. Our specialists can assist you in reducing the burden of security management and delivering the results you need.</p>
                    </div>
                </div>

                <div className="row g-0 cr">

                    <div className="col-lg-6 text-box tb-2">
                        <h3>​Keeping your Business in Compliance and Secured</h3>
                        <p>Is your IT operating model becoming more complex and decentralized? Now more than ever, it's essential to reduce risk by making IT security more accessible and manageable. ZOG Global's built-in security technology automatically verifies the integrity of your data infrastructure, making security and compliance easier.</p>
                    </div>
                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/cyber-security/sec-2-2.png"></img>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container cyber-security-sec-3">

                <div className="non-breaking-mob-container">
                    <div className="tab2 row">


                        <div className="col-6 d-flex justify-content-between">

                            <button className="tab-btn2 active" onClick={(e) => toggleTab2(0)}>Cloud<br />Security</button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(1)}>Managed<br />Security<br />Services
                            </button>
                        </div>
                        <div className="col-6 d-flex justify-content-between">
                            <button className="tab-btn2" onClick={(e) => toggleTab2(2)}>Data<br />Security
                            </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(3)}>Network<br />Security
                            </button>
                        </div>

                    </div>
                </div>

                <div className="tab-content2">

                    <h3>Secure Access to Public, Private, and Hybrid Clouds</h3>
                    <p>Businesses today use more than 90% of applications hosted in the cloud. The cloud offers advantages such as rapid deployment, scalability, and significant cost savings. Cloud security is paramount as the demand for private, public and hybrid cloud infrastructure continues to grow.
                        ZOG Global's cloud security practices help customers use the cloud safely. In this way, customers remain protected from cyber threats whenever information in the cloud is accessed.
                    </p>
                </div>

                <div className="tab-content2">

                    <h3>Improved Cybersecurity Against Evolving Cyberattacks</h3>
                    <p>With the expansion of the digital economy, businesses today rely on multiple channels to operate, making them more vulnerable to cyber threats and attacks than ever. As such, cybersecurity has become a much deeper topic of discussion among executives today. Additionally, the growing emphasis on government-mandated safety compliance makes business more difficult.
                        As a Managed Security Service Provider (MSSP), ZOG Global understands that every business is different, so our approach is cost-effective and flexible to meet the needs of each organization.
                    </p>
                </div> <div className="tab-content2">

                    <h3>Corporate Data Security that Matters</h3>
                    <p>Protecting corporate data is paramount in today's competitive world. Unfortunately, ever-increasing cyber threats such as ransomware, extortion, and data theft are increasing daily and, if left unchecked, can cause severe damage to organizations and reputations. Our Data Security service helps organizations protect sensitive data from all these threats and provides real-time analysis of the data environment. This allows you to report and monitor security breaches and take corrective action to prevent data theft.</p>
                </div> <div className="tab-content2">

                    <h3>Cybersecurity for Network Endpoints</h3>
                    <p>Network firewalls, web application firewalls to defend against L7 attacks, and endpoint protection to strengthen the security of network gear and other appliances are some of the components that make up today's network security systems. In today's hybrid environments, where applications are hosted both on-premises and in the cloud, establishing security levels has become more challenging, adding another complexity to the security architecture.
                        ZOG Global has invested in building a full-fledged security practice to handle the market's security concerns today by offering and integrating various solutions and hardening skills to handle them.
                    </p>
                </div>


            </CommonSection>

            <CommonSection className="container  education-sec-4 cyber-security-sec-4">
                <div className="bg">
                    <div className="mask  full-height">
                        <div className="row" >
                            <div className="col-lg-6">
                                {window.innerWidth>992?
                                <h3>We Make It Easy to Get Started </h3>
                            :
                            <h3>We Make It Easy<br/>to Get Started </h3>
                            }
                            </div>
                        </div>
                        <div className="non-breaking-mob-container">
                        <div className="row">
                            <div className="col-4">
                                <div className="box">
                                    <h4>Schedule a Call </h4>
                                    <p>Conduct a vulnerability scan on your digital landscape with our help. </p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="box">
                                    <h4>We'll Build<br></br>
                                        You a Plan</h4>
                                    <p>Once we identify your vulnerabilities and risks, we will create a detailed mitigation plan.</p>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="box">
                                    <h4>Together<br></br>
                                        We'll Implement a<br></br> Security Program </h4>
                                    <p>We will provide ongoing support to help you automate your compliance and protection.</p>
                                </div>
                            </div>

                        </div>
                        </div>
                        <button className="btn btn-borderless orange-btn">
                            Get Started

                        </button>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container  education-sec-4">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Be Securely Satisfied</h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-4">
                        <div className="box">
                            <h4>Prevent </h4>
                            <p>Let our experts help you comprehend the threat environment related to your organisation so you can design the procedures and solutions to improve your security posture.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box">
                            <h4>Detect</h4>
                            <p>We proactively deliver solutions and services that help you detect and eliminate threats before damage is done.</p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="box">
                            <h4>Remediate </h4>
                            <p>Our team looks ahead to helping you to neutralize cyber threats that may hamper your organization's Network & Compute assets.</p>
                        </div>
                    </div>

                </div>
                </div>
                
            </CommonSection>
        </>
    )
}

export default QualityAssurance