import React from 'react'
import { useState } from "react";

import { Link } from "react-router-dom";
import Captcha from './captcha';
import API from './API';
import { useContext } from 'react';
import { UserContext } from './UserContext';

import ErrorModal from "./ErrorModal";


const View = () => {


    const { login, isOTPInputShown, showOTPInput, otpTimer, otpTimeout, setRedirectUrl } = useContext(UserContext);

    setRedirectUrl("/")

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [message, setMessage] = useState(null);




    const [formData, setFormData] = useState({
        username_or_email: "",
        password: "",
    });

    const [passwordShown, showPassword] = useState(false);





    const [errors, setErrors] = useState({});

    const [OTP, setOTP] = useState([null, null, null, null, null, null])


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        console.log("entered to submit")
        e.preventDefault();

        if (!isOTPInputShown) {  // for login button
            // Validate the form fields
            const validationErrors = validateForm(formData);
            setErrors(validationErrors);
console.log("validation errors", validationErrors)
            if (Object.keys(validationErrors).length === 0) {



                login(formData); // Call the login function from UserContext

            }
        }
        else {   //for OTP SUBMIT BUTTON.
            if (OTP[0] && OTP[1] && OTP[2] && OTP[3] && OTP[4] && OTP[5]) {
                setErrors({})
                login(

                    {
                        "user_id": window.localStorage.getItem("userID"),
                        "otp": `${OTP[0]}${OTP[1]}${OTP[2]}${OTP[3]}${OTP[4]}${OTP[5]}`
                    }
                )
            }
            else {
                setErrors({ otp: "Please enter 6 digits" })
            }
        }

    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.username_or_email.trim()) {
            errors.username_or_email = "username or email is required.";
        }
        else if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(data.username)) {
            errors.general = "Invalid username or password" //Username must start with a letter and can only contain letters and numbers. Error details are not showing to user because security reason
        }
        else if (/^\d+$/.test(data.username_or_email)) {
            errors.general = "Invalid username or password" //username or email cannot be full of numbers.  Error details are not showing to user because security reason
        }

        if (!data.password.trim()) {
            errors.password = "Password is required.";
        }
        else if (data.password.length < 8) {
            errors.general = "Invalid username or password"//Password must be at least 8 characters. Error details are not showing to user because security reason
        }
        else {
            // Password must contain at least one letter, one number, and one special character Error details are not showing to user because security reason
            if (!/[a-zA-Z]/.test(data.password)) {
                errors.general = "Invalid username or password"//Password must contain at least one letter."; Error details are not showing to user because security reason
            }
            else if (!/\d/.test(data.password)) {
                errors.general = "Invalid username or password" //Password must contain at least one number. Error details are not showing to user because security reason
            }
            else if (!/[!@#$%^&*]/.test(data.password)) {
                errors.general = "Invalid username or password" //Password must contain at least one special character (!@#$%^&*). Error details are not showing to user because security reason
            }
        }

        return errors;
    };




    const handleOTPInputChange = (e, currentIndex) => {
        console.log("e", e)
        if (currentIndex < 6) {
            // Handle backspace
            if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {


                let temp = [...OTP];
                temp[currentIndex] = null;
                setOTP(temp);

                // Move to the previous input
                if (currentIndex > 0) {
                    document.getElementById(`otp${currentIndex - 1}`).focus();
                }
            } else if (/[0-9]/.test(e.target.value)) {
                // Update the current input value
                const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                let temp = [...OTP];
                temp[currentIndex] = inputValue;
                setOTP(temp);

                // Move to the next input
                if (currentIndex < 5) {
                    document.getElementById(`otp${currentIndex + 1}`).focus();
                }
            }
        }
    };




    const onGoogleLoginSuccess = (response) => {
        console.log(response.credential);

        API.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.credential}`, {
            headers: {
                Authorization: `Bearer ${response.credential}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                console.log("google user basic details", res.data);
            })
            .catch((err) => console.log("error ongoogle user basic details", err));

    };
    const onGoogleLoginError = (error) => {
        console.log(error);
    };






    return (
        <>
            <div className='container d-flex flex-column justify-content-center align-items-center login-section '>
                <div className='col-12 hidden'>
                    <button className='btn mt-5'><img src="/images/blue-arrow.svg" alt="" /></button>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <h1>Login To Your Account To Apply</h1>
                        <p className='mt-4 mb-4'>Access your account and unlock a seamless application process by logging in today</p>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='mt-5'>
                                <label for="username_or_email" className="form-label">Email</label>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12 mb-2">
                                        <input
                                         type="text"
                                          placeholder="Enter user name or email"
                                          className={`form-control ${errors.username_or_email ? "is-invalid" : ""}`}
                                          value={formData.username_or_email}
                                          onChange={handleChange}
                                          disabled={isOTPInputShown}
                                            id="username_or_email"
                                             name="username_or_email" />
                                    </div>
                                </div>
                                {errors.username_or_email && <div className="invalid-feedback">{errors.username_or_email}</div>}

                            </div>
                            <div className='mt-3'>
                                <label for="password" className="form-label">Password</label>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12 mb-2">
                                    <input
                                    type={passwordShown ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                    value={formData.password}
                                    onChange={handleChange}
                                    disabled={isOTPInputShown}
                                />
                                    </div>
                                </div>
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}

                            </div>
                            {/* <div className='box mt-4'>
                                <input type="checkbox" id="human" name="human" className="human mt-3" />
                                <label for="human" id="captcha"> I am Human</label><span className='captcha'></span>
                            </div> */}



                            <div className='row mt-4'>
                                <div className='col-lg-6 col-md-12 p-2'>
                                    <Link to="/forgot-password"><button className='btn login-btn p-0'>Forgot Password?</button></Link>
                                </div>
                                <div className='col-lg-6 col-md-12 justify-content-end d-flex act-btn p-2 mt-2 mt-lg-0'>
                                    <Link to="/sign-up"><button className='btn login-btn p-0'>Don't have an account yet?</button></Link>
                                </div>
                            </div>


                            <br></br>
                            {!isOTPInputShown &&
                              <div className='col-12 mt-4 mb-5'>
                              <button className='btn home-book-button'>Sign In <span className='ms-1'><img src="/images/arrow 1.svg" alt="" /></span></button>
                          </div>
                            }
                            {(localStorage.getItem("userRoleRequest") === "admin") && isOTPInputShown && (
                                <>
                                    <label htmlFor="otp" className="form-label">
                                        OTP
                                    </label>
                                    <div className="input-group">
                                        {[0, 1, 2, 3, 4, 5].map((index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                id={`otp${index}`}
                                                name={`otp${index}`}
                                                value={OTP[index] || ''}
                                                onChange={(e) => handleOTPInputChange(e, index)}
                                                maxLength={1}
                                                className="otp-input"

                                            />
                                        ))}

                                    </div>
                                    {errors.otp && <div className="text-danger">{errors.otp}</div>}
                                    {otpTimeout ? <p className="otp-timer expired">OTP expired! <span className="resend" onClick={() => showOTPInput(false)}>Resend OTP</span></p> : <div className="otp-timer">OTP will expire within {Math.floor(otpTimer / 60)}:{(otpTimer % 60) < 10 && "0"}{(otpTimer % 60)} min.</div>}
                                    <button className="btn btn-secondary form-control w-100 text-center mt-4">SUBMIT OTP</button>
                                </>
                            )}



                            
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default View
