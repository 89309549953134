import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }








    const settings2 = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };



    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    


    return (
        <>
            <Banner
                bgUrl="/images/industries/insurance/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={"Make a Real Impact on the Insurance Industry"}
                content={
                    "Delivering top-notch process expertise, technology, and services on a grand scale to revolutionise operations and elevate results."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
            >

            </Banner>
            <CommonSection className="container engagement-model-sec-1">
                <div className="row">


                    <div className="col-lg-12 tab-btn-container responsive-col-gap">
                        <button className="tab-btn active" onClick={(e) => toggleTab(0)}>
                            Operations
                        </button>
                        <button className="tab-btn " onClick={(e) => toggleTab(1)}>
                            Accuracy
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>
                            Compliance
                        </button>

                    </div>


                </div>

                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                          {window.innerWidth>992?
                            <h3>Enhance Critical Operations </h3>
                        :
                        <h3>Enhance<br/>Critical Operations </h3>}

                        </div>
                        <div className="col-lg-6">
                            <p className="">Discover the immense potential of technology-driven services that can transform the way you operate. With digital document management, automated enrollment verification, and seamless digital communication, you can streamline your processes and enjoy unparalleled efficiency. </p>

                        </div>
                    </div>
                </div>


                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            
                          {window.innerWidth>992?
                            <h3>Improve Accuracy, Efficiency and Outcomes</h3>
:
<h3>Improve<br/>Accuracy, Efficiency and Outcomes</h3>
}
                        </div>
                        <div className="col-lg-6">
                            <p className="">Enhance the accuracy, efficiency, and overall outcomes of your insurance industry by utilizing effective strategies and advanced technologies. By implementing innovative solutions and streamlining processes, your organisation can achieve better results and provide superior services to your clients.</p>

                        </div>
                    </div>
                </div>


                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            <h3>Strengthen Compliance</h3>

                        </div>
                        <div className="col-lg-6">
                            <p className="">Our advanced technology can help you stay up-to-date with the latest regulations, ensuring that you're always in compliance. With our top-of-the-line solutions, you can rest assured that your business is fully protected and operating smoothly.</p>

                        </div>
                    </div>
                </div>


            </CommonSection>

            <CommonSection className="container engagement-model-sec-1 insurance-sec-2">
                <div className="row gapless-box-container g-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div className="wrapper">
                                <img src="/images/industries/insurance/sec-2-1.svg"></img>
                            <h4>Security Services </h4>
                            </div>
                             <p>
                            To prevent data breaches, insurance agencies must take precautions. We know how to keep sensitive information safe and secure. Our team can identify potential vulnerabilities and create a plan to keep your systems secure. Talk to us for an IT audit and network security assessment.
                            </p>  </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                        <div className="wrapper">
                        <img src="/images/industries/insurance/sec-2-2.svg"></img>
                            <h4>Recovery Services</h4>
                          </div>  <p>
                            It's crucial to prepare for unforeseen circumstances such as natural disasters, crimes or power outages, as they can cause severe damage. Our IT support includes backups and contingency plans that enable your business to recover quickly and continue pursuing your objectives.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                        <div className="wrapper">
                        <img src="/images/industries/insurance/sec-2-3.svg"></img>
                            <h4>Networking Services </h4>
                           </div> <p>
                            As your business evolves, your network must also adapt to keep up. Our network solution ensures that you remain operational on a daily basis and can handle any changes in the insurance industry. Regardless of whether you need to accommodate more users or incorporate new features, our network is equipped to handle it all.
                            </p>  </div>
                    </div>

                </div>
            </CommonSection>
            <CommonSection className="container telecom-sec-1">
                <div className="row align-items-center cr g-0">

                    <div className="col-lg-6 ">
                        <h3>Transform Your Insurance Business with Exceptional Customer Experiences</h3>

                        <p>In today's world, insurance companies face the challenge of catering to the needs of consumers who always want things personalised and delivered instantly. It's a challenging environment out there, with regulations to comply with and competition from up-and-coming Insure Tech players. However, it presents a chance to stay one step ahead and excel beyond the competition.

                            <br></br><br></br>That's where ZOG Global comes in. Our IT Services use cutting-edge technologies like AI, Cloud, Automation, and Machine Learning to provide transformative solutions to insurers. We work with insurers from all around the world to create engaging customer journeys, use advanced analytics to expand globally while still meeting local needs, and modernise legacy systems.
                        </p>
                    </div>

                    <div className="col-lg-6 responsive-col-gap">
                        <img src="/images/industries/insurance/sec-2.png"></img>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid managed-it-services-sec-3 technology-sec-2 banking-sec-4  insurance-sec-4">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <h3>Tech Support Services to Solve<br/>Your Challenges  </h3>
                                    <p>Even the most top-notch IT environment will experience issues at some point, and when that happens,<br/> you need the right solutions to help you recover as quickly as possible. Our tech support<br/>services are available round the clock, ensuring that we are always here to help you whenever you need it.  </p>
                                </div>
                                <button className="btn btn-borderless orange-btn">
                                Contact Us 
                                    
                                </button>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>

            <CommonSection className="container healthcare-sec-5">
                <div className="row align-items-end">
                    <div className="col-lg-12">
                    <h3>What Makes ZOG Global<br/>Different? </h3>
                    </div>
                    <div className="col-lg-6">
                       
                        <p>At ZOG Global, we strive to stand out from the crowd of IT providers. Our top priority is always our clients, and we work tirelessly to design and manage IT systems tailored to their unique needs and objectives. Whether you manage a small startup or a large business, we are dedicated to delivering personalised service and customised solutions that help you achieve your business goals. </p>
                        <p>We understand the importance of your clients and their data security. That's why we're dedicated to equipping you with the best tools to guarantee your information is protected while offering an exceptional customer service experience. </p>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>Our exceptional approach to client service provides the following benefits. </b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li>Optimise your resources and reduce costs associated with IT department maintenance by leveraging our on-demand services.  </li>
                                <li>Our staff of experts is highly qualified in every area of IT and can help you minimise time wasted on troubleshooting. </li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li>Take the hassle out of IT or data security issues. Our team provide professional solutions to give you peace of mind and allow your business to continue progressing. </li>
                                <li>We help you outpace the competition with our advanced IT systems. You can count on us for exceptional, secure, and dependable services that meet current and future needs. </li>
                            </div>
                        </ul>
                    </div>
                </div>

            </CommonSection>
            <CommonSection className="container-fluid technology-sec-5 insurance-sec-7">
                <div className="container">
                    <div className="row">
                        <div className="col-kg-6">
                            <h3>Related Insights </h3>
                        </div>
                    </div>
                </div>
                <Slider {...settings2} className="simple-slider">
                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>
                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>
                </Slider>
            </CommonSection>

        </>
    )
}

export default QualityAssurance