import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";

import { useEffect, useState } from "react";
import $ from 'jquery';
const WhyZogGlobal = () => {
    const [tabImgUrl, setTabImgUrl] = useState("/images/why-zog-global/sec-3-0.png");


    useEffect(() => {


    }, [tabImgUrl])


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    function toggleTab(index) {
$(".fading-img").animate({ opacity: "0" }, "fast", function() {
    setTabImgUrl("/images/why-zog-global/sec-3-" + index + ".png");
    });
        
        $(".fading-img").animate({ opacity: "1" }, "fast");
    }
    return (
        <>
            <Banner
                bgUrl="/images/why-zog-global/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "8.6042", bottom: "12" }}
                heading={"We Create<br/>Success Stories<br/>Using Technology"}
                content={window.innerWidth>992?
                    "We trust technology and continually preparing for the future."
                    :window.innerWidth>778?
                    "We trust technology and continually preparing for the future."
                    :
                    "We trust technology and<br/> continually preparing for<br/>the future."
                    } 
                contentWidth="30rem"
                mobileVerticalAlignment="flex-start"
                mobileTextAlign="center"
                customClassName='why-zog-global-banner'
            >

            </Banner>
            <CommonSection className="container why-zog-global-sec-1">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Discover the Next</h3>
                        <p>We amplify human potential with advanced technologies to create the next opportunity for people, enterprises, and communities.</p>
                        <button className="btn btn-transparent">
                            Learn more
                            <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span>
                        </button>
                    </div>
                </div>


                <div className="row gapless-box-container g-0">
                    <div className="col-lg-6">
                        <div className="box">
                            <div >
                                <h4>A Reliable Partner</h4>
                            </div>
                            <p>
                                Due to the concierge approach we take to handling their IT requirements; our partners perceive value in our collaboration. We offer a top-notch, unlimited service with everything you may need. It's a genuine partnership, not merely a supplier-client arrangement. Our clients frequently express their gratitude for our prompt response times, expert service, and low reactive concerns while also praising the fact that we are a reputable, well-established company. </p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="box">
                            <div><h4>Top-Notch Service </h4> </div>
                            <p>
                                When businesses use the cloud and the contemporary workplace, they frequently have to choose between security, productivity, and convenience. It's not necessary to be like this. Our clients receive best-in-class, multi-layered security solutions from us while still enjoying the freedom, adaptability, and mobility that come with a virtual, cloud-based office.  </p>

                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid why-zog-global-sec-2">
                <div className="grey-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 responsive-col-gap">
                                <h4>A True Partner</h4>
                                <h3>We are Agile, Innovative,<br/>and Professional </h3>
                            </div>
                            <div className="col-lg-6">
                                {window.innerWidth > 768 ?
                                    <div className="strip">
                                        <img src="/images/why-zog-global/sec-2-1.svg" alt="icon"></img>
                                        <div>
                                            <h4>Our Mission</h4>
                                            <p>Achieving global leadership in digital engineering, automation, and artificial intelligence.</p>
                                        </div>
                                    </div> :


                                    <div className="strip">

                                        <div>
                                            <img src="/images/why-zog-global/sec-2-1.svg" alt="icon"></img>
                                            <h4>Our Mission</h4>
                                        </div>
                                        <p>Achieving global leadership in digital engineering, automation, and artificial intelligence.</p>

                                    </div>}


                                {window.innerWidth > 768 ?
                                    <div className="strip">
                                        <img src="/images/why-zog-global/sec-2-1.svg" alt="icon"></img>
                                        <div>
                                            <h4>Our Vision</h4>
                                            <p>Creating customer-centric digital solutions for brands transforming into digital.</p>
                                        </div>
                                    </div> : <div className="strip">

                                        <div>
                                            <img src="/images/why-zog-global/sec-2-1.svg" alt="icon"></img>
                                            <h4>Our Vision</h4>

                                        </div>
                                        <p>Creating customer-centric digital solutions for brands transforming into digital.</p>

                                    </div>}

                                {window.innerWidth > 768 ?
                                    <div className="strip">
                                        <img src="/images/why-zog-global/sec-2-1.svg" alt="icon"></img>
                                        <div>
                                            <h4>Our Values</h4>
                                            <p>As a company, we strive to build happiness for everyone we work with: Technology Partners, Employees, and Clients.</p>
                                        </div>
                                    </div> :
                                    <div className="strip">

                                        <div>
                                            <img src="/images/why-zog-global/sec-2-1.svg" alt="icon"></img>
                                            <h4>Our Values</h4>
                                        </div>
                                        <p>As a company, we strive to build happiness for everyone we work with: Technology Partners, Employees, and Clients.</p>

                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container why-zog-global-sec-3">

                {window.innerWidth > 992 ?
                    <div className="row ">
                        <div className="col-lg-6 responsive-col-gap">
                            <h4>Our Story </h4>
                            <h3>What we believe</h3>
                            <img className="fading-img" src={tabImgUrl}></img>
                        </div>
                        <div className="col-lg-6">
                            <ul>
                                <li onClick={() => toggleTab(0)}>We are proud to embrace the same values that shaped the company from the beginning. Since day one, we've created abiding relationships and a culture of integrity.</li>
                                <li onClick={() => toggleTab(1)}>According to our belief, success for our clients means success for all of us. This is why we are devoted to developing strong leaders who can steer their teams toward success.</li>
                                <li onClick={() => toggleTab(2)}>Nothing is more important than our reputations. We do business with honesty, uniqueness, and professionalism.</li>
                                <li onClick={() => toggleTab(3)}>Take care of each other. We work together on respect, equality, and trust to attain our objectives.</li>                             </ul>
                        </div>
                    </div> : <>
                        <div className="row ">
                            <div className="col-lg-12 responsive-col-gap">
                                <h4>Our Story </h4>
                                <h3>What we believe</h3>

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-lg-6">
                                <img src="/images/why-zog-global/sec-3-0.png"></img>
                            </div>
                            <div className="col-lg-6">
                                <p>We are proud to embrace the same values that shaped the company from the beginning. Since day one, we've created abiding relationships and a culture of integrity.</p>
                            </div>
                            
                            
                            <div className="col-lg-6">
                                <img src="/images/why-zog-global/sec-3-1.png"></img>
                            </div>
                            <div className="col-lg-6">
                                <p>According to our belief, success for our clients means success for all of us. This is why we are devoted to developing strong leaders who can steer their teams toward success.</p>
                            </div>
                            
                            
                            <div className="col-lg-6">
                                <img src="/images/why-zog-global/sec-3-2.png"></img>
                            </div>
                            <div className="col-lg-6">
                                <p>Nothing is more important than our reputations. We do business with honesty, uniqueness, and professionalism.</p>
                            </div>

                            <div className="col-lg-6">
                                <img src="/images/why-zog-global/sec-3-3.png"></img>
                            </div>
                            <div className="col-lg-6">
                               <p>Take care of each other. We work together on respect, equality, and trust to attain our objectives.</p>
                            </div>
                        </div></>

                }
            </CommonSection>


            <CommonSection className="container-fluid why-zog-global-sec-4">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">
                            
                            {window.innerWidth>992?
                                <h3>Start your Journey With Us </h3>
:window.innerWidth>778?
<h3>Start your Journey With Us </h3>
:
<h3>Start your Journey<br/>With Us </h3>
}  

                                <button className="btn btn-primary btn-transparent home-book-button">
                                Contact Now
                                    <span>
                                        <img src="/images/btn-right-arrow-white-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>

            <CommonSection className="container why-zog-global-sec-5">
                <div className="row">
                    <div className="col-lg-5 responsive-col-gap">

                        <h3>Case Study</h3>
                        <img src="/images/why-zog-global/sec-5.png"></img>
                        <p>Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments. Our unique dual WAF solution helps minimize risk, downtime and delays associated with.</p>
                    </div>
                    <div className="col-lg-7 non-breaking-mob-container">
                        <div className="row">
                            <div className="col-6">
                                <div className="box">
                                    <h4>Lörem ipsum
                                        dopåvis onade. </h4>
                                    <p>Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments. Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments.</p>
                                </div>
                                <div className="box">
                                    <h4>Lörem ipsum
                                        dopåvis onade. </h4>
                                    <p>Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments. Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments.</p>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="box">
                                    <h4>Lörem ipsum
                                        dopåvis onade. </h4>
                                    <p>Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments. Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments.</p>
                                </div>
                                <div className="box">
                                    <h4>Lörem ipsum
                                        dopåvis onade. </h4>
                                    <p>Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments. Our unique dual WAF solution helps minimize risk, downtime and delays associated with a lack of information by providing predictability when pushing changes to production environments.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>
 
            <CommonSection className="container why-zog-global-sec-6">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Insights </h3>
                    </div>
                </div>

                <div className="row gapless-box-container g-0">
                    <div className="col-lg-4">
                        <div className="box">
                            <div className="special-border">
                                <h4>Lörem ipsum<br/>
                                    dopåvis onade.  </h4>
                            </div>
                            <p>
                                Verizon offers the networking and security expertise needed to
                                implement an adaptive, enterprise-grade secure access service
                                edge (SASE) environment. enterprise-grade secure access service
                                edge (SASE) environment.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="box">
                            <div className="special-border"><h4>Lörem ipsum<br/>
                                dopåvis onade.  </h4> </div>
                            <p>
                                Verizon offers the networking and security expertise needed to
                                implement an adaptive, enterprise-grade secure access service
                                edge (SASE) environment. enterprise-grade secure access service
                                edge (SASE) environment.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="box">
                            <div className="special-border">
                                <h4>Lörem ipsum<br/>
                                    dopåvis onade.  </h4>
                            </div>
                            <p>
                                Verizon offers the networking and security expertise needed to
                                implement an adaptive, enterprise-grade secure access service
                                edge (SASE) environment. enterprise-grade secure access service
                                edge (SASE) environment.
                            </p>
                        </div>
                    </div>
                </div>
            </CommonSection>
        </>
    )
}

export default WhyZogGlobal