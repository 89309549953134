import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected2, setTabSelected2] = useState(0);


    useEffect(() => {
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");

    }, [tabSelected2])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function toggleTab2(index) {
        setTabSelected2(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>

            <Banner
                bgUrl="/images/AI-Automation/workdeck-ai/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "8", bottom: "8" }}
                heading={"Unlock Unimaginable<br/>Possibilities in Technology and<br/>Business With Our AI Solutions"}
                content={
                    "Processes can be automated and optimised to reduce costs for your business. Allowing workers to concentrate on more meaningful tasks will increase productivity. By employing AI in place of manual data processing, efficiency will increase."
                }

                mobileVerticalAlignment="center"
                mobileTextAlign="left"
                customClassName={"workdeck-ai-banner"}
            >
            </Banner>

            <CommonSection className="container workdeck-ai-sec-1">
                <div className="row align-items-center justify-content-between g-5">

                    <div className="col-md-6 responsive-col-gap">

                        <h3>Enhance Your Business and Measure the Success</h3>

                        <p><b>Our talented teams understand the industry's business models. Combined with technology and process, we can achieve cost objectives and measurable benefits for enterprises. </b></p>
                        <br></br>
                        <p>ZOG Global makes a lasting difference in your firm and through it, the world. To ensure this comes through, we have established ourselves as a market leader and specialist in providing future-relevant, innovative tech solutions that not only solve your problems but also place your firm ahead of its peers.</p>  </div>

                    <div className="col-md-5">
                        <img src="/images/AI-Automation/workdeck-ai/sec-1.png"></img>
                    </div>
                </div>
            </CommonSection>


            <CommonSection className="container workdeck-ai-sec-2">
                <div className="row align-items-center">

                    <div className="col-lg-8 responsive-col-gap">

                        <h4>Transform The Way You Work</h4>
                        <h3> Applying Intelligence to Solve Problems</h3>
                        {window.innerWidth<992&&<div className="col-lg-4">
                        <img src="/images/AI-Automation/workdeck-ai/sec-2.png"></img>
                    </div>
}
                        <p>Reduce the overload on your employee's daily mundane or repetitive tasks using our next-gen automation services. We are proud to deliver our customers the tools to provide world-class digital services with a vast array of integrated solutions.</p>
                    </div>
                    {window.innerWidth>992&&<div className="col-lg-4">
                        <img src="/images/AI-Automation/workdeck-ai/sec-2.png"></img>
                    </div>
}
                </div>
            </CommonSection>


            <CommonSection className="container workdeck-ai-sec-3">
                <div className="row">

                    <div className="col-lg-6">

                        <h4>How We Fix Problems​</h4>
{window.innerWidth>992?
                        <h3> Activated<br />Intelligence is<br />What We Are</h3>

:
<h3> Activated Intelligence<br /> is What We Are</h3>
}
                    </div>
                    <div className="col-lg-6">
                        <div className="box">
                            <h4>Accelerate your Business Success </h4>
                            <p>We go a step further to understand how things operate and find ways to optimize them for better performance. With our strong proficiencies in Product development, Contact Centre applications, Cloud Applications, Cybersecurity, Process Automation, Machine Learning, and Artificial Intelligence, we deliver first-class solutions that best fit your business.</p>

                        </div>

                    </div>
                </div>
            </CommonSection>


            <CommonSection className="container artificial-intelligence-sec-6">
                <div className="row">
                    <div className="col-lg-12">
{window.innerWidth>992?
                        <h3>Browse Our Latest Thoughts and News</h3>
:
<h3>Browse Our Latest<br/>Thoughts and News</h3>
}                    </div>
                </div>
                <div className="row g-5">
                    <div className="col-md-6 responsive-col-gap">
                        <div className="box">
                            <h4>Sit sed et massa interdum. </h4>
                            <p>Nulla arcu morbi mattis nibh odio praesent. Bibendum non in duis felis. Vitae pharetra, dictum lobortis pulvinar semper quis elit. Commodo faucibus ut enim mauris ut. Nibh lobortis tincidunt dui eget sed ante dictum vitae. Egestas scelerisque nulla est sed. Augue a libero turpis maecenas eget diam tincidunt turpis. Mollis vitae amet nisi aliquam. Suspendisse faucibus arcu ut montes, elementum nisl, mauris malesuada facilisi.
                                Malesuada ligula pharetra id metus feugiat euismod id. Molestie amet elementum volutpat amet mauris, condimentum. Lorem fringilla amet a enim, facilisis non, consequat. Integer sed condimentum maecenas sapien fermentum tortor scelerisque volutpat lacus. Dignissim arcu euismod felis vitae ultrices. Fringilla in quis nullam leo ut et nulla.</p>
                            <button className="btn btn-transparent">See paper details <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span></button>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="box">
                            <h4>Ultricies urna, ut pellentesque enim vitae, pharetra. Ut ullamcorper. </h4>
                            <p>Eget vel pretium, posuere dignissim orci, velit sed. Nunc habitant risus porttitor pulvinar aliquet. Tristique semper vestibulum habitant feugiat id massa tempor, fusce sit. Aliquam diam ut turpis sapien nunc, faucibus. Vivamus vel vitae nunc sit in blandit aliquam eleifend erat. Senectus pharetra scelerisque pulvinar aliquam. Laoreet ante nam pharetra, vulputate. Amet eu aliquet tortor arcu donec purus. Feugiat condimentum hac fringilla dui sodales laoreet fermentum praesent bibendum. Libero mattis eros, at viverra erat purus. Ullamcorper amet.</p>
                            <button className="btn btn-transparent">See paper details  <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span></button>

                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                {window.innerWidth>992?
                                <h3>Have You Got a New Project in Mind?<br />
                                We Can Make It Happen</h3>
                                :
                                <h3>Have You Got<br /> a New Project in Mind?<br />
                                    We Can Make It Happen</h3>}
                                {window.innerWidth>992?
                                <p>Transform your vision into reality! Experience the next level of innovation with<br />our AI-powered solutions. Let's craft the future together. Contact us today to ignite your ideas!
                                </p>
                                :
                                <p>Transform your vision into reality! Experience the next level of innovation with our AI-powered solutions. Let's craft the future together. Contact us today to ignite your ideas!
                                </p>}
                                <a href="../contact"> <button className="btn btn-transparent">
                                    Contact Us
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance