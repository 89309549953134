import React from "react";
import "./Footer.css";

import { useNavigate  } from 'react-router-dom';

const Footer = () => {
  let navigate = useNavigate(); 
  var LOGOURL;

  if(window.innerWidth>768){
      LOGOURL="/images/zog-logo.svg"
  }
  else{
    
    LOGOURL="/images/zog-logo-mobile-footer.svg"
  }


  return (
    <div className="footer container-fluid">
      <div className="container">
        <div className="row main">
          <div className="col-xl-3 col-lg-6 main-col">
            <ul>
            <li onClick={()=>navigate("/what-we-do")}>
                What We Do
              </li>
              <li onClick={()=>navigate("/what-we-do/transform-your-business")}>Transform Your Business</li>
              <li onClick={()=>navigate("/what-we-do/protect-your-customer-data")}>Protect Your Customers Data</li>
              <li onClick={()=>navigate("/what-we-do/managed-it-services")}>Managed IT Services</li>
              <li onClick={()=>navigate("/what-we-do/engagement-model")}>Engagement Model</li>
            </ul>
            <ul>
              <li  onClick={()=>navigate("/quality-assurance/")} className="heading">
                Quality Assurance
              </li>
              <li onClick={()=>navigate("/quality-assurance/automation-testing")} >Automation Testing</li>
              <li onClick={()=>navigate("/quality-assurance/mannual-testing")} >Manual Testing</li>
              <li onClick={()=>navigate("/quality-assurance/penetration-testing")} >Penetration Testing</li>
              <li onClick={()=>navigate("/quality-assurance/perfomance-testing")} >Performance</li>
              <li onClick={()=>navigate("/quality-assurance/application-security-testing")} >Application Security Testing</li>
              <li onClick={()=>navigate("/quality-assurance/accessibility-testing")} >Accessibility Testing</li>
            </ul>
          </div>
          <div className="col-xl-3  col-lg-6 main-col">
            <ul>
              <li onClick={()=>navigate("/industries/")}  className="heading">
                Industries
              </li>
              <li onClick={()=>navigate("/industries/healthcare")}>Healthcare</li>
              <li onClick={()=>navigate("/industries/technology")}>Technology</li>
              <li onClick={()=>navigate("/industries/telecom")}>Telecom</li>
              <li onClick={()=>navigate("/industries/education")}>Education</li>
              <li onClick={()=>navigate("/industries/travel")}>Travel</li>
              <li onClick={()=>navigate("/industries/banking")}>Banking and Financial Services</li>
              <li onClick={()=>navigate("/industries/insurance")}>Insurance</li>
              <li onClick={()=>navigate("/industries/legal")}>Legal</li>
              <li onClick={()=>navigate("/industries/entertainment")}>Media & Entertainment</li>
              <li onClick={()=>navigate("/industries/pharma")}>Pharma</li>
            </ul>
          </div>
          <div className="col-xl-3 col-lg-6 main-col">
            <ul>
              <li onClick={()=>navigate("/digital-engineering/")} className="heading">
                Digital Engineering
              </li>
              <li onClick={()=>navigate("/digital-engineering/automation")}>Automation</li>
              <li onClick={()=>navigate("/digital-engineering/devops")}>DevOps</li>
              <li onClick={()=>navigate("/digital-engineering/devsecops")}>DevSecOps</li>
              <li onClick={()=>navigate("/digital-engineering/ANS")}>Advance NetworkingServices<br></br>(SD-WAN, SDN)</li>
              <li onClick={()=>navigate("/digital-engineering/cloud")}>Cloud</li>
              <li onClick={()=>navigate("/digital-engineering/cyber-security")}>Cybersecurity</li>
              <li onClick={()=>navigate("/digital-engineering/software-development")}>Software Development</li>
              <li onClick={()=>navigate("/digital-engineering/testing")}>Testing</li>
              <li onClick={()=>navigate("/digital-engineering/unified-communication")}>Unified Communication</li>
            </ul>
          </div>
          <div className="col-xl-3 col-lg-6 main-col">
            <ul>
              <li onClick={()=>navigate("/ai-automation/")}>AI & Automation</li>
              <li onClick={()=>navigate("/ai-automation/artificial-intelligence")}>Artificial Intelligence</li>
              <li onClick={()=>navigate("/ai-automation/automation-robotics")}>Automation & Robotics</li>
              <li onClick={()=>navigate("/ai-automation/workdeck-ai")}>Workdeck-AI (Next-Gen IT Automation)</li>
            </ul>
            <ul>
              <li onClick={()=>navigate("/insight/")}>Insight</li>
              <li onClick={()=>navigate("/blog/")}>Blog</li>
              <li onClick={()=>navigate("/be-a-partner/")}>Be A Partner</li>
              <li onClick={()=>navigate("/career/")}>Career</li>
            </ul>
            <ul>
              <li className="social-heading">Follow</li>
              
                <div className="row social-box">
                  <div className="col-md-6 px-0">
                  <img onClick={()=>window.location.href = "https://www.facebook.com/zogglobal/"} src="/images/facebook.svg"></img>
                  <img onClick={()=>window.location.href = "https://www.instagram.com/zogglobal/?hl=en"} src="/images/instagram.svg"></img>
                  </div>
                
                
                 
                  <div className="col-md-6 px-0">
                  <img onClick={()=>window.location.href = "https://twitter.com/zogglobal?lang=en"} src="/images/twitter.svg"></img>
                  <img onClick={()=>window.location.href = "https://uk.linkedin.com/company/zogglobal?original_referer=https%3A%2F%2Fwww.google.com%2F"} src="/images/linkedin.svg"></img>
                  </div>
              
                </div>
            
            </ul>
            {window.innerWidth < 768?<ul className="link-box">
                     <li onClick={()=>navigate("/legal-notice/")} >Legal Notices</li>
                    <li onClick={()=>navigate("/modern-slavery-statement/")} >Modern Slavery Statement</li>
                    <li onClick={()=>navigate("/terms-conditions/")} >Terms & Conditions</li>
                    <li onClick={()=>navigate("/accessibility/")} >Accessiblity</li>
                  <li onClick={()=>navigate("/cookie-policy/")} >Cookie Policy</li>
                </ul>:null}

          </div>
        </div>
         <div className=" tail">
                <div className="img-box">
                    <img src={LOGOURL}></img>
                </div>

                {window.innerWidth > 992?<div className="link-box">
                    <a onClick={()=>navigate("/legal-notice/")}>Legal Notices</a>
                    <a onClick={()=>navigate("/modern-slavery-statement/")}>Modern Slavery Statement</a>
                    <a onClick={()=>navigate("/terms-conditions/")}>Terms & Conditions</a>
                    <a onClick={()=>navigate("/accessibility/")}>Accessiblity</a>
                    <a onClick={()=>navigate("/cookie-policy/")}>Cookie Policy</a>
                </div>:null}

                <div className="cr-box"><p>© 2022 ZOG Global</p></div>
            </div>
      </div>
    </div>
  );
};

export default Footer;