import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])

    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            {window.innerWidth > 992 ? <CommonSection className="container de-banner cloud-banner">
                <div className="row cr">

                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Create<br />
                            A Digital<br />Foundation<br />with Cloud<br />Computing</h3>

                        <p>Automate, accelerate, and simplify crucial business applications.<br />With years of experience, ZOG Global’s skilled and expert team<br />provides top-notch cloud computing services.</p>
                    </div>
                    <div className="col-lg-6 responsive-col-gap">
                        <div className=" responsive-col-gap"></div>
                        <img src="/images/digital-engineering/cloud/banner.png"></img>
                    </div>

                </div>

            </CommonSection>
                :
                <Banner
                    bgUrl="/images/digital-engineering/cloud/banner.png"
                    theme="dark"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"Create A Digital Foundation with Cloud Computing"}
                    content={
                        "Automate, accelerate, and simplify crucial business applications. With years of experience, ZOG Global’s skilled and expert team provides top-notch cloud computing services."
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-end"
                    mobileTextAlign="left"
                >

                </Banner>}

            <CommonSection className="container cloud-sec-1">
                <div className="row">
                    <div className="col-lg-6 ">
                        <h3>Leverage The Cloud to Meet Your Business Goals</h3>
                    </div>
                    <div className="col-lg-6">
                        <p>We can provide everything in cloud computing, from consulting cloud strategy, installations, migration, advice, cloud backup solutions, etc., to ensure that you have the proper infrastructure and cloud-based solutions with SaaS, IaaS, or PaaS. Our range of modular Cloud Consulting Services enables clients rapidly, effortlessly, and cost-effectively to integrate comprehensive Business Continuity and Data Protection Services into their businesses.</p>
                    </div>
                </div>

                <div className="row gapless-box-container g-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="box">

                            <h4>Amazon Web <br></br>Service</h4>

                            <ul>
                                <li>AWS Analytics Services</li>
                                <li>AWS Application Integration Services</li>
                                <li>Amazon Sumerian</li>
                                <li>Amazon Blockchain Services</li>
                            </ul>

                        </div>

                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <h4>Google<br></br>
                                Cloud </h4>
                            <ul>
                                <li>Hybrid & Multi-Cloud</li>
                                <li>VM Migration</li>
                                <li>SAP Migration</li>
                                <li>Google G Suite</li>
                            </ul>

                        </div>

                    </div>

                    <div className="col-lg-4">
                        <div className="box">
                            <h4>Microsoft <br></br>Azure </h4>
                            <ul>
                                <li>Windows Azure Compute Services</li>
                                <li>Window Azure Storage</li>
                                <li>SQL Azure</li>
                                <li>Windows Azure AppFabric</li>
                                <li>Windows Azure Marketplace</li>
                            </ul>

                        </div>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2">
                <div className="row g-0 cr">

                    <div className="col-lg-7 responsive-col-gap border">
                        <h3> Discover more, maintain<br />less</h3>

                        <p className="bold">With our managed cloud hosting service, you can focus on growing<br />   your website without worrying about anything.</p>
                        <ul>
                            <li>Support from Experts</li>
                            <li>Maximize Cloud ROI</li>
                            <li>Strategy-First Approach</li>
                            <li>Certified Industry Professionals</li>
                            <li>Reliability</li>
                            <li>Advanced Technology</li>
                        </ul>
                    </div>

                    <div className="col-lg-5">
                        <img src="/images/digital-engineering/cloud/sec-2.png"></img>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container healthcare-sec-3 cloud-sec-3">
                <div className="row g-0">
                    <div className="col-lg-4">
                        <div className="box">
                            {window.innerWidth > 992 ?
                                <h3>What We <br></br>
                                    Can Do</h3>
                                :
                                <h3>What We Can Do</h3>}
                            <p>We provide cost-effective Cloud service in the UK with unmatched technical expertise. </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="box">
                            <h4>Cloud<br></br> Architecture</h4>
                            <p>We create a cloud computing environment tailored to your business requirements and in line with your business goals.</p>
                        </div>
                        <div className="box">

                            <h4>Cloud<br></br>
                                Security</h4>
                            <p>We ensure your organization's cloud environment is secure and compliant with applicable regulations and standards.</p>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="box">
                            <h4>Cloud <br></br>Migration </h4>
                            <p>Evaluate your organization's existing IT infrastructure and develop a plan for migrating your workloads to the cloud.</p>
                        </div>
                        <div className="box">
                            <h4>Cloud<br></br>
                                ROI </h4>
                            <p>Develop a cloud budget and strategy for future investments after assessing the possible return on investment (ROI) of migrating to the cloud.</p>
                        </div>

                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container protect-your-customers-data-sec-4 cloud-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Journey into
                            the Cloud </h3>

                        <p>ZOG Global provides a comprehensive set of cloud support services to overcome setbacks as organizations embrace cloud computing. </p>
                    </div>

                </div>
                {window.innerWidth > 992 ?
                    <div className="row justify-content-between g-5">
                        <div className="col-md-5">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Amazon Web Services (AWS)</button>
                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Google Cloud Platform (GCP)
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Microsoft Azure
                                </button>


                            </div>

                        </div>

                        <div className="col-md-6">


                            <div className="tab-content">
                                <h4>Amazon Web Services </h4>

                                <p>We focus on AWS, Cloud-Generated, and Cloud-Native. And with the constant flow of new capabilities, we can take you to a real cloud-local environment using AWS.
                                    Each of our AWS professionals has unique skills to fill your organization’s knowledge gaps, ensuring that you have reached your short- and long-term goals.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Google Cloud Platform </h4>

                                <p>No matter what solution you think of in the Cloud, ZOG Global's cloud experts will help you achieve that. Our Google cloud experts support you in modernizing your business to world-class infrastructure and building your business environment in the Cloud.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Microsoft Azure </h4>

                                <p>Azure provides automated OS and service patching, designed to fix network load balancing and hardware failure, and supports an extension design that allows you to upgrade your application without wasting time.
                                    ZOG Global’s Azure Consulting Services team allows our clients to get more value from their cloud investment and avoid costly interruptions.
                                </p>


                            </div>

                        </div>
                    </div>
                    :
                    <div className="row justify-content-between g-5">
                        <div className="col-12">

                            <div className="tab-btn-container">
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Amazon Web Services (AWS)</button>
                                <div className="tab-content">
                                    <h4>Amazon Web Services </h4>

                                    <p>We focus on AWS, Cloud-Generated, and Cloud-Native. And with the constant flow of new capabilities, we can take you to a real cloud-local environment using AWS.
                                        Each of our AWS professionals has unique skills to fill your organization’s knowledge gaps, ensuring that you have reached your short- and long-term goals.</p>
                                </div>

                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Google Cloud Platform (GCP)
                                </button>
                                <div className="tab-content">
                                    <h4>Google Cloud Platform </h4>

                                    <p>No matter what solution you think of in the Cloud, ZOG Global's cloud experts will help you achieve that. Our Google cloud experts support you in modernizing your business to world-class infrastructure and building your business environment in the Cloud.</p>


                                </div>
                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Microsoft Azure
                                </button>

                                <div className="tab-content">
                                    <h4>Microsoft Azure </h4>

                                    <p>Azure provides automated OS and service patching, designed to fix network load balancing and hardware failure, and supports an extension design that allows you to upgrade your application without wasting time.
                                        ZOG Global’s Azure Consulting Services team allows our clients to get more value from their cloud investment and avoid costly interruptions.
                                    </p>


                                </div>

                            </div>

                        </div>
                    </div>}
            </CommonSection>


            <CommonSection className="container  education-sec-4 cloud-sec-5">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Why Choose ZOG Global as your Cloud Partner?</h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="col-4">
                            <div className="box">
                                <h4>Industry<br></br> Experts </h4>
                                <p>ZOG Global has extensive knowledge in dealing with issues related to IT support, software testing, software development, project management, business intelligence, and cybersecurity. We provide a one-stop solution for your ever-changing technology needs.</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box">
                                <h4>Reliable Point<br></br>  of Contact</h4>
                                <p>Instead of collaborating with many vendors, you'll work with a dedicated team that is intimately familiar with your business solutions. This team will be directed by a project manager, assisted by an account manager, and occasionally overseen by a management team.</p>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <h4>Organize around<br></br>  Business Goals </h4>
                                <p>As teams and businesses expand, companies often need to catch up on their IT goals and objectives. ZOG Global provides a service model that enables you to choose the assistance you require, from maintaining or outsourcing your complete IT department to offering specialist resources as required.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid managed-it-services-sec-3  banking-sec-4 devops-sec-6 cloud-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    {window.innerWidth > 992 ?
                                        <h3>Elevate Your Business with Seamless<br></br>Cloud Solutions</h3>

                                        :
                                        <h3>Elevate Your Business with<br></br>Seamless Cloud Solutions</h3>}
                                    {window.innerWidth > 992 ?
                                        <p>Experience unparalleled scalability, security, and accessibility tailored to your business needs. Feel free to<br />reach out to us today and discover how our cloud services can bring a revolution to your business operations.</p>
                                        :
                                        <p>Experience unparalleled scalability, security, and accessibility tailored to your business needs. Feel free to reach out to us today and discover how our cloud services can bring a revolution to your business operations.</p>
                                    }

                                </div>
                                <a href="../contact"> <button className="btn btn-borderless orange-btn">
                                    Contact Us

                                </button></a>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance