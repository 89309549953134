import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            {window.innerWidth > 992 ? <CommonSection className="container de-banner software-development-banner">
                <div className="row cr">

                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Developing Sophisticated and Elegant Web & Software Applications</h3>

                        <p>Do you need a highly experienced and professional team of Software Developers and App Developers in the UK? Then you are in the right place. ZOG Global builds reliable and world-class software solutions for your IT requirements that are tailored to meet the needs of a dynamic market. </p>
                    </div>
                    <div className="col-lg-6 responsive-col-gap">
                        <div className=" responsive-col-gap"></div>
                        <img src="/images/digital-engineering/software-development/banner.png"></img>
                    </div>

                </div>

            </CommonSection>
                :
                <Banner
                    bgUrl="/images/digital-engineering/software-development/banner.png"
                    theme="light"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"Developing Sophisticated and Elegant Web & Software Applications"}
                    content={
                        "Do you need a highly experienced and professional team of Software Developers and App Developers in the UK? Then you are in the right place. ZOG Global builds reliable and world-class software solutions for your IT requirements that are tailored to meet the needs of a dynamic market. "
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-end"
                    mobileTextAlign="left"
                >

                </Banner>}

            <CommonSection className="container soft-dev-sec-1">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>How We Develop</h3>
                    </div>
                    <div className="col-lg-10 mb-4">
                        <p>ZOG Global has a fully equipped and passionate software development and engineering team with a wealth of experience to ensure the quality and timely delivery of projects.</p>

                    </div>
                </div>

                <div className="row gapless-box-container g-0">
                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>Describe your project requirements</h4>
                            <p>In order to ececute your project effectively, our analyst will evaluate your project requirements comprehensively and select the most appropriate resource.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>Talk To Our Analysts About The Project</h4>
                            <p>We will contact you within 24 hours to discuss your project requirements in detail and to provide you with the best-customised solution.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>Timeline And<br /> Terms Of<br />Engagement</h4>
                            <p>We will discus different enggement models and timelineS with you as part of the project cunsultation process.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>Securely Pay<br /> Online And<br />Get Started</h4>
                            <p>We ensure that your payment is made securely online so that our team can begin working on your project as soon as we recieve it.</p>
                        </div>
                    </div>
                </div>
            </CommonSection>


            <CommonSection className="container soft-dev-sec-2">
                <div className="row">
                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Robust and Highly Scalable Software Development Solutions</h3>
                    </div>
                    <div className="col-lg-10 mb-4">
                        <p>ZOG Global specializes in deploying software solutions for various business domains nationwide.<br /> We help enterprises overcome their technical limitations and improve their operations.</p>

                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 responsive-col-gap">

                        <img src="/images/digital-engineering/software-development/sec-2-1.png"></img>
                        <h4>Dedicated Development Team as a Service</h4>
                        <p>ZOG Global provides you to nurture your inner talent with our dedicated team of engineering professionals, architects, and project managers with proven experience in building design, such as designing complete solutions, commissioning, and performing tasks.
                            91% of our workforce is senior- mid-level engineers with degrees in computing or math. Our inner strength lies in strengthening your current teams with our talent or building the best teams from bottom to top.</p>
                    </div>

                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/software-development/sec-2-2.png"></img>
                        <h4>Our Software Development Lifecycle Strategy</h4>
                        <p>Our team will consult with you to ascertain the preferred functionality for your software package before creating a thorough evaluation for you. A non-disclosure agreement is signed, assuring that you and you alone own the intellectual property of the project.
                            The project’s assets are yours, and you will sign the source code upon final payment. This streamlined and efficient production cycle produces high-quality software that meets your specifications.</p>
                    </div>

                </div>
            </CommonSection>


            <CommonSection className="container soft-dev-sec-3">
                {window.innerWidth > 992 ?
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="box title-box">
                                <h3 className="mb-5">What Makes Us Unique?</h3>
                                <p>Your business relationship with us matters as much as the impression we make on your clients. </p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="box number-box">
                                <div className="text-box">
                                    <h4>Flexible Models of Engagement</h4>
                                    <p>We offer dedicated resources, no additional fees, low pricing, and no overhead to precisely match our client's needs. We are eager to collaborate with you to develop customised solutions for your company.</p>

                                </div>
                                <img src="/images/digital-engineering/software-development/sec-3-1.svg"></img>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="box number-box">
                                <div className="text-box">
                                    <h4>Result-Driven Development</h4>
                                    <p>We are passionate about designing, building, and delivering valuable solutions with actual customer value, not just services. Our goal is to help you solve real problems and achieve tangible results. </p>

                                </div>
                                <img src="/images/digital-engineering/software-development/sec-3-2.svg"></img>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="box number-box">
                                <div className="text-box">
                                    <h4>On-Time Delivery & Competitive Prices</h4>
                                    <p>We get a low-cost infrastructure for advanced development, which significantly reduces costs for our customers. We also assure on-time delivery of the project.</p>

                                </div>
                                <img src="/images/digital-engineering/software-development/sec-3-3.svg"></img>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="box number-box">
                                <div className="text-box">
                                    <h4>High Competence in Technology</h4>
                                    <p>We combine in-depth technical knowledge, architectural capabilities, and program management skills to help you integrate and acquire new capabilities for disparate systems covering core and new technologies.</p>

                                </div>
                                <img src="/images/digital-engineering/software-development/sec-3-4.svg"></img>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="box number-box">
                                <div className="text-box">
                                    <h4>Full Post-Release Support</h4>
                                    <p>We take great pride in every application we build and are prepared to offer you complete technical support during and after the SDLC. We work with you to continuously improve your product, increase its functionality, and keep it relevant for your target audience.</p>

                                </div>
                                <img src="/images/digital-engineering/software-development/sec-3-5.svg"></img>
                            </div>
                        </div>



                    </div>
                    : <>
                        <div className="row">
                            <div className="col-12">
                                <div className="box title-box">
                                    <h3 className="mb-5">What Makes Us Unique?</h3>
                                    <p>Your business relationship with us matters as much as the impression we make on your clients. </p>
                                </div>
                            </div>
                        </div>
                       <div className="non-breaking-mob-container">
                       <div className="row">
                            <div className="col-4">
                                <div className="box number-box">
                                    <div className="text-box">
                                        <h4>Flexible Models of Engagement</h4>
                                        <p>We offer dedicated resources, no additional fees, low pricing, and no overhead to precisely match our client's needs. We are eager to collaborate with you to develop customised solutions for your company.</p>

                                    </div>
                                    <img src="/images/digital-engineering/software-development/sec-3-1.svg"></img>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="box number-box">
                                    <div className="text-box">
                                        <h4>Result-Driven Development</h4>
                                        <p>We are passionate about designing, building, and delivering valuable solutions with actual customer value, not just services. Our goal is to help you solve real problems and achieve tangible results. </p>

                                    </div>
                                    <img src="/images/digital-engineering/software-development/sec-3-2.svg"></img>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="box number-box">
                                    <div className="text-box">
                                        <h4>On-Time Delivery & Competitive Prices</h4>
                                        <p>We get a low-cost infrastructure for advanced development, which significantly reduces costs for our customers. We also assure on-time delivery of the project.</p>

                                    </div>
                                    <img src="/images/digital-engineering/software-development/sec-3-3.svg"></img>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="box number-box">
                                    <div className="text-box">
                                        <h4>High Competence in Technology</h4>
                                        <p>We combine in-depth technical knowledge, architectural capabilities, and program management skills to help you integrate and acquire new capabilities for disparate systems covering core and new technologies.</p>

                                    </div>
                                    <img src="/images/digital-engineering/software-development/sec-3-4.svg"></img>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="box number-box">
                                    <div className="text-box">
                                        <h4>Full Post-Release Support</h4>
                                        <p>We take great pride in every application we build and are prepared to offer you complete technical support during and after the SDLC. We work with you to continuously improve your product, increase its functionality, and keep it relevant for your target audience.</p>

                                    </div>
                                    <img src="/images/digital-engineering/software-development/sec-3-5.svg"></img>
                                </div>
                            </div>



                        </div>
                       </div>
                    </>
                }
            </CommonSection>

            <CommonSection className="container soft-dev-sec-4">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>What We Do</h3>
                    </div>

                </div>

                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-3">
                        <div className="box">
                            <img src="/images/digital-engineering/software-development/sec-4.svg"></img>

                            <h4>Custom Software Development</h4>
                            <p>Tailored solutions crafted to meet specific business needs, from design to implementation, ensuring optimal functionality and efficiency.
                                .</p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                            <img src="/images/digital-engineering/software-development/sec-4.svg"></img>
                            <h4>Mobile App Development</h4>
                            <p>Creating intuitive, high-performance mobile applications across platforms enhances user experiences and drives engagement.</p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                            <img src="/images/digital-engineering/software-development/sec-4.svg"></img>
                            <h4>E-commerce Development</h4>
                            <p>Creating online shopping platforms with secure payment gateways, intuitive interfaces, and scalable infrastructure for seamless customer transactions and growth..</p>
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="box">

                            <img src="/images/digital-engineering/software-development/sec-4.svg"></img>
                            <h4>Maintenance and Support</h4>
                            <p>Continuous monitoring, updates, and troubleshooting to ensure software systems operate smoothly, providing ongoing technical assistance and enhancements.</p>
                        </div>
                    </div>
                </div>
                </div>
            </CommonSection>


            <CommonSection className="container-fluid  devsecops-sec-4 soft-dev-sec-5">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/digital-engineering/software-development/sec-5-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Bring Bold Business Ideas to Life with Exceptional Digital Products. </h3>
                                        <p>You are Happy when you get more than what you expected. ZOG Global makes sure you are happy with our outstanding service.</p>
                                        <a href="../contact"><button className="btn btn-transparent">Get in Touch<span><svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg></span></button></a>

                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/digital-engineering/software-development/sec-5-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Unlock Limitless Possibilities with Our Custom Software Solutions</h3>
                                        <p>With our team of experienced and innovative developers, we craft tailor-made software solutions that align perfectly with your unique needs and goals.</p>

                                        <a href="../contact"><button className="btn btn-transparent">Talk to our Experts<span><svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg></span></button></a>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>





                </Slider>
            </CommonSection>


            <CommonSection className="container  education-sec-4">
                <div className="row" >
                    <div className="col-lg-6">
                      {window.innerWidth>992?
                        <h3>Explore Latest Tech<br />Stories and News</h3>
                    :
                    <h3>Explore Latest<br/>Tech Stories and News</h3>}
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                </div>
                </div>
                
            </CommonSection>
        </>
    )
}

export default QualityAssurance