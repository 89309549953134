export const jobsAPITestData = [{
    "category": "Business Technology", "list": [

        {"id":"0","jobID":"ZG01" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG02" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG03" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        { "id":"0","jobID":"ZG04" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        
    ]
},

{
    "category": "Engineering", "list": [

        {"id":"0","jobID":"ZG05" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG06" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG07" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG08" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        
    ]
},

{
    "category": "Customer First", "list": [

        {"id":"0","jobID":"ZG09" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG10" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG11" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG12" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        
    ]
},

{
    "category": "G&A", "list": [

        {"id":"0","jobID":"ZG13" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG14" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG15" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG16" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        
    ]
},

{
    "category": "Marketing", "list": [

        { "id":"0","jobID":"ZG17" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG18" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG19" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        { "id":"0","jobID":"ZG20" , "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        
    ]
},

{
    "category": "Marketing", "list": [

        {"id":"0","jobID":"ZG21" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG22" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG23" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        {"id":"0","jobID":"ZG24" ,  "title": "Massa dui luctus sit euismod nunc bibendum.", "location": "Cochin/Work From Home","type":"Full Time" },
        
    ]
},


]
