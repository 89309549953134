import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };




    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    

    return (
        <>
            <Banner
                bgUrl="/images/industries/pharma/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={"IT Services for<br/>the Pharmaceutical Industry "}
                content={
                    "Improve scalability, security, and flexibility in the pharmaceutical industry by utilising available resources and modernising processes. "
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
            >

            </Banner>
            <CommonSection className="container technology-sec-3">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Exceptional IT Support from a Reliable Partner  </h3>
                    </div>
                    <div className="col-lg-6">
                        <p>Our tailored managed IT services are designed to meet the specific requirements of the pharmaceutical industry. We provide comprehensive support for your organisation's systems and technology, ensuring that your confidential information is always kept secure and protected. With our expertise and experience, you can rest assured that your IT infrastructure is in good hands and that your business operations will run smoothly and efficiently.
                            <br></br><br></br>
                            We guarantee that you will receive top-notch support from our team of IT professionals who specialise in regulatory compliance and have extensive experience in the field. So you can trust us to back you up entirely.
                            <br></br><br></br>
                            Unlock new horizons and blaze a trail of success, exceed your customers' expectations with unparalleled service, and unleash the potential of data to make informed and visionary decisions with us. </p></div>
                </div>
            </CommonSection>


            <CommonSection className="container healthcare-sec-1 education-sec-3 pharma-sec-2">
                <div className="row" >
                    <div className="col-lg-6 img-box">
                        <div className="mask">
                            <div>
                                {window.innerWidth>992?
                                <h3>Future of IT in the Pharmaceutical Industry </h3>
                                :
                                <h3>Future of IT<br/>in the Pharmaceutical Industry </h3>
}
                                <p>With fierce competition out there, adaptation is key to success. In the pharmaceutical industry, the latest advancements can assist in creating high-quality medicines and improve patient outcomes. </p>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <diV className="gapless-box-container">
                            <div className="my-row">
                                <div className="col-lg-6">
                                    <img src="/images/industries/pharma/sec-2-1.svg"></img>
                                    <h4>Deliver a Better Customer Experience </h4>
                                    <p>Innovations in technology, such as web and mobile platforms, are making it easier for pharmaceutical companies to improve customer relationships. Online ordering capabilities make the buying process significantly more intuitive and efficient – increasing customer loyalty.  </p>
                                </div>

                                <div className="col-lg-6">
                                <img src="/images/industries/pharma/sec-2-2.svg"></img>
                                    <h4>Spread More Information </h4>
                                    <p>In the modern healthcare landscape, online access to data on medications is essential. This allows pharmacy customers to quickly become informed on drug performance and common side effects so they can have a productive conversation with their doctor.   </p>
                                </div>
                            </div>
                            <div className="my-row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center   ">
                                        <img src="/images/industries/pharma/sec-2-3.svg" className="me-3"></img>
                                        <h4>Enhance Drug Development</h4>
                                    </div>
                                    <p>Digital technology is revolutionising the pharma industry, and one exciting example is the use of artificial intelligence. With its ability to analyse massive amounts of data, AI is uncovering valuable patterns and connections, leading to faster and higher-quality drug development. </p>
                                </div>
                            </div>
                        </diV>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Our Offerings to the Pharma Industry</h3>
                        <p>Empower your pharmaceutical enterprise with our comprehensive IT solutions tailored to the industry's unique demands.</p>
                    </div>

                </div>
                {window.innerWidth>992?
                <div className="row justify-content-between g-5">
                <div className="col-md-5">

                    <div
                        className="tab-btn-container"

                    >
                        <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Software Development </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(1)}>AI & Machine Learning
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>Cloud Computing
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(3)}>Cybersecurity
                        </button>

                    </div>

                </div>

                <div className="col-md-6">


                    <div className="tab-content">
                        <h4>Software Development </h4>

                        <p>Our custom-developed pharma software is beneficial as it helps to minimise paperwork, thus making the doctor-pharmacist-patient interaction significantly faster. Ultimately, this leads to improved patient health outcomes and more efficient business processes for pharmaceutical companies. </p>


                    </div>
                    <div className="tab-content">
                        <h4>AI & Machine Learning </h4>

                        <p>The pharmaceutical industry stands to gain a lot from the power of AI and ML. These technologies provide higher levels of data analysis, predictive capabilities, and automated processes, allowing drug creators to increase their efficiency and improve the quality of production significantly. </p>


                    </div>
                    <div className="tab-content">
                        <h4>Cloud Computing </h4>

                        <p>Our cloud computing services offer scalability by providing flexible storage and computing resources that can adapt to the pharma industry's changing needs. This allows pharma companies to handle large amounts of data without worrying about the infrastructure's limitations. </p>


                    </div>
                    <div className="tab-content">
                        <h4>Cybersecurity  </h4>

                        <p>Companies in the pharma sector need to have a reliable and compliant cybersecurity protocol that protects their valuable data. Here at ZOG Global, we provide HIPAA-compliant cybersecurity solutions with multiple layers of defence, so pharma businesses can manage their data securely while providing excellent services to customers. </p>


                    </div>

                </div>
            </div>
            :
            <div className="row justify-content-between g-5">
                    <div className="col-12">

                        <div
                            className="tab-btn-container"

                        >
                            <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Software Development </button>

                            <div className="tab-content">
                            <h4>Software Development </h4>

                            <p>Our custom-developed pharma software is beneficial as it helps to minimise paperwork, thus making the doctor-pharmacist-patient interaction significantly faster. Ultimately, this leads to improved patient health outcomes and more efficient business processes for pharmaceutical companies. </p>


                        </div>


                            <button className="tab-btn" onClick={(e) => toggleTab(1)}>AI & Machine Learning
                            </button>

                            <div className="tab-content">
                            <h4>AI & Machine Learning </h4>

                            <p>The pharmaceutical industry stands to gain a lot from the power of AI and ML. These technologies provide higher levels of data analysis, predictive capabilities, and automated processes, allowing drug creators to increase their efficiency and improve the quality of production significantly. </p>


                        </div>


                            <button className="tab-btn" onClick={(e) => toggleTab(2)}>Cloud Computing
                            </button>

                            <div className="tab-content">
                            <h4>Cloud Computing </h4>

                            <p>Our cloud computing services offer scalability by providing flexible storage and computing resources that can adapt to the pharma industry's changing needs. This allows pharma companies to handle large amounts of data without worrying about the infrastructure's limitations. </p>


                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(3)}>Cybersecurity
                            </button>

                            <div className="tab-content">
                            <h4>Cybersecurity  </h4>

                            <p>Companies in the pharma sector need to have a reliable and compliant cybersecurity protocol that protects their valuable data. Here at ZOG Global, we provide HIPAA-compliant cybersecurity solutions with multiple layers of defence, so pharma businesses can manage their data securely while providing excellent services to customers. </p>


                        </div>



                        </div>

                    </div>

                    
                </div>}
            </CommonSection>


           


            <CommonSection className="container home-sec-2 technology-sec-6 travel-sec-4 pharma-sec-5 ">
                <div className="row">
                    <div className="col-lg-4">
                        <div>

                            <h3>Custom Solutions for Your Needs</h3>
                           
                        </div>
                        <p>We take the time to truly understand your requirements and create a custom technological solution that perfectly aligns with them. </p>
                    </div>

                    <div className="col-lg-8">
                        <div className="bg-box">
                            <h4 className="mb-5">Manage Your Business with Ease </h4>
                            <div className="row g-0">
                                <div className="col-md-6">
                                    <h4>Enhanced Efficiency</h4>
                                    <p>At ZOG Global, we understand the unique needs of the pharmaceutical business when it comes to management and administration. We provide tailored technology solutions that help optimise profits, enhance operational and technical efficiencies, and bring standardised systems for pharmaceutical enterprises.                                     </p>

                                </div>
                                <div className="col-md-6">
                                    <h4>Enhanced Results</h4>
                                    <p>Our suite of technological tools, solutions, and applications is designed to be accessible, adaptable, and user-friendly. We prioritise data protection and security to ensure that our clients can achieve better organisational results with confidence.  </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </CommonSection>





            <CommonSection className="container healthcare-sec-5">
                <div className="row align-items-end">
                    <div className="col-lg-12">
                    <h3>Benefits Of Choosing Our<br/>Pharmaceutical IT Solution<br/> Services </h3>
                    </div>
                    <div className="col-lg-6 align-self-baseline">
                        
                        <p>ZOG Global is dedicated to transforming the Pharmaceutical Industry by providing a range of digital solutions designed to accelerate drug discovery, enhance clinical trial effectiveness and reduce the timeline for introducing new drugs onto the market. We specialise in Artificial Intelligence, Automation and other IT Support Services, all with an unyielding focus on security and complete integration.
                            <br></br><br></br>
                            You can be confident that our unwavering dedication to excellence guarantees that your business will achieve optimal results. </p>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>Considering us for Pharmaceutical IT Solutions could garner many advantages. Among these are: </b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li>We have extensive experience in helping organisations with their digital transformation journeys, and we can provide the guidance and support you need to ensure your project is a success. </li>
                                <li>We provide Unified Communication as a Service (UCaaS) solutions tailored to complex infrastructures, ensuring optimal user experience for employees and customers. </li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li>Our advanced technology is designed to provide you with the tools and resources you require to streamline your processes, enhance your productivity, and achieve your business goals with ease.  </li>
                                <li>We help you to be proactive in your response to critical service performance problems so that you can address them before they impact your business. </li>
                            </div>
                        </ul>
                    </div>
                </div>

            </CommonSection>

            <CommonSection className="container-fluid managed-it-services-sec-3 technology-sec-2 banking-sec-4 pharma-sec-7">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <h3>Harness the Power of Technology<br/> to Accomplish Your Mission</h3>
                                    <p>We stay updated with the latest trends and technologies in the pharma industry<br/>so that we can provide you with the best service and support possible. So why wait?<br/>  Get in touch with us today! </p>
                                </div>
                                <button className="btn btn-borderless orange-btn">
                                Talk To Us
                                    
                                </button>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance