import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";

import { useEffect, useState } from "react";
import $ from 'jquery';

const QualityAssurance = () => {


    const [tabSelected2, setTabSelected2] = useState(0);


    useEffect(() => {
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");

    }, [tabSelected2])





    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);









    return (
        <>

            <Banner
                bgUrl="/images/AI-Automation/automation-robotics/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "11", bottom: "11" }}
                heading={window.innerWidth > 992 ?
                    "Automate & Optimize your<br/>Business Processes"
                    :
                    "Automate &<br/>Optimize your<br/>Business Processes"}
                content={
                    window.innerWidth > 992 ?
                        "End-to-end Robotic Process Automation (RPA) expert services offered by Zog Global achieve your business goals by empowering your digital workforce, increasing productivity, and lowering operational costs while attracting and retaining customers."

                        :
                        "End-to-end Robotic Process Automation (RPA) expert services<br/>offered by Zog Global achieve your business goals by<br/> empowering your digital workforce, increasing productivity, <br/>and lowering operational costs while attracting and retaining<br/> customers."
                }
                customClassName={"robotics-banner"}
            >
            </Banner>

            <CommonSection className="container robitics-sec-1">
                <div className="row gx-5">
                    <div className="col-lg-4">
                        <img src="/images/AI-Automation/automation-robotics/sec-1.png"></img>
                    </div>
                    <div className="col-lg-8">
                        <p>Robotic process automation (RPA) is a method of automating using software bots or code snippets, thus making repetitive tasks that consume valuable human time more efficient.<br></br><br></br>
                            Unlike human-driven processes, robotic process automation achieves exceptional results. It does not tolerate human error and is available 24/7. As a result, businesses often use RPA to automate repetitive operations, freeing employees to concentrate on problems and aspects that demand higher-level thinking.
                        </p>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container">
                <div className="row gx-5">
                    <div className="col-lg-4">
                        <h3>A Quick Overview <br></br>of RPA</h3>
                    </div>
                    <div className="col-lg-8">
                        <p><b>Robotic process automation ecosystems have developed to a very high level of sophistication, and they must be adequately planned, designed, and governed to succeed. </b></p>
                        <p>
                            Bots can automate an activity if it is predictable, repeatable, and includes decision trees that always lead to a precise result. The system would detect a mistake using conventional business process management and fixing it would require human interaction. However, robotic process automation allows bots to identify problems, locate missing data, and complete forms without user intervention.
                            Robotic process automation can be an effective efficiency-boosting tool for companies trying to reduce costs while making it easier to use more comprehensive business process automation systems.
                        </p>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container robotics-sec-3">
                <div className="row">
                    <dic className="col-lg-6 responsive-col-gap">
                        {window.innerWidth > 992 ?
                            <h3>Enhance your Business Growth with our Reliable RPA Services</h3>

                            :
                            <h3>Enhance your Business Growth
                                <br />with our Reliable RPA Services</h3>
                        }                        <p>Zog Global offers comprehensive RPA development solutions to all businesses across the UK. We use well-known RPA tools to automate business processes, increase cost-effectiveness, reduce errors, save time, and produce measurable business results that significantly increase ROI.</p>

                    </dic>
                </div>
                <div className="row gx-5">
                    <div className="col-lg-6 responsive-col-gap">

                        <div className="bg-box">
                            <div className="mask">
                                <div>
                                    <h3>Have RPA Development Project in Mind?</h3>
                                    <p>Streamline your business processes and increase your success with the RPA Development Services. </p>

                                </div>
                                <button className="btn btn-transparent">Talk to an Expert <span>
                                    <img src="/images/btn-right-arrow-white-long-tailed.svg"></img>
                                </span></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-6">
                                <img src="/images/AI-Automation/automation-robotics/sec-3-2.png" ></img>
                                <div className="box">
                                    <h4>RPA<br />Consulting Services</h4>
                                    <p>Choosing the appropriate RPA tools, automation PoCs, and automation plans are part of our RPA services' approach to assisting businesses in increasing productivity. In addition, we guarantee comprehensive support for our customers, from recognizing the automation process to implementing and selecting the right tools.</p>
                                </div>
                            </div>

                            <div className="col-6">
                                <img src="/images/AI-Automation/automation-robotics/sec-3-2.png"></img>
                                <div className="box">
                                    <h4>Custom<br />Automation Services</h4>
                                    <p>Our tailored robotic process automation solutions reduce the number and complexity of administrative tasks. We understand that your business is unique and has specific needs, so we ensure our RPA solutions are perfect for startups and enterprises.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container  robotics-sec-4">
                <div className="row gx-5 cr">

                    <div className="col-lg-8">
                        <h3>Fames lacinia<br />pharetra vitae in.</h3>
                        <p>Sit in tortor consectetur eget nam scelerisque rutrum nunc elit. Pharetra sed felis, scelerisque odio vulputate eu. Ut sapien tortor vel risus arcu. Ultrices ipsum convallis tortor amet in neque semper. Ornare habitant arcu dui proin dolor sed potenti.
                            Malesuada lorem aliquet cursus tellus aliquet fermentum. Sed lacus dolor malesuada morbi est egestas mi eu sit. Non tristique id interdum non. Elit, aliquet sed tellus, eu lacus. Tellus lorem sed justo ac eget egestas sodales dui integer. Mauris nec, ac id placerat sem. Morbi porta duis cursus nibh nisl sed pellentesque. Viverra ut nibh nunc enim nam augue consectetur. Sit donec turpis elit, arcu senectus porta varius habitant. Ridiculus risus mattis vel, in tempus ultricies turpis velit euismod. Viverra nibh sit eu dolor etiam urna netus mauris. Eget egestas amet, libero velit tellus feugiat volutpat leo. Gravida cursus nunc quisque pellentesque. Quisque molestie sit sit odio libero viverra enim. Nunc vitae convallis tempor ultricies vitae mi ut.
                            Malesuada sit ac ut mi quis velit potenti. Urna, faucibus nisi mauris scelerisque massa mauris senectus et quam. Condimentum dui elit ipsum purus morbi non urna odio. Elit tellus eget sed porta arcu .</p>
                    </div>

                    <div className="col-lg-4">
                        {window.innerWidth>992?
                        <img src="/images/AI-Automation/automation-robotics/sec-4.png"></img>
                    :
                    <img src="/images/AI-Automation/automation-robotics/sec-4-tab.png" className="w-100"></img>}
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container artificial-intelligence-sec-6">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>Read Our Latest Blogs </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 responsive-col-gap">
                        <div className="box">
                            <h4>Sit sed et massa interdum. </h4>
                            <p>Nulla arcu morbi mattis nibh odio praesent. Bibendum non in duis felis. Vitae pharetra, dictum lobortis pulvinar semper quis elit. Commodo faucibus ut enim mauris ut. Nibh lobortis tincidunt dui eget sed ante dictum vitae. Egestas scelerisque nulla est sed. Augue a libero turpis maecenas eget diam tincidunt turpis. Mollis vitae amet nisi aliquam. Suspendisse faucibus arcu ut montes, elementum nisl, mauris malesuada facilisi.
                                Malesuada ligula pharetra id metus feugiat euismod id. Molestie amet elementum volutpat amet mauris, condimentum. Lorem fringilla amet a enim, facilisis non, consequat. Integer sed condimentum maecenas sapien fermentum tortor scelerisque volutpat lacus. Dignissim arcu euismod felis vitae ultrices. Fringilla in quis nullam leo ut et nulla.</p>
                            <button className="btn btn-transparent">Read More <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span></button>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="box">
                            <h4>Ultricies urna, ut pellentesque enim vitae, pharetra. Ut ullamcorper. </h4>
                            <p>Eget vel pretium, posuere dignissim orci, velit sed. Nunc habitant risus porttitor pulvinar aliquet. Tristique semper vestibulum habitant feugiat id massa tempor, fusce sit. Aliquam diam ut turpis sapien nunc, faucibus. Vivamus vel vitae nunc sit in blandit aliquam eleifend erat. Senectus pharetra scelerisque pulvinar aliquam. Laoreet ante nam pharetra, vulputate. Amet eu aliquet tortor arcu donec purus. Feugiat condimentum hac fringilla dui sodales laoreet fermentum praesent bibendum. Libero mattis eros, at viverra erat purus. Ullamcorper amet.</p>
                            <button className="btn btn-transparent">Read More     <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span></button>

                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                <h3>Let's work together<br />to make things better</h3>
                                <p>Discover how our technologies can benefit your business,<br />and how you can invest in ZOG Global </p>
                                <button className="btn btn-transparent">
                                    Contact Us
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance