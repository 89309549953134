import React from "react";
import './App.css';

import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom'

import { UserProvider } from "./UserContext"




import UserLayout from "./user-panel/common-componets/Layout"
import AdminLayout from "./admin-panel/common-components/Layout.js"

import SignUp from './SignUp.js';
import Login from './Login';
import EmailConfirmation from './EmailConfirmation';
import EmailConfirmed from './EmailConfirmed';
import ForgotPassword from './ForgotPassword';
import Otp from './Otp';
import NewPassword from './NewPassword';
import PasswordChanged from './PasswordChanged';







function App() {
 
 
  return (

    
    <div className="App">
      
    
   <Router>
   <UserProvider>
      <Routes >


      <Route path="/*" element={<UserLayout />} />
            
            
            <Route exact path="/admin-login/*" element={
              <>
                {localStorage.setItem("userRoleRequest", "admin")}
                <Navigate to="/login" />
              </>
            } />

            <Route exact path="/admin/*" element={<AdminLayout />} />     {/* element={isLoggedIn ? <AdminLayout /> : <Navigate to="/admin-login" />}  */}
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/confirm-mail" element={<EmailConfirmation />} />
            <Route path="/mail-confirmed" element={<EmailConfirmed />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/password-reset/confirm/*" element={<NewPassword />} />
            <Route path="/password-changed" element={<PasswordChanged />} />

            
      
        
     
       

      </Routes >
      </UserProvider>
     
    </Router>
    
      
    </div>
  );
}

export default App;
