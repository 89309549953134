import React from "react";
import { useEffect, useState } from "react";
import CommonSection from "../common-componets/CommonSection";
import Slider from "react-slick";
const Insight = () => {

    const bannerStyle = {
        backgroundImage: `url("/images/insight/banner.png")`,

        /* Center and scale the image nicely */
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
       
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    }


    const slideSettings = {
        centerMode: true,
        centerPadding: '0px',
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
        ]
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    return (
        <>
            <div className=" insight-banner" style={bannerStyle} >
                <div className="bg-box">
                    <h1 style={{ color: "#F6F6F6", textAlign: "left" }}>New Events and Helpful Articles </h1>
                    {window.innerWidth>992?
                    <p style={{ color: "#F6F6F6", textAlign: "left" }}>Discover how we can navigate unprecedented and<br/>unpredictable times together by reading ZOG Global's latest<br/>thought leadership.</p>

:window.innerWidth>778?
<p style={{ color: "#F6F6F6", textAlign: "left" }}>Discover how we can navigate unprecedented and<br/>unpredictable times together by reading ZOG Global's latest<br/>thought leadership.</p>

:
<p style={{ color: "#F6F6F6", textAlign: "left" }}>Discover how we can navigate<br/> unprecedented and unpredictable<br/> times together by reading ZOG<br/> Global's latest thought leadership.</p>

}                     <button className="btn btn-transparent">
                    Discover More
                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg>
                                    </span>
                    </button>
                </div>

            </div>

            <CommonSection className="container insight-sec-1">

                <h3 className="text-center mb-4">Explore Industry Insights</h3>
                <Slider {...slideSettings} className="insight-slider">


                    <div className="slide">
                        <div className="transform-box">
                            <div className="img-box">
                                <img src="/images/insight/sec-1-1.png"></img>
                            </div>
                            <div className="text-box">
                                <div>
                                    <h4>Lörem ipsum
                                        förväntis </h4>
                                    <p>Lesam väling. Jymobelt king. Etnomatisk vavis. Berar. Epiberar jöngen. </p>
                                </div>
                                <button className="btn btn-borderless">
                                    Read More
                                    <span>
                                        <img src="/images/btn-tailed-right-arrow-white.svg"></img>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="slide">
                        <div className="transform-box">
                            <div className="img-box">
                                <img src="/images/insight/sec-1-1.png"></img>
                            </div>
                            <div className="text-box">
                                <div>
                                    <h4>Lörem ipsum
                                        förväntis </h4>
                                    <p>Lesam väling. Jymobelt king. Etnomatisk vavis. Berar. Epiberar jöngen. </p>
                                </div>
                                <button className="btn btn-borderless">
                                    Read More
                                    <span>
                                        <img src="/images/btn-tailed-right-arrow-white.svg"></img>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="slide">
                        <div className="transform-box">
                            <div className="img-box">
                                <img src="/images/insight/sec-1-1.png"></img>
                            </div>
                            <div className="text-box">
                                <div>
                                    <h4>Lörem ipsum
                                        förväntis </h4>
                                    <p>Lesam väling. Jymobelt king. Etnomatisk vavis. Berar. Epiberar jöngen. </p>
                                </div>
                                <button className="btn btn-borderless">
                                    Read More
                                    <span>
                                        <img src="/images/btn-tailed-right-arrow-white.svg"></img>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="transform-box">
                            <div className="img-box">
                                <img src="/images/insight/sec-1-1.png"></img>
                            </div>
                            <div className="text-box">
                                <div>
                                    <h4>Lörem ipsum
                                        förväntis </h4>
                                    <p>Lesam väling. Jymobelt king. Etnomatisk vavis. Berar. Epiberar jöngen. </p>
                                </div>
                                <button className="btn btn-borderless">
                                    Read More
                                    <span>
                                        <img src="/images/btn-tailed-right-arrow-white.svg"></img>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>


                </Slider>
                <button className="btn btn-primary btn-transparent">
                    Discover More
                    <span>
                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                    </span>
                </button>
            </CommonSection>

            <CommonSection className="container insight-sec-2">
                <div className="row gx-0">
                    <div className="col-lg-7 ">
                        <div className="bg">
                            <div className="mask">

                                <img src="/images/insight/sec-2-mask.png"></img>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-5">
                        <div className="box">
                        <h3>Be a Partner</h3>
                        <p>Partnerships are an essential principle and guiding value of ZOG Global. Investing in mutually beneficial collaborations is one of the best ways to grow and create a more significant impact through sharing knowledge, experience, commitment, and investments. We are here to improve the world by partnering with public, private, and corporate partners.</p>
                        <a href="./contact"><button className="btn btn-primary btn-transparent">
                        Talk to Us
                            <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span>
                        </button></a>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid insight-sec-3">
                <div className="container box">
                    <div className="col-lg-6">
                        <h4> Career</h4>
                        <h3>Take your Professional Journey to New Heights with Us</h3>
                    </div>
                    <p>Through our global network of projects, we empower our members to make an impact on a global scale in a collaborative and supportive environment.</p>
                    <button className="btn btn-primary btn-transparent">
                    Latest Vacancies
                        <span>
                            <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                        </span>
                    </button>
                    </div>
            </CommonSection>
        </>
    )
}

export default Insight