import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);





    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>
            <Banner
                bgUrl="/images/quality-assurance/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={window.innerWidth > 992 ?
                    "Quality Assurance<br/>Services "
                    :
                    "Quality Assurance Services "
                }
                content={window.innerWidth>992?
                    "Enhancing the performance of your software with our high-</br>quality testing services"
                    :window.innerWidth>778?
                    "Enhancing the performance of your software with our high-</br>quality testing services"
                    :
                    "Enhancing the performance of your</br> software with our high-quality testing</br> services"
                    } 
                mobileVerticalAlignment="center"
                mobileTextAlign="center"
            >

            </Banner>
            <CommonSection className="container quality-assurance-sec-1 ">
                {window.innerWidth > 992 ?
                    <div className="row ">
                        <div className="col-lg-10">
                            <h3>Maintain Quality Assurance<br></br>
                                Enhance the Customer Experience</h3>
                            <p>We perform end-to-end testing of your infrastructure, applications, and data to guarantee that all your software is offered <br />bug-free to end users.</p>
                        </div>

                        <div className="row tab-btn-container">

                            <div className="col-md-4">
                                <button onClick={(e) => toggleTab(0)} className={tabSelected === 0 ? "tab-btn active" : "tab-btn"} to="/" >Automation Testing</button>
                                <button onClick={(e) => toggleTab(3)} className={tabSelected === 3 ? "tab-btn active" : "tab-btn"} to="/" >Performance Testing</button>
                            </div>

                            <div className="col-md-4">
                                <button onClick={(e) => toggleTab(1)} className={tabSelected === 1 ? "tab-btn active" : "tab-btn"} to="/" >Manual Testing</button>
                                <button onClick={(e) => toggleTab(4)} className={tabSelected === 4 ? "tab-btn active" : "tab-btn"} to="/" >Application Security Testing</button>
                            </div>

                            <div className="col-md-4">
                                <button onClick={(e) => toggleTab(2)} className={tabSelected === 2 ? "tab-btn active" : "tab-btn"} to="/" >Penetration Testing</button>
                                <button onClick={(e) => toggleTab(5)} className={tabSelected === 5 ? "tab-btn active" : "tab-btn"} to="/" >Accessibility Testing</button>
                            </div>
                        </div>


                        {tabSelected === 0 &&
                            <div className="tab-content">
                                <div className="row g-5">

                                    <div className="col-lg-6 pe-5">
                                        <h4>Automation Testing</h4>
                                        <h5>Automate Processes to Save Time</h5>
                                        <p>Automated test scripts can help the testing process run more smoothly and effectively. Testing an application's performance, load, and balance is best done through automation.</p>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                        <h5>Leveraging Automation Testing </h5>
                                        <ul>
                                            <li>Help ensure your testing ecosystem is fully automated to support DevOps and agile techniques.</li>
                                            <li>Automate test cases with ZOG Global’s programmable frameworks and scripts to accelerate software deployment.</li>
                                            <li>Ensure that your testing ecosystem is collaboratively seamless.</li>  </ul>
                                    </div>
                                </div>
                            </div>
                        }

                        {tabSelected === 1 &&
                            <div className="tab-content">
                                <div className="row g-5">

                                    <div className="col-lg-6 pe-5">
                                        <h4>Manual Testing </h4>
                                        <h5>Avoid Complications in the Development Process </h5>
                                        <p>We provide top-notch manual testing services to minimize the risks of coding flaws or irrational programme behaviour. We find bugs as soon as we can to assist teams in avoiding difficulties throughout the development process, making it more deliberate and practical from the early stages through the final release. </p>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                        <h5 className="">Leveraging Manual Testing  </h5>
                                        <ul>
                                            <li>We carefully examine your project to develop the appropriate testing strategy for it. </li>
                                            <li>We will test your product across all platforms to ensure consistent performance regardless of how many there are. </li>
                                            <li>With us, you can modify the dimensions of your project as needed. </li>
                                            <li>We will use the tool that will suit your budget and project requirements to manage the testing process. </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }


                        {tabSelected === 2 &&
                            <div className="tab-content">
                                <div className="row g-5">

                                    <div className="col-lg-6 pe-5">
                                        <h4>Penetration Testing  </h4>
                                        <h5>Analyse the Security Level of your Services  </h5>
                                        <p>You can evaluate the performance of your security teams with the aid of penetration testing. We look for security flaws that could expose sensitive data or situations where a harmful user might access sensitive data without the proper authorization. </p>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                        <h5>Leveraging Penetration Testing </h5>
                                        <ul>
                                            <li>Create a thorough security programme to safeguard your application environment and infrastructure. </li>
                                            <li>It aids in locating the main vulnerabilities that can be exploited. </li>
                                            <li>Your IT and network infrastructure will be adequately safeguarded by applying remedial solutions to any detected vulnerabilities immediately. </li>
                                            <li>No company is safe from cyberattacks; routine network security audits are an excellent method to help keep ordinary business activities running smoothly. </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }

                        {tabSelected === 3 &&
                            <div className="tab-content">
                                <div className="row g-5 pe-5">

                                    <div className="col-lg-6 ps-5">
                                        <h4>Performance Testing </h4>
                                        <h5>Measure the Effectiveness of the System  </h5>
                                        <p>Performance testing evaluates the system's efficiency, considering responsiveness, speed, and user experience under various workloads. This can involve heavy traffic, a substantial volume of data, and many requests. </p>
                                    </div>
                                    <div className="col-lg-6">
                                        <h5>Leveraging Performance Testing  </h5>
                                        <ul>
                                            <li>Ensure that your application uses resources most efficiently for its performance, response time, scalability, reliability, stability, and workloads. </li>
                                            <li>To compute API calls, database transactions, and the load on VMs, know when the programme will likely be used most and least. </li>
                                            <li>Create the necessary infrastructure and carry out capacity planning. </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }

                        {tabSelected === 4 &&
                            <div className="tab-content">
                                <div className="row g-5">

                                    <div className="col-lg-6 pe-5">
                                        <h4>Application Security Testing </h4>
                                        <h5>Eliminate Critical Vulnerabilities  </h5>
                                        <p>Our security testing services give clients constant access to security testing professionals with the knowledge, resources, and work ethic required to examine any application at any time and in any depth while remaining cost-effective. </p>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                        <h5>Leveraging Security Testing </h5>
                                        <ul>
                                            <li>We offer scalable testing options available on-demand, via contract, or on-site for organisations with limited resources. </li>
                                            <li>Get consistent, high-quality testing outcomes for any application. </li>
                                            <li>We review your test results with you and assist you in creating the appropriate remediation strategy for your requirements. </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }

                        {tabSelected === 5 &&
                            <div className="tab-content">
                                <div className="row g-5">

                                    <div className="col-lg-6 pe-5">
                                        <h4>Accessibility Testing </h4>
                                        <h5>Improve the Efficiency of the Application  </h5>
                                        <p>Our experts assess the application's level of accessibility and compliance and create a clear remedy plan. Our audit results can be used to boost system compliance and support your company's legal action. </p>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                        <h5>Leveraging Accessibility Testing  </h5>
                                        <ul>
                                            <li>The provision of accessibility testing services for an application also guarantees effective automation. If the programme successfully passes the automated test, it is also ideal for accessibility. </li>
                                            <li>The main goal of accessibility testing is to make things easier to use for the population of impaired persons. However, the aggregate results of accessibility testing provide an application's whole user base with straightforward accessibility. </li>
                                            <li>Testing for accessibility improves the quality of the application. For example, people with hearing impairments can benefit from transcribing sounds and music into descriptions. </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className="row">

                        <div className="col-lg-12">
                            <h3>Maintain Quality Assurance<br></br>
                                Enhance the Customer Experience</h3>
                            <p>We perform end-to-end testing of your infrastructure, applications, and data to guarantee that all your software is offered bug-free to end users.</p>
                        </div>

                        <div className="col-12 tab-contents-container">


                            <button className={tabSelected===0?"tab-btn active":"tab-btn"} type="button" onClick={(e) => toggleTab(0)}>Automation</button>


                            {tabSelected === 0 &&
                                <div className="tab-content">
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <h4>Automation Testing</h4>
                                            <h5>Automate Processes to Save Time</h5>
                                            <p>Automated test scripts can help the testing process run more smoothly and effectively. Testing an application's performance, load, and balance is best done through automation.</p>
                                        </div>
                                        <div className="col-lg-6 mt-lg-5">
                                            <h5 className="mt-lg-5">Leveraging Automation Testing </h5>
                                            <ul>
                                                <li>Help ensure your testing ecosystem is fully automated to support DevOps and agile techniques.</li>
                                                <li>Automate test cases with ZOG Global’s programmable frameworks and scripts to accelerate software deployment.</li>
                                                <li>Ensure that your testing ecosystem is collaboratively seamless.</li>  </ul>
                                        </div>
                                    </div>
                                </div>
                            }

                            <button className={tabSelected===1?"tab-btn active":"tab-btn"} type="button" onClick={(e) => toggleTab(1)}>Performance</button>


                            {tabSelected === 1 &&
                                <div className="tab-content">
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <h4>Performance Testing </h4>
                                            <h5>Measure the Effectiveness of the System  </h5>
                                            <p>Performance testing evaluates the system's efficiency, considering responsiveness, speed, and user experience under various workloads. This can involve heavy traffic, a substantial volume of data, and many requests. </p>
                                        </div>
                                        <div className="col-lg-6 mt-lg-5">
                                            <h5 className="mt-lg-5">Leveraging Performance Testing  </h5>
                                            <ul>
                                                <li>Ensure that your application uses resources most efficiently for its performance, response time, scalability, reliability, stability, and workloads. </li>
                                                <li>To compute API calls, database transactions, and the load on VMs, know when the programme will likely be used most and least. </li>
                                                <li>Create the necessary infrastructure and carry out capacity planning. </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            }

                            <button className={tabSelected===2?"tab-btn active":"tab-btn"} type="button" onClick={(e) => toggleTab(2)}>Manual Testing</button>


                            {tabSelected === 2 &&

                                <div className="tab-content">
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <h4>Manual Testing </h4>
                                            <h5>Avoid Complications in the Development Process </h5>
                                            <p>We provide top-notch manual testing services to minimize the risks of coding flaws or irrational programme behaviour. We find bugs as soon as we can to assist teams in avoiding difficulties throughout the development process, making it more deliberate and practical from the early stages through the final release. </p>
                                        </div>
                                        <div className="col-lg-6 mt-lg-5">
                                            <h5 className="mt-lg-5">Leveraging Manual Testing  </h5>
                                            <ul>
                                                <li>We carefully examine your project to develop the appropriate testing strategy for it. </li>
                                                <li>We will test your product across all platforms to ensure consistent performance regardless of how many there are. </li>
                                                <li>With us, you can modify the dimensions of your project as needed. </li>
                                                <li>We will use the tool that will suit your budget and project requirements to manage the testing process. </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            }

                            <button className={tabSelected===3?"tab-btn active":"tab-btn"} type="button" onClick={(e) => toggleTab(3)}>Application Security Testing</button>

                            {tabSelected === 3 &&
                                <div className="tab-content">
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <h4>Application Security Testing </h4>
                                            <h5>Eliminate Critical Vulnerabilities  </h5>
                                            <p>Our security testing services give clients constant access to security testing professionals with the knowledge, resources, and work ethic required to examine any application at any time and in any depth while remaining cost-effective. </p>
                                        </div>
                                        <div className="col-lg-6 mt-lg-5">
                                            <h5 className="mt-lg-5">Leveraging Security Testing </h5>
                                            <ul>
                                                <li>We offer scalable testing options available on-demand, via contract, or on-site for organisations with limited resources. </li>
                                                <li>Get consistent, high-quality testing outcomes for any application. </li>
                                                <li>We review your test results with you and assist you in creating the appropriate remediation strategy for your requirements. </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }

                            <button className={tabSelected===4?"tab-btn active":"tab-btn"} type="button" onClick={(e) => toggleTab(4)}>Penetration Testing</button>


                            {tabSelected === 4 &&
                                <div className="tab-content">
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <h4>Penetration Testing  </h4>
                                            <h5>Analyse the Security Level of your Services  </h5>
                                            <p>You can evaluate the performance of your security teams with the aid of penetration testing. We look for security flaws that could expose sensitive data or situations where a harmful user might access sensitive data without the proper authorization. </p>
                                        </div>
                                        <div className="col-lg-6 mt-lg-5">
                                            <h5 className="mt-lg-5">Leveraging Penetration Testing </h5>
                                            <ul>
                                                <li>Create a thorough security programme to safeguard your application environment and infrastructure. </li>
                                                <li>It aids in locating the main vulnerabilities that can be exploited. </li>
                                                <li>Your IT and network infrastructure will be adequately safeguarded by applying remedial solutions to any detected vulnerabilities immediately. </li>
                                                <li>No company is safe from cyberattacks; routine network security audits are an excellent method to help keep ordinary business activities running smoothly. </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }

                            <button className={tabSelected===5?"tab-btn active":"tab-btn"} type="button" onClick={(e) => toggleTab(5)}>Accessibility Testing</button>

                            {tabSelected === 5 &&

                                <div className="tab-content">
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <h4>Accessibility Testing </h4>
                                            <h5>Improve the Efficiency of the Application  </h5>
                                            <p>Our experts assess the application's level of accessibility and compliance and create a clear remedy plan. Our audit results can be used to boost system compliance and support your company's legal action. </p>
                                        </div>
                                        <div className="col-lg-6 mt-lg-5">
                                            <h5 className="mt-lg-5">Leveraging Accessibility Testing  </h5>
                                            <ul>
                                                <li>The provision of accessibility testing services for an application also guarantees effective automation. If the programme successfully passes the automated test, it is also ideal for accessibility. </li>
                                                <li>The main goal of accessibility testing is to make things easier to use for the population of impaired persons. However, the aggregate results of accessibility testing provide an application's whole user base with straightforward accessibility. </li>
                                                <li>Testing for accessibility improves the quality of the application. For example, people with hearing impairments can benefit from transcribing sounds and music into descriptions. </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div >
                }
            </CommonSection >
            <CommonSection className="container-fluid quality-assurance-sec-2">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/quality-assurance/sec-2-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Why Partner with ZOG Global?</h3>
                                        <p>A successful product starts with an innovative idea. But the whole user experience can be ruined by bugs, errors, and other inefficiencies. ZOG Global reduces your time to market for digital products through our Quality Assurance Services. We will ensure your software runs like clockwork and fulfils your customers’ expectations.</p>

                                        <a href="/contact"><button className="btn btn-borderless">
                                            Get in Touch
                                            <span>
                                                <svg width="38" height="13" viewBox="0 0 38 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 5.75C0.585786 5.75 0.25 6.08579 0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25V5.75ZM37.5303 7.03033C37.8232 6.73744 37.8232 6.26256 37.5303 5.96967L32.7574 1.1967C32.4645 0.903806 31.9896 0.903806 31.6967 1.1967C31.4038 1.48959 31.4038 1.96447 31.6967 2.25736L35.9393 6.5L31.6967 10.7426C31.4038 11.0355 31.4038 11.5104 31.6967 11.8033C31.9896 12.0962 32.4645 12.0962 32.7574 11.8033L37.5303 7.03033ZM1 7.25H37V5.75H1V7.25Z" fill="#F6F6F6" />
                                                </svg>

                                            </span>
                                        </button></a>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/quality-assurance/sec-2-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Safeguard your Reputation and Success  </h3>
                                        <p>The value of your company and the demands of your consumers are the foundation of a successful product. Quality Assurance is aware of these two factors and uses this information to safeguard your goods from flaws and problems. This allows for the creation of a product that is both reliable and delightful. </p>

                                        <a href="/contact"> <button className="btn btn-borderless">
                                            Let’s Work Together
                                            <span>
                                                <svg width="38" height="13" viewBox="0 0 38 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 5.75C0.585786 5.75 0.25 6.08579 0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25V5.75ZM37.5303 7.03033C37.8232 6.73744 37.8232 6.26256 37.5303 5.96967L32.7574 1.1967C32.4645 0.903806 31.9896 0.903806 31.6967 1.1967C31.4038 1.48959 31.4038 1.96447 31.6967 2.25736L35.9393 6.5L31.6967 10.7426C31.4038 11.0355 31.4038 11.5104 31.6967 11.8033C31.9896 12.0962 32.4645 12.0962 32.7574 11.8033L37.5303 7.03033ZM1 7.25H37V5.75H1V7.25Z" fill="#F6F6F6" />
                                                </svg>

                                            </span>
                                        </button></a>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>



                </Slider>
            </CommonSection>
            <CommonSection className="container quality-assurance-sec-3 ">
                <div className="row g-5 justify-content-between cr">
                    <div className="col-lg-6 responsive-col-gap">
                        <img src="/images/quality-assurance/sec-3.png"></img>
                    </div>
                    <div className="col-lg-6">
                        <div className="box">
                            {window.innerWidth > 992 ?
                                <h3>Secure your<br />Product’s Success<br />with Us </h3>
                                :
                                <h3>Secure your Product’s<br />Success with Us </h3>
                            }
                            <h5>We are Agile and Supportive​</h5>

                            <p>ZOG Global delivers independent quality assurance services and software testing solutions across the globe to next-generation enterprises. Our quality assurance professionals are highly experienced and skilled and understand enterprise's challenges in the digital transformation process from end to end.</p>
                            <button className="btn btn-borderless"> Read Story<span>
                                <svg width="38" height="13" viewBox="0 0 38 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5.75C0.585786 5.75 0.25 6.08579 0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25V5.75ZM37.5303 7.03033C37.8232 6.73744 37.8232 6.26256 37.5303 5.96967L32.7574 1.1967C32.4645 0.903806 31.9896 0.903806 31.6967 1.1967C31.4038 1.48959 31.4038 1.96447 31.6967 2.25736L35.9393 6.5L31.6967 10.7426C31.4038 11.0355 31.4038 11.5104 31.6967 11.8033C31.9896 12.0962 32.4645 12.0962 32.7574 11.8033L37.5303 7.03033ZM1 7.25H37V5.75H1V7.25Z" fill="#66C6EB" />
                                </svg>

                            </span></button>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container quality-assurance-sec-4">
                <div className="row">
                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Our Expertise<br />in Quality Assurance</h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="col-4 responsive-col-gap">
                            <div className="box">


                                <h4>Decades of Experience in<br />Quality Management </h4>
                                <p>We have tested countless software solutions to ensure their trouble-free operation. Over the past few years, we've tested dozens of mobile applications and complex enterprise software for various companies. By doing so, we ensure that your company has fail-safe software. </p>
                                <button className="btn btn-borderless">Read More ></button>
                            </div>
                        </div>

                        <div className="col-4 responsive-col-gap">
                            <div className="box">

                                <h4>Set our Own<br />Standards</h4>
                                <p>We offer QA services according to a set of procedures, guidelines, and project documents we designed based on the most acceptable industry practices and standards. Thus, our QA testers enable the delivery of high-quality and timely software solutions by preventing defects from occurring at all. </p>
                                <button className="btn btn-borderless">Read More ></button>
                            </div>
                        </div>

                        <div className="col-4 responsive-col-gap">
                            <div className="box">

                                <h4>Smart & Agile<br />Automation </h4>
                                <p>To ensure fast software delivery without failing quality, we utilize a selected approach to automated QA. We employ unit testing on every project, while automated acceptance and other testing rely on your enterprise software architecture, tech stack, project scale, and other factors. Our DevOps engineers and QA testers work side by side to allow seamless and continuous agile delivery. </p>
                                <button className="btn btn-borderless">Read More ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>



        </>
    )
}

export default QualityAssurance