import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            {window.innerWidth > 992 ?
                <CommonSection className="container de-banner ans-banner">
                    <div className="row cr">

                        <div className="col-lg-6 responsive-col-gap">
                            <h3>Embrace The<br />Software<br />Defined<br />Network<br />Transformation</h3>

                            <p>Enhancing your network to an SD-WAN can provide a<br />much better user experience with high performance<br />and agility. You'll be able to take advantage of new<br />applications and services while still providing a<br />reliable experience to your users.</p>
                        </div>

                        <div className="col-lg-6 responsive-col-gap">
                            <div className=" responsive-col-gap"></div>
                            <img src="/images/digital-engineering/ANS/banner.png"></img>
                        </div>

                    </div>

                </CommonSection>
                :
                <Banner
                    bgUrl="/images/digital-engineering/ANS/banner.png"
                    theme="light"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"Embrace The Software Defined Network Transformation"}
                    content={
                        "Enhancing your network to an SD-WAN can provide a much better user experience with high performance and agility. You'll be able to take advantage of new applications and services while still providing a reliable experience to your users."
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-end"
                    mobileTextAlign="left"
                >

                </Banner>}
            <CommonSection className="container ans-sec-1">
                <div className="row ">
                    <div className="col-lg-6">
                        {window.innerWidth > 992 ?
                            <h3>Use Your Network<br></br> Efficiently to <br></br> Standout in The <br></br> Industry</h3>

                            :
                            <h3>Use Your Network Efficiently to <br></br> Standout in The Industry</h3>
                        }
                    </div>
                    <div className="col-lg-6">
                        <p ><b>SD-WAN cuts down on expenses and improves networking performances. It also helps you manage your network more effectively.</b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li><b> Enhanced performance</b> of the <br></br> application creates a unique user <br></br>experience.</li>
                                <li><b> Secure your business</b> continuity and<br></br> guarantee integrity at all levels.</li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li><b> Simplified management</b> of real-time<br></br> operations accelerates the decision<br></br> process.</li>
                                <li><b>Cost-effective, </b> flexible tools to<br></br> optimize your network connectivity. </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container healthcare-sec-1  ans-sec-2">
                <div className="row g-0" >
                    <div className="col-lg-6 img-box">
                        <div className="mask">
                            <div>
                                <h3>Reduce Risk and Simplify Operations</h3>

                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="gapless-box-container">
                            <div className="my-row">
                                <div className="col-lg-6">
                                    <img src="/images/digital-engineering/ANS/sec-3-icon-1.svg"></img>
                                    <h4>Ultimate<br></br>Agility</h4>
                                    <p>Connectivity between branches, data centers, and multi-cloud infrastructures can be unified. Streamline IT operations with the ability to quickly provision new sites while optimizing applications through dynamic steering on the optimal transport.</p>
                                </div>

                                <div className="col-lg-6">
                                    <img src="/images/digital-engineering/ANS/sec-3-icon-2.svg"></img>
                                    <h4>Ultimate Performance</h4>
                                    <p>Maximise application speed and performance with up to 33 times faster delivery for all users, regardless of their location. Conquer latency, link quality issues, and congestion for a smooth and efficient user experience.</p>
                                </div>
                            </div>
                            <div className="my-row">
                                <div className="col-lg-6">

                                    <img src="/images/digital-engineering/ANS/sec-3-icon-3.svg" className="me-3"></img>
                                    <h4>Ultimate Security</h4>

                                    <p>Ensure the safety of your business by choosing a security architecture that meets your specific needs. Fortify each branch with advanced integrated security technologies, or link with 3rd party security services to form an effective service chain.</p>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid  devsecops-sec-4 ans-sec-3">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/digital-engineering/ANS/sec-3-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Reliable Network with Outstanding Efficiency</h3>
                                        <p>The cloud has transformed the way we do business, and SD-WAN is a cost-effective way to keep up with the demand for cloud-based applications. SD-WAN can increase bandwidth, boost performance, and quickly add new locations and services, making it an essential tool for businesses that rely on the cloud.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/digital-engineering/ANS/sec-3-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Enhance Any Application to Any User Over Any Network</h3>
                                        <p>No amount of bandwidth can address the performance impact of latency - but ZOG Global's SaaS Accelerator can increase app performance by up to 10x. With WAN optimization, cloud acceleration, SaaS acceleration, and client acceleration, ZOG Global delivers end-to-end application performance.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>





                </Slider>
            </CommonSection>

            <CommonSection className="container devops-sec-4 devsecops-sec-3 ans-sec-4">
                <div className="row cr">

                    {window.innerWidth > 992 ?
                        <div className="col-lg-9 responsive-col-gap">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-6 responsive-col-gap">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-1.png"></img>
                                        <h4>Ignite Employee Engagement </h4>
                                        <p>Evolve the technology and give people a workplace experience that meets their expectations.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/ANS/sec-4-icon-3.png"></img>
                                                <h4>Protect Your Data</h4>
                                                <p>
                                                    Embrace a next-gen managed security service and take control of your data in the cloud.</p>
                                            </div>

                                        </div>
                                        <div className="col-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/ANS/sec-4-icon-4.png"></img>
                                                <h4>Enhance & Evolve Your Network</h4>
                                                <p>
                                                    Refine your current infrastructure to fasten the digital transformation with increased ROI.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 col-sm-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-2.png"></img>
                                        <h4>Act Fast Without Losing Control</h4>
                                        <p>
                                            Give customers what they need when they need it and create new customer touchpoints quickly.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/ANS/sec-4-icon-5.png"></img>
                                                <h4>Gear-up Your Customer Experience</h4>
                                                <p>
                                                    Prioritise critical business apps, and gather & distribute data in real-time, to offer new customer experiences.</p>
                                            </div>

                                        </div>
                                        <div className="col-6">
                                            <div className="box">
                                                <img src="/images/digital-engineering/ANS/sec-4-icon-6.png"></img>
                                                <h4>Boost the Productivity</h4>
                                                <p>
                                                    Give employees the freedom to work anywhere and connect your application fast and securely.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-lg-9 responsive-col-gap">
                            <div className="row">
                                
                                
                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-1.png"></img>
                                        <h4>Ignite Employee Engagement </h4>
                                        <p>Evolve the technology and give people a workplace experience that meets their expectations.</p>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-3.png"></img>
                                        <h4>Protect Your Data</h4>
                                        <p>
                                            Embrace a next-gen managed security service and take control of your data in the cloud.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-4.png"></img>
                                        <h4>Enhance & Evolve Your Network</h4>
                                        <p>
                                            Refine your current infrastructure to fasten the digital transformation with increased ROI.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-2.png"></img>
                                        <h4>Act Fast Without Losing Control</h4>
                                        <p>
                                            Give customers what they need when they need it and create new customer touchpoints quickly.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-5.png"></img>
                                        <h4>Gear-up Your Customer Experience</h4>
                                        <p>
                                            Prioritise critical business apps, and gather & distribute data in real-time, to offer new customer experiences.</p>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="box">
                                        <img src="/images/digital-engineering/ANS/sec-4-icon-6.png"></img>
                                        <h4>Boost the Productivity</h4>
                                        <p>
                                            Give employees the freedom to work anywhere and connect your application fast and securely.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    }
                    <div className="col-lg-3">
                        <div className="box">
                            <h3 className="orange-line">Transform The Way You Connect</h3>
                        </div>

                    </div>
                </div>
                <div className="row g-0 bottom">

                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>80%</h4>
                            <p>Reduction in<br/>troubleshooting time</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>67%</h4>
                            <p>Reduction in<br/>configuration change time</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>61%</h4>
                            <p>Average reduction<br/>in OPEX cost</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>50%</h4>
                            <p>Reduction in<br/>infrastructure costs</p>
                        </div>
                    </div>
                </div>
            </CommonSection >

            <CommonSection className="container telecom-sec-1 devsecops-sec-2 ans-sec-5">
                <div className="row g-0 cr">

                    <div className="col-lg-7 responsive-col-gap border">
                        <h3>Why SD-WAN from ZOG Global?</h3>

                        <p className="bold">By combining SD-WAN with Secure Access Service Edge (SASE) and OfficeSuite UC® solutions, you can completely revamp your network and the way your customers interact with you. Our SD-WAN service ensures your network is:</p>
                        <ul>
                            <li>Optimised to support digital transformation and offer a robust solution for current and future needs.</li>
                            <li> A team consists of expert professionals with a dedication to excellence, ensuring customer satisfaction. </li>
                            <li>Boost innovation, maximize efficiency, and provide exceptional convenience while ensuring secure operations. </li>
                            <li>Helping you identify the true value of SD-WAN for your organisation and will work with you to deliver your needs.</li>
                        </ul>
                    </div>

                    <div className="col-lg-5">
                        <img src="/images/digital-engineering/devsecops/sec-2.png"></img>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container  education-sec-4 ans-sec-6">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Related Resources</h3>
                    </div>
                </div>
                
                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="box">
                            <h4>Lörem ipsum dopåvis onade. </h4>
                            <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                        </div>
                    </div>

                </div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                <h3>Find the Perfect SD-WAN Solution for Your Needs</h3>
                                <p>Unlock the potential of your global software-defined network with our comprehensive solutions and interactive guidance. Benefit from our knowledge and expertise as you kickstart your journey into the world of SD-WAN.</p>
                                <a href="../contact"><button className="btn btn-transparent">
                                    Talk to Us
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance