import React from "react";
import { useEffect, useState } from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";

import $ from 'jquery';
import { redirect, useNavigate } from "react-router-dom";

const Industries = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

const navigate=useNavigate()

    var myImg = $("#resp-img");
    var currHeight = myImg.clientHeight;
    $(".resp-box").css("height", currHeight + "px");
    $(".resp-box").css("display", "flex")
    $(".resp-box").css("flex-direction", "column")
    $(".resp-box").css("align-items", "center");

    
   
    
    return (
        <>
            <Banner
                bgUrl="/images/industries/banner.png"
                theme="dark"
                txtAlign="center"
                paddingProp={{ top: "8.6042", bottom: "19" }}
                heading={"Dipping Deep into Your Domain"}
                content={
                    "We dive deep into your domain to discover new opportunities"
                }
                contentWidth="35rem"
                mobileVerticalAlignment="flex-start"
                mobileTextAlign="left"
                backgroundSize='100%'
                customClassName='industries-banner'
            >

            </Banner>
            <CommonSection className="container industries-sec-1">
                <div className="row">
                    <div className="col-lg-5">

                        <img id="resp-img" src="/images/industries/sec-1.png"></img>

                    </div>
                    <div className="col-lg-7">
                        <div className="box resp-box">
                            <h3>Unlock New Possibilities</h3>
                            <p>ZOG Global unlocks the power of Technology to create opportunities for businesses and society. We help companies possess incredible precision and speed to maximize every opportunity.</p>

                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container industries-sec-2">
                <div className="row cr">

                    <div className="col-lg-7">
                        <div className="box resp-box">
                            <h3>Innovative Solutions to Accelerate Growth </h3>
                            <p>At ZOG Global, we are proud to serve a diverse range of industries and businesses. From small startups to large enterprises, we provide innovative and custom solutions to help our clients achieve their goals and accelerate their growth.  
<br></br>
Our professional team of experts is committed to providing custom-tailored solutions that meet each client's needs and address any challenges they face. </p>
                        </div>

                    </div>
                    <div className="col-lg-5 ">
                        <img src="/images/industries/sec-2.png"></img>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid industries-sec-3">
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="col-lg-12 ">
                            <h3>The Ideal Fusion of Innovation<br></br> for Every Industry</h3>
                        </div>
                    </div>
                </div>
<div className="non-breaking-mob-container">
                <div className="row g-0 ">

                    <div className="col-6">
                        <div className="box">
                            <img src="/images/industries/sec-3-1.png"></img>
                            <div className="mask">
                                <h4>Healthcare</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/Healthcare')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                        <div className="box">
                            <img src="/images/industries/sec-3-2.png"></img>
                            <div className="mask">
                                <h4>Technology</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn"  onClick={()=>navigate('/industries/technology')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="box">
                            <img src="/images/industries/sec-3-3.png"></img>
                            <div className="mask">
                                <h4>Telecom</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/telecom')}>
                                    Discover More
                                 
                                </button>
                            </div>
                        </div>
                        <div className="box">
                            <img src="/images/industries/sec-3-4.png"></img>
                            <div className="mask">
                                <h4>Education</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/education')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                    </div><div className="col-6">
                        <div className="box">
                            <img src="/images/industries/sec-3-5.png"></img>
                            <div className="mask">
                                <h4>Travel</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/travel')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                        <div className="box">
                            <img src="/images/industries/sec-3-6.png"></img>
                            <div className="mask">
                                <h4>Banking and Financial Services</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/banking')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                    </div><div className="col-6">
                        <div className="box">
                            <img src="/images/industries/sec-3-7.png"></img>
                            <div className="mask">
                                <h4>Insurance</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/insurance')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                        <div className="box">
                            <img src="/images/industries/sec-3-8.png"></img>
                            <div className="mask">
                                <h4>Legal</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/legal')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                    </div><div className="col-6">
                        <div className="box">
                            <img src="/images/industries/sec-3-9.png"></img>
                            <div className="mask">
                                <h4>Media & Entertainment</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/entertainment')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                        <div className="box">
                            <img src="/images/industries/sec-3-10.png"></img>
                            <div className="mask">
                                <h4>Pharma</h4>
                                <button className="btn btn-borderless btn-icon-changer-white discover-more-btn" onClick={()=>navigate('/industries/pharma')}>
                                    Discover More
                                    
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid industries-sec-4">
                <div className="row g-0">

                    <div className="col-lg-5">
                        <div className="box">
                            <div className="special-border">
                                <h3>Ready To Work<br/>With Us? </h3>
                            </div>
                           {window.innerWidth>768?<a href="/contact"> <button className="btn btn-transparent">
                                Submit a Request
                                <span>
                                    <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                </span>
                            </button></a>:null} 
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="box">
                            <p>We take each query seriously and are delighted to address any questions you may have. A member of our customer service team will get in touch with you within 24 hours and would be happy to do a free analysis of your needs. Alternatively, you can contact us via our phone number: +44 2045424350 or email at connect@zogglobal.com.</p>
                            {window.innerWidth<=768?<a href="/contact"> <button className="btn btn-primary btn-transparent">
                                Submit a Request
                                <span>
                                    <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                </span>
                            </button></a>:null} 
                        </div>
                    </div>
                </div>
            </CommonSection>
        </>
    )
}

export default Industries