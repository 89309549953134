import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

import $ from 'jquery';
import Slider from "react-slick";
import { useEffect, useState } from "react";


const HealthCare = () => {

    const [tabSelected, setTabSelected] = useState(0);
    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function toggleTab(index) {
        setTabSelected(index);

    }

    return (
        <>
            <Banner
                bgUrl="/images/industries/Healthcare/banner.png"
                theme="dark"
                txtAlign="left"
                paddingProp={{ top: "8.6042", bottom: "8" }}
                heading={"Together, Let's Shape<br/>the Future of Healthcare"}
                content={
                    "Streamline administrative processes to free up employees' time while<br/> maintaining compliance with security regulations."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"heathcare-banner"}
            >

            </Banner>
            <CommonSection className="container healthcare-sec-1">
                <div className="row" >
                    <div className="col-lg-6">
                        <div className=" img-box">
                            <div className="mask">
                                {window.innerWidth > 992 ?
                                    <h3>Delivering Complete<br />Technology<br />Management<br />Solutions</h3>

                                    :
                                    <h3>Delivering Complete Technology<br />Management Solutions</h3>

                                }                            <p>With the right technology solutions, healthcare businesses can rest assured that their data is secure, their operations are organized, and their customers are satisfied. </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 ">
                        <diV className="gapless-box-container">
                            <div className="my-row">
                                <div className="col-lg-6">
                                    <img src="/images/industries/Healthcare/sec-1-icon-1.svg"></img>
                                    <h4>Data Analytics in Healthcare </h4>
                                    <p>We use customized data analytics solutions to find valuable insights from all the data at your disposal. The right data analytics tools can analyze historical and current healthcare data and forecast future trends to enhance outreach and better manage the spread of diseases.</p>
                                </div>

                                <div className="col-lg-6">
                                    <img src="/images/industries/Healthcare/sec-1-icon-2.svg"></img>
                                    <h4>Maintain Intellectual Property Safe </h4>
                                    <p>Research labs generate and store intellectual property that is highly valuable to stakeholders as well as to cyber criminals. Our solutions are integrated into an adaptive cybersecurity ecosystem to guarantee the protection and safety of those assets.</p>
                                </div>
                            </div>
                            <div className="my-row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <img src="/images/industries/Healthcare/sec-1-icon-3.svg" className="me-3"></img>
                                        <h4>Guarantee The Availability of Healthcare Services </h4>
                                    </div>
                                    <p>Our cybersecurity solutions encourage operational continuity and limit downtime by boosting dependability, resource efficiency, and resilience, as the disruption of healthcare services could severely affect patients' health. </p>

                                </div>
                            </div>

                        </diV>

                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Our Contribution to<br />Healthcare</h3>

                        <p>Whether you are a healthcare service provider, a pharma<br />company, or a start-up looking to extend its wings, ZOG Global’s<br />custom healthcare solutions will seamlessly suit your business<br />requirements. </p>
                    </div>

                </div>
                {window.innerWidth > 992 ?
                    <div className="row justify-content-between g-5">
                        <div className="col-md-5">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Transform Product Concept into Reality</button>
                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Offer Better Patient Care
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Simplify Patient Data
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(3)}>Increase Patient Satisfaction

                                </button>

                            </div>

                        </div>

                        <div className="col-md-6">


                            <div className="tab-content">
                                <h4>Transform Product Concept<br />into Reality</h4>

                                <p>Our full-cycle custom healthcare IT services bring your healthcare software development solutions to life. Our stellar work reflects our capabilities, from product ideation, designing, programming, and testing to product sustainability and maintenance.</p>


                            </div>

                            <div className="tab-content">
                                <h4>Offer Better Patient Care</h4>

                                <p>Our healthcare application development services let you spend your valuable time providing better patient care. At the same time, we automate vital processes such as data collection, patient management, lab management, and test result analysis.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Simplify Patient Data</h4>

                                <p>Assure personalized patient care utilizing our customized healthcare solutions that let you record all the health data related to a patient's medical history at a single point and refer to it on the go.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Increase Patient Satisfaction</h4>

                                <p>Confirm that your patients are always satisfied with your services by offering them more control over their healthcare decisions. Our healthcare solutions permit you to contact your customers right when they need you.</p>


                            </div>

                        </div>
                    </div>
                    :
                    <div className="row justify-content-between g-5">
                        <div className="col-12">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Transform Product Concept into Reality</button>

                                <div className="tab-content">
                                    <h4>Transform Product Concept<br />into Reality</h4>

                                    <p>Our full-cycle custom healthcare IT services bring your healthcare software development solutions to life. Our stellar work reflects our capabilities, from product ideation, designing, programming, and testing to product sustainability and maintenance.</p>


                                </div>



                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Offer Better Patient Care
                                </button>

                                <div className="tab-content">
                                    <h4>Offer Better Patient Care</h4>

                                    <p>Our healthcare application development services let you spend your valuable time providing better patient care. At the same time, we automate vital processes such as data collection, patient management, lab management, and test result analysis.</p>


                                </div>


                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Simplify Patient Data
                                </button>

                                <div className="tab-content">
                                    <h4>Simplify Patient Data</h4>

                                    <p>Assure personalized patient care utilizing our customized healthcare solutions that let you record all the health data related to a patient's medical history at a single point and refer to it on the go.</p>


                                </div>




                                <button className="tab-btn" onClick={(e) => toggleTab(3)}>Increase Patient Satisfaction

                                </button>

                                <div className="tab-content">
                                    <h4>Increase Patient Satisfaction</h4>

                                    <p>Confirm that your patients are always satisfied with your services by offering them more control over their healthcare decisions. Our healthcare solutions permit you to contact your customers right when they need you.</p>


                                </div>


                            </div>

                        </div>

                        
                    </div>
                }
            </CommonSection>
            <CommonSection className="container healthcare-sec-3">
                <div className="row g-0">
                    <div className="col-lg-4">
                        <div className="box">
                            <h3>Leveraging Technology Solutions</h3>
                            <p>The need for technology solutions to help manage the rapidly changing environment is clear. But how can we help the healthcare industry make the most of the available technology solutions? Understanding your needs and the technologies available will help you identify the best solution.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <h4>Unlock the<br />Value of Data </h4>
                            <p>Discover the total value of patient information with AI and ML applications that democratize data and analytics, accelerate time-to-insight, and drive better patient care.</p>
                        </div>
                        <div className="box">
                            <h4>Capability<br />Enhancement</h4>
                            <p>Boost your current functionalities as per the latest industry standards. Our custom software services will always keep you ahead of the game.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <h4>Comply With<br />Healthcare Standards </h4>
                            <p>Our healthcare IT solutions are designed in accordance with all the needed compliances and regulation standards mandatory for healthcare organizations.</p>
                        </div><div className="box">
                            <h4>End-to-end<br />Customized Software<br />Solution</h4>
                            <p>Tell us your vision, and let us help you bring it to life. Transform your business ideas into viable solutions using our tech expertise.</p>
                        </div>
                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container-fluid healthcare-sec-4">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/industries/healthcare/sec-4-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Embracing Innovation </h3>
                                        <p>The healthcare industry will undergo a paradigm shift as a result of developments in artificial intelligence (AI), machine learning (ML), robotic process automation (RPA), and the Internet of Things (IoT). These innovations range from AI-assisted diagnostics to IoT-based remote patient monitoring. As a result, there is less need for hospital beds, home care can be offered, and patient interventions can happen more quickly.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="slide">
                        <img src="/images/industries/healthcare/sec-4-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Clinical Mobility </h3>
                                        <p>A secure digital workspace and an agile infrastructure can enhance patient outcomes by giving healthcare staff access to the data and applications they require regardless of where they are. We have the tools and the expertise to help deploy and adopt these solutions.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="slide">
                        <img src="/images/industries/healthcare/sec-4-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Fortifying Cybersecurity </h3>
                                        <p>Protecting patient data and healthcare systems from cyber-attacks that can jeopardise patient care requires a comprehensive security, governance, and compliance plan. ZOG Global's approach encompasses all aspects of IT solutions and usage.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="slide">
                        <img src="/images/industries/healthcare/sec-4-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Data Centre Architecture </h3>
                                        <p>Healthcare organisations are forced to leverage more innovation from their data centres to increase patient care and system efficiencies. Our knowledge contributes to developing flexible IT infrastructure that meets data requirements across the entire care continuum while lowering risk and expense.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>





                </Slider>
            </CommonSection>
            <CommonSection className="container healthcare-sec-5">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>The Role of Us&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
                    </div>
                    <div className="col-lg-6">

                        <p>ZOG Global's Artificial intelligence services in the healthcare industry will allow doctors and hospitals to expiate the issues in areas where the number of doctors is inadequate to attend to the entire populace. For example, with artificial intelligence, doctors can cut down the time required for pathological tests and expedite the treatment by hours. In addition, by examining notes and reports from a patient's file, conducting additional research, and identifying case studies of clinical knowledge, artificial intelligence in the healthcare sector will also assist clinicians in sorting through medical data more quickly.</p>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>With the help of our technology services, you can take advantage of new chances while overcoming your specific problems.</b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li><b>Digitalization Must Not Compromise Cybersecurity:</b> Systems are now interconnected, and mobile devices are extensively used for remote access and data sharing. Healthcare organisations are becoming more vulnerable to both generic and targeted attacks as a result of digitalisation.</li>
                                <li><b>SaaS Operations:</b> Cloud-based solutions from ZOG Global enable you to focus on running your business without the hassle associated with managing and maintaining your system.</li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li><b>Support and Services Operations:</b> Our global monitoring centers provide emergency fixes, security updates, workarounds, and break-fixes.</li>
                                <li><b>Execution of Services:</b> Using best practices from hundreds of global implementations, our implementation teams create solutions suited to your organisation's needs based on best practices.</li>
                            </div>
                        </ul>
                    </div>
                </div>

            </CommonSection>
            <CommonSection className="container home-sec-7">
                <div className="row">
                    <div className="col-12">
                        <h2>Explore Latest Tech Stories and News</h2>


                    </div>
                </div>
                <div class="non-breaking-mob-container">
                    <div className="row">

                        <div className="col-4">
                            <img src="/images/industries/Healthcare/sec-6-1.png"></img>
                            <h5>50 Essential Free Resources for Lorem Ipsum 2022</h5>
                            <span>Published by <b>Lorem Ipsum</b></span> <span className="date">13-06-2022</span>
                        </div>

                        <div className="col-4">
                            <img src="/images/industries/Healthcare/sec-6-2.png"></img>
                            <h5>50 Essential Free Resources for Lorem Ipsum 2022</h5>
                            <span>Published by <b>Lorem Ipsum</b></span> <span className="date">13-06-2022</span>
                        </div>

                        <div className="col-4">
                            <img src="/images/industries/Healthcare/sec-6-3.png"></img>
                            <h5>50 Essential Free Resources for Lorem Ipsum 2022</h5>
                            <span>Published by <b>Lorem Ipsum</b></span> <span className="date">13-06-2022</span>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                {window.innerWidth > 992 ?
                                    <h3>Are you ready to discover how<br></br>we can benefit your company?</h3>
                                    :
                                    <h3>Are you ready to<br></br> discover how we<br></br> can benefit your<br></br> company?</h3>
                                }
                                <p>Explore how our technology, knowledge, and support may help you handle the issues facing the healthcare industry today. </p>
                                <a href="../contact"><button className="btn btn-transparent">
                                    Talk to Our Experts
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default HealthCare