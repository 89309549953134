import React, { Children } from "react";
import './Banner.css';


 const Banner=({bgUrl,marginBottom, theme,txtAlign,   mobileTextAlign, paddingProp, heading,  content,contentWidth, children,customClassName, mobileVerticalAlignment,backgroundSize})=>{
var headColor="";
var contentColor=""
var alignment;
var TEXTALIGN
var BGURL;
var JUSTIFYCONTENT;  //VERTICAL ALIGNMENT FOR MOBILE


if(window.innerWidth>768){
  BGURL=bgUrl
  JUSTIFYCONTENT="flex-start"
  if(txtAlign=="left"){
    alignment="flex-start"
  }
  else{
    alignment="center"
  }
  TEXTALIGN=txtAlign
}
else{
  const temp=bgUrl.substring(0,bgUrl.length-4);
  console.log(temp)
 BGURL=temp+"-mobile"+".png"

 JUSTIFYCONTENT=mobileVerticalAlignment

 if(mobileTextAlign=="left"){
  alignment="flex-start"
}
else{
  alignment="center"
}
TEXTALIGN=mobileTextAlign
}
if(theme=="dark"){
  headColor="#191919";
  contentColor="#191919";
}
else{
  headColor="#F6F6F6";
  contentColor="#F6F6F6";
}
const PT=paddingProp.top+"rem"
const PB=paddingProp.bottom+"rem"
const PL="8.493033rem"
console.log("PT",PT)


const bannerContainerStyle={
  backgroundImage: `url(`+BGURL+`)`,
  marginBottom:marginBottom,
  /* Center and scale the image nicely */
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: backgroundSize?backgroundSize:"cover",
  WebkitBackgroundSize: "cover",
  MozBackgroundSize: "cover",
  OBackgroundSize: "cover",
}
const bannerStyle={ 
 
     paddingTop: PT,
     paddingBottom:PB,
     display: "flex",
    flexDirection:"column",
  alignItems:alignment,
justifyContent:JUSTIFYCONTENT}

  var CONTENTWIDTH
  if ((window.innerWidth >= 992)) {
   CONTENTWIDTH=contentWidth
  }
  else{
      CONTENTWIDTH="80%"
  }

  const bannerContainerClass="container-fluid banner-container "+customClassName

   // Use dangerouslySetInnerHTML to render HTML with line breaks
   const renderHTML = (text) => ({ __html: text });

    return(
      <div className={bannerContainerClass} style={bannerContainerStyle} >
         <div className=" banner container" style={bannerStyle} >
      <h1 style={{color:headColor, textAlign:TEXTALIGN, width:CONTENTWIDTH}} dangerouslySetInnerHTML={renderHTML(heading)}></h1>
      <p style={{color:contentColor, textAlign:TEXTALIGN , width:CONTENTWIDTH}}    dangerouslySetInnerHTML={renderHTML(content)}></p>
      {children}
    </div>
      </div>
    

)
}

export default Banner