import React from "react";
import { Link } from 'react-router-dom';
<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {



    return (
        <>
            <div className="full-screen-container full-screen-container-borderless">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 ">
                        <div className="full-sceen-alert-page-card">

                            <div className="body">
                                <Link to={"/sign-up"} className="back-btn"><img src="/images/authentication-screens/back-icon.svg" ></img></Link>
                                <img src="/images/authentication-screens/otp-icon.svg"></img>
                                <h2 className="title">Enter Verification code </h2>
                                <div class="otp-container mb-3">
                                    <input class="otp-input" type="text" maxlength="1" />
                                    <input class="otp-input" type="text" maxlength="1" />
                                    <input class="otp-input" type="text" maxlength="1" />
                                    <input class="otp-input" type="text" maxlength="1" />
                                </div>
                                
                                <button className="btn btn-secondary">Verify</button>

                                <div className="footer">

                                    <span>If you don’t receive the OTP <Link className="link-span"> Resend</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default View;