import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const WhatWeDo = () => {

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Banner
                bgUrl="/images/what-we-do/banner.png"
                theme="dark"
                txtAlign="left"

                paddingProp={{ top: "14", bottom: "6" }}
                heading={window.innerWidth > 992 ?
                    "Deliver the Best of</br>Technology and Human</br>Ingenuity "
                    : window.innerWidth > 772 ?
                        "Deliver the Best of</br>Technology and Human</br>Ingenuity "
                        :
                        "Deliver the Best of</br>Technology and Human</br>Ingenuity "}
                content={window.innerWidth > 992 ?
                    "Introducing a bold new digital landscape powered by advanced</br>technology, encouraged by people for a lasting impact."

                    : window.innerWidth > 778 ?
                        "Introducing a bold new digital landscape powered by advanced</br>technology, encouraged by people for a lasting impact."
                        :
                        "Introducing a bold new digital</br> landscape powered by advanced </br>technology, encouraged by people for</br> a lasting impact."
                }
                customClassName="what-we-do-banner"
                contentWidth="40rem"
                mobileVerticalAlignment="center"
                mobileTextAlign="center"
                backgroundSize="120%"
            >

            </Banner>

            <CommonSection className="container what-we-do-sec-1">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>Transform Business To A New Edge</h3>
                    </div>
                </div>

                <div className="row gapless-box-container g-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="box box1">
                            <div className="special-border">
                                {window.innerWidth > 992 ?
                                    <h4>IT<br></br>Support</h4>

                                    : window.innerWidth > 778 ?
                                        <h4>IT<br></br>Support</h4>
                                        : <h4>IT Support</h4>

                                }                            </div>
                            <p>
                                Don't take on the burden of managing your IT needs by yourself. Let us handle it for you. Our team of professionals is well-versed in technology and knowledgeable in business needs, so you can trust that your IT requirements are taken care of quickly and efficiently.
                            </p>
                            <a href="./digital-engineering"> <button className="btn btn-borderless">
                                Read More <span>></span>
                            </button></a>
                        </div>

                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="box box2">

                            <div className="special-border">
                                {window.innerWidth > 992 ?
                                    <h4>Business<br></br>Security</h4>

                                    : window.innerWidth > 778 ?
                                        <h4>Business<br></br>Security</h4>

                                        :
                                        <h4>Business Security</h4>

                                }
                            </div>
                            <p>
                                The number one priority is security, and we'll do everything in our capacity to assist you in keeping your company secure. Whether you're looking for a comprehensive security overhaul or just some basic security measures, we can help.
                            </p>
                            <a href="./digital-engineering/cyber-security"> <button className="btn btn-borderless">
                                Read More <span>></span>
                            </button></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box box3">
                            <div className="special-border">
                                <h4>Business<br></br>Transformation </h4>
                            </div>
                            <p>
                                We help businesses of all sizes overcome their IT challenges and improve their productivity and efficiency. We are dedicated to offering our clients the best IT solutions to support their growth and success.
                            </p>
                            <a href="./industries"> <button className="btn btn-borderless">
                                Read More <span>></span>
                            </button></a>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid what-we-do-sec-2">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Managed IT Services</h3>
                                <p> Managed service takes the hassle out of maintaining your IT infrastructure. Our team of experts takes a proactive approach to service and support to reduce risk, improve operations, manage expenses, and align your technology for business growth.</p>

                                <button className="btn btn-transparent" onClick={() => navigate('/managed-it-services')}>
                                    Explore Our Services
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>

            <CommonSection className="container what-we-do-sec-3">
                <div className="row">
                    <div className="col-lg-8">
                        <h3>Strengthening the Core </h3>
                        <p>While understanding the complicated legacy landscape with ever-changing marketing trends.<br></br> We drive new outcomes and furnish design-led experiences for consumer delight through </p>
                    </div>
                </div>

                <div className="row row-1 gapless-box-container g-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div>
                                <h4>AI </h4>
                                <p>
                                    Artificial Intelligence (AI) services are undeniably growing in importance and popularity. After all, AI services can help businesses automate repetitive tasks, improve customer service, and make better decisions. Not to mention, AI services can also help businesses save time and money.
                                </p>
                            </div>

                            <a href="./ai-automation/artificial-intelligence"> <button className="btn btn-borderless">
                                Learn More <span>></span>
                            </button></a>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div><h4>Machine Learning </h4>
                                <p>
                                    Machine learning services can provide your business with predictive analytics and insights, helping you to make better decisions and stay one step ahead of the competition. By using historical data, machine learning can provide predictions about future trends and patterns, allowing you to take proactive action to stay ahead of the curve.
                                </p>
                            </div>

                            <a href="./ai-automation/artificial-intelligence"> <button className="btn btn-borderless">
                                Learn More <span>></span>
                            </button></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div>
                                <h4>Automation </h4>
                                <p>
                                    Automation services are gaining prominence as a tool to simplify business procedures and boost productivity. Businesses can increase efficiency and productivity by automating repetitive and time-consuming operations. Automation services can also help businesses save money by reducing labor costs.
                                </p>
                            </div>

                            <a href="./ai-automation/automation-robotics"> <button className="btn btn-borderless">
                                Learn More <span>></span>
                            </button></a>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div>
                                <h4>Cybersecurity</h4>
                                <p>
                                    We provide a range of cyber security services to safeguard your company from the rising threat of cybercrime. In addition to firewalls, intrusion detection and prevention, antivirus, and anti-malware protection, we also provide a full range of services that can be customised to meet your unique needs.
                                </p>
                            </div>

                            <a href="./digital-engineering/cyber-security"><button className="btn btn-borderless">
                                Learn More <span>></span>
                            </button></a>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div><h4>Cloud Computing  </h4>
                                <p>
                                    Cloud services are designed to make it easy for you to store, share, and access your files from anywhere. As a result, businesses can benefit from cloud services to cut expenses, increase agility, and speed up innovation.
                                </p>
                            </div>

                            <a href="./digital-engineering/cloud"><button className="btn btn-borderless">
                                Learn More <span>></span>
                            </button></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div>
                                <h4>Unified Communication </h4>
                                <p>
                                    Unified Communication services provide features that allow businesses to customize the way they communicate. For example, businesses can use it to set up call forwarding, voicemail, and conferencing. By utilising Unified Communication services, businesses can improve communication and overall efficiency.
                                </p>
                            </div>

                            <a href="./digital-engineering/unified-communication"><button className="btn btn-borderless">
                                Learn More <span>></span>
                            </button></a>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container what-we-do-sec-4">
                <div className="row align-items-end">
                    <div className="col-lg-6 ">
                        <div className="img-side">
                            <div className="mask">
                                <div>
                                    <h3>Personalise Customer Experience</h3>
                                    <p>Automation can be a great way to personalise your customers' experiences. By using automation, you can keep track of your customers' preferences and customize your communication with them accordingly. This can help you create a more personal relationship with your customers and build loyalty.</p>

                                </div>
                                <button className="btn btn-borderless">
                                    Learn More
                                    <span>
                                        <img src="/images/btn-tailed-right-arrow-white.svg"></img>
                                    </span>
                                </button>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-6 accordion-side">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Advance Networking Services
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Advanced networking services go beyond the basic connectivity and management of a network. ZOG Global's advanced networking services can help companies increase productivity, reduce expenses, and gain a competitive edge by automating network management tasks and offering real-time visibility into network performance.</p>
                                        <ul>
                                            <li>Infra-Solution & Application Automation</li>
                                            <li>Network Automation Solutions</li>
                                        </ul>
                                        <button className="btn btn-borderless">
                                            Discover More <span>></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Cloud Services
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Our cloud services are designed to help businesses run more efficiently and effectively and adapt to changing needs. We provide a range of services, including cloud storage, cloud computing, cloud security, and more.</p>
                                        <ul>
                                            <li>Cloud Hosting</li>
                                            <li>Virtualization</li>
                                        </ul>
                                        <button className="btn btn-borderless">
                                            Discover More <span>></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Unified Communications
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>ZOG Global’s unified communication services are designed to streamline business functions, save valuable time, and improve customer engagement. By consolidating multiple communication channels into a single, unified platform, businesses can more easily manage their incoming and outgoing communications and can more effectively engage with customers and clients.</p>
                                        <ul>

                                            <li>RPA in Contact Center</li>
                                            <li>CRM Integration</li>
                                        </ul>
                                        <button className="btn btn-borderless">
                                            Discover More <span>></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Cybersecurity Services
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>By constantly monitoring the latest threats and vulnerabilities, ZOG Global is able to provide timely and effective protection against the most severe attacks. In addition, ZOG Global's team of security experts is always available to provide guidance and advice on the best course of action in the event of an attack.</p>
                                        <ul>
                                            <li>Data Protection</li>
                                            <li>Identity & Access Management</li>
                                        </ul>
                                        <button className="btn btn-borderless">
                                            Discover More <span>></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Software Development
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>ZOG Global is staffed with a team of software developers and engineers with years of experience delivering high-quality and timely projects. The team is experienced in a wide range of programming languages and tools and is always up-to-date on the latest technologies.</p>
                                        <ul>
                                            <li>Custom Software Development</li>
                                            <li>Software Testing</li>
                                        </ul>
                                        <button className="btn btn-borderless">
                                            Discover More <span>></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container what-we-do-sec-5">
                <div className="row full-height g-0 cr">
                    <div className="col-lg-4 responsive-col-gap">
                        <div className="box">
                            <h3>Engagement Model</h3>
                            <p> We offer custom-tailored engagement models designed to meet the varied needs of our customers and partners. This wide range of options allows us to provide the best possible solution based on your specific requirements. Rest assured that with any engagement model you choose, we will deliver high-quality results.</p>
                            <button className="btn btn-transparent">
                                Discover More
                                <span>
                                    <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                </span>
                            </button>
                        </div>

                    </div>
                    <div className="col-lg-8">
                        {window.innerWidth > 768 ?
                            <img src="/images/what-we-do/sec-5.png"></img>

                            :
                            <img src="/images/what-we-do/sec-5-mob.png"></img>
                        }                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container what-we-do-sec-6">
                <row>
                    <div className="col-12">
                        <img className="logo" src="/images/what-we-do/sec-6.svg" alt="logo"></img>
                        
                        {window.innerWidth > 992 ?
                            <h3 className="text-center">See How We Can help You</h3>
                            : window.innerWidth > 778 ?
                                <h3 className="text-center">See How We Can help You</h3>
                                :
                                <h3 className="text-center">See How<br/>We Can help You</h3>
                        }

                        <button className="btn btn-primary btn-transparent">
                            Get Started
                            <span>
                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                            </span>
                        </button>
                    </div>
                </row>
            </CommonSection>
        </>
    )
}

export default WhatWeDo