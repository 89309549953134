import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react'
import ReactDom from 'react-dom'

const Captcha = () => {

    ReactDom.render(
        <GoogleReCaptchaProvider
          reCaptchaKey="[Your recaptcha key]"
          language="[optional_language]"
          useRecaptchaNet="[optional_boolean_value]"
          useEnterprise="[optional_boolean_value]"
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined // optional, default undefined
          }}
          container={{ // optional to render inside custom element
            element: "[required_id_or_htmlelement]",
            parameters: {
              badge: '[inline|bottomright|bottomleft]', // optional, default undefined
              theme: 'dark', // optional, default undefined
            }
          }}
        >
        </GoogleReCaptchaProvider>,
        document.getElementById('app')
      );
}
export default Captcha

