import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const View = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    


    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>
            <Banner
                bgUrl="/images/industries/entertainment/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "13", bottom: "10" }}
                heading={"Unleashing the Power of<br/>Entertainment Technology "}
                content={
                    "We assist companies in using advanced technology to create strong networks that can handle large amounts of media data storage, computing, and networking for content production up to delivery. "
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"entertainment-banner"}
            >

            </Banner>
            <CommonSection className="container healthcare-sec-5">
                <div className="row g-5">
                    <div className="col-lg-12">
                    <h3>Media & Entertainment IT<br/>Solutions </h3>
                    </div>
                    <div className="col-lg-6">
                      
                        <p>The unprecedented growth in media and entertainment consumption has led to extraordinary challenges for many organisations in this space. They must possess massive computing power to create new content, store and manage colossal amounts of data, distribute large files across global networks with lightning-fast speeds, analyse usage and preferences through millions of customers and protect everything against cyber-attacks.

                            At ZOG Global, we specialise in customer data security, business intelligence application development and integration, and AI and automation implementation for streamlined analytics and operations. Our services assure businesses that their digital infrastructure is secure and up to date. </p>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>Comprehensive IT solutions that support and empower the media and entertainment industry. </b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li><b>Cloud Services:</b>  ZOG Global has been a trusted partner for countless media and entertainment companies, working hand-in-hand with tech and infrastructure teams to push the boundaries of hybrid cloud solutions, software-defined data centres, and data backup and recovery. </li>
                                <li><b>Cybersecurity:</b>  Our range of cybersecurity offerings includes industry-specific security assessments, the implementation of next-generation firewall protections, digital asset management, identity management and access control, alongside endpoint security management which minimises threats. </li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li><b>Digital Transformation:</b> ZOG Global offers a range of cutting-edge engineering and DevOps services, such as API engineering, advanced analytics, orchestration and automation, AI and machine learning. Our mission is to drive business performance and efficiency through powerful operations intelligence tools. </li>
                                <li><b>Software Development:</b>  Our team specialises in creating fully functional, robust, and scalable software that can stand the test of time. From technology consultation to development, testing, support, and maintenance, we've got you covered every step of the way. </li>
                            </div>
                        </ul>
                    </div>
                </div>

            </CommonSection>
            <CommonSection className="container-fluid managed-it-services-sec-3 technology-sec-2 entertainment-sec-2">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>IT Solutions for Business Success </h3>
                                <p>Technology has revolutionised entertainment. With IT for the industry, businesses can make informed decisions to enhance experiences. The right technology is crucial for success, whether it's a movie streaming app or events lighting control software.  </p>

                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>
            <CommonSection className="container engagement-model-sec-1">
                <div className="row">


                    <div className="col-lg-12 tab-btn-container responsive-col-gap">
                        <button  className="tab-btn active" onClick={(e) => toggleTab(0)}>
                            Personalised Experience
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(1)}>
                            High Scalability
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>
                            Market Presence
                        </button>

                    </div>


                </div>

                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            <h3>Offering Personalised<br/>Experience </h3>

                        </div>
                        <div className="col-lg-6">
                            <p className="">In order to provide a top-notch experience for your customers, it's important to offer personalised messages and content that align with their individual preferences. This can easily be accomplished by utilising AI and extensive data management, which will allow you to stay ahead of their needs and desires.  </p>

                        </div>
                    </div>
                </div>

                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            <h3>Achieving High<br/>Scalability </h3>

                        </div>
                        <div className="col-lg-6">
                            <p className="">The key to success is flexibility and adaptability. With customised digital products designed for your unique niche, you'll be armed with the tools to tackle any obstacle that comes your way. Don't just survive in the media landscape - thrive with personalised solutions. </p>

                        </div>
                    </div>
                </div>
                <div className="tab-content">
                    <div className="row mb-5">

                        <div className="col-lg-6">
                            <h3>Expanding Market<br/>Presence </h3>

                        </div>
                        <div className="col-lg-6">
                            <p className="">Unlock unlimited growth opportunities for your business with cutting-edge software! Share your content and reach out to audiences worldwide, opening up exciting new markets for your brand. The possibilities are truly endless! </p>

                        </div>
                    </div>


                </div>
            </CommonSection>

            <CommonSection className="container engagement-model-sec-1 entertainment-sec-5">
                <div className="row gapless-box-container g-0">
                    <div className="col-lg-4 col-md-6">
                        <div className="box">
                            <div className="wrapper">
                            <img src="/images/industries/entertainment/sec-5-1.svg"></img>
                            <h4>Performance   </h4>
                            </div>
                             <p>
                                In order to stay ahead, it's crucial to perform at your best. ZOG Global can help with this by creating tailored digital strategies for businesses. Our strategies include cost-effective cloud-based platforms and big data analytics, which can enhance performance.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                    
                        <div className="box">
                        <div className="wrapper">
                        <img src="/images/industries/entertainment/sec-5-2.svg"></img>

                            <h4>Multichannel  </h4>
                           </div>
                            <p>
                                To explore new channels for growth, we understand that a multichannel approach is essential for success in today's world. Our team of experts provides you with the latest cross-platform solutions and technologies, making your content accessible across various devices.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                    
                        <div className="box">
                        <div className="wrapper">
                        <img src="/images/industries/entertainment/sec-5-3.svg"></img>

                            <h4>Security  </h4>
                           </div>
                            <p>
                                We employ cutting-edge security measures and encryption technologies to safeguard your sensitive information against any cyber threats. Our experts identify and mitigate potential risks with comprehensive security strategies, ensuring that your data is always secure.
                            </p>
                        </div>
                    </div>
                </div>
            </CommonSection>

          




            <CommonSection className="container home-sec-2 technology-sec-6 travel-sec-4 entertainment-sec-6">
                <div className="row">
                    <div className="col-lg-4">
                        <div>

                            <h3>Why Choose ZOG Global?</h3>
                           
                        </div>
                        <p>Our team of experts has a remarkable history of providing exceptional products and services.  </p>
                    </div>

                    <div className="col-lg-8">
                        <div className="bg-box">
                            <h4 className="mb-5">A trusted partner for<br/> your business journey</h4>
                            <div className="row g-0">
                                <div className="col-md-6">
                                    <h4>On-Time<br/>Project Delivery</h4>
                                    <p>
                                    We take pride in crafting projects that are not only reliable and secure but also perfectly aligned with our client's unique objectives. We are here to assist you in achieving your objectives with our exceptional advice and unwavering assistance.  </p>

                                </div>
                                <div className="col-md-6">
                                    <h4>Commitment to<br/>Customer Satisfaction </h4>
                                    <p>Our top priority is to provide unparalleled customer satisfaction throughout the entire development process. Our commitment to delivering outstanding service is the foundation of our success, and we strive to maintain that level of excellence with every project we undertake. </p>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </CommonSection>





            <CommonSection className="container technology-sec-3">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Automating Processes and Workflows </h3>
                    </div>
                    <div className="col-lg-6">
                        <p> Our team specialises in a wide range of solutions designed to streamline your operations and unlock new levels of efficiency. We offer process mapping to identify areas for improvement, system integration to ensure seamless communication between different software systems, custom software development to meet specific needs, and ongoing support to ensure that everything runs smoothly.
                            <br></br>
                            Our team of skilled and passionate experts are dedicated to collaborating with our clients to comprehend their distinct aspirations and objectives truly.
                            <br></br>
                            With our help, businesses can automate tasks, reduce errors, and optimise their workflows, enabling them to focus on their core competencies and achieve sustainable growth. </p></div>
                </div>
            </CommonSection>


            <CommonSection className="container-fluid travel-sec-2 entertainment-sec-8">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/industries/entertainment/sec-6-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Maximise Your Uptime with Efficient IT Management  </h3>
                                        <p>With our cutting-edge technology, you can enjoy high availability, reliability, security, and privacy for all your data, networks, apps, and endpoints. </p>
                                        <button className="btn btn-borderless btn-icon-changer-white">Get Started
                                            
                                        </button>

                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/industries/entertainment/sec-6-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Protect Your Business with Strong Network Security, Data Storage, and Backup Protocols  </h3>
                                        <p>Rest assured that your corporate, talent, and consumer data is in good hands with our top-notch security measures. Our firewalls, anti-intrusion systems, end-to-end data encryption, and user controls work together to keep your information safe from external breaches and internal theft. </p>
                                        <button className="btn btn-borderless btn-icon-changer-white">Learn more
                                            
                                        </button>

                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>

                </Slider>
            </CommonSection>

        </>
    )
}

export default View