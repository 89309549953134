import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const View = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    return (
        <>
            <Banner
                bgUrl="/images/industries/legal/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "8", bottom: "10" }}
                heading={window.innerWidth > 992 ?
                    "Legal-Specific Technology<br/>to Accelerate Digital<br/>Transformation "
                    :
                    "Legal-Specific<br/>Technology to Accelerate<br/>Digital Transformation "}
                content={
                    "From cloud computing to document management systems, legal IT services can help your firm stay organised, collaborate more effectively, and ultimately provide better service to your clients. "
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"legal-banner"}
            >

            </Banner>
            <CommonSection className="container healthcare-sec-5">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>IT Solutions: The Game-Changer<br />in the Legal Industry </h3>

                    </div>
                    <div className="col-lg-6">
                        <p>Our team at ZOG Global offers expert IT support to legal firms. We have years of experience and an impressive collection of advanced toolsets created to address your complex problems efficiently and effectively. We are acutely aware that your requirements may surface at any moment, and we are always on hand to provide the necessary support.

                            <br></br>

                            To ensure everything runs smoothly, we provide a variety of services for law firms, such as document management for secure client data, instant access to information anytime and anywhere, and fast and reliable support. With our help, you can focus on what matters most to your firm. </p>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>Stay ahead of emerging technologies as you deliver streamlined solutions for an ever-evolving landscape. </b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li><b>Improved Efficiency:</b>   End users benefit from the streamlined operations and improved efficiency that comes with unified system management. </li>
                                <li><b>Simplify Operations:</b>  Gain complete oversight and control of your IT infrastructure with end-to-end visibility and automated app lifecycle management capabilities.  </li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li><b> Ensure Accessibility:</b> Deliver the best service to your customers across the board with a robust platform that can handle any workload at any time, quickly and effortlessly. </li>
                                <li><b>Secure Data and Apps:</b> We provide data and application security designs that go beyond the strict standards set by the UK government, ensuring your sensitive information is well protected.  </li>
                            </div>
                        </ul>
                    </div>
                </div>

            </CommonSection>



            <CommonSection className="container-fluid travel-sec-2">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/industries/legal/sec-2-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Maximising Your Hybrid Work Environment for Competitive Business Advantage</h3>
                                        <p>Success comes to law firms that embed innovation into their culture and look for the right tools to empower long-term growth. To maximise performance, consider solutions and systems that complement your business strategy and foster a unique culture of achievement. </p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/industries/legal/sec-2-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Balancing Client Service Standards and Confidentiality </h3>
                                        <p>IT teams in the legal sector face intense pressure to meet the growing demands of their clients. It's their responsibility to ensure partners, associates, paralegals and other staff have fair access to whatever applications and files they need - all while protecting sensitive client data from unauthorised access. </p>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>


                </Slider>
            </CommonSection>


            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Security Focused IT Services for Law Firms </h3>

                        <p>We understand the importance of keeping data protected and accessible. To that end, our IT solutions are designed to provide safe and secure methods for data storage and handling.  </p>
                    </div>

                </div>
                {window.innerWidth>992?
                <div className="row justify-content-between g-5">
                <div className="col-md-5">

                    <div
                        className="tab-btn-container"

                    >
                        <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Cybersecurity Services </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(1)}>Backup & Disaster Recovery
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>Cloud Services
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(3)}>Managed IT Services
                        </button>

                    </div>

                </div>

                <div className="col-md-6">


                    <div className="tab-content">
                        <h4>Cybersecurity Services  </h4>

                        <p>We understand how important it is for law firms to protect the sensitive data they handle daily. We are proud to offer various services designed to help law firms put systems in place to keep their data safe. From data security audits to policy development and implementation, we can help your firm ensure that your data is always protected.  </p>


                    </div>
                    <div className="tab-content">
                        <h4>Backup & Disaster Recovery </h4>

                        <p>Your law firm must have proper data backup and recovery plans in place in combination with cybersecurity measures. If an incident or disaster occurs, you must have reliable backups and procedures ready to help limit damage and protect against data loss. </p>


                    </div>
                    <div className="tab-content">
                        <h4>Cloud Services  </h4>

                        <p>Cloud storage provides a secure and convenient way for law firms to store large amounts of data. By keeping data off-site and out of the hands of hackers, cloud storage can help law firms protect their confidential information and comply with data security regulations. </p>


                    </div>
                    <div className="tab-content">
                        <h4>Managed IT Services </h4>

                        <p>At ZOG Global, we understand that each law firm has unique IT needs. That's why we offer custom-tailored managed IT services to meet those needs. So whether you need help with data security, cloud computing, or any other IT issue, we can provide the assistance you need. </p>


                    </div>

                </div>
            </div>
            :
            <div className="row justify-content-between g-5">
                    <div className="col-12">

                        <div
                            className="tab-btn-container"

                        >
                            <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Cybersecurity Services </button>

                            <div className="tab-content">
                            <h4>Cybersecurity Services  </h4>

                            <p>We understand how important it is for law firms to protect the sensitive data they handle daily. We are proud to offer various services designed to help law firms put systems in place to keep their data safe. From data security audits to policy development and implementation, we can help your firm ensure that your data is always protected.  </p>


                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(1)}>Backup & Disaster Recovery
                            </button>

                            <div className="tab-content">
                            <h4>Backup & Disaster Recovery </h4>

                            <p>Your law firm must have proper data backup and recovery plans in place in combination with cybersecurity measures. If an incident or disaster occurs, you must have reliable backups and procedures ready to help limit damage and protect against data loss. </p>


                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(2)}>Cloud Services
                            </button>

                            <div className="tab-content">
                            <h4>Cloud Services  </h4>

                            <p>Cloud storage provides a secure and convenient way for law firms to store large amounts of data. By keeping data off-site and out of the hands of hackers, cloud storage can help law firms protect their confidential information and comply with data security regulations. </p>


                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(3)}>Managed IT Services
                            </button>

                            <div className="tab-content">
                            <h4>Managed IT Services </h4>

                            <p>At ZOG Global, we understand that each law firm has unique IT needs. That's why we offer custom-tailored managed IT services to meet those needs. So whether you need help with data security, cloud computing, or any other IT issue, we can provide the assistance you need. </p>


                        </div>




                        </div>

                    </div>

                    
                </div>}
            </CommonSection>


            <CommonSection className="container telecom-sec-2 legal-sec-5">
                <div className="row align-items-center g-0">
                    <div className="col-lg-6">
                        <img src="/images/industries/legal/sec-4.png"></img>
                    </div>
                    <div className="col-lg-6 border">
                        <h3> Transform Your Law Firm into a Next-Generation Leader </h3>

                        <p>At ZOG Global, we do not just provide quick solutions for your technological needs. Instead, we go above and beyond by crafting detailed plans that integrate your entire IT infrastructure with your long-term business strategy. This ensures that your legacy is safeguarded and that your enterprise can thrive for many generations to come.
                            <br></br>
                            Our approach is geared towards securing a sustainable future for your business, and we are dedicated to providing you with outstanding outcomes that go above and beyond your anticipations. </p>

                    </div>
                </div>
            </CommonSection>


            <CommonSection className="container  education-sec-4 legal-sec-6">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Related Insights </h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </CommonSection>
            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                {window.innerWidth>992?
                                <h3>Upgrade Your Law Firm's IT Strategy  </h3>
                            :
                            <h3>Upgrade Your Law Firm's<br/>IT Strategy  </h3>}
                                <p>From cloud-based solutions to data management and security, we can provide the latest and advanced technology to help your firm stay ahead of the curve. </p>
                                <button className="btn btn-transparent">
                                    Contact Us
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default View