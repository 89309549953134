import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";


const Contact = () => {


  return (

    <>

      <CommonSection className="page-not-found page-under-construction full-height d-flex flex-column align-items-center justify-content-center w-100  text-center">
       <h4>Please Hold on!</h4>
       <img src="/images/under-construction.png"></img>
        <h1>This Page is Under Construction!</h1>
        <h3> This page will be available soon. <br></br><i>We are working to deliver the best experience for you.</i></h3>
      </CommonSection>
    </>

  )
};

export default Contact;
