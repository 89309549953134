import React from "react";
import { useEffect, useState } from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";

import Slider from "react-slick";
const QualityAssurance = () => {







    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings2 = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
              }
            },
        ]
    };


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    


    return (
        <>
            <Banner
                bgUrl="/images/industries/technology/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "14", bottom: "2" }}
                heading={"Bring More Power to Your Tech Business"}
                content={
                    "Businesses must adjust rapidly to rapidly changing markets by leveraging digital convergence and enhancing the customer experience to stay competitive. Our goal is to help you develop new business models and optimise the customer experience."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
            >
            </Banner>
            <CommonSection className="container healthcare-sec-5">
                <div className="row">
                    <div className="col-lg-12">
                    <h3>Are You Not Satisfied with<br/>Your IT Provider?</h3>

                    </div>
                    <div className="col-lg-6">
                        <p>You're not alone. The majority of our clients were in your situation. They had IT assistance, but they still had reoccurring issues, downtime, techs who couldn't explain technology in layman's terms, and, worst of all, poor response times.<br></br><br></br>
At first, they thought it was just the cost of doing business—until they found us. By working with us, they were able to improve their IT infrastructure, eliminate recurring issues, and get their business back on track. We can help you in the same way to accomplish it.
</p>

                    </div>
                    <div className="col-lg-6">
                        <p ><b>We will provide your industry with the appropriate technology and services.</b></p>
                        <ul className="ps-4 w-100">
                            <div className="d-flex justify-content-between w-100">
                                <li>	Listening to business goals, understanding current compliance requirements, and preparing use cases enable us to craft the right tech stack. </li>
                                <li>	Our experienced IT professionals are available to offer unlimited technical support whenever you require it.</li>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <li>	We offer 24x7 monitoring and proactive support to ensure maximum system uptime, minimal hiccups, and swift problem resolution.</li>
                                <li>	Our managed security services are designed with your industry in mind to provide enhanced security capabilities. </li>
                            </div>
                        </ul>
                    </div>
                </div>

            </CommonSection>
            <CommonSection className="container-fluid managed-it-services-sec-3 technology-sec-2">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Transform your Workforce and Enhance your Performance</h3>
                                <p>We’ve been serving high-tech enterprises in workforce transformation and performance improvement for many years. Our comprehensive on-the-ground local office networks provide proven solutions, applicable processes, and flexible veteran expertise to assist your team in meeting its objectives. </p>

                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>
            <CommonSection className="container technology-sec-3">
                <div className="row">
                    <div className="col-lg-6">
{window.innerWidth>992?
                        <h3>Why ZOG Global <br></br>for your Small to<br></br> Medium Business IT Solutions?</h3>
:
<h3>Why ZOG Global for your Small to<br></br> Medium Business IT Solutions?</h3>
}                    </div>
                    <div className="col-lg-6">
                        <p> The heart of our business is built on developing strong relationships with top technology brands. This makes it possible to serve our clients with the most advanced IT solutions to solve their toughest challenges. Our services and solutions are designed specifically for our client's needs.
                            <br></br><br></br>At ZOG Global, we strive for excellence in client service and support. Our team of experts is highly trained and experienced, providing only the best service to our customers. We're ready to offer advice and help anytime you need it.
                            <br></br><br></br>Working together, we can create an IT environment that improves workflows, boosts productivity, and enhances product and service delivery. By focusing on our client's needs, we can help them reach their full potential.
                        </p></div>
                </div>
            </CommonSection>
            <CommonSection className="container-fluid technology-sec-4">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/industries/technology/sec-4-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Leveraging Innovative Technology</h3>
                                        <p>Businesses can take advantage of innovative technology for a variety of beneficial purposes. Potential uses include streamlining intricate machine maintenance processes, training employees safely and securely on potentially serious procedures, and cutting costs associated with business travels.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                    <div className="slide">
                        <img src="/images/industries/technology/sec-4-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Modern Testing Approaches for an Improved Client Experience </h3>
                                        <p>Testing and quality assurance (QA) has become increasingly important tasks in the digital age. Companies today need to reliably measure user experience in order to stay ahead of customer needs and respond quickly. By regularly running tests, companies can assess where course corrections may be needed to maintain customer satisfaction.</p>


                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>




                
                </Slider>
            </CommonSection>
            <CommonSection className="container-fluid technology-sec-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3>IT Services for<br/>Small to Medium Businesses</h3>
                        </div>
                    </div>
                </div>
                <Slider {...settings2} className="simple-slider">
                    <div className="slide">
                        <h4>Managed IT Services</h4>
                        <p>We have the proven methodology and vendor partnerships to ensure that every aspect of your infrastructure and endpoints is taken care of when it comes to keeping your day-to-day operations running as smoothly and efficiently as possible.</p>
                    </div>

                    <div className="slide">
                        <h4>Backup and Recovery </h4>
                        <p>The success of your business depends on safe and secure data backups. That's why we offer our Cloud Backup Assessment: an in-depth evaluation of your systems that can help you identify any vulnerabilities and strengthen your processes.</p>
                    </div>

                    <div className="slide">
                        <h4>Security </h4>
                        <p>Ensure that your organisation is prepared for the latest cyber-security threats with our advanced security solutions. We'll provide a complete, integrated defence system for all elements of your IT landscape so that you can stay ahead of the curve.</p>
                    </div>

                    <div className="slide">
                        <h4>Automation </h4>
                        <p>Business process automation software makes it easy to orchestrate data, business processes, and people. Through this system, you can create and automate any kind of business process you need.</p>
                    </div>

                 
                </Slider>
            </CommonSection>
            <CommonSection className="container home-sec-2 technology-sec-6">
                <div className="row">
                    <div className="col-lg-4">
                        <div>

                            <h3>Need IT Support?</h3>
                            <p>We'll assist you in leveraging technology to provide world-class experiences at a price your finance team will appreciate. Speak with our IT support team right away. </p>
                        </div>
                        <a href="/contact"><button className="btn btn-borderless btn-icon-changer-white discover-more-btn">
                        Let’s Connect
                            
                        </button></a>
                    </div>

                    <div className="col-lg-8">
                        <div className="bg-box">
                            <h4>Your Transformation Journey<br/>Begins Here </h4>
                            <p>
                            The contemporary business landscape is highly competitive. To succeed, businesses must be proactive and always look for new ways to improve. In many cases, this means transformations—whether it’s a total rebranding or a shift in focus. In the past, business transformations were often costly and time-consuming endeavours. But with the help of technology, businesses can now transform much more quickly and effectively. Technology can help businesses in a number of ways, from streamlining processes to improving communications and marketing efforts.<br></br><br></br>
Transformation requires time, effort, and a commitment to change, as it is not an event. The right partner can help you stay the course, providing support and guidance along the way. Here at ZOG Global, we specialise in helping our clients achieve lasting transformations. We work with you to set clear goals and objectives and then help you achieve them through our proven process.

                                </p>
                           
                        </div>
                    </div>
                  
                </div>
            </CommonSection>
        </>
    )
}

export default QualityAssurance