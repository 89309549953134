import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);
   
const resImage=$("#responsiveImage")
resImage.onload = function() {
    console.log("image loaded")
   var catImageWidth = this.naturalWidth;
    console.log("image height",catImageWidth) //logs 600
}



    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");
        
    }, [tabSelected])


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    
    function toggleTab(index) {
        setTabSelected(index);

    }




    

    const settings2 = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    
        responsive: [
            {
                breakpoint: 768,
                settings: {
                  dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
                }
              },
        ]
    };



    return (
        <>
            <Banner
                bgUrl="/images/industries/banking/banner.png"
                theme="light"
                txtAlign="left"
                paddingProp={{ top: "8", bottom: "7" }}
                heading={window.innerWidth>992?
                    "A Strategy for Securing<br/>Banks' High-Frequency<br/>Trading Platforms and Data<br/>Centres"
                :
                "A Strategy for Securing Banks'<br/>High-Frequency Trading<br/> Platforms and Data<br/>Centres"}
                content={
                    "ZOG Global offers comprehensive cybersecurity solutions and<br/>services, enabling various banking and finance institutions to<br/>overcome their security challenges today."
                }
                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"banking-banner"}
            >

            </Banner>
            
            <CommonSection className="container telecom-sec-2 ">
                <div className="row  g-0">
                    <div className="col-lg-6">
                        <img id="responsiveImage" src="/images/industries/banking/sec-1.png"></img>
                    </div>
                    <div className="col-lg-6 border">
                        <h3>Follow The Future of<br/>Financial Services with Us</h3>

                        <p>After the last pandemic, financial services increasingly relied on technology to enable transformation and growth to meet customer expectations. Providing clients with stronger, more sustainable financial services is what we do. It's how we contribute to building a better workplace. No matter how simple or complex your business challenge is, no matter how large or small, ZOG Global is committed to delivering solutions that work in the present and the future.</p>

                    </div>
                </div>
            </CommonSection>


            <CommonSection className="container telecom-sec-1">
                <div className="row cr  g-0">

                    <div className="col-lg-6 border">
                        <h3> Sustainability In<br/>Financial Services</h3>

                        <p>A sustainable world needs sustainable finance. We are helping to build a better financial services industry that is stronger, fairer, and more sustainable by helping organizations create value, accelerate the transition and make a measurable difference.</p>
                    </div>

                    <div className="col-lg-6">
                        <img src="/images/industries/banking/sec-2.png"></img>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        <h3>Automate your<br/>Financial Services </h3>

                        <p>Within financial services, automation already assists businesses in<br/>achieving efficient growth by applying pre-programmed rules to<br/>various structured and unstructured data. </p>
                    </div>

                </div>
                {window.innerWidth>992?
                <div className="row justify-content-between g-5">
                <div className="col-md-5">

                    <div
                        className="tab-btn-container"

                    >
                        <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Act Quickly and Remain Compliant</button>
                        <button className="tab-btn" onClick={(e) => toggleTab(1)}>Delight Your Customers
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(2)}>Optimise your Performance
                        </button>
                        <button className="tab-btn" onClick={(e) => toggleTab(3)}>Ensure Excellence In Operations
                        </button>

                    </div>

                </div>

                <div className="col-md-6">


                    <div className="tab-content">
                        <h4>Act Quickly and<br/>Remain Compliant</h4>

                        <p>Business environments are rapidly evolving, so organizations need to be proactive. To thrive and remain compliant, automate and optimize business processes.</p>


                    </div>
                    <div className="tab-content">
                        <h4>Delight Your<br/>Customers </h4>

                        <p>Technology is becoming more intelligent every day. Customers get high-quality, lightning-fast services with automation in financial services without burdensome administration.</p>


                    </div>
                    <div className="tab-content">
                        <h4>Optimise your<br/>Performance </h4>

                        <p>Automating crucial tasks lowers your risk of human error and aids in loss prevention. Then all the advantages come with adopting advanced analytics, including improved scoring methods, more data sources, and more outstanding data quality.</p>


                    </div>
                    <div className="tab-content">
                        <h4>Ensure Excellence<br/>In Operations</h4>

                        <p>Automate business inefficiencies using process mapping to stop operations from stagnating. Streamlining operations and understanding processes can reduce errors and help an organization run more efficiently.</p>


                    </div>
                    <div className="tab-content">
                        <h4>Lörem ipsum förväntis </h4>

                        <p>Lesam väling. Jymobelt king. Etnomatisk vavis. Berar. Epiberar jöngen. Kvasiss mosus. Oniska. Beledes preniska.  Lesam väling. Jymobelt king. Etnomatisk vavis.  Lesam väling. Jymobelt king. Etnomatisk vavis. Berar.</p>


                    </div>
                    <div className="tab-content">
                        <h4>Lörem ipsum förväntis </h4>

                        <p>Lesam väling. Jymobelt king. Etnomatisk vavis. Berar. Epiberar jöngen. Kvasiss mosus. Oniska. Beledes preniska.  Lesam väling. Jymobelt king. Etnomatisk vavis.  Lesam väling. Jymobelt king. Etnomatisk vavis. Berar.</p>


                    </div>
                    <div className="tab-content">
                        <h4>Lörem ipsum förväntis </h4>

                        <p>Lesam väling. Jymobelt king. Etnomatisk vavis. Berar. Epiberar jöngen. Kvasiss mosus. Oniska. Beledes preniska.  Lesam väling. Jymobelt king. Etnomatisk vavis.  Lesam väling. Jymobelt king. Etnomatisk vavis. Berar.</p>


                    </div>
                </div>
            </div>
            :
            <div className="row justify-content-between g-5">
                    <div className="col-12">

                        <div
                            className="tab-btn-container"

                        >
                            <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Act Quickly and Remain Compliant</button>

                            <div className="tab-content">
                            <h4>Act Quickly and<br/>Remain Compliant</h4>

                            <p>Business environments are rapidly evolving, so organizations need to be proactive. To thrive and remain compliant, automate and optimize business processes.</p>
                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(1)}>Delight Your Customers
                            </button>

                            <div className="tab-content">
                            <h4>Delight Your<br/>Customers </h4>

                            <p>Technology is becoming more intelligent every day. Customers get high-quality, lightning-fast services with automation in financial services without burdensome administration.</p>
                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(2)}>Optimise your Performance
                            </button>

                            <div className="tab-content">
                            <h4>Optimise your<br/>Performance </h4>

                            <p>Automating crucial tasks lowers your risk of human error and aids in loss prevention. Then all the advantages come with adopting advanced analytics, including improved scoring methods, more data sources, and more outstanding data quality.</p>
                        </div>



                            <button className="tab-btn" onClick={(e) => toggleTab(3)}>Ensure Excellence In Operations
                            </button>
                            <div className="tab-content">
                            <h4>Ensure Excellence<br/>In Operations</h4>

                            <p>Automate business inefficiencies using process mapping to stop operations from stagnating. Streamlining operations and understanding processes can reduce errors and help an organization run more efficiently.</p>
                        </div>

                        </div>

                    </div>

                   
                </div>}
            </CommonSection>

            <CommonSection className="container-fluid managed-it-services-sec-3 technology-sec-2 banking-sec-4">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <h3>Are you ready to experience the<br/>difference?</h3>
                                    <p>Future growth will depend on innovation and business-led transformation. In order to<br/>remain relevant and competitive, banks and other financial institutions need to embrace<br/>disruption and create better ecosystems strategically.</p>
                                </div>
                                <button className="btn btn-borderles btn-icon-changer-white discover-more-btn">
                                Talk to an Expert
                                    
                                </button>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>
            <CommonSection className="container-fluid technology-sec-5 banking-sec-5">
                <div className="container">
                    <div className="row">
                        <div className="col-kg-6">
                            <h3>Read Our Latest Thinking</h3>
                        </div>
                    </div>
                </div>
                <Slider {...settings2} className="simple-slider">
                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>

                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>
                    <div className="slide">
                        <h4>Lörem ipsum dopåvis onade. </h4>
                        <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekro.</p>
                    </div>
                </Slider>
            </CommonSection>

        </>
    )
}

export default QualityAssurance