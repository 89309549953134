import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected2, setTabSelected2] = useState(0);


    useEffect(() => {
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");
        console.log("tabSelected2", tabSelected2)
    }, [tabSelected2])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function toggleTab2(index) {
        setTabSelected2(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>


            {window.innerWidth > 992 ? <CommonSection className="container de-banner DE-testing-banner">
                <div className="row cr">

                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Ensure Software Quality with Reliable Testing</h3>

                        <p>ZOG Global offers a qualified, dependable, all-inclusive method for getting rid of bugs, malfunctions, and other challenges. We provide high-quality testing services to organizations at an optimal budget.</p>
                    </div>
                    <div className="col-lg-6 responsive-col-gap">
                        <div className=" responsive-col-gap"></div>
                        <img src="/images/digital-engineering/testing/banner.png"></img>
                    </div>

                </div>

            </CommonSection>
                :
                <Banner
                    bgUrl="/images/digital-engineering/testing/banner.png"
                    theme="light"
                    txtAlign="left"
                    paddingProp={{ top: "14", bottom: "2" }}
                    heading={"Ensure Software Quality with Reliable Testing"}
                    content={
                        "ZOG Global offers a qualified, dependable, all-inclusive method for getting rid of bugs, malfunctions, and other challenges. We provide high-quality testing services to organizations at an optimal budget."
                    }
                    contentWidth="30rem"
                    mobileVerticalAlignment="flex-end"
                    mobileTextAlign="left"
                >

                </Banner>}

            {window.innerWidth > 992 ? <CommonSection className="container DE-testing-sec-1">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="box">
                            <h3>Our Testing Service Highlights</h3>
                            <p >We provide a full range of customised software testing services to optimise your business software's quality, performance, and security since we understand the importance of enhancing your client experience.</p>
                        </div>
                        <div className="box">
                            <h4>Device and Browser Compatibility </h4>
                            <p>Testing cross-platform, browser, and mobile app testing with real users is one of our specialties.</p>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="box">
                            <h4>Functional Testing</h4>
                            <p>The testing of websites, web apps, and mobile applications across multiple channels, whether they are waterfall or agile.</p>
                        </div>
                        <div className="box">
                            <h4>A User Testing Service</h4>
                            <p>Reduce risks, cut costs, and produce the best quality product by getting your product in front of real people.</p>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div className="box">
                            <h4>Non-Functional Testing </h4>
                            <p>Performance testing, security testing, accessibility testing, and infrastructure testing—which may also include tests for disaster recovery and resilience testing—are our key services in this area.</p>
                        </div>
                        <div className="box">
                            <h4>Scripting & Planning</h4>
                            <p>A carefully planned scripted approach ensures that your test process is measurable, traceable, and repeatable.</p>
                        </div>
                    </div>

                </div>
            </CommonSection>
                :
                <CommonSection className="container DE-testing-sec-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="box">
                                {window.innerWidth>992?
                                <h3>Our Testing Service Highlights</h3>
                            :
                            <h3>Our Testing Service<br/>Highlights</h3>}
                                <p >We provide a full range of customised software testing services to optimise your business software's quality, performance, and security since we understand the importance of enhancing your client experience.</p>
                            </div>


                        </div>
                    </div>
                    <div className="non-breaking-mob-container">
                        
                        <div className="row">
                            <div className="box">
                                <h4>Device and Browser Compatibility </h4>
                                <p>Testing cross-platform, browser, and mobile app testing with real users is one of our specialties.</p>
                            </div>

                            <div className="box">
                                <h4>Functional Testing</h4>
                                <p>The testing of websites, web apps, and mobile applications across multiple channels, whether they are waterfall or agile.</p>
                            </div>
                            <div className="box">
                                <h4>A User Testing Service</h4>
                                <p>Reduce risks, cut costs, and produce the best quality product by getting your product in front of real people.</p>
                            </div>

                            <div className="box">
                                <h4>Non-Functional Testing </h4>
                                <p>Performance testing, security testing, accessibility testing, and infrastructure testing—which may also include tests for disaster recovery and resilience testing—are our key services in this area.</p>
                            </div>
                            <div className="box">
                                <h4>Scripting & Planning</h4>
                                <p>A carefully planned scripted approach ensures that your test process is measurable, traceable, and repeatable.</p>
                            </div>
                        </div>
                    </div>

                </CommonSection >}

            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2 DE-testing-sec-2">
                <div className="row align-items-center g-0 cr">

                    <div className="col-lg-7 border">
                        <h3> A Balanced Test Automation Approach</h3>

                        <p className="bold">The test automation roadmap should be comprehensive enough to consider all variables in the testing process, from the nature of the workflow to choosing the right testing tools. The following are the steps we use in our balanced Test Automation approach.</p>
                        <ul>
                            <li>Assessing your application landscape and automation testing requirements.</li>
                            <li>Evaluation of your existing testing solutions, including manual testing and automation testing.</li>
                            <li>Selection of Test Automation tool, Proof of Concept, and implementation.</li>
                            <li>Design of regression test suites with flexible execution options.</li>
                            <li>Enhance the quality and save time and money by reviewing and deploying test automation processes.</li>
                            <li>Provide continuous performance with various types of testing tools efficiently and accurately.</li>
                            <li>Decrease manual errors and fatigue using test automation tools, which is better when compared to manual testing in complex operations.</li>
                            <li>Mix and match types of automation, fixed, programmable, and flexible, are needed in your software development life cycle and thus streamline development and testing activities.</li>


                        </ul>
                    </div>

                    <div className="col-lg-5">
                        <img src="/images/digital-engineering/testing/sec-2.png"></img>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container cyber-security-sec-3 DE-testing-sec-3">
                <div className="tab-btn-wrapper">
                    <div className="tab2 row">


                        <div className="col-lg-12 d-flex justify-content-between">

                            <button className="tab-btn2 active" onClick={(e) => toggleTab2(0)}>Requirement Analysis    </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(1)}>Planning & Development
                            </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(2)}>Test Environment Creation
                            </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(3)}>Test Execution
                            </button>
                            <button className="tab-btn2" onClick={(e) => toggleTab2(4)}>Test Report Creation
                            </button>
                        </div>

                    </div>
                </div>


                <div className="tab-content2">

                    <h3>Requirement Analysis    </h3>
                    <p>Requirement analysis is one of the most critical phases since problems with the project can be almost totally fixed at no expense during this phase. The requirement analysis step identifies the potential need for automated testing and makes it possible to predict economic labour costs based on project estimation. At this point, the Entry & Exit Criteria are discussed and documented.</p>
                </div>

                <div className="tab-content2">

                    <h3>Planning & Development</h3>
                    <p>At this stage, a test strategy is developed. All testing phases, including time, participants, and roles, have been actualized at this point. Test development comprises human and automated testing, with the method depending on the criteria specified beforehand to cover the functionality and features of the application completely.</p>
                </div> <div className="tab-content2">

                    <h3>Test Environment Creation</h3>
                    <p>The test plan specifically states which test environment must be used. This phase involves the deployment of databases, operating systems, virtual machines, Selenium, Katalon Studio, and the project's test environment.</p>
                </div> <div className="tab-content2">

                    <h3>Test Execution</h3>
                    <p>Tests are executed based on prepared documentation and a properly configured test environment. The test management system maintains a record of every test outcome. Negatively passed tests—those whose results were not as expected—were flagged as errors and sent to the project team for script amendment and correction.</p>
                </div>
                <div className="tab-content2">

                    <h3>Test Report Creation</h3>
                    <p>The last phase is to produce testing reports for the customer. These must include the time expended, the proportion of flaws found to successful test results, and the total number of flaws found and fixed. For the testing department, this is the time to review its performance, assemble the results, gauge its productivity, and present suggestions for improving the testing standard.</p>
                </div>


            </CommonSection>

            <CommonSection className="container soft-dev-sec-2">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Read
                            Our Blog</h3>
                    </div>

                </div>

                <div className="row">
                    <div className="col-lg-6 responsive-col-gap">

                        <img src="/images/digital-engineering/testing/sec-4-1.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/testing/sec-4-2.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container  education-sec-4 DE-testing-sec-4">
                <div className="row" >
                    <div className="col-lg-6">
                        {window.innerWidth>992?
                        <h3>Benefits of<br/>Software Testing Services</h3>
                    :<h3>Benefits of<br/>Software Testing Services</h3>}
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                <div className="row">
                    <div className="col-4">
                        <div className="box">
                            <h4>Best-Quality<br/>Software </h4>
                            <p>Our highly skilled test engineers offer industry-specific expertise backed by pride in their work and an understanding of the importance of performance, reliability, and security; you can rest assured that ZOG Global only signs off on the highest quality software.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box">
                            <h4>Timely<br/>Releases</h4>
                            <p>We are aware of the necessity to shorten the time to market. Therefore, regardless of the project, we are committed to testing it quickly without sacrificing quality.</p>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="box">
                            <h4>Reduce<br/>Costs</h4>
                            <p>Our flexible strategy, prioritisation of testing tasks, and integration of test automation tools guarantee that testing expenses are fully optimised.</p>
                        </div>
                    </div>

                </div>
                </div>
            </CommonSection>


            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                {window.innerWidth>992?
                                <h3>Start Testing Your Software Today</h3>
                            :
                            <h3>Start Testing<br/>Your Software Today</h3>}
                                <p>Optimise the quality, performance, and security of your business software with us.</p>
                                <a href="../contact"><button className="btn btn-transparent">
                                    Get Started
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance