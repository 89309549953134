export const navArray = [{
    "label": "What We Do", "path": "/what-we-do", "subMenu": [

        { "label": "Transform Your Business", "path": "/what-we-do/transform-your-business" },
        { "label": "Protect Your Customers Data –(Cyber security)", "path": "/what-we-do/protect-your-customer-data" },
        { "label": "Managed IT Services", "path": "/managed-it-services" },
        { "label": "Engagement Model", "path": "/what-we-do/engagement-model" },

    ]
},
{
    "label": "Why ZOG Global", "path": "/why-zog-global", "subMenu": [
    ]
},
{
    "label": "Industries", "path": "/industries", "subMenu": [

        { "label": "Healthcare", "path": "/industries/Healthcare" },
        { "label": "Technology", "path": "/industries/technology" },
        { "label": "Telecom", "path": "/industries/telecom" },
        { "label": "Education", "path": "/industries/education" },
        { "label": "Travel", "path": "/industries/travel" },
        { "label": "Banking and Financial Services", "path": "/industries/banking" },
        { "label": "Insurance", "path": "/industries/insurance" },
        { "label": "Legal", "path": "/industries/legal" },
        { "label": "Media & Entertainment", "path": "/industries/entertainment" },
        { "label": "Pharma", "path": "/industries/pharma" },

    ]
},
{
    "label": "Digital Engineering", "path": "/digital-engineering", "subMenu": [
        { "label": "Automation", "path": "/digital-engineering/automation" },
        { "label": "DevOps", "path": "/digital-engineering/devops" },
        { "label": 'Advance Networking Services  (SD-WAN, SDN)', "path": "/digital-engineering/advanced-networking-services" },
        { "label": "DevSecOps", "path": "/digital-engineering/devsecops" },
        { "label": "Cloud", "path": "/digital-engineering/cloud" },
        { "label": "Cybersecurity", "path": "/digital-engineering/cyber-security" },
        { "label": "Software Development", "path": "/digital-engineering/software-development" },
        { "label": "Testing", "path": "/digital-engineering/testing" },
        { "label": "Unified Communication", "path": "/digital-engineering/unified-communication" },
    ]
},
{
    "label": "Quality Assurance", "path": "/quality-assurance", "subMenu": [
        { "label": "Automation Testing", "path": "/quality-assurance/automation-testing" },
        { "label": "Manual Testing", "path": "/quality-assurance/mannual-testing" },
        { "label": "Penetration Testing", "path": "/quality-assurance/penetration-testing" },
        { "label": "Performance Testing", "path": "/quality-assurance/perfomance-testing" },
        { "label": "Application Security Testing", "path": "/quality-assurance/application-security-testing" },
        { "label": "Accessibility Testing", "path": "/quality-assurance/accessibility-testing" },

    ]
},
{
    "label": "AI & Automation", "path": "/ai-automation", "subMenu": [
        { "label": "Artificial Intelligence", "path": "/ai-automation/artificial-intelligence" },
        { "label": "Automation & Robotics", "path": "/ai-automation/automation-robotics" },
        { "label": "Workdeck-AI (Next-Gen IT Automation)", "path": "/ai-automation/workdeck-ai" },
    ]
},
{
    "label": "Insight", "path": "/insight", "subMenu": [
        { "label": "Blog", "path": "/insight/blog" },
        { "label": "Be A Partner", "path": "/insight/be-a-partner" },
        { "label": "Career", "path": "/insight/career/" },
    ]
},
{
    "label": "Contact", "path": "/contact", "subMenu": [
    ]
}


]
