import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {


    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };




    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>


            <Banner
                bgUrl="/images/quality-assurance/perfomance-testing/banner.png"
                theme="dark"
                txtAlign="left"
                paddingProp={{ top: "8", bottom: "8" }}
                heading={window.innerWidth>992?
                    "Performance Testing Services":
                    "Performance<br/>Testing Services"}
                content={
                    "ZOG Global's performance testing services help ensure that all your web applications continue to perform correctly and provide the optimal user experience even when there are massive numbers of concurrent users."
                }

                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"perfomance-testing-banner"}
            >
            </Banner>
            <CommonSection className="container perfomance-testing-sec-2">
                <div className="row align-items-center g-5 cr">

                    <div className="col-lg-6">
                        <h3>Unleash The Power of Your Software with Performance Testing Services </h3>

                        <p>Understanding how your software will perform under load is crucial to ensuring a positive user experience. Our performance testing solutions can help you evaluate your software's ability to handle increased traffic and identify potential areas of improvement.
                            <br></br><br></br>
                            By exploring the potential of your software with performance testing, you can ensure that your users have a positive experience with your product.
                            <br></br><br></br>
                            Our comprehensive performance solutions assist our clients with the deployment of resilient applications in web, mobile, client-server and distributed systems environments. We also specialise in handling high-volume transaction systems, cloud databases and sophisticated programs. Additionally, futureproofing is an integral part of what we do as we strive to ensure long-term operational success for our clients. </p>

                    </div>
                    <div className="col-lg-6">
                        <img src="/images/quality-assurance/perfomance-testing/sec-1.png"></img>
                    </div>
                </div>
            </CommonSection>
            <CommonSection className="container perfomance-testing-sec-3">
               
                <div className="row align-items-center g-5">

<div className="col-lg-12">
    <h3 className="text-center w-100">Types of Performance Testing Services We Offer
    </h3>
</div>

</div>
                
                
                <div className="non-breaking-mob-container">

                <div className="row g-0 gapless-box-container">

                    <div className="col-4">
                        <div className="box">
                            <img src="/images/quality-assurance/perfomance-testing/sec-2-1.svg"></img>
                            <h4>Load Testing</h4>
                            <p>This type of performance testing is conducted to measure the performance of a system under a heavy load. The load could be a certain number of users, transactions, or requests.</p>
                        </div>
                    </div>
                    <div className="col-4">
                    <div className="box">
                            <img src="/images/quality-assurance/perfomance-testing/sec-2-1.svg"></img>
                            <h4>Stress Testing</h4>
                            <p>Stress testing is done to determine the system's ability to handle extreme loads beyond its capacity. This is done by simulating vast numbers of users, transactions, or requests beyond the system's limit.
</p>
                        </div>
                    </div>
                    <div className="col-4">
                    <div className="box">
                            <img src="/images/quality-assurance/perfomance-testing/sec-2-1.svg"></img>
                            <h4>Endurance Testing</h4>
                            <p>Endurance testing involves keeping the system under heavy load for a prolonged period to simulate real-world scenarios. The aim is to determine if the system can perform consistently over time and detect performance degradation that can occur over time.</p>
                        </div>
                    </div>
                    <div className="col-4">
                    <div className="box">
                            <img src="/images/quality-assurance/perfomance-testing/sec-2-1.svg"></img>
                            <h4>Spike Testing</h4>
                            <p>Spike testing is designed for a sudden increase in traffic, as may happen during a sale or promotional period. This type of testing is done to ascertain the system's resilience in handling sudden surges in traffic.
</p>
                        </div>

                    </div>

                    <div className="col-4">
                    <div className="box">
                            <img src="/images/quality-assurance/perfomance-testing/sec-2-1.svg"></img>
                            <h4>Compatibility Testing</h4>
                            <p>Compatibility testing ensures the system works seamlessly across different hardware and software configurations, including different hardware, browsers, and operating systems.
</p>
                        </div>
                    </div>
                    <div className="col-4">
                    <div className="box">
                            <img src="/images/quality-assurance/perfomance-testing/sec-2-1.svg"></img>
                            <h4>Scalability Testing</h4>
<p>Scalability testing evaluates how the system performs as the demand grows or decreases. This type of testing ensures that the system will be able to withstand and adequately process a large number of users, transactions, or requests.</p>                        </div>
                    </div>
                    
                </div>
                </div>
            </CommonSection>
            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2  perfomance-testing-sec-4A ">
                <div className="row align-items-center gx-5">
                    {window.innerWidth>992&&
                    <div className="col-lg-6">
                        <img src="/images/quality-assurance/perfomance-testing/sec-2.png"></img>
                    </div>}
                    <div className="col-lg-6 responsive-col-gap ">
                        <h3>Benefits of Our Performance Testing Services </h3>

                        <p className="bold mb-2">Performance testing techniques can positively contribute to organisational efficiency by optimising speed and accuracy. </p>
                        {window.innerWidth<992&&<img src="/images/quality-assurance/perfomance-testing/sec-2.png"></img>
                        
                        }
                        <ul>
                            <li>Identifies bottlenecks or performance issues that can affect software performance before deployment.  </li>
                            <li>Evaluates application scalability and the maximum ability to handle the load at any given time. </li>
                            <li>Early identification of issues and bugs helps to address them quickly, saving time and cost on troubleshooting and expensive bug fixes. </li>
                            <li>Ensure smooth application performance, improving the overall user experience. </li>
                            <li>Identifies potential problems and provides solutions to minimise downtime and avoid high-impact issues.  </li>
                            <li>It provides accurate data on the application’s performance and service level agreements. </li>
                        </ul>
                    </div>


                </div>
            </CommonSection>

            <CommonSection className="container industries-sec-3 automation-testing-sec-4 mannual-testing-sec-3 perfomance-testing-sec-4B">
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <h3 className="mb-3">Our Performance Testing Process </h3>
                    </div>
                </div>
                <div className="row g-0 gapless-box-container">

                    <div className="col-6">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-1.png"></img>
                            <p>Building a test strategy </p>
                        </div>
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-2.png"></img>
                            <p>Choosing a test tool </p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-3.png"></img>
                            <p>Test execution and analysing results</p>
                        </div>
                        <div className="box">
                            <img src="/images/quality-assurance/automation-testing/sec-4-4.png"></img>
                            <p>Submitting the report doc to the client </p>
                        </div>

                    </div>
                </div>



            </CommonSection>

            <CommonSection className="container perfomance-testing-sec-4">
                <div className="row gx-5 cr">

                    <div className="col-lg-8">
                        <h3>Leveraging Advanced Technologies </h3>
                        <p>
                            At ZOG Global, we understand how difficult it can be for businesses to meet and exceed customer or user expectations in the digital age. If your business fails to fulfil these demands, it can cause significant damage to your reputation and level of customer loyalty. Therefore, we remain committed to providing you with the latest tools, technologies and professional expertise so your customers are always satisfied.
                            <br></br><br></br>


                            ZOG Global's full-cycle performance testing services provide comprehensive management and mitigation of operational issues in mission-critical business applications. Our comprehensive tests ensure you understand your application's behaviour before it goes live. With our expertise, we can avert any potential bottlenecks and render uninterrupted service experiences for users. So let ZOG Global handle the pre-emptive measures to keep your app running at peak performance! </p>
                    </div>

                    <div className="col-lg-4">
{window.innerWidth>992?
                        <img src="/images/AI-Automation/automation-robotics/sec-4.png"></img>

:
<img src="/images/AI-Automation/automation-robotics/sec-4-tab.png"></img>
}                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container soft-dev-sec-2">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Related Insights </h3>
                    </div>

                </div>

                <div className="row g-5">
                    <div className="col-lg-6">

                        <img src="/images/quality-assurance/application-security-testing/sec-4-1.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                    <div className="col-lg-6">
                        <img src="/images/quality-assurance/application-security-testing/sec-4-2.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container telecom-sec-1 devsecops-sec-2 cloud-sec-2 perfomance-testing-sec-8">
                <div className="row g-0">

                    <div className="col-lg-7 responsive-col-gap border">
                        <h3>Why Clients Choose Us For Performance Testing </h3>

                        <p className="bold">Our performance test experts have a decade of experience and can pinpoint any existing or potential problems. With their analysis, you can trust that your application or software is ready for the market. </p>
                        {window.innerWidth<992&& <img src="/images/quality-assurance/perfomance-testing/sec-6.png"></img>}
                       
                        <ul>
                            <li>When it comes to client engagement, we believe in being open, collaborative, transparent, and communicative.  </li>
                            <li>We employ various testing tools and infrastructure services to ensure that all components are running optimally.  </li>
                            <li>We are an affordable and reliable business with an array of technological tools to identify and rectify any problems that may arise. </li>
                        </ul>
                    </div>

                    <div className="col-lg-5">
                       {window.innerWidth>992&& <img src="/images/quality-assurance/perfomance-testing/sec-6.png"></img>}
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid tyb-sec-6 mb-0">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

                                <h3>Need to Enhance<br/>your Software Performance? </h3>
                                <p>Our advanced testing services will enable you to improve performance,<br/> eliminate hazards, and ensure your product's success. Get in touch with our testing<br/>experts today. </p>
                                <button className="btn btn-transparent">
                                    Talk To an Expert
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>

        </>
    )
}

export default QualityAssurance