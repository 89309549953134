import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {
    const navigate = useNavigate()

    const [tabSelected, setTabSelected] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>
            <Banner
                bgUrl="/images/digital-engineering/automation/banner.png"
                theme="dark"
                txtAlign="left"
                paddingProp={{ top: "9", bottom: "11" }}
                heading={window.innerWidth > 992 ?
                    "Simplifying Complex Workflows"
                    :
                    "Simplifying<br/>Complex<br/>Workflows"}
                content={
                    window.innerWidth > 992 ?
                        "ZOG Global can analyse and automate business most  time-consuming processes using the most effective algorithms and machine-learning techniques."

                        :
                        "ZOG Global can analyse and automate business most<br/>time-consuming processes using the most effective algorithms<br/>and machine-learning techniques."
                }
                contentWidth="30rem"
                customClassName={"automation-banner"}
            >

            </Banner>

            <CommonSection className="container protect-your-customers-data-sec-4">
                <div className="row">

                    <div className="col-lg-5 responsive-col-gap">
                        {window.innerWidth > 992 ?
                            <h3>Leveraging Automation in Business Processes</h3>

                            :
                            <h3>Leveraging Automation in<br />Business Processes</h3>
                        }
                        <p>With all of our automated solutions at your disposal, you can focus on what's most important to your business while making sure everything runs smoothly. </p>
                    </div>

                </div>

                {window.innerWidth > 992 ?
                    <div className="row justify-content-between g-5">
                        <div className="col-md-5">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Increased Efficiency</button>
                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Higher Productivity
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Easy Compliance
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(3)}>Reduced Operating Costs
                                </button>

                            </div>

                        </div>

                        <div className="col-md-6">


                            <div className="tab-content">
                                <h4>Increased Efficiency</h4>

                                <p>Automation saves time, money, and effort while lowering manual errors, giving your company more time to concentrate on its primary goals.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Higher Productivity</h4>

                                <p>With the help of automation tools, teams no longer need to spend valuable time on tedious admin tasks. Consequently, productivity is boosted. Your team members can accomplish more when fewer processes and routine chores are holding them back.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Easy Compliance</h4>

                                <p>Increased process clarity is one of the advantages of automation. Your company follows guidelines. So, making sure your workflows are compliant with the law is simple. Additionally, it supports audit trials, records, and alarms. So you get a clear view of what, how, and when things go wrong.</p>


                            </div>
                            <div className="tab-content">
                                <h4>Reduced Operating Costs</h4>

                                <p>Fixing simple, preventable mistakes cost businesses significant time and money. Automation, however, can help a business save a significant amount of money by producing more work and fewer errors.</p>


                            </div>


                        </div>
                    </div>
                    :
                    <div className="row justify-content-between g-5">
                        <div className="col-12">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Increased Efficiency</button>

                                <div className="tab-content">
                                    <h4>Increased Efficiency</h4>

                                    <p>Automation saves time, money, and effort while lowering manual errors, giving your company more time to concentrate on its primary goals.</p>


                                </div>


                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>Higher Productivity
                                </button>

                                <div className="tab-content">
                                    <h4>Higher Productivity</h4>

                                    <p>With the help of automation tools, teams no longer need to spend valuable time on tedious admin tasks. Consequently, productivity is boosted. Your team members can accomplish more when fewer processes and routine chores are holding them back.</p>


                                </div>


                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>Easy Compliance
                                </button>

                                <div className="tab-content">
                                    <h4>Easy Compliance</h4>

                                    <p>Increased process clarity is one of the advantages of automation. Your company follows guidelines. So, making sure your workflows are compliant with the law is simple. Additionally, it supports audit trials, records, and alarms. So you get a clear view of what, how, and when things go wrong.</p>


                                </div>


                                <button className="tab-btn" onClick={(e) => toggleTab(3)}>Reduced Operating Costs
                                </button>

                                <div className="tab-content">
                                    <h4>Reduced Operating Costs</h4>

                                    <p>Fixing simple, preventable mistakes cost businesses significant time and money. Automation, however, can help a business save a significant amount of money by producing more work and fewer errors.</p>


                                </div>


                            </div>

                        </div>

                        <div className="col-md-6">








                        </div>
                    </div>
                }
            </CommonSection>

            <CommonSection className="container automation-sec-2">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/automation/sec-2.png"></img>
                    </div>
                    <div className="col-lg-6">
                        <div>
                            <h3>Eliminate the bottlenecks that inevitably waste time and money</h3>
                            <p>As your business grows, you must deal with increasingly more significant paperwork, including contracts with suppliers, workers, and possibly clients, receipts, invoices, emails, internal documents, etc. Still, processing and retrieving many documents are complex, especially when the content of the documents needs to be processed and searched.</p>
                            <p>ZOG Global can create solutions to automate documents' storage, processing, organization, and retrieval, extracting the right information needed to automate downstream tasks and immensely cutting the costs of manually searching and processing documents.</p>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container automation-sec-3">
                <div className="row">
                    <div className="col-lg-6">
                        {window.innerWidth > 992 ?
                            <h3 className="mb-5">Organizations Can Be Enabled To</h3>
                            :
                            <h3 className="mb-5">Organizations Can Be<br />Enabled To</h3>
                        }                    </div>
                </div>
                <div className="row g-5">
                    <div className="col-md-6">
                        <div className="box">
                            <h4>Improve Information<br />Flow Between Teams </h4>
                            <p>Reduces response times within an organization and increases the accuracy of information flow, resulting in a reduction of paper use, faster approvals, and improved customer engagement.</p>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box">
                            <h4>Optimize Your<br />Operational Costs </h4>
                            <p>By moving materials to electronic formats, operating costs can be reduced through reduced storage costs and improved workflows.</p>

                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container automation-sec-4">
                <div className="row g-0 responsive-col-gap">
                    <div className="col-lg-6">
                        <h3>Integrate Channels for Easy Collaboration</h3>
                        <p className="bold">Everyone knows that to keep up with the latest trends; you need to be on top of what’s new and trending. Therefore, any organization must stay updated about the latest social media channels, user-friendly software tools, and other innovative services. </p>
                        <p>Collaboration is a crucial component in any business today. It facilitates information sharing among team members and helps build trust and transparency throughout the organization. Businesses should integrate different communication channels into one system to enable team collaboration. Using an automation service makes this process easy for businesses of all sizes.  </p>
                    </div>
                    <div className="col-lg-6">
                        <img src="/images/digital-engineering/automation/sec-4.png"></img>
                    </div>
                </div>
                <div className="row g-0">

                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>15+</h4>
                            <p>Successful projects</p>
                        </div>
                    </div>
                    <div className="col-lg-3  col-md-6">
                        <div className="box">
                            <h4>97%</h4>
                            <p>Happy customers</p>
                        </div>
                    </div>
                    <div className="col-lg-3  col-md-6">
                        <div className="box">
                            <h4>10+</h4>
                            <p>Years of industry-experienced team</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="box">
                            <h4>30%</h4>
                            <p>Reduce costs by up to 30%</p>
                        </div>
                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container healthcare-sec-1 education-sec-3 pharma-sec-2  automation-sec-5">
                <div className="row" >
                    <div className="col-lg-3">


                        <h3>Enhance the performance
                            of the business</h3>
                        <p>Organizations today encounter numerous obstacles when trying to evolve in the constantly shifting digital environment. Businesses must be creative to differentiate themselves from competitors, grab market opportunities before competitors do, and adapt quickly to shifting conditions.</p>



                    </div>
                    <div className="col-lg-9 ">
                        <diV className="gapless-box-container">
                            <div className="my-row">
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <img src="/images/digital-engineering/automation/sec-5-1.svg" className="me-3"></img>
                                        <h4>Automate Workflows</h4>
                                    </div>
                                    <p>Automate all the organizational manual processes you pay back-office staff to do today with us. We help you to reduce the chance of clerical errors and drive better business decisions faster.</p>
                                </div>

                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <img src="/images/digital-engineering/automation/sec-5-2.svg" className="me-3"></img>
                                        <h4>An Integrated Approach</h4>
                                    </div>
                                    <p>Build automated workflows across business-critical record systems, content repositories, and communication channels. </p>
                                </div>
                            </div>
                            <div className="my-row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <img src="/images/digital-engineering/automation/sec-5-3.svg" className="me-3"></img>
                                        <h4>Easy-To-Use</h4>
                                    </div>
                                    <p>With our tools, we can help you make complex decisions more efficiently, or even automatically, taking into account all the available data so that you can make confident decisions.</p>

                                </div>
                            </div>

                        </diV>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container  education-sec-4  automation-sec-6">
                <div className="row" >
                    <div className="col-lg-6">
                        <h3>Explore Latest Tech Stories and News</h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade.  </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="box">
                                <h4>Lörem ipsum dopåvis onade. </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things. Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container-fluid travel-sec-2 automation-sec-7 ">
                <Slider {...slideSettings} className="banner-slider">
                    <div className="slide">
                        <img src="/images/digital-engineering/automation/sec-7-1.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Are you planning a new project?
                                            Let’s make it happen.</h3>
                                        <p>Our team of consultants and architects provides the knowledge and expertise to institutionalize and manage the proposed automation approach by understanding your processes and designing and implementing solutions specific to your needs.</p>

                                        <button className="btn btn-transparent">Contact Us<span><svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg></span></button>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="slide">
                        <img src="/images/digital-engineering/automation/sec-7-2.png"></img>
                        <div className="mask ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>Leveraging RPA in Business Process</h3>
                                        <p>Our services include end-to-end RPA implementation and management, from process identification and analysis to configuration, testing, and deployment.
                                        </p>

                                        <button className="btn btn-transparent" onClick={() => navigate("/ai-automation/automation-robotics")}>Learn more<span><svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg></span></button>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>

                        </div>

                    </div>



                </Slider>
            </CommonSection>
        </>
    )
}

export default QualityAssurance