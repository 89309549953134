import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { jobsAPITestData } from "../constants/APITestData";

const QualityAssurance = () => {


    const [tabSelected2, setTabSelected2] = useState(0);


    useEffect(() => {
        $(".tab-btn2").removeClass("active");
        $(".tab-btn2").eq(tabSelected2).addClass("active");

        $(".tab-content2").css("display", "none");
        $(".tab-content2").eq(tabSelected2).css("display", "block");

    }, [tabSelected2])

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    

    function toggleTab2(index) {
        setTabSelected2(index);

    }




    const slideSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };





    return (
        <>
            <CommonSection className="container career-details-sec-1">
                <div className="row justify-content-between align-items-center pt-5 pb-5 mb-5 row-1 py-2">
                    <div className="col-lg-4">
                        <h3>Benyhet sagen innovatör</h3>
                        <h5>Design</h5>
                    </div>
                    <div className="col-lg-3">
                        <button className="btn btn-primary btn-share">
                            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_136_1501)">
                                    <path d="M9 21.25C11.3472 21.25 13.25 19.3472 13.25 17C13.25 14.6528 11.3472 12.75 9 12.75C6.65279 12.75 4.75 14.6528 4.75 17C4.75 19.3472 6.65279 21.25 9 21.25Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M23.875 30.8125C26.2222 30.8125 28.125 28.9097 28.125 26.5625C28.125 24.2153 26.2222 22.3125 23.875 22.3125C21.5278 22.3125 19.625 24.2153 19.625 26.5625C19.625 28.9097 21.5278 30.8125 23.875 30.8125Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M23.875 11.6875C26.2222 11.6875 28.125 9.78471 28.125 7.4375C28.125 5.09029 26.2222 3.1875 23.875 3.1875C21.5278 3.1875 19.625 5.09029 19.625 7.4375C19.625 9.78471 21.5278 11.6875 23.875 11.6875Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M20.3024 9.73511L12.5728 14.7023" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.5728 19.2976L20.3024 24.2648" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_136_1501">
                                        <rect width="34" height="34" fill="white" transform="translate(0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Share</button>
                    </div>
                </div>


                <div className="row mb-3 row-2 py-2">
                    <div className="col-lg-2">
                        <h6>Job ID</h6>
                        <p>012</p>
                    </div>
                    <div className="col-lg-2">
                        <h6>Job Type</h6>
                        <p>adada</p>
                    </div>
                    <div className="col-lg-4">
                        <h6>Working Hours</h6>
                        <p>aaaaa</p>
                    </div>
                    <div className="col-lg-2">
                        <h6>Salary Range</h6>
                        <p>aaaaaa</p>
                    </div>
                    <div className="col-lg-2">
                        <h6>Location</h6>
                        <p>aaaaa</p>
                    </div>
                </div>
                <div className="row row-3 mb-5">
                    <button className="btn btn-primary btn-transparent home-book-button career-button mb-5">
                        Apply Now
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                            </svg>
                        </span>
                    </button>

                </div>

                <div className="row py-2 description-box">
                    <h5>Your Role</h5>
                    <ul>
                        <li>Ogt kar paradat oligt i sekelsiffror, prebel därför att white label. Gretaeffekten båse skajpa filoaktiv och ade heterocentrism. Ont misogisk syssna supraras sys: terar, ajånde decigt. 
</li>
                        <li>Mikrolögt anatik essade. Dosat belågt i bebolig, synde huruvida blandkostare. Svischa blåstråle vipp. 
</li>
                        <li>Dekasa infraling i paktig bågisk därför att sönar. Röstsamtal köttrymden alåbel, fost fomo för mobilmobbning, exekvera, transitet. Stenor tesölingar diktig pogisk langen fas. 
</li>
                        <li>Pol servicebarn: benera dekar hackathon i niktig bel kesösade. Åv astros, ett byng see now buy now polypp vipasamma. Rör äbåskade, skogsbad. 
 </li>
                        <li> 
Spel decisat eurodina sir tis. Tredire jål i paddeltennis robothandel furade i ultraligt. Nikobel polassa att jålölig. </li>
                       

                    </ul>
                </div>

                <div className="row description-box" >
                    <h5>Your Expertise</h5>
                    <h6>Skillset</h6>
                    <p>Adobe Photoshop, Adobe Illustrator, Figma</p>
                    <p>Ogt kar paradat oligt i sekelsiffror, prebel därför att white label. Gretaeffekten båse skajpa filoaktiv och ade heterocentrism. Ont misogisk syssna supraras sys: terar, ajånde decigt. 
Mikrolögt anatik essade. Dosat belågt i bebolig, synde huruvida blandkostare. Svischa blåstråle vipp. 
Dekasa infraling i paktig bågisk därför att sönar. Röstsamtal köttrymden alåbel, fost fomo för mobilmobbning, exekvera, transitet. Stenor tesölingar diktig pogisk langen fas. 
Pol servicebarn: benera dekar hackathon i niktig bel kesösade. Åv astros, ett byng see now buy now polypp vipasamma. Rör äbåskade, skogsbad. 
Spel decisat eurodina sir tis. Tredire jål i paddeltennis robothandel furade i ultraligt. Nikobel polassa att jålölig. 
</p>

                </div>

                <div className="row description-box">
                    <h5>About Us</h5>
                    <p>Ogt kar paradat oligt i sekelsiffror, prebel därför att white label. Gretaeffekten båse skajpa filoaktiv och ade heterocentrism. Ont misogisk syssna supraras sys: terar, ajånde decigt. 
Mikrolögt anatik essade. Dosat belågt i bebolig, synde huruvida blandkostare. Svischa blåstråle vipp. 
Dekasa infraling i paktig bågisk därför att sönar. Röstsamtal köttrymden alåbel, fost fomo för mobilmobbning, exekvera, transitet. Stenor tesölingar diktig pogisk langen fas. 
Pol servicebarn: benera dekar hackathon i niktig bel kesösade. Åv astros, ett byng see now buy now polypp vipasamma. Rör äbåskade, skogsbad. 
Spel decisat eurodina sir tis. Tredire jål i paddeltennis robothandel furade i ultraligt. Nikobel polassa att jålölig. 
</p>
                </div>


                <div className="row description-box">
                    <h5>Disclaimer / Policy Statements</h5>
                    <p>Ogt kar paradat oligt i sekelsiffror, prebel därför att white label. Gretaeffekten båse skajpa filoaktiv och ade heterocentrism. Ont misogisk syssna supraras sys: terar, ajånde decigt. 
Mikrolögt anatik essade. Dosat belågt i bebolig, synde huruvida blandkostare. Svischa blåstråle vipp. 
Dekasa infraling i paktig bågisk därför att sönar. Röstsamtal köttrymden alåbel, fost fomo för mobilmobbning, exekvera, transitet. Stenor tesölingar diktig pogisk langen fas. 
Pol servicebarn: benera dekar hackathon i niktig bel kesösade. Åv astros, ett byng see now buy now polypp vipasamma. Rör äbåskade, skogsbad. 
Spel decisat eurodina sir tis. Tredire jål i paddeltennis robothandel furade i ultraligt. Nikobel polassa att jålölig. 
</p>
                </div>




            </CommonSection >

        </>
    )
}

export default QualityAssurance