import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

import $ from 'jquery';
import { useEffect, useState } from "react";


const ManagedITServices = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      

    const [tabSelected, setTabSelected] = useState(0);


    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");

        $(".tab-content").css("display", "none");
        $(".tab-content").eq(tabSelected).css("display", "block");

    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);

    }

    
    
    
      
    return (
        <>
            <Banner
                bgUrl="/images/digital-engineering/banner.png"
                theme="dark"
                txtAlign="left"
                paddingProp={{ top: "8.6042", bottom: "8" }}
                heading={"Accelerate your Business Growth - Managed IT Services & Solutions"}
                content={window.innerWidth>992?
                    "We design the scope of managed IT services individually for each client that helps them to simplify and optimise their service delivery."
:
"We design the scope of managed IT services individually for<br/>each client that helps them to simplify and optimise their<br/>service delivery."
}
                mobileVerticalAlignment="center"
                mobileTextAlign="center"
                customClassName={'managed-it-services-banner'}
            >

            </Banner>
            <CommonSection className="container managed-it-services-sec-1">
                <div className="row" >
                    <div className="col-lg-4">
                        <div className="box">
                            {window.innerWidth>992?
                            <h3>World-class<br></br>Managed IT<br></br>Service for <br></br> Your<br></br>Business</h3>

                            :
                            <h3>World-class Managed IT<br></br>Service for Your Business</h3>
                            
                            }
                            <p>Our Managed Service contract includes software, infrastructure, networking, communications, and support, which means that your IT can be partially or fully outsourced.</p>

                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row gapless-box-container g-0">
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>The Industry Innovator in IT Managed Services </h4>
                                    <p>ZOG Global has been at the forefront of providing top-notch managed IT services in the UK for security, cloud, and IT support. Equipped with our cutting-edge technology, APEX platform, our certified engineers are always ready to provide professional, responsive, and accountable IT services.<br></br>
                                        <br></br>Our seamless integration of technology and highly qualified professionals enables us to deliver a fully managed IT service that is unrivaled in quality and flexibility. Experience the best the industry offers with ZOG Global as your managed IT services provider.
                                    </p>
                                    <button className="btn btn-borderless">Learn more></button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box">
                                    <h4>Being Flexible to your needs</h4>
                                    <p>Some clients leverage our technology and IT-managed services to augment their existing IT team, while others outsource their entire IT function to us, as they need more in-house IT expertise.<br></br>
                                        <br></br>Whether you're seeking to harness the full potential of Microsoft Azure, lower expenses through automation, offer first-level IT support services, or outsource everything fully, ZOG Global's range of IT-managed services is designed to meet your requirements, giving you the flexibility and agility you need.</p>
                                    <button className="btn btn-borderless">Learn more></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>



            <CommonSection className="container managed-it-services-sec-2">
                {window.innerWidth > 992 ?
                    <div className="row g-5">
                        <div className="col-md-3 pe-5">

                            <div
                                className="tab-btn-container"

                            >
                                <button className="tab-btn active" onClick={(e) => toggleTab(0)}>Automation</button>
                                <button className="tab-btn" onClick={(e) => toggleTab(1)}>DevOps
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(2)}>DevSecOps
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(3)}>Advance Networking Services<br/>(SD-WAN, SDN)
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(4)}>Cloud
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(5)}>Cybersecurity
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(6)}>Software Development
                                </button>

                                <button className="tab-btn" onClick={(e) => toggleTab(7)}>Testing
                                </button>
                                <button className="tab-btn" onClick={(e) => toggleTab(8)}>Unified Communication
                                </button>

                            </div>

                        </div>

                        <div className="col-md-9 ps-5">


                            <div className="tab-content">
                                <h3>Automation Services </h3>
                                <p>We combine deep industry and process knowledge with experts from our global delivery centres to simplify and accelerate project value with a wide range of industry-ready automation tools.</p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn More
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Drive End-to-End<br/> Process Outcomes</h4>
                                            <p>Automate your processes to achieve successful end-to-end results. Through the transformation and automation of end-to-end processes, ZOG Global's approach to automation uses a broad range of automation, AI, and low-code process management technologies.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Reaching Strategic<br/> Objectives </h4>
                                            <p>Organizations can reimagine complex processes by automating their operational models with ZOG Global's assistance. The aim? To assist our clients in transforming their processes, making sense of disparate data sets, and achieving ever-more-ambitious strategic goals.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>DevOps Services </h3>
                                <p>We are one of the top DevOps vendors when it comes to maximising cloud implementations and cutting expenses while enhancing the security, scalability, and dependability of the applications. </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Reduces Time to Market  </h4>
                                            <p>Enhance the amount and speed of releases to improvise and improve your product more quickly. The software release process is automated through continuous integration and continuous delivery techniques. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Efficient & Easy Scalable  </h4>
                                            <p>Make your infrastructure and development processes more efficient and scalable. With its spotless consistency, DevOps Automation assists in the practical and risk-free management of complicated or progressing systems. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>DevSecOps Services   </h3>
                                <p>DevSecOps as a service is helpful during the elaboration process of a business. This strategy makes prioritizing security, analysing business processes, and monitoring operations continuously possible.  </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Improve<br/>Workflows  </h4>
                                            <p>Organisations focusing on rapid software development can look to DevSecOps to improve their workflows. Its automated checks are essential for keeping up velocity while they identify and fix bugs quickly. Having these checks in place also helps maintain the speed of software updates. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Early Detection of Vulnerabilities  </h4>
                                            <p>Static and dynamic application security checks offer a quick and cost-effective way to fix any vulnerabilities. These tools provide automated testing and reporting when embedded into the delivery process. As an additional option, businesses can create isolated validation processes for extra protection and monitoring. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>Advance Networking Services  </h3>
                                <p>ZOG Global provides innovative networking infrastructure and technology solutions to small, medium, and large organizations. Our services help you establish an optimized network and an effective data management system. </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Centralised Network Processing </h4>
                                            <p>A centralised controller handles all decision-making in the Software-Defined Networking (SDN) architecture, providing simpler network element configurations and quicker service delivery. This centralised control benefits virtual and physical devices, which can be managed more efficiently in one location. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Improved Network Performance  </h4>
                                            <p>Software-Defined Networking (SDN) enables network administrators to efficiently shape and control data traffic. This technology supports Quality of Service (QoS), providing improved responsiveness and a superior user experience with voice and multimedia applications. SDN helps maximize reliable, high-level communication performance. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>Cloud Services </h3>
                                <p>ZOG Global provides cutting-edge cloud computing services across Google Cloud Platform, Microsoft Azure, and Amazon Web Services. Our team of expert professionals understands the complexity of cloud solutions and has the expertise to create reliable models to meet the varying needs of all organizations. </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Securing Confidential Data </h4>
                                            <p>Cloud-based platforms can provide a high level of security by proactively restricting access to sensitive data. Through granular permissions and federated roles, only those employees with the relevant authorities can access confidential information, limiting the potential attack vector for cybercriminals. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Prevent Data Loss </h4>
                                            <p>With cloud computing, even if on-site equipment breaks down, your data is always accessible. Additionally, cloud-based services provide reliable business continuity support for natural disasters or power outages. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>Cybersecurity Services  </h3>
                                <p>Businesses are constantly exposed to cyberattacks. To protect your networks from internal and external threats, you need to have strong digital asset protection measures in place. </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Protects Business Reputation  </h4>
                                            <p>The success of a business depends on retaining customers. Data breaches can damage the reputation and make it harder to keep customers. Organisations need to focus on cybersecurity to build trust and keep customers. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Enhanced Data Management </h4>
                                            <p>Data is the key to successful products and marketing strategies. Losing data at the last minute can force companies to start from scratch. Therefore, it is essential for organisations to constantly monitor their data to ensure that security measures are in place. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>Software Development Services  </h3>
                                <p>At ZOG Global, we are highly experienced in providing full-cycle software development services tailored to the specifics of your project and business objectives. </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>360° Service Model  </h4>
                                            <p>Our 360° framework is a comprehensive approach to developing, integrating, and managing enterprise applications, offering everything from planning and implementation to post-launch support. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Time-to-Market  </h4>
                                            <p>By leveraging high-level expertise and a variety of solutions accelerators, customers can enjoy rapid product rollout, fast customizations, and smooth delivery. These benefits result in reduced development costs and more timely market entry. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>Testing Services </h3>
                                <p>We offer high-quality assurance services and testing skills tailored to your industry and environment. This intimate understanding of your domain helps us create highly targeted solutions. </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Increase Efficiency  </h4>
                                            <p>We strive to create reusable assets from all artifacts produced and reduce the time spent creating scripts, test cases, and other user stories by automating them. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Collaborate Effortlessly  </h4>
                                            <p>Team collaboration models help ensure that parallel teams can work together without encountering difficulty due to miscommunication. These models provide a structure for parallel teams to effectively maximize their output and minimize the potential for bugs and delays. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <h3>Unified Communication Services </h3>
                                <p>At ZOG Global, we create, service, and facilitate contact centers to yield impactful business transformation. We strive to bring added value to our clients, partners, and market-leading retailers. </p>
                                <button className="btn btn-primary btn-transparent">
                                    Learn more
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                                <div className="row gapless-box-container g-0">
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Ease of Use </h4>
                                            <p>UCaaS platforms provide businesses with a comprehensive toolkit for communication, giving both tech-savvy and traditional users access to powerful resources. With this single unified platform, there's no need to open multiple applications. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box">
                                            <h4>Cost-Effective  </h4>
                                            <p>Cloud communication services streamline business operations using a subscription-based payment system with predictable monthly charges. This eliminates the need for costly hardware investments, resulting in a more cost-effective solution with minimal upfront investment. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    :

                    <div className="row">
                        <div className="col-12 tab-contents-container">
                            <div class="accordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" >
                                        <button class=" tab-btn" type="button" onClick={(e) => toggleTab(0)}>

                                            Automation
                                        </button>
                                    </h2>


                                    <div className="tab-content">
                                        <h3>Automation Services </h3>
                                        <p>We combine deep industry and process knowledge with experts from our global delivery centres to simplify and accelerate project value with a wide range of industry-ready automation tools.</p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn More
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Drive End-to-End Process Outcomes</h4>
                                                    <p>Automate your processes to achieve successful end-to-end results. Through the transformation and automation of end-to-end processes, ZOG Global's approach to automation uses a broad range of automation, AI, and low-code process management technologies.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Reaching Strategic Objectives </h4>
                                                    <p>Organizations can reimagine complex processes by automating their operational models with ZOG Global's assistance. The aim? To assist our clients in transforming their processes, making sense of disparate data sets, and achieving ever-more-ambitious strategic goals.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(1)}>

                                            DevOps
                                        </button>
                                    </h2>


                                    <div className="tab-content">
                                        <h3>DevOps Services </h3>
                                        <p>We are one of the top DevOps vendors when it comes to maximising cloud implementations and cutting expenses while enhancing the security, scalability, and dependability of the applications. </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Reduces Time to Market  </h4>
                                                    <p>Enhance the amount and speed of releases to improvise and improve your product more quickly. The software release process is automated through continuous integration and continuous delivery techniques. </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Efficient & Easy Scalable  </h4>
                                                    <p>Make your infrastructure and development processes more efficient and scalable. With its spotless consistency, DevOps Automation assists in the practical and risk-free management of complicated or progressing systems. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(2)}>

                                            DevSecOps
                                        </button>
                                    </h2>

                                    <div className="tab-content">
                                        <h3>DevSecOps Services   </h3>
                                        <p>DevSecOps as a service is helpful during the elaboration process of a business. This strategy makes prioritizing security, analysing business processes, and monitoring operations continuously possible.  </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Improve Workflows  </h4>
                                                    <p>Organisations focusing on rapid software development can look to DevSecOps to improve their workflows. Its automated checks are essential for keeping up velocity while they identify and fix bugs quickly. Having these checks in place also helps maintain the speed of software updates. </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Early Detection of Vulnerabilities  </h4>
                                                    <p>Static and dynamic application security checks offer a quick and cost-effective way to fix any vulnerabilities. These tools provide automated testing and reporting when embedded into the delivery process. As an additional option, businesses can create isolated validation processes for extra protection and monitoring. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(3)}>

                                            Advance Networking Services
                                        </button>
                                    </h2>

                                    <div className="tab-content">
                                        <h3>Advance Networking Services  </h3>
                                        <p>ZOG Global provides innovative networking infrastructure and technology solutions to small, medium, and large organizations. Our services help you establish an optimized network and an effective data management system. </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Centralised Network Processing </h4>
                                                    <p>A centralised controller handles all decision-making in the Software-Defined Networking (SDN) architecture, providing simpler network element configurations and quicker service delivery. This centralised control benefits virtual and physical devices, which can be managed more efficiently in one location. </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Improved Network Performance  </h4>
                                                    <p>Software-Defined Networking (SDN) enables network administrators to efficiently shape and control data traffic. This technology supports Quality of Service (QoS), providing improved responsiveness and a superior user experience with voice and multimedia applications. SDN helps maximize reliable, high-level communication performance. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(4)}>

                                            Cloud
                                        </button>
                                    </h2>

                                    <div className="tab-content">
                                        <h3>Cloud Services </h3>
                                        <p>ZOG Global provides cutting-edge cloud computing services across Google Cloud Platform, Microsoft Azure, and Amazon Web Services. Our team of expert professionals understands the complexity of cloud solutions and has the expertise to create reliable models to meet the varying needs of all organizations. </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Securing Confidential Data </h4>
                                                    <p>Cloud-based platforms can provide a high level of security by proactively restricting access to sensitive data. Through granular permissions and federated roles, only those employees with the relevant authorities can access confidential information, limiting the potential attack vector for cybercriminals. </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="box">
                                                    <h4>Prevent Data Loss </h4>
                                                    <p>With cloud computing, even if on-site equipment breaks down, your data is always accessible. Additionally, cloud-based services provide reliable business continuity support for natural disasters or power outages. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(5)}>

                                        Cybersecurity
                                        </button>
                                    </h2>

                                    <div className="tab-content">
                                        <h3>Cybersecurity Services  </h3>
                                        <p>Businesses are constantly exposed to cyberattacks. To protect your networks from internal and external threats, you need to have strong digital asset protection measures in place. </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>Protects Business Reputation  </h4>
                                                    <p>The success of a business depends on retaining customers. Data breaches can damage the reputation and make it harder to keep customers. Organisations need to focus on cybersecurity to build trust and keep customers. </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>Enhanced Data Management </h4>
                                                    <p>Data is the key to successful products and marketing strategies. Losing data at the last minute can force companies to start from scratch. Therefore, it is essential for organisations to constantly monitor their data to ensure that security measures are in place. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(6)}>

                                            Software Development
                                        </button>
                                    </h2>

                                    <div className="tab-content">
                                        <h3>Software Development Services  </h3>
                                        <p>At ZOG Global, we are highly experienced in providing full-cycle software development services tailored to the specifics of your project and business objectives. </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>360° Service Model  </h4>
                                                    <p>Our 360° framework is a comprehensive approach to developing, integrating, and managing enterprise applications, offering everything from planning and implementation to post-launch support. </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>Time-to-Market  </h4>
                                                    <p>By leveraging high-level expertise and a variety of solutions accelerators, customers can enjoy rapid product rollout, fast customizations, and smooth delivery. These benefits result in reduced development costs and more timely market entry. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(7)}>

                                            Testing
                                        </button>
                                    </h2>

                                    <div className="tab-content">
                                        <h3>Testing Services </h3>
                                        <p>We offer high-quality assurance services and testing skills tailored to your industry and environment. This intimate understanding of your domain helps us create highly targeted solutions. </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>Increase Efficiency  </h4>
                                                    <p>We strive to create reusable assets from all artifacts produced and reduce the time spent creating scripts, test cases, and other user stories by automating them. </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>Collaborate Effortlessly  </h4>
                                                    <p>Team collaboration models help ensure that parallel teams can work together without encountering difficulty due to miscommunication. These models provide a structure for parallel teams to effectively maximize their output and minimize the potential for bugs and delays. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="tab-btn" type="button" onClick={(e) => toggleTab(8)}>

                                            Unified Communication
                                        </button>
                                    </h2>
                                    <div className="tab-content">
                                        <h3>Unified Communication Services </h3>
                                        <p>At ZOG Global, we create, service, and facilitate contact centers to yield impactful business transformation. We strive to bring added value to our clients, partners, and market-leading retailers. </p>
                                        <button className="btn btn-primary btn-transparent">
                                            Learn more
                                            <span>
                                                <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                            </span>
                                        </button>
                                        <div className="row gapless-box-container g-0">
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>Ease of Use </h4>
                                                    <p>UCaaS platforms provide businesses with a comprehensive toolkit for communication, giving both tech-savvy and traditional users access to powerful resources. With this single unified platform, there's no need to open multiple applications. </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="box">
                                                    <h4>Cost-Effective  </h4>
                                                    <p>Cloud communication services streamline business operations using a subscription-based payment system with predictable monthly charges. This eliminates the need for costly hardware investments, resulting in a more cost-effective solution with minimal upfront investment. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>}
            </CommonSection>

            <CommonSection className="container managed-it-services-sec-3">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Empower Your Business with ZOG Global’s Managed IT Support Services  </h3>
                                <p>We collaborate with you to develop a thorough grasp of your technical challenges and business imperatives as an extension of your team. You will benefit by having a single point of contact for various vendors, product lines, and solutions. </p>

                                <a href="/contact"> <button className="btn btn-transparent">
                                    Get in Touch Now
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="12" viewBox="0 0 38 12" fill="none">
                                            <path d="M1 5.41699C0.585786 5.41699 0.25 5.75278 0.25 6.16699C0.25 6.58121 0.585786 6.91699 1 6.91699V5.41699ZM37.5303 6.69732C37.8232 6.40443 37.8232 5.92956 37.5303 5.63666L32.7574 0.863691C32.4645 0.570798 31.9896 0.570798 31.6967 0.863691C31.4038 1.15658 31.4038 1.63146 31.6967 1.92435L35.9393 6.16699L31.6967 10.4096C31.4038 10.7025 31.4038 11.1774 31.6967 11.4703C31.9896 11.7632 32.4645 11.7632 32.7574 11.4703L37.5303 6.69732ZM1 6.91699H37V5.41699H1V6.91699Z" fill="#F3F1F1" />
                                        </svg>
                                    </span>
                                </button></a>
                            </div>
                            <div className="col-lg-6"></div>
                        </div>

                    </div>

                </div>

            </CommonSection>

            <CommonSection className="container managed-it-services-sec-4">
                <div className="row">
                    <div className="col-lg-6 responsive-col-gap">
                        <h3>Blog </h3>
                    </div>
                </div>
                <div className="non-breaking-mob-container">
                    <div className="row">
                        <div className="col-4 responsive-col-gap">
                            <div className="box">


                                <h4>Lörem ipsum förväntis </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things.</p>
                                <button className="btn btn-borderless">Read More ></button>
                            </div>
                        </div>

                        <div className="col-4 responsive-col-gap">
                            <div className="box">

                                <h4>Lörem ipsum förväntis </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things.</p>
                                <button className="btn btn-borderless">Read More ></button>
                            </div>
                        </div>

                        <div className="col-4 responsive-col-gap">
                            <div className="box">

                                <h4>Lörem ipsum förväntis </h4>
                                <p>Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things cirkulär ekonomi kluster pror pore så prekror, cirkulär ekonomi kluster pror pore så prekror.Lörem ipsum lysm internet of things.</p>
                                <button className="btn btn-borderless">Read More ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSection>




        </>
    )
}

export default ManagedITServices