import React from "react";
import Banner from "../common-componets/Banner";
import CommonSection from "../common-componets/CommonSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import $ from 'jquery';
import Slider from "react-slick";
const QualityAssurance = () => {




    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>

            <Banner
                bgUrl="/images/quality-assurance/application-security-testing/banner.png"
                theme="dark"
                txtAlign="center"
                paddingProp={{ top: "8.6042", bottom: "8" }}
                heading={window.innerWidth > 992 ?
                    "Application Security Testing "
                    :
                    "Application<br/>Security Testing "}
                content={
                    "Get access to specialised resources and expertise for faster, more efficient application security testing. Take advantage of the on-demand model to ensure that your applications are protected against emerging threats and security vulnerabilities. "
                }

                mobileVerticalAlignment="flex-end"
                mobileTextAlign="left"
                customClassName={"app-sec-testing-banner"}
            >

            </Banner>

            <CommonSection className="container ">
                <div className="row align-items-center g-5">
                    <div className="col-lg-6 responsive-col-gap pe-lg-5">
                        {window.innerWidth > 992 ? <img className="w-100" src="/images/quality-assurance/automation-testing/sec-1.png"></img>
                            : <img className="w-100" src="/images/quality-assurance/automation-testing/sec-1-tab.png"></img>
                        }
                    </div>
                    <div className="col-lg-6 ps-lg-5">
                        <h3> Get Ahead of a Breach:<br/>Secure Your Digital Assets</h3>

                        <p>With so many high-profile cyber breaches making headlines, it's of the utmost importance that measures are taken to ensure data integrity and confidentiality. One way to do this is by ensuring that all applications are secure, which can be done with the help of application security testing.
                            <br></br><br></br>
                            By testing for vulnerabilities, businesses can identify and fix potential weak points before attackers have a chance to exploit them. This can help organisations get ahead of a potential breach and avoid the costly damages that can result.
                            <br></br><br></br>
                            ZOG Global's dynamic application security testing team provides expert security penetration testing services for your most important applications. Our specialists use a mix of cybersecurity testing tools, customised pen testing setups, and professional ethical hacking techniques to uncover areas of vulnerability in apps and prioritise the most critical security issues. </p>

                    </div>
                </div>
            </CommonSection>

            <CommonSection className="container app-sec-testing-sec-2">
                <div className="row">
                    <div className="col-lg-12">
                       {window.innerWidth>992?
                        <h3 className="text-center">Application Security Services We Offer </h3>:
                        <h3 className="text-left">Application Security<br/>Services We Offer </h3>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box">
                                    <img src="/images/quality-assurance/application-security-testing/sec-2.svg"></img>
                                    <h4>Static Application<br />Security Testing (SAST)</h4>
                                    <p>SAST is a type of security testing performed on source code. This type of testing can detect vulnerabilities such as injection flaws and buffer overflows. </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box">
                                    <img src="/images/quality-assurance/application-security-testing/sec-2.svg"></img>
                                    <h4>Dynamic Application<br />Security Testing (DAST) </h4>
                                    <p>DAST uses automated tools to analyse the application during runtime. This type of testing can detect vulnerabilities such as cross-site scripting (XSS) and SQL injection. </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="box">
                                    <img src="/images/quality-assurance/application-security-testing/sec-2.svg"></img>
                                    <h4>Interactive Application<br />Security Testing (IAST) </h4>
                                    <p>IAST combines SAST and DAST testing methods to provide precise and efficient detection of security vulnerabilities. It involves running the application in a simulated environment and analysing its behaviour. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <div className="box">
                            <img src="/images/quality-assurance/application-security-testing/sec-2.svg"></img>
                            <h4>Penetration<br />Testing </h4>
                            <p>Penetration testing is a valuable tool for organisations to use to assess their security posture. By simulating an attack, organisations can identify weaknesses in their systems and applications that an attacker could exploit. Additionally, penetration testing can provide insights into how an attacker may exploit these vulnerabilities. </p>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="box">
                            <img src="/images/quality-assurance/application-security-testing/sec-2.svg"></img>
                            <h4>Fuzz<br />Testing</h4>
                            <p>Fuzz testing involves feeding random data into an application to detect any faults that may cause the application to crash or malfunction. This testing method can help to find vulnerabilities such as buffer overflows. </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box">
                            <img src="/images/quality-assurance/application-security-testing/sec-2.svg"></img>
                            <h4>Mobile Application<br />Security Testing </h4>
                            <p>This type of testing focuses specifically on the security of a mobile application. It involves analysing the application’s code and runtime behaviour to identify security weaknesses such as data leakage and unauthorised access. </p>
                        </div>
                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container app-sec-testing-sec-3">
                <div className="row">
                    <div className="col-lg-6 mb-5">
                        {window.innerWidth>992?
                        <h3 >Application Security Testing Benefits</h3>
 :<h3 >Application Security<br/>Testing Benefits</h3>
                        }
                        <p>It's important to understand the benefits of application security testing in order to make informed decisions about how to secure your applications. </p>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Enhance Digital Trust </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Reduce Time to Market </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Accelerate GDPR Compliance </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Continuous Security Testing </p>
                        </div>
                    </div>

                
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Improve Test Coverage </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Enhance Software Quality</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Reduce Attack Surface </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="box">
                            <p>Reduce Cost of Testing </p>
                        </div>
                    </div>

                </div>
            </CommonSection>

            <CommonSection className="container soft-dev-sec-2">
                <div className="row">
                    <div className="col-lg-6">
                        <h3>Related Insights </h3>
                    </div>

                </div>

                <div className="row g-5">
                    <div className="col-lg-6 pe-lg-5">

                        <img src="/images/quality-assurance/application-security-testing/sec-4-1.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                    <div className="col-lg-6 ps-lg-5">
                        <img src="/images/quality-assurance/application-security-testing/sec-4-2.png"></img>
                        <h4>Libero diam amet pellentesque.</h4>
                        <p>Tortor non, auctor semper mauris iaculis integer sit. Lacus, porta vitae nunc orci tincidunt nunc mattis placerat maecenas. Convallis tincidunt id venenatis ultrices. Varius amet rhoncus ac ultricies. Maecenas vel aenean convallis aliquam, nibh sit. Amet ut tellus faucibus elementum velit volutpat.
                            Tortor id in pellentesque sagittis, mi, laoreet duis. Lacinia viverra nunc pellentesque quam ligula eu ornare suspendisse. Sed nunc nunc sit quam. Eget sed ornare metus, pellentesque dui quisque tellus. Pulvinar nibh ac eu quam feugiat aliquet mattis lectus sagittis. Metus tellus mi congue ac.</p>
                    </div>

                </div>
            </CommonSection>



            <CommonSection className="container-fluid tyb-sec-6">

                <div className="mask ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ">

{window.innerWidth>992?
                                <h3>Secure Your Application<br/> From The Start  </h3>

:
<h3>Secure Your Application<br/>From The Start  </h3>
}                                <p>Let's discuss how we can quickly move you along on the path towards strengthening your defences. </p>
                                <button className="btn btn-transparent">
                                    Talk to Us
                                    <span>
                                        <img src="/images/btn-right-arrow-black-long-tailed.svg"></img>
                                    </span>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </CommonSection>
        </>
    )
}

export default QualityAssurance